import ConnectMediaPage from './connectMedia.component';
import { connect } from 'react-redux';
import { addProfile, updateProfile, deleteProfile, getFBToken } from './../../api/profile/profileAction';
import { fetchCurrentUser, getAccount, googleAccount, googleLocation, getLinkedinPage } from './../../api/user/userAction';

const mapDispatchToProps = {
    addProfile,
    fetchCurrentUser,
    updateProfile,
    deleteProfile,
    getAccount,
    getFBToken,
    googleAccount,
    googleLocation,
    getLinkedinPage
}

const mapStateToProps = state => ({
    user: state.userPage.loggedUser,
    isLoading: state.profilePage.isLoading,
    isSaved: state.profilePage.isSaved,
    isError: state.profilePage.isError,
    profiles: state.profilePage.profiles,
    selectedAccount: state.userPage.selectedAccount,
    fbToken: state.profilePage.fbToken,
    gmbAccounts: state.userPage.gmbAccounts,
    gmbLocations: state.userPage.gmbLocations,
    linkedinPages: state.userPage.linkedinPages,
});

export const ConnectMediaPageContainer = connect(mapStateToProps, mapDispatchToProps)(ConnectMediaPage);