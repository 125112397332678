// import { useState } from "react";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import MDBox from "./../../shared/components/MDBox";
// import MDAvatar from "./../../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
// import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import ProfileImages from "./../../shared/components/profile-images.component";
import MDButton from "./../../shared/components/MDButton";
// import smassign from "./../../../assets/images/smassign.png";
// import facebookk from "./../../../assets/images/facebookk.png";
// import AddIcon from "@mui/icons-material/Add";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import Switch from "@mui/material/Switch";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
// import { Link } from "react-router-dom";
// // import { FacebookProvider,Page } from 'react-facebook';
// import { LinkedIn } from "react-linkedin-login-oauth2";
// import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
// import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export default function ImgLibraryPage() {
  return (
    <Grid container style={{ textAlign: "center", display: "grid" }}>
      <MDTypography
        style={{
          color: "#09032B",
          fontSize: "36px",
          fontWeight: "700",
          marginTop: "71px",
        }}
      >
        Select Your Image Gallery
      </MDTypography>
      <Grid
        item
        style={{
          backgroundColor: "white",
          margin: "37px auto",
          borderRadius: "12px",
          marginTop: "32px",
          height: "auto",
        }}
        sx={{
          "@media (min-width: 320px)": {
            width: "320px",
            // height: "1015px",
          },
          "@media (min-width: 576px)": {
            width: "528px",
            // height: "805px",
          },
          "@media (min-width: 991px)": {
            width: "817px",
            // height: "1071px",
          },
        }}
      >
        <MDBox
          style={{
            display: "grid",
            textAlign: "left",
            padding: "32px 12px",
          }}
        >
          <MDTypography
            style={{ color: "#344767", fontSize: "20px", fontWeight: "700" }}
          >
            See and save our vast image library
          </MDTypography>
          <MDTypography
            style={{ color: "#7B809A", fontSize: "14px", fontWeight: "400" }}
          >
            Here you can setup and manage your integration settings.
          </MDTypography>
          <MDBox sx={{ marginTop: "30px" }}>
            <TextField
              fullWidth
              label=" &nbsp;&nbsp;&nbsp;&nbsp;Search by type of topics"
              id="fullWidth"
            />
          </MDBox>
          <Grid container spacing={3} style={{ marginTop: "15px" }}>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "15px" }}>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "15px" }}>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <ProfileImages />
              <Grid item>
                <MDBox style={{ display: "flex", marginTop: "12px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    Name of the image set
                  </MDTypography>
                  <MDBox sx={{ ml: "auto" }}>
                    <BookTwoToneIcon style={{ color: "#7B809A" }} />
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
          </Grid>
          <MDBox style={{ marginTop: "15px" }}>
            <MDButton
              component={Link}
              to="/design"
              variant="contained"
              style={{
                background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                color: "#fff",
                minWidth: "295px",
              }}
            >
              Save and continue
            </MDButton>
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}
