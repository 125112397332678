export const SUBSCRIBE = "SUBSCRIBE";
export const SUBSCRIBE_FAILURE = "SUBSCRIBE_FAILURE";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";

export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_FAILURE = "CREATE_SESSION_FAILURE";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";

export const FETCH_SUBSCRIPTION = "FETCH_SUBSCRIPTION";
export const FETCH_SUBSCRIPTION_FAILURE = "FETCH_SUBSCRIPTION_FAILURE";
export const FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS";

export const FETCH_PACKAGES = "FETCH_PACKAGES";
export const FETCH_PACKAGES_FAILURE = "FETCH_PACKAGES_FAILURE";
export const FETCH_PACKAGES_SUCCESS = "FETCH_PACKAGES_SUCCESS";

export const FETCH_USER_SUBSCRIPTION = "FETCH_USER_SUBSCRIPTION";
export const FETCH_USER_SUBSCRIPTION_FAILURE = "FETCH_USER_SUBSCRIPTION_FAILURE";
export const FETCH_USER_SUBSCRIPTION_SUCCESS = "FETCH_USER_SUBSCRIPTION_SUCCESS";

export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION";
export const CANCEL_SUBSCRIPTION_FAILURE = "CANCEL_SUBSCRIPTION_FAILURE";
export const CANCEL_SUBSCRIPTION_SUCCESS = "CANCEL_SUBSCRIPTION_SUCCESS";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICES_FAILURE = "FETCH_INVOICES_FAILURE";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";

export const FETCH_PAYMENT_METHODS = "FETCH_PAYMENT_METHODS";
export const FETCH_PAYMENT_METHODS_FAILURE = "FETCH_PAYMENT_METHODS_FAILURE";
export const FETCH_PAYMENT_METHODS_SUCCESS = "FETCH_PAYMENT_METHODS_SUCCESS";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD";
export const UPDATE_PAYMENT_METHOD_FAILURE = "UPDATE_PAYMENT_METHOD_FAILURE";
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS";

export const DETACH_PAYMENT_METHOD = "DETACH_PAYMENT_METHOD";
export const DETACH_PAYMENT_METHOD_FAILURE = "DETACH_PAYMENT_METHOD_FAILURE";
export const DETACH_PAYMENT_METHOD_SUCCESS = "DETACH_PAYMENT_METHOD_SUCCESS";