import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import MDButton from "./../../shared/components/MDButton";
import MDBox from "./../../shared/components/MDBox";
import logo from "./../../assets/images/logo-ct-dark.png";
import Footer from "./../../shared/components/Footer";
import FormField from "./../../shared/components/FormField";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import googleImage from "./../../assets/images/googleLogo.png";
import { makeStyles } from '@material-ui/styles';
import Loading from './../../shared/Loading';

const googleBtn = {
  backgroundColor: "#F1F1F1", 
  borderRadius: "8px", 
  color: "#1B172E", 
  fontWeight: "700", 
  fontSize: "14px",
  textTransform: "inherit",
}
const button = {
  backgroundColor: "#09032B", 
  color: "#F0FDF4", 
  fontWeight: "500", 
  fontSize: "16px", 
  textTransform: "capitalize"
}

export default function SignupPageI(props) {
  // const classes = useStyles();
  const navigate = useNavigate();
  const { token } = useParams();
  const { user, isLoggedIn, isLoading, jwtDetails, getJWTDetails, inviteUser } = props;
  const mdText1 = {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  };
  const mdText2 = {
    color: "red",
    fontSize: "12px",
    fontWeight: 400,
  };
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    retypePassword: "",
    socialLogin: 0,
    emailVerified: 1
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandle = (event) => {
    event.preventDefault();
    setErrors(validate(values));
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;

    if(!values.firstName && !values.lastName && !values.email && !values.password && !values.retypePassword) {
      isError = true;
      errorss.firstName = "First name is required";
      errorss.lastName = "Last name is required";
      errorss.email = "Email is required";
      errorss.password = "Password is required";
      errorss.retypePassword = "Retype password is required";
    } else if (!values.firstName) {
      isError = true;
      errorss.firstName = "First name is required";
    } else if (!values.lastName) {
      isError = true;
      errorss.lastName = "Last name is required";
    } else if (!values.email) {
      isError = true;
      errorss.email = "Email is required";
    } else if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    } else if (!regex.test(values.email)) {
      isError = true;
      errorss.email = "This is not a valid email format";
    }
    if (!isError) {
      inviteUser({ ...values, accountId: jwtDetails.AccountId, type: jwtDetails.firstName == "" ? 1 : 2 });
    }
    return errorss;
  };

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const x = 0;
  const y = -11;
  const ortext = {
    transform: `translate(${x}px, ${y}px)`,
    backgroundColor: "white",
    width: "33px",
    margin: "0px auto",
    borderRadius: "25px",
    color: "#344767",
    fontSize: "14px",
    fontWeight: "400"
  };
  const orborder = {
    borderTop: "1px dashed #E4E4E4",
    textAlign: "center",
    marginTop: "24px",
  };

  useEffect(() => {
    if(token) {
      getJWTDetails(token);
    }
  }, [token]);

  useEffect(() => {
    if(jwtDetails.id) {
      console.log("JWT DETAILS: ", jwtDetails);
        setValues({
          ...values,
          email: jwtDetails.email,
          status: 1,
          firstName: jwtDetails.firstName,
          lastName: jwtDetails.lastName
        })
    } else {
      // window.location.href = `${process.env.REACT_APP_BASE_URL}/#/login`;
    }
  }, [jwtDetails]);

  if(isLoggedIn && jwtDetails.id) {
    inviteUser({ accountId: jwtDetails.AccountId, type: 3, email: jwtDetails.email });
    window.location.href = `${process.env.REACT_APP_BASE_URL}/#/dashboard`;
  }

  return (
    <>
      <Loading loading={isLoading} />
      <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
        <Grid
          item
          style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
        >
          <MDAvatar
            src={logo}
            variant="square"
            style={{ width: "13px", height: "22px", marginTop: "5px" }}
          />
          &nbsp;&nbsp;
          <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <MDBox
            style={{
              backgroundColor: "white",
              width: "441px",
              height: "auto",
              padding: "0px 25px",
              borderRadius: "12px",
              marginTop: "21px",
              marginBottom: "21px",
            }}
          >
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "25px",
                  fontWeight: "700",
                  marginTop: "20px",
                }}
              >
                Create your account
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "5px",
                }}
              >
                Let’s get social
              </MDTypography>
            </MDBox>
            <form>
              <MDBox mb={2} style={{ marginTop: "15px" }} sx={jwtDetails.firstName == "" ? { display: "none" } : { }}>
                <FormField
                  label="First name"
                  placeholder="John"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                />
                {errors.firstName && (
                <MDTypography style={mdText1}>{errors.firstName}</MDTypography>
              )}
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "15px" }} sx={jwtDetails.firstName == "" ? { display: "none" } : { }}>
                <FormField
                  label="Last name"
                  placeholder="Doe"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                />
                {errors.lastName && (
                <MDTypography style={mdText1}>{errors.lastName}</MDTypography>
              )}
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "23px" }}>
                <FormField
                  label="Email"
                  placeholder="john@email.com"
                  name="email"
                  value={values.email}
                  // onChange={handleChange}
                />
                {errors.email && (
                  <MDTypography style={mdText1}>{errors.email}</MDTypography>
                )}
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "23px" }}>
                <FormField
                  label="Password"
                  type="password"
                  placeholder="****************"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                />
                {errors.password && (
                <MDTypography style={mdText2}>{errors.password}</MDTypography>
              )}
              </MDBox>
              <MDBox sx={{my: "24px"}}>
                <MDButton style={button}
                  onClick={submitHandle}
                  // component={Link}
                  // to="/verify-email"
                  size="large"
                  fullWidth
                  type="submit"
                >
                  Accept 
                </MDButton>
              </MDBox>
            </form>
            {/* <MDBox style={orborder}>
              <MDTypography style={ortext}>OR</MDTypography>
            </MDBox>
            <MDBox style={{ display: "flex" }}>
            <Button style={googleBtn} fullWidth
              onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/user/google-register-link`}>
              <img src={googleImage} alt="Lock logo" style={{marginRight: "10px"}} />
              Signin with Google
            </Button>
            </MDBox> */}
          </MDBox>
        </Grid>
        <Grid item style={{ marginTop: "2%" }}>
          <Footer />
        </Grid>
        <ToastContainer style={{ fontSize: "14px", width: "auto" }} />
      </Grid>
    </>
  );
}
