import PropTypes from "prop-types";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// Material Dashboard 2 PRO React components
import MDBox from "./../../../../../shared/components/MDBox";

function Header({ tabValue, tabHandler, children }) {
  return (
    <>
      <Grid container style={{marginTop: "31px", justifyContent: "center"}}>
        <Grid item xs={12} md={4}>
          <MDBox mx="auto">
            <AppBar position="static">
              <Tabs value={tabValue} onChange={tabHandler}>
                <Tab
                  id="monthly"
                  label={
                    <MDBox py={0.5} px={2} color="inherit">
                      Monthly
                    </MDBox>
                  }
                />
                <Tab
                  id="annual"
                  label={
                    <MDBox py={0.5} px={2} color="inherit">
                      Annual
                    </MDBox>
                  }
                />
              </Tabs>
            </AppBar>
          </MDBox>
        </Grid>
      </Grid>
      {children}
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Header;
