import * as actionTypes from "./themeActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const uploadBG = (data) => {
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
    return (dispatch) => {
        dispatch(uploadBGInit());
        axios.post(`${baseUrl}/theme/upload-image`, params)
        .then((response)=>{
            dispatch(uploadBGSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(uploadBGFailure());
        })
    };
};

export const uploadBGInit = () => ({
    type: actionTypes.UPLOAD_BG
});

export const uploadBGFailure = data => ({
    type: actionTypes.UPLOAD_BG_FAILURE
});

export const uploadBGSuccess = data => ({
    type: actionTypes.UPLOAD_BG_SUCCESS,
    payload: { data }
});

export const fetchLayouts = (id) => {
    return (dispatch) => {
        dispatch(fetchLayoutsInit());
        axios.get(`${baseUrl}/layout/theme/${id}`)
        .then((response)=>{
            dispatch(fetchLayoutsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchLayoutsFailure());
        })
    };
};

export const fetchLayoutsInit = () => ({
    type: actionTypes.FETCH_LAYOUTS
});

export const fetchLayoutsFailure = data => ({
    type: actionTypes.FETCH_LAYOUTS_FAILURE
});

export const fetchLayoutsSuccess = data => ({
    type: actionTypes.FETCH_LAYOUTS_SUCCESS,
    payload: { data }
});

export const fetchThemes = () => {
    return (dispatch) => {
        dispatch(fetchThemesInit());
        axios.get(`${baseUrl}/theme`)
        .then((response)=>{
            dispatch(fetchThemesSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchThemesFailure());
        })
    };
};

export const fetchThemesInit = () => ({
    type: actionTypes.FETCH_THEMES
});

export const fetchThemesFailure = data => ({
    type: actionTypes.FETCH_THEMES_FAILURE
});

export const fetchThemesSuccess = data => ({
    type: actionTypes.FETCH_THEMES_SUCCESS,
    payload: { data }
});