import { toast } from 'react-toastify';

export function checkError(err) {
    console.log("err: ", err.response.status);
    if (err.response.status === 401) {
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("selectedAccount");
        window.location.reload();
    }
    toast.error(err.response.data.message, {
        position: toast.POSITION.TOP_RIGHT
    });
}