import { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import MDButton from "../../shared/components/MDButton";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Divider from "@mui/material/Divider";
import { useMaterialUIController } from "./../../context";
import MDAvatar from "../../shared/components/MDAvatar";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import TeamMatesDialog from "./component/team-mates-dialog";
import { preetyDate } from "./../../helper";
import { IconButton } from "@mui/material";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { DeleteConfirm } from "./component/delete-confirm";

const standerdplan = {
  backgroundColor: "#4CAE67",
  borderRadius: "12.5px",
  color: "#FFFFFF",
  width: "122px",
  height: "25px",
  paddingTop: "3px",
  fontSize: "12px",
  marginTop: "10px",
  textAlign: "center",
};

export function TeamPage(props) {
  const { teams, isLoading, fetchTeam, selectedAccount, sendInvite, deleteTeam, teamDeleted, user } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({ isTrue: false, type: "", text: "" });
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [selected, setSelected] = useState({ 
    accountName: selectedAccount.name,
    accountId: selectedAccount.id,
    emailId: "",
    role: ""
  });
  
  useEffect(() => {
    fetchTeam(selectedAccount.id);
  }, []);

  useEffect(() => {
    if(teamDeleted){
      fetchTeam(selectedAccount.id);
      setSelected({ 
        accountName: selectedAccount.name,
        accountId: selectedAccount.id,
        emailId: "",
        role: ""
      });
      setDeleteOpen(false);
    }
  }, [teamDeleted]);

  const allowedUsers = selectedAccount && selectedAccount.Subsciption && selectedAccount.Subsciption.userAccess;
  const existingUsers = user.accounts && user.accounts.length;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteConfirm
        deleteTeam={deleteTeam}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected({ 
            accountName: selectedAccount.name,
            accountId: selectedAccount.id,
            emailId: "",
            role: ""
          });
          setDeleteOpen(false);
        }}
      />
      <TeamMatesDialog
        open={open}
        onClose={() => {
          setSelected({
            accountName: selectedAccount.name,
            accountId: selectedAccount.id,
            emailId: "",
            role: ""
          });
          setError({ isTrue: false, type: "", text: "" });
          setOpen(false);
        }}
        sendInvite={sendInvite}
        selected={selected}
        setSelected={setSelected}
        error={error}
        setError={setError}
      />
      <MDBox pt={6} pb={3} style={{ overflow: "auto" }}>
        <MDBox mb={3}>
          <Card
            sx={{
              background: "#FFFFFF",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
              borderRadius: "12px",
              width: "972px",
            }}
          >
            <MDBox style={{ display: "flex", padding: "23px 20px" }}>
              <MDBox>
                <MDTypography
                  style={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#344767",
                  }}
                >
                  Team Members
                </MDTypography>
                <MDTypography
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7B809A",
                  }}
                >
                  View and manage who has access to this workspace.
                </MDTypography>
              </MDBox>
              <MDBox sx={{ ml: "auto" }}>
                {allowedUsers ===  existingUsers ? (
                  <Tooltip title="Upgrade subscription to add more team members" arrow>
                    <MDTypography
                      style={{
                        fontSize: "12px",
                        backgroundColor: "#FFFFFF",
                        color: "#09032B",
                        textTransform: "capitalize",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                        borderColor: "#09032B",
                        padding: "5px 8px",
                        borderRadius: "4px",
                        opacity: 0.4
                      }}
                      size="small"
                    >
                    <AddIcon sx={{ color: "#09032B" }} />
                      Add New
                    </MDTypography>
                  </Tooltip>
                ) : (
                  <MDButton
                    onClick={() => setOpen(true)}
                    variant="outlined"
                    style={{
                      backgroundColor: "#FFFFFF",
                      color: "#09032B",
                      textTransform: "capitalize",
                      boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                      borderColor: "#09032B",
                    }}
                    size="small"
                  >
                    <AddIcon sx={{ color: "#09032B" }} />
                      Add New
                    </MDButton>
                )}
              </MDBox>
            </MDBox>
            <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
            {teams && Array.isArray(teams) && teams.map(x => {
              return (
                <>
                  <Grid container style={{ padding: "8px 20px" }}>
                    <Grid item xs={12} md={6}>
                      <MDBox display="flex" alignItems="center">
                        <MDAvatar src={x.dp} alt="User DP" />
                        <MDBox ml={2}>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "17px",
                              color: "#344767",
                            }}
                          >
                            {x.firstName} {x.lastName}
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "500",
                              fontSize: "12px",
                              color: "#F4233D",
                              marginTop: "3px",
                            }}
                          >
                            {x.accounts && Array.isArray(x.accounts) && x.accounts[0].Account_User.RoleId == 1 ? "OWNER" : "Member"}
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "14px",
                              color: "#344767",
                              marginTop: "6px",
                            }}
                          >
                            Last Login:{" "}
                            <span
                              style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#7B809A",
                              }}
                            >
                              {preetyDate(x.lastLogin)}
                            </span>
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDBox
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <MDBox>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "17px",
                              color: "#344767",
                            }}
                          >
                            0
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#AEAEAE",
                            }}
                          >
                            Posts this month
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          <MDTypography
                            style={{
                              fontWeight: "700",
                              fontSize: "17px",
                              color: "#344767",
                            }}
                          >
                            0
                          </MDTypography>
                          <MDTypography
                            style={{
                              fontWeight: "400",
                              fontSize: "12px",
                              color: "#AEAEAE",
                            }}
                          >
                            Total posts
                          </MDTypography>
                        </MDBox>
                        <MDBox>
                          {x.accounts && Array.isArray(x.accounts) && x.accounts[0].Account_User.RoleId != 1 && selectedAccount && selectedAccount.Account_User && selectedAccount.Account_User.RoleId == 1 && (
                            <IconButton
                              onClick={() => {
                                setSelected(selectedAccount.Account_User);
                                setDeleteOpen(true);
                              }}>
                              <DeleteForeverOutlinedIcon sx={{color: "red"}} />
                            </IconButton>
                          )}
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Divider style={{ background: "#E9EAED", opacity: "0.7" }} />
                </>
              )
            })} 
          </Card>
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}
