import React from "react";

// mui
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import { Stack } from "@mui/material";

// md
import MDBox from "./../../../../shared/components/MDBox";
import MDTypography from "./../../../../shared/components/MDTypography";

// img
import homeDecor1 from "./../../../../assets/images/library.png";

// icons
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

  const mdBox = {
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
    width: "auto",
  };
  const iconText = {
    color: "#000",
    width: "15px",
    height: "15px",
    margin: "2px 6px",
    cursor: "pointer"
  };
  const topText = {
    fontWeight: "400",
    fontSize: "14px",
    color: "#344767",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
  const box = {
    backgroundColor: "#F0F2F5",
    height: "28px",
    width: "28px",
    borderRadius: "6px",
  };
  const cardText = {
    width: "100%",
    margin: 0,
    boxShadow: "inset 0px 12px 40px rgba(0, 0, 0, 0.2)",
    objectFit: "cover",
    objectPosition: "center",
    height: "250px",
    borderRadius: "12px 12px 0px 0px"
  };
  
export default function GridData(props) {
  const { content, approveContent, selected, setSelected, setOpen, deleteContent, setDeleteOpen, setDynamicFields } = props;
  const checkPr = (x, type) => {
    if (Array.isArray(x)) {
      const y = x.filter(z => z.type == type);
      return y.length > 0;
    }
    return false;
  }
  return(
        
    <Grid container spacing={3}>
      {content && Array.isArray(content) && content.map(x => {
        return (
          <Grid item xs={12} md={3}>
            <MDBox style={mdBox}>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
              >
                <div style={{ position: "absolute", top: "10px", left: "10px", padding: "4px 8px", lineHeight: "100%", background: "#E4FFE6", borderRadius: "8px" }}>
                  <span style={{ fontWeight: 700, fontSize: "12px", color: "#4CAF50" }}>Social Post</span>
                </div>
                <div style={{ position: "absolute", top: "10px", right: "10px", padding: "2px",lineHeight: "100%", background: "#E4FFE6", borderRadius: "8px" }}>
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "insta") && (
                    <InstagramIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "facebook") && (
                    <FacebookRoundedIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}  
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "linkedin") && (
                    <LinkedInIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "gmb") && (
                    <StorefrontRoundedIcon
                      style={{
                        borderRadius: "4px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                </div>
                <CardMedia style={cardText} src={x.images && Array.isArray(JSON.parse(x.images)) && JSON.parse(x.images).length > 0 && JSON.parse(x.images)[0].image} component="img" />
              </MDBox>
              <MDBox style={{padding: "16px"}}>
                <MDTypography style={topText}>
                  {JSON.parse(x.content).all}
                </MDTypography>
                <MDBox style={{ display: "flex", marginTop: "23px" }}>
                  <Stack direction="row" spacing="1">
                  { x.status == 1 ? 
                    (
                      <FolderOutlinedIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#FB8C00",
                          marginRight: "4px",
                        }}
                      />
                    ) : x.status == 6 ? 
                    (
                      <SendIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#41B853",
                          marginRight: "4px",
                          transform: "rotate(-45deg)"
                        }}
                      />
                    ) : x.status == 4 ? 
                    (<InfoOutlinedIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#3672E7",
                        marginRight: "4px",
                      }}
                    />) : (
                      <AccessTimeIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#FB8C00",
                          marginRight: "4px",
                        }}
                      />
                    ) }
                    
                    <MDTypography
                      style={{
                        // whiteSpace: "nowrap",
                        // textOverflow: "ellipsis",
                        color: "#6B6880",
                        fontSize: "12px",
                        fontWeight: "400"
                      }}
                    >
                      { x.status == 1 ? "Draft" : x.status == 6 ? "Published" : x.status == 4 ? "In Queue" : x.status == 5 ? "Scheduled" : x.status == 7 ? "Failed" : "In Review" }
                    </MDTypography>
                  </Stack>
                  {x.status == 6 ? (
                    <MDBox
                    style={box}
                    sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                  >
                    <ContentCopyIcon style={iconText} onClick={() => {
                      setSelected({
                        AccountId: x.AccountId,
                        ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                        content: x.content && JSON.parse(x.content),
                        layouts: x.layouts && JSON.parse(x.layouts),
                        images: JSON.parse(x.images)[0].image,
                        hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                        scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                        scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                        status: x.status,
                        dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                      });
                      setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                      setOpen(true);
                    }}/>
                  </MDBox>
                  ) : x.status == 2 ? (
                    <MDBox
                        sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                      >
                    <Button
                      variant="contained"
                      sx={{color: "#fff", backgroundColor: "#000"}}
                      onClick={() => approveContent(x.id)}
                    >Approve</Button>
                    </MDBox>
                  ) : (
                    <>
                      <MDBox
                        style={box}
                        sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                      >
                        <ModeEditOutlinedIcon style={iconText} onClick={() => {
                          setSelected({
                            id: x.id,
                            AccountId: x.AccountId,
                            ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                            content: x.content && JSON.parse(x.content),
                            layouts: x.layouts && JSON.parse(x.layouts),
                            images: JSON.parse(x.images)[0].image,
                            hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                            scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                            scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                            status: x.status,
                            dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                          });
                          setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                          setOpen(true);
                        }}/>
                      </MDBox>
                      <MDBox style={box}>
                        <DeleteOutlineIcon
                          style={iconText}
                          onClick={() => {
                            setSelected({
                              id: x.id,
                              AccountId: x.AccountId,
                              ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                              content: JSON.parse(x.content),
                              layouts: JSON.parse(x.layouts),
                              images: x.images,
                              hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].hashtags),
                              scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                              scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scTime,
                              status: x.status,
                              scheduledId: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].id
                            });
                            setDeleteOpen(true);
                          }}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        )
      })}
    </Grid>
  );
}