import SelectThemePage from './select-theme.component';
import { connect } from 'react-redux';
import { updateAccount } from './../../api/account/accountAction';
import { fetchCurrentUser } from './../../api/user/userAction';
import { fetchPackages } from './../../api/subscription/subscriptionAction';
import { fetchThemes } from './../../api/theme/themeAction';
import { getMasterContent } from './../../api/master-content/masterContentAction';

const mapDispatchToProps = {
    updateAccount,
    fetchCurrentUser,
    fetchThemes,
    getMasterContent,
    fetchPackages
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading || state.accountPage.isLoading,
    isError: state.userPage.isError,
    isSaved: state.accountPage.isSaved,
    user: state.userPage.loggedUser,
    content: state.masterContentPage.masterContent,
    themes: state.themePage.themes,
    selectedAccount: state.userPage.selectedAccount,
    packages: state.subscribePage.packages
});

export const SelectThemePageContainer = connect(mapStateToProps, mapDispatchToProps)(SelectThemePage);