import * as actionType from "./tagActionType";

const initialState = {
    isError: false,
    isLoading: false,
    tags: [],
}

export const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_TAGS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_TAGS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_TAGS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                tags: action.payload ? action.payload.data : [],
            };
        case actionType.ADD_TAG:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.ADD_TAG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.ADD_TAG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default: 
            return state
    }
}