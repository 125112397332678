import { useEffect } from 'react';

export function LayoutFrameContainer () {
    useEffect(() => {
        window.addEventListener("message", receiveMessage, false);
        return () => {
            window.removeEventListener("message", receiveMessage, false);
        }
      }, []);

    function receiveMessage (ev) {
        if (["webpackClose", "webpackWarnings", "webpackInvalid"].indexOf(ev.data.type) === -1) {
            const data = JSON.parse(ev.data);
            console.log("DataI: ", data);
            if (data.type === "setup") {
                setSetUp(data.data.dynamicFields);
            }
            if (data.type === "full") {
                setFull(data.data);
            }
        }
    }

    function setFull(data) {
        document.getElementsByTagName("iframe")[0].remove();
        document.body.innerHTML = data.full;
        setSetUp(data.fields);
    }

    const isOverflown = ({ clientHeight, scrollHeight }) => scrollHeight > clientHeight

    const resizeText = ({ element, elements, minSize = 10, maxSize = 512, step = 1, unit = 'px' }) => {
        (elements || [element]).forEach(el => {
        let i = minSize
        let overflow = false
        const parent = el

        while (!overflow && i < maxSize) {
            el.style.fontSize = `${i}${unit}`
            overflow = isOverflown(parent)

            if (!overflow) i += step
        }
        el.style.fontSize = `${i - step}${unit}`
        })
    }

    function setSetUp(fields) {
        for (var key in fields) {
            if (fields.hasOwnProperty(key) && key != "Image Library" && fields[key] != "") {
                let a = document.querySelector('[name="' + key + '"] .textItem').innerHTML = fields[key];
                resizeText({ element: document.querySelector('[name="' + key + '"] .textItem') });
            }
            if (fields.hasOwnProperty(key) && key == "Image Library" && fields[key] != "") {
                const imageEle = document.querySelector('[name="' + key + '"] .imageItem');
                if(imageEle) {
                    imageEle.style["background-image"] = "url(" + fields["Image Library"] + ")";
                    imageEle.style["background-position"] = "center";
                    imageEle.style["background-repeat"] = "no-repeat";
                    imageEle.style["background-size"] = "cover";
                }
            }
        }
    }

    return (
        <></>
    )
}