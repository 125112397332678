import * as actionTypes from "./scheduleActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const getSchedules = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(getSchedulesInit());
        axios.get(`${baseUrl}/schedule/account/${id}`, config)
        .then((response)=>{
            dispatch(getSchedulesSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getSchedulesFailure());
        })
    };
};

export const getSchedulesInit = () => ({
    type: actionTypes.GET_SCHEDULES
});

export const getSchedulesFailure = data => ({
    type: actionTypes.GET_SCHEDULES_FAILURE
});

export const getSchedulesSuccess = data => ({
    type: actionTypes.GET_SCHEDULES_SUCCESS,
    payload: { data }
});

export const updateSchedule = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(updateScheduleInit());
        axios.put(`${baseUrl}/schedule/update`, data, config)
        .then((response)=>{
            dispatch(updateScheduleSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateScheduleFailure());
        })
    };
};

export const updateScheduleInit = () => ({
    type: actionTypes.UPDATE_SCHEDULES
});

export const updateScheduleFailure = data => ({
    type: actionTypes.UPDATE_SCHEDULES_FAILURE
});

export const updateScheduleSuccess = data => ({
    type: actionTypes.UPDATE_SCHEDULES_SUCCESS,
    payload: { data }
});

export const updateOnlySchedule = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(updateOnlyScheduleInit());
        axios.put(`${baseUrl}/schedule/update-schedule`, data, config)
        .then((response)=>{
            dispatch(updateOnlyScheduleSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateOnlyScheduleFailure());
        })
    };
};

export const updateOnlyScheduleInit = () => ({
    type: actionTypes.UPDATE_ONLY_SCHEDULES
});

export const updateOnlyScheduleFailure = data => ({
    type: actionTypes.UPDATE_ONLY_SCHEDULES_FAILURE
});

export const updateOnlyScheduleSuccess = data => ({
    type: actionTypes.UPDATE_ONLY_SCHEDULES_SUCCESS,
    payload: { data }
});