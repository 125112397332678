// ProductsList page components
import IdCell from "./../components/IdCell";
import DefaultCell from "./../components/DefaultCell";
// img
import drake from "./../../../../assets/images/drake.jpg";
// Icons
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Stack } from "@mui/material";
import MDAvatar from "../../MDAvatar";
import MDBox from "../../MDBox";
import MDTypography from "../../MDTypography";

const dataTableData = {
  columns: [
    {
      Header: "Content",
      accessor: "id",
      Cell: ({ value }) => <IdCell id={value} />,
    },
    {
      Header: "Content Type",
      accessor: "date",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "status",
      accessor: "status",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Platform",
      accessor: "customer",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Date & Time",
      accessor: "product",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Actions",
      accessor: "revenue",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
  ],

  rows: [
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Ready for Review
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <ElectricBoltIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#41B853",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Published
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
    {
      id: (
        <MDBox style={{display: "flex", alignItems: "center"}}>
          <MDAvatar
            src={drake}
            variant="square"
            style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
          />
          <MDTypography style={{  color: "#344767", fontSize: "14px", fontWeight: "400" }}>
            Lorem ipsum dolor sit am ...
          </MDTypography>
        </MDBox>
      ),
      date: (
        <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
          borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
          Social Post
        </MDTypography>
      ),
      status: (
        <Stack direction="row" spacing="1">
          <AccessTimeIcon
            style={{
              width: "20px",
              height: "20px",
              color: "#FB8C00",
              marginRight: "6px",
            }}
          />
          <MDTypography
            style={{ color: "#534F6B", fontSize: "12px", fontWeight: "400" }}
          >
            Scheduled
          </MDTypography>
        </Stack>
      ),
      customer: (
        <Stack direction="row" spacing="1">
          <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
          <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
        </Stack>
      ),
      product: (
        <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
          May 22,08:00AM
        </MDTypography>
      ),
      revenue: (
        <Stack direction="row" spacing="1">
          <EditIcon
            style={{ width: "16px", height: "16px", color: "#757575" }}
          />
          <DeleteIcon
            style={{
              width: "16px",
              height: "16px",
              color: "#757575",
              marginLeft: "8px",
            }}
          />
        </Stack>
      ),
    },
  ],
};

export default dataTableData;
