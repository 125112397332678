import * as actionType from "./contentActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    content: [],
    isRegenerated: false
}

export const contentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_CONTENT:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false,
        };
        case actionType.ADD_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.ADD_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
            case actionType.REGENERATE_CONTENT:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isRegenerated: false,
                };
            case actionType.REGENERATE_CONTENT_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isRegenerated: false,
                };
            case actionType.REGENERATE_CONTENT_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isRegenerated: true
                };
            case actionType.APPROVE_CONTENT:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isSaved: false,
                };
            case actionType.APPROVE_CONTENT_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isSaved: false,
                };
            case actionType.APPROVE_CONTENT_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isSaved: true
                };
        case actionType.UPDATE_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.UPDATE_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.DELETE_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.DELETE_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.GET_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                content: action.payload ? action.payload.data.rows : [],
            };
        default: 
            return state
    }
}