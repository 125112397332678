import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/styles';
import MDButton from "../../../shared/components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import MDAvatar from "../../../shared/components/MDAvatar";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import Switch from "@mui/material/Switch";
import FormField from "./../../../shared/components/FormField";
import TextField from "@mui/material/TextField";
import { Cancel } from "@mui/icons-material";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
    form: {
      '& .MuiInputBase-adornedStart': {
        flexWrap: "wrap"
      }
    }
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const mdbox = {
    background: "#E6E8EB",
    height: "40px",
    width: "40px",
    borderRadius: "100px",
}
const box = {
    marginTop: "49px",
}
const text = {
    fontWeight: "600", 
    fontSize: "15px", 
    color: "#344767", 
    marginBottom: "25px", 
    marginTop: "32px"
}
const imageText = {
    fontWeight: "500", 
    fontSize: "18px", 
    color: "#344767"
}
const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "capitalize",
}

const Tags = ({ data, handleDelete }) => {
  return (
    <MDBox
      sx={{
        background: "#283240",
        height: "100%",
        display: "flex",
        padding: "5px 10px",
        margin: "0.2rem",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
        borderRadius: "10px"
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography sx={{ fontSize: "12px" }}>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </MDBox>
  );
};

export default function SelectPagesDialog(props) {
  const classes = useStyles();
  const tagRef = useRef();
  const { linkedinPages, fbToken, fbUserId, open, setOpen, type, facebookPages, instaPages, submitHandle, setSelected, selected, errors, setErrors, onClose } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };

  const handleProfileSelect = x => {
    if (type === "fb") {
      setSelected({ ...selected, custom: JSON.stringify({ userToken: fbToken, fbUserId: fbUserId }), picture: x.picture.data.url, pageId: x.id, name: x.name, type: type, accessToken: x.access_token  });
    } else if(type === "insta") {
      setSelected({ ...selected, custom: JSON.stringify({ userToken: fbToken, fbUserId: fbUserId }), picture: x.picture.data.url, pageId: x.instagram_business_account.id, name: `${x.instagram_business_account.name}(${x.instagram_business_account.username})`, type: type, accessToken: x.access_token });
    } else {
      setSelected({ ...selected, pageId: x["organizationalTarget"], picture: x["roleAssignee~"]["profilePicture"]["displayImage~"]["elements"][0]["identifiers"][0]["identifier"], name: x["organizationalTarget~"]["localizedName"], type: "linkedin", accessToken: sessionStorage.getItem("lat"), refreshToken: sessionStorage.getItem("lrt") });
    }
  }

  const handleDelete = (value) => {
    const newtags = selected.hashtags.filter((val) => val !== value);
    setSelected({...selected, hashtags: newtags});
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let val = "";
    if (tagRef.current.value.trim() != "") {
      if (/^#/i.test(tagRef.current.value)) {
        val = tagRef.current.value;
      } else {
        val = "#" + tagRef.current.value;
      }
      setSelected({...selected, hashtags: [...selected.hashtags, val]});
      tagRef.current.value = "";
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            {selected.id ? (
              <MDBox>
                <MDTypography style={topHead} >
                  Update Info
                </MDTypography>
                </MDBox>
            ) : (
              <>
              <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Select Your Page
                </MDTypography>
                <MDTypography style={topText} >
                  You can add your page from here
                </MDTypography>
              </MDBox>
              <MDBox>
                <ClearIcon  onClick={() => onClose()}
                  style={{ width: "32px", height: "32px" }} 
                />
              </MDBox>
            </MDBox>
            <MDTypography style={text}>Pages</MDTypography>
            {type==="linkedin" && Array.isArray(linkedinPages) && linkedinPages.map((item) => {
              return (
                <MDBox style={{display: "flex"}}>
                  <MDAvatar src={item["roleAssignee~"]["profilePicture"]["displayImage~"]["elements"][0]["identifiers"][0]["identifier"]} alt="profile-image" size="sm" shadow="sm"
                      style={{marginRight: "12px"}}
                  />
                  <MDBox style={{display: "flex", alignItems: "center"}}>
                      <MDTypography style={imageText} >
                        {item["organizationalTarget~"]["localizedName"]}
                      </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                      <List className={classes.listText} >
                          <FormGroup>
                              <FormControlLabel
                              control={<Checkbox checked={selected.pageId === item["organizationalTarget"]} onChange={() => handleProfileSelect(item)} />}
                              label="Select page"
                              />
                          </FormGroup>
                      </List>
                  </MDBox>
                </MDBox>
              )
            })}
            {type==="insta" && Array.isArray(instaPages) && instaPages.map((item) => {
              return (
                <MDBox style={{display: "flex"}}>
                  <MDAvatar src={item.picture.data.url} alt="profile-image" size="md" shadow="sm"
                      style={{marginRight: "12px"}}
                  />
                  <MDBox style={{display: "flex", alignItems: "center"}}>
                      <MDTypography style={imageText} >
                        {item.instagram_business_account.name}
                      </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                      <List className={classes.listText} >
                          <FormGroup>
                              <FormControlLabel
                              control={<Checkbox checked={selected.pageId === item.instagram_business_account.id} onChange={() => handleProfileSelect(item)} />}
                              label="Select page"
                              />
                          </FormGroup>
                      </List>
                  </MDBox>
                </MDBox>
              )
            })}
            {type==="fb" && Array.isArray(facebookPages) && facebookPages.map((item) => {
              return (
                <MDBox style={{display: "flex", marginBottom: "15px"}}>
                  <MDAvatar src={item.picture.data.url} alt="profile-image" size="md" shadow="sm"
                      style={{marginRight: "12px"}}
                  />
                  <MDBox style={{display: "flex", alignItems: "center"}}>
                      <MDTypography style={imageText} >
                        {item.name}
                      </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                      <List className={classes.listText} >
                          <FormGroup>
                              <FormControlLabel
                              control={<Checkbox checked={selected.pageId === item.id} onChange={() => handleProfileSelect(item)} />}
                              label="Select page"
                              />
                          </FormGroup>
                      </List>
                  </MDBox>
                </MDBox>
              )
            })}
            {errors.pageId && (
              <MDTypography style={mdText1}>{errors.pageId}</MDTypography>
            )}
              </>
            )}
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Profile Post Call To Action"
                  name="cta"
                  placeholder="Profile Post Call To Action"
                  type="text"
                  value={selected.cta}
                  onChange={e => setSelected({ ...selected, cta: e.target.value })}
                />
                {errors.cta && (
                <MDTypography style={mdText1}>{errors.cta}</MDTypography>
                )}
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
              <form className={classes.form} onSubmit={e => handleOnSubmit(e)}>
                <TextField
                  inputRef={tagRef}
                  fullWidth
                  disabled={selected && selected.hashtags && selected.hashtags.length >= 30}
                  variant='standard'
                  size='small'
                  sx={{ margin: "1rem 0" }}
                  margin='none'
                  placeholder={selected && selected.hashtags && selected.hashtags.length < 30 ? "Enter hashtags" : ""}
                  InputProps={{
                    startAdornment: (
                      <React.Fragment>
                        {selected && Array.isArray(selected.hashtags) && selected.hashtags.map((data, index) => {
                          return (
                            <Tags data={data} handleDelete={handleDelete} key={index} />
                          );
                        })}
                      </React.Fragment>
                    ),
                  }}
                />
              </form>
                {errors.hashtags && (
                  <MDTypography style={mdText1}>{errors.hashtags}</MDTypography>
                )}
              
              </MDBox>
              {type==="insta" && (
                <MDBox>
                  <Switch
                    checked={selected.firstComment == 1}
                    onChange={() => setSelected({ ...selected, firstComment: selected.firstComment == 1 ? 0 : 1 })}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <span style={{ fontWeight: 400, fontSize: "14px", color: "#7B809A" }}>Publish hashtags as first comment</span>
                </MDBox>
              )}
            <MDBox style={box} >
                <MDButton style={button} variant="contained" onClick={() => submitHandle()}>
                    Save and Continue
                </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
