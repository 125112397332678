import * as actionType from "./masterContentActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    masterContent: [],
}

export const masterContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_MASTER_CONTENT:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false,
        };
        case actionType.ADD_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.ADD_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.UPDATE_MASTER_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.UPDATE_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.UPDATE_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.DELETE_MASTER_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false,
            };
        case actionType.DELETE_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false,
            };
        case actionType.DELETE_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        case actionType.GET_MASTER_CONTENT:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_MASTER_CONTENT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_MASTER_CONTENT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                masterContent: action.payload ? action.payload.data.rows : [],
            };
        default: 
            return state
    }
}