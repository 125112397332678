import * as actionType from "./subscriptionActionType";

const initialState = {
  isError: false,
  isLoading: false,
  subscriptionSuccess: {},
  subscriptions: [],
  invoices: [],
  paymentMethods: [],
  cancelSubscriptionData: {},
  sessionId: null,
  userSubscribed: false,
  isUpdate: false,
  packages: []
};

export const subscribeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SUBSCRIBE:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.SUBSCRIBE_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.SUBSCRIBE_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                subscriptionSuccess: action.payload ? action.payload.data : [],
            };
        case actionType.CREATE_SESSION:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.CREATE_SESSION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.CREATE_SESSION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                sessionId: action.payload ? action.payload.data : null,
            };
        case actionType.CANCEL_SUBSCRIPTION:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.CANCEL_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                cancelSubscriptionData: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_SUBSCRIPTION:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                subscriptions: action.payload.data ? action.payload.data : [],
            };
        case actionType.FETCH_PACKAGES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_PACKAGES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_PACKAGES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                packages: action.payload.data ? action.payload.data.rows : [],
            };
            case actionType.FETCH_USER_SUBSCRIPTION:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    userSubscribed: false
                };
            case actionType.FETCH_USER_SUBSCRIPTION_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    userSubscribed: false
                };
            case actionType.FETCH_USER_SUBSCRIPTION_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    userSubscribed: true,
                };
        case actionType.FETCH_INVOICES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_INVOICES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_INVOICES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                invoices: action.payload ? action.payload.data : [],
            };
        case actionType.FETCH_PAYMENT_METHODS:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
        case actionType.FETCH_PAYMENT_METHODS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                paymentMethods: action.payload ? action.payload.data : [],
            };
        case actionType.UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isUpdate: false,
            };
        case actionType.UPDATE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isUpdate: false,
            };
        case actionType.UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isUpdate: true,
            };
        case actionType.DETACH_PAYMENT_METHOD:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isUpdate: false,
            };
        case actionType.DETACH_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isUpdate: false,
            };
        case actionType.DETACH_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isUpdate: true,
            };
        default:
            return state
    }
}
