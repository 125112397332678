const selectData = {
  industry: ["Technology", "Design", "UI", "Data", "Electric"],
  topic: ["Technology", "Design", "UI", "Data", "Electric"],
  timezone: [
    "(GMT-04:00) Eastern Time - New York",
    "(GMT-04:00) Eastern Time - Dehli",
    "(GMT-04:00) Eastern Time - UK",
    "(GMT-04:00) Eastern Time - China",
    "(GMT-04:00) Eastern Time - Tokyo",
  ],
  location: [
    "1901 Thornridge Cir. Shiloh, Hawaii 81063",
    "1902 Thornri Cir. Shiloh, Dehli 12345",
    "1903 Tnridge Cir. Shiloh, Mumbai 12315",
    "1904 Thornge Cir. Shiloh, Jaipur 33315",
    "1905 Thoridge Cir. Shiloh, Sikar 13315",
  ],
  brandColor: [
    "Yellow",
    "Blue",
    "Black",
    "Green",
    "Red",
  ],
  primaryColor: [
    "Yellow",
    "Blue",
    "Black",
    "Green",
    "Red",
  ],
  secondaryColor: [
    "Yellow",
    "Blue",
    "Black",
    "Green",
    "Red",
  ],
};

export default selectData;
