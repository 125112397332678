import * as actionType from "./insightActionType";

const initialState = {
    isError: false,
    isLoading: false,
    insights: [],
    count: 0,
}

export const insightReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_INSIGHTS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_INSIGHTS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_INSIGHTS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                insights: action.payload ? action.payload.data.rows : [],
                count: action.payload ? action.payload.data.count : 0
            };
        default: 
            return state
    }
}