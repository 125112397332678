import React, { useState, useEffect } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
// mui
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Icon from "@mui/material/Icon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// md
import MDBox from "./../../shared/components/MDBox";
import MDButton from "./../../shared/components/MDButton";
// icons
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddIcon from '@mui/icons-material/Add';
import ContentEditorDialog from '../../shared/content-editor-dialog';
// list/grid
import GridData from "./component/grid/index";
import List from "./component/list/index";
import Loading from './../../shared/Loading';
import { DeleteConfirm } from './component/delete-confirm';
import { UpsertTagDialog } from './component/upsert-tag-dialog';
import { CollectionPage } from './component/collection-page';
import { createApi } from 'unsplash-js';
import { MediaLibraryPage } from "./../../shared/media-library/media-library.component"

const button = {
  textTransform: "capitalize",
  background: "#FFFFFF",
  border: "1px solid #CECDD5",
  color: "#7B809A",
  borderRadius: "8px",
};

export default function AllContentPage(props) {
  const {
    user,
    content,
    fetchAccounts,
    isLoading,
    accounts,
    uploadBG,
    bgImg,
    addContent,
    getContent,
    isSaved,
    updateContent,
    deleteContent,
    fetchLayouts,
    layouts,
    getTags,
    addTags,
    tags,
    tagSaved,
    selectedAccount,
    getMedias,
    addMedias,
    medias,
    mediaSaved,
    mediaLoading,
    deleteMedia,
    approveContent
  } = props;
  const [open, setOpen] = React.useState(false);
  const [mediaOpen, setMediaOpen] = React.useState(false);
  const [mediaBlankOpen, setMediaBlankOpen] = React.useState(false);
  const [mediaBlank, setMediaBlank] = React.useState(false);
  // toggleButton
  const [alignment, setAlignment] = React.useState("left");
  const [images, setImages] = React.useState([]);
  const [imageOpen, setImageOpen] = useState(false);
  const [collection, setCollection] = React.useState(selectedAccount && JSON.parse(selectedAccount.collection));
  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const emptyContent = {
    AccountId: selectedAccount && selectedAccount.id,
    ProfileIds: [],
    content: {all: "", facebook: "", insta: "", linkedin: "", gmb: ""},
    layouts: "",
    images: undefined,
    tags: [],
    hashtags: {
      all: selectedAccount && selectedAccount.hashtags,
      facebook: selectedAccount && selectedAccount.hashtags,
      insta: selectedAccount && selectedAccount.hashtags,
      linkedin: selectedAccount && selectedAccount.hashtags,
      gmb: selectedAccount && selectedAccount.hashtags,
    },
    scTime: null,
    status: 1
  }
  // menu
  const [menu, setMenu] = React.useState(null);
  const [search, setSearch] = React.useState({});

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [menu1, setMenu1] = React.useState(null);

  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);

  const [openTag, setOpenTag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState({ name: "", status: 1 });

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setSearch({ ...search, type: null })
          closeMenu();
        }}
        
      >
        All Posts <Checkbox checked={!search.type} sx={{ ml: "auto" }}/>
      </MenuItem>
      <Divider />
      <MenuItem 
        onClick={() => {
          setSearch({ ...search, type: "Social Posts" })
          closeMenu();
        }}
        
      >
        Social Posts <Checkbox checked={search.type == "Social Posts"} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem onClick={() => {
          setSearch({ ...search, type: "Video Posts" })
          closeMenu();
        }}
        >Video Posts <Checkbox checked={search.type == "Video Posts"} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem onClick={() => {
          setSearch({ ...search, type: "RSS Feed" })
          closeMenu();
        }}
        >RSS Feed <Checkbox checked={search.type == "RSS Feed"} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem onClick={() => {
          setSearch({ ...search, type: "Blog posts" })
          closeMenu();
        }}
        >Blog posts <Checkbox checked={search.type == "Blog posts"} sx={{ ml: "auto" }}/></MenuItem>
    </Menu>
  );
  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setSearch({ ...search, status: null })
          closeMenu1();
        }}
        checked={!search.status}
        >Select All <Checkbox sx={{ ml: "auto" }}/></MenuItem>
      <Divider />
      <MenuItem
      onClick={() => {
        setSearch({ ...search, status: 5 })
        closeMenu1();
      }}
        >Scheduled <Checkbox checked={search.status == 5} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem
      onClick={() => {
          setSearch({ ...search, status: 5 })
          closeMenu1();
        }}
        >Published <Checkbox checked={search.status == 5} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 7 })
          closeMenu1();
        }}
        >Failed <Checkbox checked={search.status == 7} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 1 })
          closeMenu1();
        }}
        >Draft <Checkbox checked={search.status == 1} sx={{ ml: "auto" }}/></MenuItem>
      <Divider />
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 2 })
          closeMenu1();
        }}
        >Ready For Review <Checkbox checked={search.status == 2} sx={{ ml: "auto" }}/></MenuItem>
    </Menu>
  );
  // menu
  const [isGrid, setIsGrid] = React.useState(true);
  const [accountOptions, setAccountOptions] = React.useState(true);
  const [isList, setIsList] = React.useState(false);
  const [selected, setSelected] = React.useState(emptyContent);
  const [profiles, setProfiles] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dynamicFields, setDynamicFields] = React.useState({});
  const [selectedLayout, setSelectedLayout] = React.useState("");

  const handleGrid = () => {
    setIsGrid(true);
    setIsList(false);
  }
  const handleList = () => {
    setIsGrid(false);
    setIsList(true);
  }

  const getProfiles = async () => {
    if (selectedAccount &&
      Array.isArray(selectedAccount.Profiles)) {
        const y = await Promise.all(selectedAccount.Profiles.map(x => {
                    return { 
                      id: x.id,
                      type: x.type == "fb" ? "facebook" : x.type,
                      label: `${x.type == "fb" ? "Facebook" : x.type == "gmb" ? "Google Business" : x.type == "insta" ? "Instagram" : "Linkedin"} (${x.name})`,
                      tags: x.hashtags
                    }
                  }));
      setProfiles(y);
      return;
    }
    setProfiles([]);
    return;
  }

  function returnProfileHashtags(type, tags) {
    if (profiles && Array.isArray(profiles)) {
      const x = profiles.filter(y => y.type == type);
      if (x.length > 0) {
        console.log("ININ: ", x[0].tags);
        let arr1 = tags == "" ? [] : tags.split(" ");
        let arr2 = x[0].tags ? x[0].tags.split(" ") : [];
        return arr1.concat(arr2);
      }
      return tags == "" ? [] : tags.split(" ");
    }
    return tags == "" ? [] : tags.split(" ");
  }

  async function setHashtags() {
    setSelected({
      ...selected,
      hashtags: {
        ...selected.hashtags,
        all: await returnProfileHashtags("all", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        facebook: await returnProfileHashtags("facebook", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        insta: await returnProfileHashtags("insta", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        linkedin: await returnProfileHashtags("linkedin", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        gmb: await returnProfileHashtags("gmb", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
      }
    });
    setOpen(true);
  }

  useEffect(() => {
    getContent(selectedAccount && selectedAccount.id);
    getProfiles();
    getTags();
    getMedias({ id: selectedAccount && selectedAccount.id, limit: 24, offset: 0, orderBy: "id", orderType: "DESC" });
    fetchLayouts(selectedAccount && selectedAccount.ThemeId);
  }, []);

  useEffect(() => {
    if(search){
      getContent(selectedAccount && selectedAccount.id, search);
    }
  }, [search]);

  useEffect(() => {
    if (tagSaved) {
      getTags();
      setSelectedTag({ name: "", status: 1 });
      setOpenTag(false);
    }
  }, [tagSaved]);

  useEffect(() => {
    if (isSaved) {
      getContent(selectedAccount && selectedAccount.id);
      setSelected(emptyContent);
      setDeleteOpen(false);
      setOpen(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if (mediaSaved) {
      getMedias({ id: selectedAccount && selectedAccount.id, limit: 9, offset: 0, orderBy: "id", orderType: "DESC" });
    }
  }, [mediaSaved]);

  useEffect(async () => {
    if (accounts.length > 0) {
      const x = await Promise.all(accounts.map(y => {
        return {id: y.id, label: y.name}
      }))
      setAccountOptions(x);
    }
  }, [accounts]);

  useEffect(() => {
    if(bgImg) {
      setLoading(true);
      setDynamics("Image Library", bgImg);
      setTimeout(() => setLoading(false), 3000);
    }
  }, [bgImg]);

  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  function getCollectionById () {
    api.collections.getPhotos({ collectionId: collection && collection.id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }

  function setDynamics(x, v) {
    let obj = {};
    obj[x] = v;
    setDynamicFields({ ...dynamicFields, ...obj });
  }

  return (
    <DashboardLayout>
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaOpen}
        onClose={() => setMediaOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={setDynamics}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaBlankOpen}
        onClose={() => setMediaBlankOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={(a, b) => {
          setSelected({
            ...selected,
            layouts: ""
          });
          setDynamicFields({});
          setSelectedLayout("");
          setSelected({ ...selected, images: b });
          setMediaBlank(b);
        }}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <CollectionPage
        images={images}
        open={imageOpen}
        onClose={() => {
          setImages([]);
          setImageOpen(false);
        }}
        setSelected={setSelected}
        selected={selected}
        setDynamics={setDynamics}
        setLoading={setLoading}
      />
      <UpsertTagDialog
        addTags={addTags}
        open={openTag}
        onClose={() => {
          setSelectedTag({ name: "", status: 1 });
          setOpenTag(false);
        }}
        selected={selectedTag}
        setSelected={setSelectedTag}
      />
      <DashboardNavbar />
      <Loading loading={isLoading || loading} />
      <DeleteConfirm
        deleteContent={deleteContent}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected(emptyContent);
          setDeleteOpen(false);
        }}
      />
      <ContentEditorDialog
        selectedLayout={selectedLayout}
        setSelectedLayout={setSelectedLayout}
        setMediaBlank={setMediaBlank}
        setOpenTag={setOpenTag}
        tags={tags}
        selected={selected}
        setSelected={setSelected}
        open={open}
        onClose={() => {
          setSelected(emptyContent);
          setDynamicFields({});
          setOpen(false);
        }}
        setMediaBlankOpen={setMediaBlankOpen}
        mediaBlank={mediaBlank}
        accounts={accountOptions}
        uploadBG={uploadBG}
        addContent={addContent}
        updateContent={updateContent}
        profilesOpt={profiles}
        layouts={layouts}
        openUnsplash={() => {
          getCollectionById();
          setImageOpen(true);
        }}
        selectedAccount={selectedAccount}
        dynamicFields={dynamicFields}
        setDynamics={setDynamics}
        setDynamicFields={setDynamicFields}
        setMediaOpen={setMediaOpen}
      />
      <Grid style={{ display: "flex", marginTop: "27px" }}>
        <Grid item>
          <MDButton
            style={button}
            variant={menu ? "contained" : "outlined"}
            onClick={openMenu}
          >
            All Posts&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </Grid>
        <Grid style={{ marginLeft: "auto" }}>
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            All Topics&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            Ready for Review&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <ToggleButtonGroup
            sx={{
              marginLeft: "16px",
              background: "#E6E6EA",
              borderRadius: "12px",
              padding: "6px 8px",
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: "#fff !important",
                  backgroundColor: "#09032B !important",
                  
                },
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root": {
                border: "none",
              },
            }}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton onClick={handleGrid}
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
                marginRight: "8px",
              }} value="left" aria-label="left aligned">
              <WindowIcon />
            </ToggleButton>
            <ToggleButton onClick={handleList}
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
              }} value="right" aria-label="right aligned">
              <FormatListBulletedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <MDButton onClick={() => setHashtags()}
            style={{
              background: "linear-gradient(#09032B, #312B56)",
              color: "#FFFFFF",
              marginLeft: "16px",
              fontWeight: "700",
              fontSize: "12px",
              textTransform: "capitalize"

            }}
          ><AddIcon style={{marginRight: "10px"}} />
            Create content
          </MDButton>
        </Grid>
      </Grid>
      <MDBox style={{ marginTop: "24px" }}>
        {isGrid ? <GridData approveContent={approveContent} setDynamicFields={setDynamicFields} setDeleteOpen={setDeleteOpen} content={content} selected={selected} setSelected={setSelected} setOpen={setOpen}/> : null }
        {isList ?  <List approveContent={approveContent} setDynamicFields={setDynamicFields} setDeleteOpen={setDeleteOpen} content={content} selected={selected} setSelected={setSelected} setOpen={setOpen} /> : null }
      </MDBox>
    </DashboardLayout>
  );
}
