export const UPLOAD_BG = "UPLOAD_BG";
export const UPLOAD_BG_FAILURE = "UPLOAD_BG_FAILURE";
export const UPLOAD_BG_SUCCESS = "UPLOAD_BG_SUCCESS";

export const FETCH_LAYOUTS = "FETCH_LAYOUTS";
export const FETCH_LAYOUTS_FAILURE = "FETCH_LAYOUTS_FAILURE";
export const FETCH_LAYOUTS_SUCCESS = "FETCH_LAYOUTS_SUCCESS";

export const FETCH_THEMES = "FETCH_THEMES";
export const FETCH_THEMES_FAILURE = "FETCH_THEMES_FAILURE";
export const FETCH_THEMES_SUCCESS = "FETCH_THEMES_SUCCESS";
