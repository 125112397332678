import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
// import DashboardNavbar1 from "./../../../shared/components/DashboardNavbar1";
import MDBox from "./../../../shared/components/MDBox";
import MDAvatar from "./../../../shared/components/MDAvatar";
import MDTypography from "./../../../shared/components/MDTypography";
import MDButton from "./../../../shared/components/MDButton";
import findPage from "./../../../assets/images/findPage.svg";
import FormField from "./../../../shared/components/FormField";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { CirclePicker } from 'react-color';
import NewUser from "./../../../assets/images/New_user.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Loading from './../../../shared/Loading';
import { timezones } from './../../../timezones';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const bgcolor = {
  background: "none",
  boxShadow: "none",
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-active": {
    background: "#1B1C29",
    fill: "#1B1C29",
    stroke: "#1B1C29",
    borderColor: "#1B1C29",
    height: "16px",
    width: "16px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-completed": {
    background: "#1B1C29",
    fill: "#1B1C29",
    stroke: "#1B1C29",
    borderColor: "#1B1C29",
    height: "16px",
    width: "16px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root": {
    background: "#9B9CA3",
    fill: "#9B9CA3",
    stroke: "#9B9CA3",
    borderColor: "#9B9CA3",
    height: "16px",
    width: "16px",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-active": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-completed": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed": {
    color: "#1B1C29 !important",
  },
};

function getSteps() {
  return ["Add Business", "Select Theme", "Socials"];
}

export default function SaveBusiness(props) {
  const steps = getSteps();
  const { isLoading, getIndustries, getTopics, createAccount, topics, industries, isSaved, user, fetchCurrentUser } = props;
  const navigate = useNavigate();
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };

  const [values, setValues] = useState({
    name: "",
    industries: [],
    topics: [],
    email: user && user.email,
    phone: "",
    location: "",
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    logo: undefined,
    status: 1,
    RoleId: 1,
    accentColor: '#ffb905',
    darkColor: '#070429',
    lightColor: '#f0f2eb',
    paragraphFont: "Roboto",
    headingFont: "Roboto"
  });

  const [errors, setErrors] = useState({});
  const [address, setAddress] = React.useState("");
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
      setValues({
        ...values,
        logo: event.target.files ? event.target.files[0] : undefined,
      });
  };

  const submitHandle = () => {
    setErrors(validate(values));
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.name) {
      isError = true;
      errorss.name = "Business name is required";
    }
    if (!values.industries) {
      isError = true;
      errorss.industries = "Industry is required";
    }
    if (!values.topics) {
      isError = true;
      errorss.topics = "Topic is required";
    }
    if (!values.email) {
      isError = true;
      errorss.email = "Email is required";
    } else if (!regex.test(values.email)) {
      isError = true;
      errorss.email = "This is not a valid email format";
    }
    if (!values.phone) {
      isError = true;
      errorss.phone = "Number is required";
    }
    if (!values.location) {
      isError = true;
      errorss.location = "Business location is required";
    }
    if (!values.timezone) {
      isError = true;
      errorss.timezone = "Timezone is required";
    }
    if (!isError) {
      createAccount({ ...values, industries: JSON.stringify(values.industries), topics: JSON.stringify(values.topics) });
    }
    return errorss;
  };

  useEffect (() => {
    getIndustries();
  }, []);

  useEffect(() => {
    if(isSaved){
      fetchCurrentUser();
      navigate("/select-theme");
    }
  }, [isSaved]);

  const fetchTopics = async (x) => {
    const z = await Promise.all(x.map(y => y.id))
    getTopics(z);
  }

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setValues({
      ...values,
      location: value,
    })
    setCoordinates(latLng);
  };

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
        <Grid container justifyContent="center">
          <Grid item md={4}>
          <MDBox style={{marginTop: "64px", width: "100%"}}>
          <Stepper sx={bgcolor} activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
      </MDBox>
      
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item xs={12}><MDTypography
          sx={{
            textAlign: "center",
            color: "#09032b",
            fontSize: "36px",
            fontWeight: "700",
            marginTop: "64px"
          }}
        >
          Create Your First Workspace
        </MDTypography></Grid>
          <Grid item sm={12} md={4}>
            <MDBox
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "32px",
                height: "auto",
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              <MDBox>
                <img
                  src={findPage}
                  variant="square"
                  style={{ width: "100%", margin: "39px auto" }}
                />
                <MDTypography
                  style={{
                    color: "#2A3342",
                    fontSize: "18px",
                    fontWeight: "700",
                    marginTop: "24px",
                    textAlign: "center",
                  }}
                >
                  Add your business information to get started
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#556987",
                    fontSize: "14px",
                    fontWeight: "400",
                    textAlign: "center",
                  }}
                >
                  This information will feed in to your social content
                </MDTypography>
                <MDBox component="form" pb={3} px={3} mt={2}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormField
                        label="Business name"
                        name="name"
                        placeholder="Company Y"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <MDTypography style={mdText1}>
                          {errors.name}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        options={Array.isArray(industries) ? industries : []}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            industries: newValue,
                          });
                          fetchTopics(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Choose Industry"
                            name="buisnessIndustry"
                            placeholder="Select Industry"
                            InputLabelProps={{ shrink: true }}
                            multiple
                          />
                        )}
                      />
                      {errors.industries && (
                        <MDTypography style={mdText1}>
                          {errors.industries}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        options={Array.isArray(topics) ? topics : []}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            topics: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Topic"
                            name="topics"
                            placeholder="Select topic"
                            InputLabelProps={{ shrink: true }}
                            multiple
                          />
                        )}
                      />
                      {errors.topics && (
                        <MDTypography style={mdText1}>
                          {errors.topics}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        label="Business Email"
                        name="email"
                        placeholder="john@email.com"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <MDTypography style={mdText1}>{errors.email}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormField
                        label="Business Phone"
                        name="phone"
                        placeholder="+61 3 123 123"
                        type="number"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <MDTypography style={mdText1}>{errors.phone}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                    <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <FormField label='Address' {...getInputProps({ placeholder: "Type address" })} />
                        <div
                          style={suggestions.length > 0 ?
                            { position: 'absolute', zIndex: 9999, backgroundColor: '#fff', padding: '15px', border: '1px solid #eaeaea', minWidth: '200px' }
                          : { display: 'none' }
                          }>
                          {loading ? <div>...loading</div> : null}

                          {suggestions.map(suggestion => {
                            const style = {
                              backgroundColor: suggestion.active ? "#000" : "#fff",
                              color: suggestion.active ? "#fff" : "#000",
                              padding: '10px 10px',
                              fontSize: "12px"
                            };

                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                      {errors.location && (
                        <MDTypography style={mdText1}>
                          {errors.location}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                    <Autocomplete
                        options={timezones || []}
                        value={values.timezone}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            timezone: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Timezone"
                            name="timezone"
                            placeholder="Timezone"
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                      {errors.timezone && (
                        <MDTypography style={mdText1}>
                          {errors.timezone}
                        </MDTypography>
                      )}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <Typography sx={{ color: "#7B809A", fontSize: "14px", marginBottom: "10px" }}>Choose Your Brand Color</Typography>
                      <CirclePicker width="100%" styles={{ borderRadius: "20px" }} />
                    </Grid> */}
                  </Grid>
                  <MDBox
                    ml={0}
                    component="label"
                    style={{
                      width: "auto",
                      height: "96px",
                      background: "#E6E6EA",
                      border: "0.25px dashed #09032B",
                      borderRadius: "11px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "19px",
                      cursor: "pointer"
                    }}
                  >
                    <input
                      accept="image/*"
                      id="header-image"
                      type="file"
                      onChange={onImageChange}
                      style={{ display: "none" }}
                    />
                    <CloudUploadIcon
                      style={{
                        width: "46px",
                        height: "30.67px",
                        color: "#09032B",
                      }}
                    />
                    <MDBox>
                      <MDTypography
                        style={{
                          fontWeight: "500",
                          marginBottom: "5px",
                          fontSize: "14px",
                          lineHeight: "16px",
                          color: "#000000",
                          fontFamily: "Roboto",
                        }}
                      >
                        Upload Logo
                      </MDTypography>
                      <MDBox>
                        <MDTypography
                          style={{
                            fontWeight: "300",
                            fontSize: "12px",
                            lineHeight: "14px",
                            color: "#8097B1",
                            fontFamily: "Roboto",
                          }}
                        >
                          JPEG,PNG etc
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox style={{ marginTop: "10px", textAlign: "center" }}>
                    {values.logo && <img src={URL.createObjectURL(values.logo)} height="100px" />}
                  </MDBox>
                  <MDBox
                    mt={3}
                    style={{
                      justifyContent: "center",
                      alignIitems: "center",
                      display: "flex",
                    }}
                  >
                    <MDButton
                      onClick={() => submitHandle()}
                      style={{
                        background: "#1B172E",
                        color: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        textTransform: "capitalize"
                      }}
                      fullWidth
                    >
                      Save & continue
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          
          </Grid>
        </Grid>
      </React.Fragment>
  );
}
