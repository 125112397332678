import * as actionTypes from "./profileActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const addProfile = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }};
    return (dispatch) => {
        dispatch(addProfileInit());
        axios.post(`${baseUrl}/profile/store`, data, config)
        .then((response)=>{
            dispatch(addProfileSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addProfileFailure());
        })
    };
};

export const getFBToken = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }};
    return (dispatch) => {
        dispatch(getFBTokenInit());
        axios.post(`${baseUrl}/profile/get-fb-token`, data, config)
        .then((response)=>{
            dispatch(getFBTokenSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getFBTokenFailure());
        })
    };
};

export const getInsights = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` }};
    return (dispatch) => {
        dispatch(getInsightsInit());
        axios.post(`${baseUrl}/profile/get-insights`, data, config)
        .then((response)=>{
            dispatch(getInsightsSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getInsightsFailure());
        })
    };
};

export const updateProfile = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(updateProfileInit());
        axios.put(`${baseUrl}/profile/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateProfileSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateProfileFailure());
        })
    };
};

export const deleteProfile = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(deleteProfileInit());
        axios.delete(`${baseUrl}/profile/${id}`, config)
        .then((response)=>{
            dispatch(deleteProfileSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteProfileFailure());
        })
    };
};

export const getFBTokenInit = () => ({
    type: actionTypes.GET_FB_TOKEN
});

export const getFBTokenFailure = data => ({
    type: actionTypes.GET_FB_TOKEN_FAILURE
});

export const getFBTokenSuccess = data => ({
    type: actionTypes.GET_FB_TOKEN_SUCCESS,
    payload: { data }
});

export const getInsightsInit = () => ({
    type: actionTypes.GET_INSIGHTS
});

export const getInsightsFailure = data => ({
    type: actionTypes.GET_INSIGHTS_FAILURE
});

export const getInsightsSuccess = data => ({
    type: actionTypes.GET_INSIGHTS_SUCCESS,
    payload: { data }
});

export const addProfileInit = () => ({
    type: actionTypes.ADD_PROFILE
});

export const addProfileFailure = data => ({
    type: actionTypes.ADD_PROFILE_FAILURE
});

export const addProfileSuccess = data => ({
    type: actionTypes.ADD_PROFILE_SUCCESS,
    payload: { data }
});

export const updateProfileInit = () => ({
    type: actionTypes.UPDATE_PROFILE
});

export const updateProfileFailure = data => ({
    type: actionTypes.UPDATE_PROFILE_FAILURE
});

export const updateProfileSuccess = data => ({
    type: actionTypes.UPDATE_PROFILE_SUCCESS,
    payload: { data }
});

export const deleteProfileInit = () => ({
    type: actionTypes.DELETE_PROFILE
});

export const deleteProfileFailure = data => ({
    type: actionTypes.DELETE_PROFILE_FAILURE
});

export const deleteProfileSuccess = data => ({
    type: actionTypes.DELETE_PROFILE_SUCCESS,
    payload: { data }
});