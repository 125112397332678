export const GET_MEDIAS = "GET_MEDIAS";
export const GET_MEDIAS_FAILURE = "GET_MEDIAS_FAILURE";
export const GET_MEDIAS_SUCCESS = "GET_MEDIAS_SUCCESS";

export const ADD_MEDIA = "ADD_MEDIA";
export const ADD_MEDIA_FAILURE = "ADD_MEDIA_FAILURE";
export const ADD_MEDIA_SUCCESS = "ADD_MEDIA_SUCCESS";

export const DELETE_MEDIA = "DELETE_MEDIA";
export const DELETE_MEDIA_FAILURE = "DELETE_MEDIA_FAILURE";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";
