import { useMemo } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";

// mui
import Grid from "@mui/material/Grid";

// md
import MDBox from "./../../shared/components/MDBox";
import EventCalendar from "./../../shared/components/Calendar";

// Calendar application components
import Header from "./components/Header";
import NextEvents from "./components/NextEvents";

// Data
import calendarEventsData from "./data/calendarEventsData";

export default function DraftsPageContainer() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <MDBox display="flex" justifyContent="flex-end" mt={1} mb={4} mx={2}>
          <Header />
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} xl={8} sx={{ height: "max-content" }}>
            {useMemo(
              () => (
                <EventCalendar
                  initialView="dayGridMonth"
                  initialDate="2021-08-10"
                  events={calendarEventsData}
                  selectable
                  editable
                />
              ),
              [calendarEventsData]
            )}
          </Grid>
          <Grid item xs={12} xl={4}>
            <MDBox mb={3}>
              <NextEvents />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}
