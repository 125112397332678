import React, { useEffect, useState } from "react";
// import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import MDBox from "./../../shared/components/MDBox";
import MDButton from "./../../shared/components/MDButton";
import Footer from "./../../shared/components/Footer";
import FormField from "./../../shared/components/FormField";
import logo from "./../../assets/images/logo-ct-dark.png";
import { Navigate, useNavigate } from "react-router-dom";
import Loading from './../../shared/Loading';
import { useParams } from 'react-router-dom';

export default function ResetPasswordPage(props) {
  const { token } = useParams();
  const navigate = useNavigate();
  const { resetPassword, isLoading, passwordReseted } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const mdText2 = {
    color: "red",
    marginTop: "-14px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const [values, setValues] = useState({
    password: "",
    confirm_password: ""
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandle = (event) => {
    event.preventDefault();
    setErrors(validate(values));
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    }
    if (values.password != values.confirm_password) {
      isError = true;
      errorss.confirm_password = "password did not match.";
    }
    if (!isError) {
      resetPassword(values, token);
    }
    return errorss;
  };

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
        <Grid
          item
          style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
        >
          <MDAvatar
            src={logo}
            variant="square"
            style={{ width: "13px", height: "22px", marginTop: "5px" }}
          />
          &nbsp;&nbsp;
          <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <MDBox
            style={{
              backgroundColor: "white",
              width: "469px",
              height: "auto",
              padding: "0px 25px",
              borderRadius: "12px",
              marginTop: "149px",
            }}
            // onSubmit={handleSubmit(onSubmit)}
          >
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "25px",
                  fontWeight: "700",
                  marginTop: "40px",
                }}
              >
                Reset Password
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "5px",
                }}
              >
                Reset your password
              </MDTypography>
            </MDBox>
            <form>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="New Password"
                  name="password"
                  type="password"
                  placeholder="****************"
                  value={values.password}
                  onChange={handleChange}
                />
              </MDBox>
              {errors.password && (
                <MDTypography style={mdText2}>{errors.password}</MDTypography>
              )}
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Confirm Password"
                  name="confirm_password"
                  type="password"
                  placeholder="****************"
                  value={values.confirm_password}
                  onChange={handleChange}
                />
              </MDBox>
              {errors.confirm_password && (
                <MDTypography style={mdText2}>{errors.confirm_password}</MDTypography>
              )}
              <MDBox mt={4} mb={1}>
                <MDButton
                  onClick={submitHandle}
                  style={{ marginBottom: "20px", backgroundColor: "#09032B", color: "#F0FDF4", fontWeight: "700", fontSize: "16px", textTransform: "capitalize" }}
                  size="large"
                  fullWidth
                  type="submit"
                >
                  reset
                </MDButton>
                <MDBox sx={{ textAlign: "center", fontSize: "14px", fontWeight: "bold", py: 0, mb: "40px" }}>
                  <a href="/#/login">Go back to Login</a>
                </MDBox>
              </MDBox>
            </form>
            
          </MDBox>
        </Grid>
        <Grid item style={{ marginTop: "5%" }}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
