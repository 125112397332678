export const USER_ACCOUNT = "USER_ACCOUNT";
export const USER_ACCOUNT_FAILURE = "USER_ACCOUNT_FAILURE";
export const USER_ACCOUNT_SUCCESS = "USER_ACCOUNT_SUCCESS";

export const FETCH_ACCOUNTS = "FETCH_ACCOUNTS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";

export const UPDATE_USER_ACCOUNT = "UPDATE_USER_ACCOUNT";
export const UPDATE_USER_ACCOUNT_FAILURE = "UPDATE_USER_ACCOUNT_FAILURE";
export const UPDATE_USER_ACCOUNT_SUCCESS = "UPDATE_USER_ACCOUNT_SUCCESS";