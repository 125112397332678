import { combineReducers } from 'redux';
import { userReducer as userPage } from './../api/user/userReducer';
import { profileReducer as profilePage } from './../api/profile/profileReducer';
import { accountReducer as accountPage } from './../api/account/accountReducer';
import { themeReducer as themePage } from './../api/theme/themeReducer';
import { topicReducer as topicPage } from './../api/topic/topicReducer';
import { contentReducer as contentPage } from './../api/content/contentReducer';
import { masterContentReducer as masterContentPage } from './../api/master-content/masterContentReducer';
import { industryReducer as industryPage } from './../api/industry/industryReducer';
import { tagReducer as tagPage } from './../api/tag/tagReducer';
import { scheduleReducer as schedulePage } from './../api/schedule/scheduleReducer';
import { subscribeReducer as subscribePage } from './../api/subscription/subscriptionReducer';
import { mediaReducer as mediaPage } from './../api/media/mediaReducer';
import { insightReducer as insightPage } from "./../api/insight/insightReducer";

const appReducer = combineReducers({
    userPage,
    accountPage,
    topicPage,
    industryPage,
    profilePage,
    themePage,
    contentPage,
    tagPage,
    schedulePage,
    subscribePage,
    masterContentPage,
    mediaPage,
    insightPage
});

export default appReducer;