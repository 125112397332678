import { DateTime } from 'luxon';
export function preetyDate(d) {
    return DateTime.fromISO(d).toFormat('dd/MM/yyyy');
}
export function preetyTime(d) {
    return DateTime.fromISO(d).toFormat('hh:ii a');
}

export const fontList = [
    'Abril Fatface',
    'Arial',
    'Alegreya',
    'Alegreya Sans',
    'Archivo Narrow',
    'BioRhyme',
    'Bebas Neue',
    'Caveat Brush',
    'Cardo',
    'Comic Sans MS',
    'Courier New',
    'Chivo',
    'Cormorant',
    'Crushed',
    'Dancing Script',
    'DM Sans',
    'Eczar',
    'Elsie',
    'Fira Sans',
    'Georgia',
    'Helvetica',
    'IBM Plex Sans',
    'Inconsolata',
    'Inknut Antiqua',
    'Inter',
    'Karla',
    'Lato',
    'Libre Baskerville',
    'Libre Franklin',
    'Lobster',
    'Lora',
    'Manrope',
    'Merriweather',
    'Monoton',
    'Montserrat',
    'Neuton',
    'Open Sans',
    'Passions Conflict',
    'Playfair Display',
    'Poppins',
    'Proza Libre',
    'PT Sans',
    'PT Serif',
    'Raleway',
    'Roboto',
    'Roboto Slab',
    'Rubik',
    'Sacramento',
    'Source Sans Pro',
    'Source Serif Pro',
    'Space Grotesk',
    'Space Mono',
    'Spectral',
    'Syne',
    'Time New Roman',
    'Trebuchet MS',
    'Work Sans',
    'Zilla Slab Highlight'
  ]; 