import ForgetPasswordPage from './forget-password.component';
import { connect } from 'react-redux';
import { forgetPassword } from './../../api/user/userAction';

const mapDispatchToProps = {
    forgetPassword
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    mailSent: state.userPage.mailSent,
});

export const ForgetPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordPage);