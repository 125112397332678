import * as actionType from "./mediaActionType";

const initialState = {
    isError: false,
    isLoading: false,
    medias: [],
}

export const mediaReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_MEDIAS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_MEDIAS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_MEDIAS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                medias: action.payload ? action.payload.data : [],
            };
        case actionType.ADD_MEDIA:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.ADD_MEDIA_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isSaved: false
            };
        case actionType.ADD_MEDIA_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
            case actionType.DELETE_MEDIA:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isSaved: false
                };
            case actionType.DELETE_MEDIA_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isSaved: false
                };
            case actionType.DELETE_MEDIA_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isSaved: true
                };
        default: 
            return state
    }
}