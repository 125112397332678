import * as actionTypes from "./topicActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const getTopics = (ids) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
      return (dispatch) => {
        dispatch(getTopicsInit());
        axios.post(`${baseUrl}/topic/options`, { ids }, config)
        .then((response)=>{
            dispatch(getTopicsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getTopicsFailure());
        })
    };
};

export const getTopicsInit = () => ({
    type: actionTypes.GET_TOPICS
});

export const getTopicsFailure = data => ({
    type: actionTypes.GET_TOPICS_FAILURE
});

export const getTopicsSuccess = data => ({
    type: actionTypes.GET_TOPICS_SUCCESS,
    payload: { data }
});
