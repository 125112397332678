import React from "react";
import Dialog from "@mui/material/Dialog";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from '@material-ui/styles';
import ClearIcon from "@mui/icons-material/Clear";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
  });
const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const mdbox = {
    background: "#E6E8EB",
    height: "40px",
    width: "40px",
    borderRadius: "100px",
}
const box = {
    marginTop: "49px",
}
const text = {
    fontWeight: "600", 
    fontSize: "15px", 
    color: "#344767", 
    marginBottom: "25px", 
    marginTop: "32px"
}
const imageText = {
    fontWeight: "500", 
    fontSize: "18px", 
    color: "#344767"
}
const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "capitalize",
}

export function CollectionPage(props) {
  const classes = useStyles();
  const { open, onClose, images, selected, setSelected, setDynamics, setLoading } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };

  return (
    <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        aria-labelledby="responsive-dialog-title"
    >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item xs={10}>
                <DialogTitle id="responsive-dialog-title">
                    See and save our vast image library
                </DialogTitle>
                
                </Grid>
                <Grid item xs={2}>
                <MDBox
                    onClick={() => onClose()}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "5px" }}>
                {images && !images.errors && images.response && images.response.results.map(x => {
                return (
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                        <img
                            onClick={() => {
                                setLoading(true);
                                setDynamics("Image Library", x.urls.full);
                                setTimeout(() => setLoading(false), 3000);
                                onClose();
                            }}
                            src={ x.urls.small } style={{ cursor: "pointer", width: "100%", height: "300px", objectFit:"cover" }} width="100%" />
                    </Grid>
                )
                })}
            </Grid>
            </MDBox>
        </Dialog>
  );
}
