import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import MDTypography from "./components/MDTypography";
import PropTypes from 'prop-types';
import MDBox from "./components/MDBox";
import DefaultPricingCard from "./components//DefaultPricingCard";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useMaterialUIController } from "./../context";


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`fullwidth-tabpanel-${index}`}
      aria-labelledby={`fullwidth-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export function SubscriptionDialogePage(props) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const { onClose, createSession, selectedAccount, open, packages } = props;
  const [tabValue, setTabValue] = React.useState("month");

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  function callCheckout(price){
    createSession({ price, strippId: null, accountId: selectedAccount.id });
  }

  return (
    <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent>
        <Grid container style={{marginTop: "31px", justifyContent: "center"}}>
          <Grid item xs={12} md={4}>
            <MDBox mx="auto">
              <AppBar position="static">
                <Tabs value={tabValue} onChange={tabHandler}>
                  <Tab
                    id="monthly"
                    value="month"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MDBox>
                    }
                  />
                  <Tab
                    id="annual"
                    value="year"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Annual
                      </MDBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </MDBox>
          </Grid>
        </Grid>
        <TabPanel value={tabValue} index="month">
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "35px" }}
            >
              <Grid xs={12} item sx={{ textAlign: "center" }}>
                <MDTypography sx={{ fontSize: "36px", fontWeight: 700 }}>Upgrade Your Account</MDTypography>
              </Grid>
              {packages && Array.isArray(packages) && packages.filter(a => a.timeFrame == "Month").map(x => {
                return (
                  <Grid key={x.id} item xs={12} lg={4}>
                    <DefaultPricingCard
                      color={darkMode ? "dark" : "white"}
                      badge={{
                        color: "dark",
                        label: (
                          <MDTypography
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "700",
                              paddingTop: "5px",
                            }}
                          >
                            {x.name}
                          </MDTypography>
                        ),
                      }}
                      price={{ currency: "$", value: x.price, type: x.timeFrame }}
                      specifications={[
                        { label: `${x.platformAccess} social profile(s)`, includes: true },
                        { label: "Access to post composer and scheduler", includes: true },
                        { label: `${x.price == 0 ? "10GB" : "Unlimited"} media storage`, includes: true },
                        { label: `Access to ${x.themeAcess == 1 ? "premium" : "free"} themes and layouts`, includes: true },
                        { label: "Access to industry content", includes: x.industryAccess == 1 },
                        { label: `${x.contentNumber} content per month`, includes: true },
                      ]}
                      action={{
                        type: "internal",
                        route: () => callCheckout("price_1LUOAjSAnwstcjS1B0nVDFbH"),
                        color: darkMode ? "warning" : "dark",
                        label: "join",
                      }}
                      shadow={darkMode}
                    />
                  </Grid>
                )
              })}
            </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index="year">
          <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "35px" }}
            >
              <Grid xs={12} item sx={{ textAlign: "center" }}>
                <MDTypography sx={{ fontSize: "36px", fontWeight: 700 }}>Upgrade Your Account</MDTypography>
              </Grid>
              {packages && Array.isArray(packages) && packages.filter(a => a.timeFrame == "Year").map(x => {
                return (
                  <Grid key={x.id} item xs={12} lg={4}>
                    <DefaultPricingCard
                      color={darkMode ? "dark" : "white"}
                      badge={{
                        color: "dark",
                        label: (
                          <MDTypography
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "700",
                              paddingTop: "5px",
                            }}
                          >
                            {x.name}
                          </MDTypography>
                        ),
                      }}
                      price={{ currency: "$", value: x.price, type: x.timeFrame }}
                      specifications={[
                        { label: `${x.platformAccess} social profile(s)`, includes: true },
                        { label: "Access to post composer and scheduler", includes: true },
                        { label: `${x.price == 0 ? "10GB" : "Unlimited"} media storage`, includes: true },
                        { label: `Access to ${x.themeAcess == 1 ? "premium" : "free"} themes and layouts`, includes: true },
                        { label: "Access to industry content", includes: x.industryAccess == 1 },
                        { label: `${x.contentNumber} content per month`, includes: true },
                      ]}
                      action={{
                        type: "internal",
                        route: () => callCheckout("price_1LUOAjSAnwstcjS1B0nVDFbH"),
                        color: darkMode ? "warning" : "dark",
                        label: "join",
                      }}
                      shadow={darkMode}
                    />
                  </Grid>
                )
              })}
            </Grid>
        </TabPanel>
        </DialogContent>
      </Dialog>
  );
}
