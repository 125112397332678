/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const defaultLineChartData = {
  labels: ["Nov", "Dec", "Jan"],
  datasets: [
    {
      label: "Instagram",
      color: "error",
      data: [0, 0, 0],
    },
    {
      label: "Facebook",
      color: "info",
      data: [0, 0, 0],
    },
  ],
};

export default defaultLineChartData;
