import { useState } from "react";
// import ImgsViewer from "react-images-viewer";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import MDBox from "../../shared/components/MDBox";
import poster1 from "../../assets/images/theme1.png";
import poster2 from "../../assets/images/theme2.png";
import poster3 from "../../assets/images/theme3.png";
import poster4 from "../../assets/images/theme4.png";
// import poster5 from "../../assets/images/theme5.png";

export default function ProfileImages() {
  const [currentImage, setCurrentImage] = useState(poster1);
  const [imgsViewer, setImgsViewer] = useState(false);
  const [imgsViewerCurrent, setImgsViewerCurrent] = useState(0);

  const handleSetCurrentImage = ({ currentTarget }) => {
    setCurrentImage(currentTarget.src);
    setImgsViewerCurrent(Number(currentTarget.id));
  };

  const openImgsViewer = () => setImgsViewer(true);
  const closeImgsViewer = () => setImgsViewer(false);
  const imgsViewerNext = () => setImgsViewerCurrent(imgsViewerCurrent + 1);
  const imgsViewerPrev = () => setImgsViewerCurrent(imgsViewerCurrent - 1);

  return (
    <Grid>
      <Grid item>
        {/* <ImgsViewer
          imgs={[
            { src: poster1 },
            { src: poster2 },
            { src: poster3 },
            { src: poster4 },
            // { src: poster5 },
          ]}
          isOpen={imgsViewer}
          onClose={closeImgsViewer}
          currImg={imgsViewerCurrent}
          onClickPrev={imgsViewerPrev}
          onClickNext={imgsViewerNext}
          backdropCloseable
        /> */}

        <MDBox
          component="img"
          src={currentImage}
          alt="Product Image"
          shadow="lg"
          borderRadius="lg"
          width="100%"
          onClick={openImgsViewer}
        />
        <MDBox>
          <Stack direction="row">
            <MDBox
              component="img"
              id={1}
              src={poster2}
              alt="small image 2"
              borderRadius="2px"
              shadow="md"
              width="100%"
              height="2rem"
              minHeight="1rem"
              sx={{ cursor: "pointer", objectFit: "cover", marginRight: "5px" }}
              onClick={handleSetCurrentImage}
            />
            <MDBox
              component="img"
              id={2}
              src={poster3}
              alt="small image 3"
              borderRadius="2px"
              shadow="md"
              width="100%"
              height="2rem"
              minHeight="1rem"
              sx={{ cursor: "pointer", objectFit: "cover", marginRight: "5px" }}
              onClick={handleSetCurrentImage}
            />
            <MDBox
              component="img"
              id={3}
              src={poster4}
              alt="small image 4"
              borderRadius="2px"
              shadow="md"
              width="100%"
              height="2rem"
              minHeight="1rem"
              sx={{ cursor: "pointer", objectFit: "cover", marginRight: "5px" }}
              onClick={handleSetCurrentImage}
            />
            {/* <MDBox
              component="img"
              id={4}
              src={poster5}
              alt="small image 5"
              borderRadius="2px"
              shadow="md"
              width="100%"
              height="2rem"
              minHeight="1rem"
              sx={{ cursor: "pointer", objectFit: "cover" }}
              onClick={handleSetCurrentImage}
            /> */}
          </Stack>
        </MDBox>
      </Grid>
    </Grid>
  );
}
