import React, { useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "./components/MDBox";
import MDTypography from "./components/MDTypography";
import { makeStyles } from "@material-ui/styles";
import MDButton from "./components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import FormField from "./components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import post from "../assets/images/post-preview.png";
import SellIcon from "@mui/icons-material/Sell";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import TextField from '@mui/material/TextField';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Cancel } from "@mui/icons-material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const appUrl = process.env.REACT_APP_BASE_URL;

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const useStyles = makeStyles({
  listText: {
    "& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ": {
      color: "#344767",
      fontWeight: "500",
      fontSize: "12px",
    },
  },
  form: {
    '& .MuiInputBase-adornedStart': {
      flexWrap: "wrap"
    }
  },
  selct: {
    '& .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
        padding: "0px!important",
    },
    '& .css-ynad2y-MuiSvgIcon-root-MuiSelect-icon': {
        display: "block",
    },
  },
  iconText: {
    transform: "rotate(90deg)",
    color: "#28303F",
    marginTop: "10px"

  },
});
const topHead = {
  fontWeight: "700",
  fontSize: "28px",
  color: "#344767",
};
const box = {
  marginTop: "14px",
};
const mdButton = {
  color: "#344767",
  marginRight: "3px",
  borderColor: "#344767",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "12px",
  textTransform: "capitalize",
};
const button = {
  background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  color: "#FFFFFF",
  marginRight: "3px",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontWeight: "400",
  fontSize: "12px",
};
const btn = {
  background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  fontWeight: "700",
  fontSize: "12px",
  color: "#FFFFFF",
  textTransform: "inherit",
  marginTop: "36px",
};

const names = ["EOFY2022", "EOFY2019", "Other tags", "New one"];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      minWidth: "255px",
    },
  },
};

const Tags = ({ data, handleDelete }) => {
  return (
    <MDBox
      sx={{
        background: "#283240",
        height: "100%",
        display: "flex",
        padding: "5px 10px",
        margin: "0.2rem",
        justifyContent: "center",
        alignContent: "center",
        color: "#ffffff",
        borderRadius: "10px"
      }}
    >
      <Stack direction='row' gap={1}>
        <Typography sx={{ fontSize: "12px" }}>{data}</Typography>
        <Cancel
          sx={{ cursor: "pointer" }}
          onClick={() => {
            handleDelete(data);
          }}
        />
      </Stack>
    </MDBox>
  );
};

export default function ContentEditorDialog(props) {
  const classes = useStyles();
  const tagRef = useRef();
  const { selectedLayout, setSelectedLayout, setMediaOpen, mediaBlank, setMediaBlank, setMediaBlankOpen, setDynamicFields, setDynamics, dynamicFields, selectedAccount, openUnsplash, setOpenTag, tags, open, uploadBG, selected, setSelected, onClose, addContent, updateContent, profilesOpt, layouts } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openC = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [personName, setPersonName] = React.useState([]);
  const [contentType, setContentType] = React.useState("all");
  const [dimention, setDimention] = React.useState({width: 1200, height: 1200, zoom: .25});

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    uploadBG({ image: event.target.files[0] });
  };

  const checkEditor = type => {
    if (Array.isArray(selected.ProfileIds)) {
      const x = selected.ProfileIds.filter(y => y.type == type);
      return x.length > 0;
    }
  }
  
  function loadIframe(x) {
    const { accentColor, darkColor, lightColor } = selectedAccount;
    let css = `
      div.grid {
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        height: calc(100vh - 30%);
        border: 2px dashed #bababa;
        display: none;
      }
      div:hover~.grid {
          display: block;
      }
      .colorAccent {
        color: ${accentColor}
      }
      .colorDark {
          color: ${darkColor}
      }
      .colorLight {
          color: ${lightColor}
      }
      .bgAccent {
          background-color: ${accentColor}
      }
      .bgDark {
          background-color: ${darkColor}
      }
      .bgLight {
          background-color: ${lightColor}
      }
      .hcenter {
        position: absolute;
        left: 50%!important;
        transform: translate(-50%, 0);
      }
      .hleft {
          position: absolute;
          left: 0px!important;
      }
      .hright {
          position: absolute;
          right: 0px!important;
      }
      .vcenter {
          position: absolute;
          top: 50%!important;
          transform: translate(-50%);
      }
      .vtop {
          position: absolute;
          top: 0px!important;
      }
      .vbottom {
          position: absolute;
          top: 100%!important;
          transform: translate(0, -100%);
      }
      .imageItem {
          background-image: url("/logo512.png");
          background-size: cover;
      }
      .delete-icon {
        display: none;
      }
      .layerSelect {
        background-color: #eaeaea;
        color: red;
      }`;
      const { contentWindow } = document.getElementById("selected-layout");
      const lay = x.layouts.filter(l => l.platform == contentType);
      let selectedOne = {};
      if (lay.length > 0){
        selectedOne = lay[0];
      } else {
        selectedOne = x.layouts[0];
      }
      let m = selectedOne.width > selectedOne.height ? selectedOne.width : selectedOne.height;
      let z = (100 / (m / 300) / 100);
      setDimention({ width: selectedOne.width, zoom: z, height: selectedOne.height });
      const main = contentWindow.document.body;
      main.innerHTML = `${selectedOne.html}`;
      let head = contentWindow.document.head || contentWindow.document.getElementsByTagName('head')[0],
      style = contentWindow.document.createElement('style');
      head.appendChild(style);
      style.type = 'text/css';
      if (style.styleSheet){
        style.styleSheet.cssText = css;
      } else {
        style.appendChild(document.createTextNode(css));
      }
      let replaces = main.querySelectorAll(".businessItem");
      [].forEach.call(replaces, function(replace) {
        let replaceable = replace.parentNode.parentNode.getAttribute("data-dynamic-field");
        if(replaceable !== ""){
          replace.innerHTML = selectedAccount[replaceable];
        }
      });
        
        let replacess = main.querySelectorAll(".textItem");
        [].forEach.call(replacess, function(replace) {
            let replaceable = replace.parentNode.parentNode.getAttribute("data-dynamic-field");
            if(replaceable !== ""){
              replace.innerHTML = selectedAccount[replaceable];
            }
          });
        let replacesi = main.querySelectorAll(".imageItem");
          [].forEach.call(replacesi, function(replace) {
            console.log("Replace: ", replace);
              let replaceable = replace.parentNode.parentNode.getAttribute("data-dynamic-field");
              console.log("Replacable: ", replaceable);
              if(replaceable !== ""){
                replace.style["background-image"] = "url(" + selectedAccount[replaceable] + ")";
                replace.style["background-position"] = "center";
                replace.style["background-repeat"] = "no-repeat";
                replace.style["background-size"] = "cover";
              }
          });
        if (x.dynamics){
          const data = {
            type: "setup",
            data: {
              dynamicFields: x.dynamics
            }
          }
          setTimeout(() => document.getElementById("selected-layout").contentWindow.postMessage(JSON.stringify(data), appUrl), 100);
        }
  }

  useEffect(() => {
    if(dynamicFields) {
      const data = {
        type: "setup",
        data: {
          dynamicFields
        }
      }
      setTimeout(() => document.getElementById("selected-layout").contentWindow.postMessage(JSON.stringify(data), appUrl), 100);
    }
  }, [dynamicFields]);

  useEffect(() => {
    if(dynamicFields) {
      const data = {
        type: "setup",
        data: {
          dynamicFields
        }
      }
      setTimeout(() => document.getElementById("selected-layout").contentWindow.postMessage(JSON.stringify(data), appUrl), 100);
    }
  }, []);

  useEffect(() => {
    if(selected.layouts != "") {
      loadIframe({...selected, dynamics: dynamicFields });
    }
  }, [contentType]);

  async function setDynamicFieldsByArray(arr) {
    let obj = {};
    await Promise.all(arr.map(x => {
      obj[x] = ""  
    }));
    setDynamicFields(obj);
  }

  const handleDelete = (value) => {
    const newtags = selected.hashtags && Array.isArray(selected.hashtags[contentType]) && selected.hashtags[contentType].filter((val) => val !== value);
    if (contentType == "all") {
      setSelected({
        ...selected,
        hashtags: {
          ...selected.hashtags,
          all: newtags ? newtags : []
        }
      })
    } else if (contentType == "facebook"){
      setSelected({
        ...selected,
        hashtags: {
          ...selected.hashtags,
          facebook: newtags ? newtags : []
        }
      });
    } else if (contentType == "insta"){
      setSelected({
        ...selected,
        hashtags: {
          ...selected.hashtags,
          insta: newtags ? newtags : []
        }
      });
    } else if (contentType == "gmb"){
      setSelected({
        ...selected,
        hashtags: {
          ...selected.hashtags,
          gmb: newtags ? newtags : []
        }
      });
    } else if (contentType == "linkedin"){
      setSelected({
        ...selected,
        hashtags: {
          ...selected.hashtags,
          linkedin: newtags ? newtags : []
        }
      });
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let val = "";
    if (tagRef.current.value.trim() != "") {
      if (/^#/i.test(tagRef.current.value)) {
        val = tagRef.current.value;
      } else {
        val = "#" + tagRef.current.value;
      }
      if (contentType == "all") {
        setSelected({
          ...selected,
          hashtags: {
            ...selected.hashtags,
            all: [...selected.hashtags.all, val]
          }
        })
      } else if (contentType == "facebook"){
        setSelected({
          ...selected,
          hashtags: {
            ...selected.hashtags,
            facebook: [...selected.hashtags.facebook, val]
          }
        });
      } else if (contentType == "insta"){
        setSelected({
          ...selected,
          hashtags: {
            ...selected.hashtags,
            insta: [...selected.hashtags.insta, val]
          }
        });
      } else if (contentType == "gmb"){
        setSelected({
          ...selected,
          hashtags: {
            ...selected.hashtags,
            gmb: [...selected.hashtags.gmb, val]
          }
        });
      } else if (contentType == "linkedin"){
        setSelected({
          ...selected,
          hashtags: {
            ...selected.hashtags,
            linkedin: [...selected.hashtags.linkedin, val]
          }
        });
      }
      tagRef.current.value = "";
    }
  };

  async function saveContent() {
    let htm = "";
    let allLayouts = "";
        if (selected.id){
          if (selected.layouts != "") {
            updateContent({
              ...selected,
              scTime: selected.scTime.toLocaleString("en-US"),
              hashtags: JSON.stringify({all: selected.hashtags.all.join(" "), facebook: selected.hashtags.facebook.join(" "), insta: selected.hashtags.insta.join(" "), gmb: selected.hashtags.gmb.join(" "), linkedin: selected.hashtags.linkedin.join(" ") }),
              dynamics: JSON.stringify(dynamicFields),
              layouts: JSON.stringify(selected.layouts),
              images: JSON.stringify([{ type: "all", image: selected.images }])
            });
          } else {
            updateContent({
              ...selected,
              scTime: selected.scTime.toLocaleString("en-US"),
              images: JSON.stringify([{ type: "all", image: selected.images }]),
              dynamics: JSON.stringify(dynamicFields),
              hashtags: JSON.stringify({all: selected.hashtags.all.join(" "), facebook: selected.hashtags.facebook.join(" "), insta: selected.hashtags.insta.join(" "), gmb: selected.hashtags.gmb.join(" "), linkedin: selected.hashtags.linkedin.join(" ") })
            });
          }
        } else {
          if (selected.layouts != "") {
            addContent({
              ...selected,
              scTime: selected.scTime.toLocaleString("en-US"),
              hashtags: JSON.stringify({all: selected.hashtags.all.join(" "), facebook: selected.hashtags.facebook.join(" "), insta: selected.hashtags.insta.join(" "), gmb: selected.hashtags.gmb.join(" "), linkedin: selected.hashtags.linkedin.join(" ") }),
              dynamics: JSON.stringify(dynamicFields),
              layouts: JSON.stringify(selected.layouts),
              images: JSON.stringify([{ type: "all", image: selected.images }])
            });
          } else {
            addContent({
              ...selected,
              scTime: selected.scTime.toLocaleString("en-US"),
              images: JSON.stringify([{ type: "all", image: selected.images }]),
              dynamics: JSON.stringify(dynamicFields),
              hashtags: JSON.stringify({all: selected.hashtags.all.join(" "), facebook: selected.hashtags.facebook.join(" "), insta: selected.hashtags.insta.join(" "), gmb: selected.hashtags.gmb.join(" "), linkedin: selected.hashtags.linkedin.join(" ") })
            });
          }
        }
}

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={7}>
                <MDTypography style={topHead}>Content Editor</MDTypography>
                <MDBox style={{ display: "flex", marginTop: "32px" }}>
                  <Autocomplete
                    style={{ minWidth: "374px" }}
                    multiple
                    options={profilesOpt || []}
                    value={selected.ProfileIds || []}
                    onChange={(event, newValue) => {
                      setSelected({
                        ...selected,
                        ProfileIds: newValue,
                      });
                    }}
                    renderInput={(params) => (
                      <FormField
                        multiple
                        {...params}
                        label="Select Your Account"
                        name="accountId"
                        placeholder="select your account"
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  />
                  <IconButton
                    style={{ marginLeft: "auto" }}
                    aria-label="more"
                    id="long-button"
                    aria-controls={openC ? "long-menu" : undefined}
                    aria-expanded={openC ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <SellIcon className={classes.iconText} />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={openC}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        height: "auto",
                        width: "272px",
                        padding: "20px 17px",
                        background: "#FFFFFF",
                        boxShadow:
                          "0px 16px 24px rgb(0 0 0 / 14%), 0px 6px 30px rgb(0 0 0 / 12%), 0px 8px 10px rgb(0 0 0 / 20%)",
                        borderRadius: "8px",
                      },
                    }}
                  >
                    <MDTypography
                      style={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: " #7B809A",
                        marginBottom: "17px",
                      }}
                    >
                      Tag as:
                    </MDTypography>
                    <FormControl variant="standard" fullWidth >
                        {/* <InputLabel id="demo-simple-select-standard-label">
                            EOFY
                        </InputLabel> */}
                        <Select
                          className={classes.selct}
                          multiple
                          displayEmpty
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={personName}
                          onChange={handleChange}
                          renderValue={(selected) => {
                              if (selected.length === 0) {
                              return <em style={{color: "#B5B3BF"}}>EOFY</em> ;
                              }

                              return selected.join(", ");
                          }}
                          MenuProps={MenuProps}
                        >   
                          {tags.map((name) => (
                              <MenuItem key={name.label} value={name.label}>
                              <ListItemText sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontWeight: "400"
                                },
                              }} primary={name.label} />
                              <Checkbox checked={tags.indexOf(name.label) > -1} />
                              </MenuItem>
                          ))}
                        </Select>
                    </FormControl>
                    {/* <Divider variant="middle" /> */}
                    <MDTypography
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.6)",
                        marginTop: "20px",
                        cursor: "pointer"
                      }}
                      onClick={() => setOpenTag(true)}
                    >
                      Create new
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.6)",
                        marginTop: "15px",
                        cursor: "pointer"
                      }}
                    >
                      Manage tags
                    </MDTypography>
                  </Menu>
                  {/* <SellIcon style={{marginLeft: "auto", marginTop: "20px"}} /> */}
                </MDBox>
                <MDBox sx={{ mt: 2 }}>
                  <MDBox sx={{ backgroundColor: "#E9E7E7", padding: "10px 20px", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          onClick={() => setContentType("all")}
                          style={contentType == "all" ? { background: "#FFFFFF",
                          boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)" } : {}}
                          sx={{ 
                            fontWeight: 700,
                            fontSize: '12px',
                            padding: "2px 3px",
                            borderRadius: "3px",
                            marginRight: "10px",
                          }}
                        >
                          All
                        </Typography>
                        {checkEditor("insta") && (
                          <InstagramIcon
                          onClick={() => setContentType("insta")}
                          style={contentType == "insta" ? {
                            background: "#FFFFFF",
                            boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                            borderRadius: "4px",
                            padding: "1px",
                            marginRight: "12px",
                            width: "18px",
                            height: "18px",
                            color: "#28303F"
                          } : {
                            borderRadius: "4px",
                            padding: "1px",
                            marginRight: "12px",
                            width: "18px",
                            height: "18px",
                            color: "#28303F"
                          }}
                        />
                        )}
                        {checkEditor("facebook") && (
                          <FacebookRoundedIcon
                          onClick={() => setContentType("facebook")}
                          style={contentType == "facebook" ? {
                            background: "#FFFFFF",
                            boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                            borderRadius: "4px",
                            padding: "1px",
                            marginRight: "12px",
                            width: "18px",
                            height: "18px",
                            color: "#28303F"
                          } : {
                            borderRadius: "4px",
                            padding: "1px",
                            marginRight: "12px",
                            width: "18px",
                            height: "18px",
                            color: "#28303F"
                          }}
                        />
                        )}
                        {checkEditor("linkedin") && (
                          <LinkedInIcon
                          onClick={() => setContentType("linkedin")}
                          style={contentType == "linkedin" ? {
                            background: "#FFFFFF",
                            boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                            borderRadius: "4px",
                            padding: "1px",
                            marginRight: "12px",
                            width: "18px",
                            height: "18px",
                            color: "#28303F"
                          } : {
                            borderRadius: "4px",
                            padding: "1px",
                            marginRight: "12px",
                            width: "18px",
                            height: "18px",
                            color: "#28303F"
                          }}
                        />
                        )}
                        {checkEditor("gmb") && (
                          <StorefrontRoundedIcon
                          onClick={() => setContentType("gmb")}
                          style={contentType == "gmb" ? {
                            background: "#FFFFFF",
                            boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                            width: "18px", height: "18px", color: "#28303F"
                          } : {
                            width: "18px", height: "18px", color: "#28303F"
                          }}
                        />
                        )}
                      </div>
                  </MDBox>
                  <textarea
                    style={{ width: "100%", height: "170px", padding: "10px 15px" }}
                    onChange={e => {
                      if (contentType === "all") {
                        setSelected({
                          ...selected,
                          content: {
                            ...selected.content,
                            all: e.target.value,
                            facebook: e.target.value,
                            insta: e.target.value,
                            linkedin: e.target.value,
                            gmb: e.target.value,
                          }
                        });
                      } else if (contentType === "facebook") {
                        setSelected({
                          ...selected,
                          content: {
                            ...selected.content,
                            facebook: e.target.value
                          }
                        });
                      } else if (contentType === "insta") {
                        setSelected({
                          ...selected,
                          content: {
                            ...selected.content,
                            insta: e.target.value,
                          }
                        });
                      } else if (contentType === "linkedin") {
                        setSelected({
                          ...selected,
                          content: {
                            ...selected.content,
                            linkedin: e.target.value
                          }
                        });
                      } else {
                        setSelected({
                          ...selected,
                          content: {
                            ...selected.content,
                            gmb: e.target.value,
                          }
                        });
                      }
                    }}
                    value={selected.content[contentType]}
                  ></textarea>
                </MDBox>
                <MDTypography
                  style={{
                    fontWeight: "700",
                    fontSize: "18px",
                    color: "#000000",
                    marginTop: "24px",
                  }}
                >
                  Layouts
                </MDTypography>
                <Carousel
                  swipeable={true}
                  draggable={false}
                  autoPlay={false}
                  responsive={responsive}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  itemClass="carousel-item-margin-20-px"
                >
                  <div
                    style={{ position: "relative", overflow: "hidden", maxHeight: "150px" }}
                  >
                    {mediaBlank ? (
                      <>
                        <div onClick={() => {
                          setMediaBlank(false);
                          }}
                          style={{ position: "absolute", top: "15px", left: "15px", cursor: "pointer" }}>
                              <div style={{ fontSize: "12px", textAlign: "center", width: "20px", height: "20px", background: "#fff", borderRadius: "50%" }}>x</div>
                        </div>
                      <img src={mediaBlank} width="100%"/>
                    </>
                    ) : (
                    <MDBox
                      style={{
                        background: "#FFFFFF",
                        border: "1px dashed #848195",
                        borderRadius: "8px",
                        alignItems: "center",
                        textAlign: "center",
                        padding: "27px",
                        justifyContent: "center",
                      }}
                      onClick={() => setMediaBlankOpen(true)}
                    >
                      <AddIcon style={{ color: "#28303F" }} />
                      <MDTypography
                        style={{
                          fontWeight: "500",
                          fontSize: "16px",
                          color: "#000000",
                        }}
                      >
                        Blank
                      </MDTypography>
                    </MDBox>
                    )}
                  </div>
                  {
                    layouts && layouts.map(x => {
                      return (
                        <div
                          style={{ position: "relative", overflow: "hidden", maxHeight: "150px" }}
                          >
                            {selectedLayout === x.id ? (
                            <div onClick={() => {
                              setSelected({
                                ...selected,
                                layouts: ""
                              })
                              setSelectedLayout("");
                              setDynamicFields({});
                              }}
                              style={{ position: "absolute", top: "15px", left: "15px", cursor: "pointer" }}>
                                  <div style={{ fontSize: "12px", textAlign: "center", width: "20px", height: "20px", background: "#fff", borderRadius: "50%" }}>x</div>
                            </div>
                            ) : ("")}
                          <img
                            src={x.image} width="100%" onClick={() => {
                            setSelected({ ...selected, layouts: "" });
                            setSelectedLayout(x.id);
                            setDynamicFieldsByArray(JSON.parse(x.MasterLayout.fields));
                            setTimeout(() => setSelected({ ...selected, layouts: JSON.parse(x.data) }), 20);
                            setTimeout(() => loadIframe({...selected, layouts: JSON.parse(x.data)}), 30);
                            }}
                            style={selectedLayout === x.id ? { border: "1px solid #6a6a6a", borderRadius: "8px" } : {border: "1px dashed #6a6a6a", borderRadius: "8px"}}
                          />
                        </div>
                      );
                    })
                  }
                </Carousel>
                <MDBox>
                  {dynamicFields && Object.keys(dynamicFields).map((x) => {
                    if(["Text Field 1", "Text Field 2", "Text Field 3", "Text Field 4", "Text Field 5", "Text Field 6", "Text Paragraph"].indexOf(x) !== -1) {
                      return (
                        <FormField
                          label={x}
                          style={{ marginBottom: "15px" }}
                          type="text"
                          placeholder={x}
                          value={dynamicFields[x]}
                          onChange={e => setDynamics(x, e.target.value)}
                        />
                      )
                    } else {
                      return (
                        <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                          <MDTypography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#7B809A",
                              marginTop: "24px",
                            }}
                          >
                            Image
                          </MDTypography>
                          {dynamicFields[x] && (
                            <Grid container spacing={1} style={{ marginTop: "16px" }}>
                                <Grid item xs={12} sm={3}>
                                  <div onClick={() => {
                                    setDynamicFields({ ...dynamicFields, "Image Library": null })
                                  }}
                                  style={{ position: "relative", top: "25px", left: "10px", cursor: "pointer" }}>
                                    <div style={{ fontSize: "12px", textAlign: "center", width: "20px", height: "20px", background: "#fff", borderRadius: "50%" }}>x</div>
                                  </div>
                                  <img src={dynamicFields[x]} alt="layout-image" width="100%"/>
                                </Grid>
                            </Grid>
                          )}
                          <MDBox style={box}>
                            <MDButton variant="outlined" style={mdButton} onClick={() => setMediaOpen(true)}>
                              Image Library
                            </MDButton>
                              <label htmlFor="header-image">
                              <MDButton variant="outlined" component={Typography} style={mdButton}>
                                Upload
                              </MDButton>
                              </label>
                            <input
                              accept="image/*"
                              id="header-image"
                              type="file"
                              onChange={onImageChange}
                              style={{ display: "none" }}
                            />
                            <MDButton variant="outlined" style={mdButton} onClick={() => openUnsplash()}>
                              Unsplash
                            </MDButton>
                          </MDBox>
                        </div>
                      )
                    }
                  })}
                </MDBox>
              </Grid>
              {/* <Divider orientation="vertical" /> */}
              <Grid item xs={12} md={5}>
                <MDBox style={{ float: "right" }}>
                  <ClearIcon
                    onClick={() => onClose()}
                    style={{ width: "18px", height: "18px", color: "#000000" }}
                  />
                </MDBox>
                <MDBox style={{ display: "flex", marginTop: "32px" }}>
                  <MDTypography
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      color: "#000000",
                    }}
                  >
                    Post Preview
                  </MDTypography>
                  <MDBox style={{
                    marginLeft: "auto",
                    background: "rgb(245, 245, 245)",
                    borderRadius: "4px",
                    padding: "4px",
                    display: "flex",
                    alignItems: "center"}}
                  >
                    {checkEditor("insta") && (
                      <InstagramIcon
                      onClick={() => setContentType("insta")}
                      style={contentType == "insta" ? {
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      } : {
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      }}
                    />
                    )}
                    {checkEditor("facebook") && (
                      <FacebookRoundedIcon
                      onClick={() => setContentType("facebook")}
                      style={contentType == "facebook" ? {
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      } : {
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      }}  
                    />
                    )}
                    {checkEditor("linkedin") && (
                      <LinkedInIcon
                      onClick={() => setContentType("linkedin")}
                      style={contentType == "linkedin" ? {
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      } : {
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      }}
                    />
                    )}
                    {checkEditor("gmb") && (
                      <StorefrontRoundedIcon
                      onClick={() => setContentType("gmb")}
                      style={contentType == "gmb" ? {
                        background: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgb(0 0 0 / 25%)",
                        borderRadius: "4px",
                        padding: "1px",
                        marginRight: "12px",
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      } : {
                        width: "18px",
                        height: "18px",
                        color: "#28303F"
                      }}
                    />
                    )}  
                  </MDBox>
                </MDBox>
                <MDBox style={{ textAlign: "left", marginTop: "55px" }}>
                  {selected.layouts && selected.layouts != "" ? (
                    <div style={{ width: "300px", height: "300px", overflow: "hidden" }}>
                      <iframe
                        className="iframe-placeholder"
                        frameBorder="0"
                        src={`${process.env.REACT_APP_BASE_URL}/#/layout-frame`}
                        width={`${dimention.width}px`}
                        height={`${dimention.height}px`}
                        id="selected-layout"
                        style={{
                          transform: `scale(${dimention.zoom})`,
                          MsTransform: `scale(${dimention.zoom})`,
                          OTransform: `scale(${dimention.zoom})`,
                          MozTransform: `scale(${dimention.zoom})`,
                          WebkitTransform: `scale(${dimention.zoom})`,
                          transformOrigin: "0 0",
                          MsTransformOrigin: "0 0",
                          OTransformOrigin: "0 0",
                          WebkitTransformOrigin: "0 0"
                        }}
                        onLoad={(event) => loadIframe(selected)}
                      />
                    </div>
                  ) : (
                    <img src={selected.images ? selected.images : post} alt="post-preview" width="70%" />
                  )}
                  <p dangerouslySetInnerHTML={{ __html: selected.content[contentType].replace(/\n/g, "<br />") }}
                    style={{ color: "#1B1C29", fontSize: "16px", marginTop: "10px", fontWeight: "400" }}
                  ></p>
                  {selected.AccountId != "" ? "" : (
                    <MDTypography
                      style={{
                        fontWeight: "400",
                        fontSize: "16px",
                        color: "#1B1C29",
                        marginTop: "10px",
                      }}
                    >
                      You have not selected any social account yet!
                    </MDTypography>
                  )}
                    <form className={classes.form} onSubmit={e => handleOnSubmit(e)}>
                        <TextField
                          inputRef={tagRef}
                          fullWidth
                          disabled={selected.hashtags && Array.isArray(selected.hashtags[contentType]) && selected.hashtags[contentType].length >= 30}
                          variant='standard'
                          size='small'
                          sx={{ margin: "1rem 0" }}
                          margin='none'
                          placeholder={selected.hashtags && Array.isArray(selected.hashtags[contentType]) && selected.hashtags[contentType].length < 30 ? "Enter hashtags" : ""}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                {selected && Array.isArray(selected.hashtags[contentType]) && selected.hashtags[contentType].map((data, index) => {
                                  return (
                                    <Tags data={data} handleDelete={handleDelete} key={index} />
                                  );
                                })}
                              </React.Fragment>
                            ),
                          }}
                        />
                      </form>
                  
                  <MDBox style={box} sx={{ py: "30px" }}>
                    <MDButton size="small" onClick={() => setSelected({ ...selected, status: 6 })} variant="outlined" style={selected.status == 6 ? button: mdButton}>
                      Post Now
                    </MDButton>
                    <MDButton size="small" onClick={() => setSelected({ ...selected, status: 5 })} variant="outlined" style={selected.status == 5 ? button: mdButton}>
                      Schedule
                    </MDButton>
                    <MDButton size="small" onClick={() => setSelected({ ...selected, status: 4 })} variant="outlined" style={selected.status == 4 ? button: mdButton}>
                      Add to queue
                    </MDButton>
                    <MDButton size="small" onClick={() => setSelected({ ...selected, status: 1 })} style={selected.status == 1 ? button: mdButton} variant="outlined">
                      Draft
                    </MDButton>
                  </MDBox>
                  {selected.status == 5 && (
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DateTimePicker
                        inputFormat="dd/MM/yyyy hh:ii a"
                        className="dateTimePicker"
                        label="Date"
                        value={selected.scTime}
                        onChange={(newValue) => {
                          setSelected({ ...selected, scTime: newValue });
                        }}
                        renderInput={(params) => <TextField variant="standard" {...params} />}
                      />
                    </Grid>
                  </Grid>
                  )}
                </MDBox>
                <MDButton
                  style={btn}
                  variant="contained"
                  onClick={() => {
                    saveContent();
                  }}>
                    {selected.status == 1 ? "Save as draft" : selected.status == 6 ? "Post Now" : selected.status == 4 ? "Add to queue" : "Schedule Post"}                  
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
