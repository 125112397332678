import { useState, useEffect } from "react";
import MDBox from "../../shared/components/MDBox";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import DataTableData from "../../shared/components/DataTable/data_table/dataTableData";
import DataContent from "../../shared/components/DataTable/data_table/index";
import breakpoints from "../../assets/theme/base/breakpoints";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MDTypography from "../../shared/components/MDTypography";
import burceMars from "../../assets/images/bruce-mars.jpg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import MDAvatar from "../../shared/components/MDAvatar";

const standerdplan = {
    backgroundColor: "#4CAE67",
    borderRadius: "12.5px",
    color: "#FFFFFF",
    width: "122px",
    height: "25px",
    paddingTop: "3px",
    fontSize: "12px",
    marginTop: "10px",
  };

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <MDBox sx={{ p: 3 }}>{children}</MDBox>}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

export default function UserDetailsPage() {
    const [tabsOrientation, setTabsOrientation] = useState("horizontal");
    const [tabValue, setTabValue] = useState(0);

    useEffect(() => {
        function handleTabsOrientation() {
          return window.innerWidth < breakpoints.values.sm
            ? setTabsOrientation("vertical")
            : setTabsOrientation("horizontal");
        }
    
        window.addEventListener("resize", handleTabsOrientation);
    
        handleTabsOrientation();
    
        return () => window.removeEventListener("resize", handleTabsOrientation);
      }, [tabsOrientation]);
    
      const handleSetTabValue = (event, newValue) => setTabValue(newValue);
    
  return (
    <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
            <Card>
                <MDBox mb={3}>
                    <MDBox pl={3} pt={2}>
                        <MDTypography style={{ fontWeight: "700", fontSize: "16px", color: "#344767", marginBottom: "32px" }}>
                            <ArrowBackIcon style={{ color: "#000000", marginBottom: "-2px" }} /> Back to list
                        </MDTypography>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <MDAvatar variant="rounded" src={burceMars} alt="profile-image" size="xl" shadow="sm" />
                            </Grid>
                            <Grid item>
                                <MDBox height="100%" lineHeight={1}>
                                <MDTypography
                                    style={{
                                    fontWeight: "700",
                                    fontSize: "20px",
                                    lineHeight: "140%",
                                    color: "#344767",
                                    marginBottom: "5px"
                                    }}
                                >
                                    User Name
                                </MDTypography>
                                <MDTypography
                                    style={{
                                    fontWeight: "600",
                                    fontSize: "14px",
                                    lineHeight: "140%",
                                    color: "#344767",
                                    marginBottom: "10px"
                                    }}
                                >
                                    Last Login In: <span style={{fontWeight: "400", fontSize: "14px", color: "#848195"}}>20 Jun 2022</span>
                                </MDTypography>
                                <MDTypography
                                    style={{
                                    fontWeight: "700",
                                    fontSize: "12px",
                                    lineHeight: "140%",
                                    color: "#7B809A",
                                    textTransform: "uppercase",
                                    }}
                                >
                                    <ArrowForwardIcon /> SWITCH TO BUSINESS
                                </MDTypography>
                                </MDBox>
                            </Grid>
                        </Grid>
                    </MDBox>
                    <Grid container pl={3}>
                        <Grid item xs={12} sm={8} lg={4}>
                            <AppBar style={{ marginTop: "36px" }} position="static">
                                <Tabs 
                                    style={{background: "#F5F5F5", border: "1px solid #E9E7E7",
                                    borderRadius: "8px"}}
                                    orientation={tabsOrientation} 
                                    value={tabValue} 
                                    onChange={handleSetTabValue}>
                                    <Tab label="All Posts" {...a11yProps(0)} />
                                    <Tab label="Upcoming" {...a11yProps(1)} />
                                    <Tab label="Last Scheduled" {...a11yProps(2)} />
                                </Tabs>
                            </AppBar>
                        </Grid>
                    </Grid>
                    <TabPanel value={tabValue} index={0}>
                        <DataContent table={DataTableData} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <DataContent table={DataTableData} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                        <DataContent table={DataTableData} />
                    </TabPanel>
                </MDBox>
                
            </Card>
        </MDBox>
    </DashboardLayout>
  );
}
