import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ClearIcon from "@mui/icons-material/Clear";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Grid } from "@mui/material";
import MDBox from "../../shared/components/MDBox";
import MDProgress from "../../shared/components/MDProgress";
import MDButton from "../../shared/components/MDButton";
import MDAvatar from "../../shared/components/MDAvatar";
import MDTypography from "../../shared/components/MDTypography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import vw from "./../../assets/images/vw.png";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";

export default function WatermarkPage() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const X = 200;
  const Y = -53;
  const logo = {
    transform: `translate(${X}px, ${Y}px)`,
  };

  return (
    <div>
      <Button variant="contained" color="warning" onClick={handleClickOpen}>
        Open responsive dialog
      </Button>
      <Dialog
        sx={{
          "& .css-1dur2uz-MuiPaper-root-MuiDialog-paper": {
            height: "705px !important",
          },
        }}
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <Grid container>
          <Grid item sm={6} md={6} lg={6}>
            <DialogTitle id="responsive-dialog-title">
              {"Change Water Mark"}
            </DialogTitle>
            <MDTypography
              style={{
                color: "#7B809A",
                fontSize: "14px",
                fontWeight: "600",
                marginLeft: "18px",
                marginTop: "-16px",
              }}
            >
              Upload Watermark
            </MDTypography>
          </Grid>
          <Grid item sm={6} md={6} lg={6}>
            <MDBox
              style={{
                background: "#E6E8EB",
                height: "40px",
                width: "40px",
                borderRadius: "100px",
                float: "right",
                marginTop: "24px",
                marginRight: "24px",
              }}
            >
              <ClearIcon style={{ margin: "10px 10px" }} />
            </MDBox>
          </Grid>
        </Grid>
        <DialogContent>
          <MDBox
            ml={0}
            component="label"
            style={{
            //   width: "463px",
              height: "96px",
              background: "#E6E6EA",
              border: "0.25px dashed #09032B",
              borderRadius: "11px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "19px",
            }}
          >
            <input
              accept="image/*"
              id="header-image"
              type="file"
              //   onChange={onImageChange}
              style={{ display: "none" }}
            />
            <CloudUploadIcon
              style={{
                width: "46px",
                height: "30.67px",
                color: "#09032B",
              }}
            />
            <MDBox>
              <MDTypography
                style={{
                  fontWeight: "500",
                  marginBottom: "5px",
                  fontSize: "14px",
                  lineHeight: "16px",
                  color: "#000000",
                  fontFamily: "Roboto",
                }}
              >
                Upload Logo
              </MDTypography>
              <MDBox>
                <MDTypography
                  style={{
                    fontWeight: "300",
                    fontSize: "12px",
                    lineHeight: "14px",
                    color: "#8097B1",
                    fontFamily: "Roboto",
                  }}
                >
                  JPEG,PNG etc
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDTypography
            style={{
              color: "#7B809A",
              fontSize: "14px",
              fontWeight: "400",
              marginTop: "11px",
            }}
          >
            Image dimention 128PX - 128PX
          </MDTypography>
          <Grid container style={{ marginTop: "23px" }}>
            <Grid item sm={2} md={2} lg={2}>
              <MDAvatar
                variant="Square"
                src={vw}
                style={{ height: "41px", width: "41px" }}
              />
            </Grid>
            <Grid item sm={10} md={10} lg={10}>
              <Grid container>
                <Grid item sm={6} md={6} lg={6}>
                  <MDTypography
                    style={{
                      color: "#344767",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    image.jpg{" "}
                    <ClearIcon
                      style={{ color: "#AEAEAE", marginBottom: "-4px" }}
                    />
                  </MDTypography>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <MDTypography
                    style={{
                      color: "#7B809A",
                      fontSize: "14px",
                      fontWeight: "400",
                      float: "right",
                    }}
                  >
                    50%
                  </MDTypography>
                </Grid>
              </Grid>
              <MDProgress
                value="30"
                variant="gradient"
                color="success"
                style={{ marginTop: "14px" }}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "34px" }}>
            <Grid item sm={12} md={7} lg={7}>
              <MDBox
                style={{
                  background: "#7B809A",
                  height: "261px",
                  width: "264px",
                  textAlign: "center",
                }}
              >
                <MDTypography
                  style={{
                    color: "#ffffff",
                    fontSize: "14px",
                    fontWeight: "400",
                    padding: "113px 0px",
                  }}
                >
                  Place watermark using the arrow
                </MDTypography>
                <MDAvatar variant="square" style={logo} src={vw} />
              </MDBox>
            </Grid>
            <Grid item sm={12} md={5} lg={5}>
              <MDBox style={{ textAlign: "center", marginTop: "29px" }}>
                <MDTypography
                  style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Position
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#C7CCD0",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Left Button
                </MDTypography>
              </MDBox>
              <MDBox style={{ marginTop: "18px" }}>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Scale
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    placeholder="Enter scale parameter"
                    //   value={values.amount}
                    //   onChange={handleChange("amount")}
                    startAdornment={
                      <InputAdornment position="start">%</InputAdornment>
                    }
                  />
                </FormControl>
              </MDBox>
              <MDBox style={{ marginTop: "24px" }}>
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">
                    Opacity
                  </InputLabel>
                  <Input
                    id="standard-adornment-amount"
                    placeholder="Enter scale parameter"
                    //   value={values.amount}
                    //   onChange={handleChange("amount")}
                    startAdornment={
                      <InputAdornment position="start">%</InputAdornment>
                    }
                  />
                </FormControl>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              sm={12}
              md={12}
              lg={12}
              style={{ marginTop: "30px", textAlign: "center" }}
            >
              <MDButton
                variant="contained"
                style={{ width: "80%", background: "#09032B", color: "#fff" }}
              >
                UPDATE DETAILS
              </MDButton>
            </Grid>
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Disagree
          </Button>
          <Button onClick={handleClose} autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
