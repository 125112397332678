import * as actionTypes from "./contentActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const addContent = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(addContentInit());
        axios.post(`${baseUrl}/content/store`, data, config)
        .then((response)=>{
            dispatch(addContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addContentFailure());
        })
    };
};

export const regenerateContent = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(regenerateContentInit());
        axios.post(`${baseUrl}/content/regenerate-content`, data, config)
        .then((response)=>{
            dispatch(regenerateContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(regenerateContentFailure());
        })
    };
};

export const approveContent = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(approveContentInit());
        axios.put(`${baseUrl}/content/approve/${id}`, {}, config)
        .then((response)=>{
            dispatch(approveContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(approveContentFailure());
        })
    };
};

export const approveContentInit = () => ({
    type: actionTypes.APPROVE_CONTENT
});

export const approveContentFailure = data => ({
    type: actionTypes.APPROVE_CONTENT_FAILURE
});

export const approveContentSuccess = data => ({
    type: actionTypes.APPROVE_CONTENT_SUCCESS,
    payload: { data }
});

export const regenerateContentInit = () => ({
    type: actionTypes.REGENERATE_CONTENT
});

export const regenerateContentFailure = data => ({
    type: actionTypes.REGENERATE_CONTENT_FAILURE
});

export const regenerateContentSuccess = data => ({
    type: actionTypes.REGENERATE_CONTENT_SUCCESS,
    payload: { data }
});

export const addContentInit = () => ({
    type: actionTypes.ADD_CONTENT
});

export const addContentFailure = data => ({
    type: actionTypes.ADD_CONTENT_FAILURE
});

export const addContentSuccess = data => ({
    type: actionTypes.ADD_CONTENT_SUCCESS,
    payload: { data }
});

export const updateContent = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(updateContentInit());
        axios.put(`${baseUrl}/content/${data.id}`, data, config)
        .then((response)=>{
            dispatch(updateContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateContentFailure());
        })
    };
};

export const updateContentInit = () => ({
    type: actionTypes.UPDATE_CONTENT
});

export const updateContentFailure = data => ({
    type: actionTypes.UPDATE_CONTENT_FAILURE
});

export const updateContentSuccess = data => ({
    type: actionTypes.UPDATE_CONTENT_SUCCESS,
    payload: { data }
});

export const deleteContent = (contentId, scheduleId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    return (dispatch) => {
        dispatch(deleteContentInit());
        axios.delete(`${baseUrl}/content/${contentId}/${scheduleId}`, config)
        .then((response)=>{
            dispatch(deleteContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteContentFailure());
        })
    };
};

export const deleteContentInit = () => ({
    type: actionTypes.DELETE_CONTENT
});

export const deleteContentFailure = data => ({
    type: actionTypes.DELETE_CONTENT_FAILURE
});

export const deleteContentSuccess = data => ({
    type: actionTypes.DELETE_CONTENT_SUCCESS,
    payload: { data }
});

export const getContent = (id, params) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }, params: params };
    return (dispatch) => {
        dispatch(getContentInit());
        axios.get(`${baseUrl}/content/account/${id}`, config)
        .then((response)=>{
            dispatch(getContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getContentFailure());
        })
    };
};

export const getContentInit = () => ({
    type: actionTypes.GET_CONTENT
});

export const getContentFailure = data => ({
    type: actionTypes.GET_CONTENT_FAILURE
});

export const getContentSuccess = data => ({
    type: actionTypes.GET_CONTENT_SUCCESS,
    payload: { data }
});