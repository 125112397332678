import * as actionType from "./userActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isLoggedIn: sessionStorage.getItem('user') !== null ? true : false,
    loggedUser: JSON.parse(sessionStorage.getItem('user')) || {},
    isResend: false,
    mailSent: false,
    passwordReseted: false,
    accounts: [],
    refreshToken: null,
    locations: [],
    selectedAccount: JSON.parse(sessionStorage.getItem('selectedAccount')) || {},
    accountSelected: false,
    teams: [],
    jwtDetails: {},
    linkedInCreated: false,
    teamDeleted: false,
    dashboard: {},
    gmbAccounts: [],
    gmbLocations: [],
    linkedinPages: []
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionType.USER_REGISTER:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isLoggedIn: false
        };
    case actionType.USER_REGISTER_FAILURE:
        return {
            ...state,
            isError: true,
            isLoggedIn: false,
            isLoading: false
        };
    case actionType.USER_REGISTER_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            isLoggedIn: true,
            loggedUser: action.payload ? action.payload.data : {},
        };
    case actionType.SEND_INVITE:
        return {
            ...state,
            isError: false,
            isLoading: true
        };
    case actionType.SEND_INVITE_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.SEND_INVITE_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
        };
    case actionType.DELETE_TEAM:
        return {
            ...state,
            isError: false,
            isLoading: true,
            teamDeleted: false
        };
    case actionType.DELETE_TEAM_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.DELETE_TEAM_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            teamDeleted: true
        };
    case actionType.CREATE_LINKEDIN:
        return {
            ...state,
            isError: false,
            isLoading: true,
            linkedInCreated: false
        };
    case actionType.CREATE_LINKEDIN_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.CREATE_LINKEDIN_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            linkedInCreated: true
        };
    case actionType.INVITE_USER:
        return {
            ...state,
            isError: false,
            isLoading: true
        };
    case actionType.INVITE_USER_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.INVITE_USER_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            isLoggedIn: true,
            loggedUser: action.payload ? action.payload.data : {},
        };
    case actionType.GET_DASHBOARD:
        return {
            ...state,
            isError: false,
            isLoading: true
        };
    case actionType.GET_DASHBOARD_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.GET_DASHBOARD_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            dashboard: action.payload ? action.payload.data : {},
        };
    case actionType.GET_LINKEDIN_PAGE:
        return {
            ...state,
            isError: false,
            isLoading: true
        };
    case actionType.GET_LINKEDIN_PAGE_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.GET_LINKEDIN_PAGE_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            linkedinPages: action.payload ? action.payload.data : {},
        };
    case actionType.FETCH_TEAM:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.FETCH_TEAM_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.FETCH_TEAM_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            teams: action.payload ? action.payload.data : [],
        };
        case actionType.GOOGLE_ACCOUNT:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GOOGLE_ACCOUNT_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false
            };
        case actionType.GOOGLE_ACCOUNT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                gmbAccounts: action.payload ? action.payload.data : [],
            };
            case actionType.GOOGLE_LOCATION:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                };
            case actionType.GOOGLE_LOCATION_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false
                };
            case actionType.GOOGLE_LOCATION_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    gmbLocations: action.payload ? action.payload.data : [],
                };
        case actionType.GET_JWT_DETAILS:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.GET_JWT_DETAILS_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.GET_JWT_DETAILS_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            jwtDetails: action.payload ? action.payload.data : {},
        };
    case actionType.SELECT_ACCOUNT:
        return {
            ...state,
            isError: false,
            accountSelected: false
        };
    case actionType.SELECT_ACCOUNT_SUCCESS:
        return {
            ...state,
            isError: false,
            accountSelected: true,
            selectedAccount: action.payload.data
        };
    case actionType.GET_ACCOUNT:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.GET_ACCOUNT_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.GET_ACCOUNT_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            accounts: action.payload ? action.payload.data.accounts : {},
            refreshToken: action.payload ? action.payload.data.refreshToken : {},
        };
    case actionType.GET_LOCATION:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.GET_LOCATION_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.GET_LOCATION_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            locations: action.payload ? action.payload.data : {},
        };
    case actionType.FORGET_PASSWORD:
        return {
            ...state,
            isError: false,
            isLoading: true,
            mailSent: false
        };
    case actionType.FORGET_PASSWORD_FAILURE:
        return {
            ...state,
            isError: true,
            mailSent: false,
            isLoading: false
        };
    case actionType.FORGET_PASSWORD_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            mailSent: true,
            loggedUser: action.payload ? action.payload.data : {},
        };
    case actionType.RESET_PASSWORD:
        return {
            ...state,
            isError: false,
            isLoading: true,
            passwordReseted: false
        };
    case actionType.RESET_PASSWORD_FAILURE:
        return {
            ...state,
            isError: true,
            passwordReseted: false,
            isLoading: false
        };
    case actionType.RESET_PASSWORD_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            passwordReseted: true,
            loggedUser: action.payload ? action.payload.data : {},
        };
        case actionType.SEND_VERIFY:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isLoggedIn: false
        };
        case actionType.SEND_VERIFY_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.SEND_VERIFY_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
            case actionType.RESEND_VERIFY:
                return {
                    ...state,
                    isError: false,
                    isLoading: true,
                    isResend: false
                };
            case actionType.RESEND_VERIFY_FAILURE:
                return {
                    ...state,
                    isError: true,
                    isLoading: false,
                    isResend: false
                };
            case actionType.RESEND_VERIFY_SUCCESS:
                return {
                    ...state,
                    isError: false,
                    isLoading: false,
                    isResend: true
                };
        case actionType.USER_LOGIN:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isLoggedIn: false
        };
        case actionType.USER_LOGIN_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.USER_LOGIN_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionType.USER_AUTH:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.USER_AUTH_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.USER_AUTH_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        case actionType.FETCH_CURRENT_USER:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isCurrentUserFetched: false
            };
        case actionType.FETCH_CURRENT_USER_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                isCurrentUserFetched: false
            };
        case actionType.FETCH_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isCurrentUserFetched: true,
                isLoggedIn: true,
                loggedUser: action.payload ? action.payload.data : {},
            };
        default: 
            return state
    }
}