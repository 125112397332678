import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../../shared/components/MDBox";
import MDTypography from "../../../../shared/components/MDTypography";
import FormField from "./../../../../shared/components/FormField";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from '@material-ui/styles';
import MDButton from "../../../../shared/components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
    selct: {
      '& .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
          padding: "0px!important",
      },
      '& .css-ynad2y-MuiSvgIcon-root-MuiSelect-icon': {
          display: "block",
      },
    },
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const mdbox = {
    background: "#E6E8EB",
    height: "40px",
    width: "40px",
    borderRadius: "100px",
}
const box = {
    marginTop: "49px",
}

const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "inherit",
    fontSize: "12px",
    fontWeight: "700"
}

export default function GooglePagesDialog(props) {
  const classes = useStyles();
  const { open, accounts, getLocations, onClose, selected, setSelected, submitHandle, locations } = props;
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                 Google Business
                </MDTypography>
                <MDTypography style={topText} >
                  You can add your google my businessaccount
                </MDTypography>
              </MDBox>
              <MDBox>
                <ClearIcon  onClick={() => onClose()}
                  style={{ width: "32px", height: "32px" }} 
                />
              </MDBox>
            </MDBox>
            <Grid container>
              <Grid item xs={12} style={{marginTop: "24px"}}>
                <FormControl variant="standard" fullWidth >
                    <InputLabel id="-select-label">
                        Account Name
                    </InputLabel>
                    <Select
                      label="Account"
                      className={classes.selct}
                      labelId="-select-label"
                      id="-simple-select"
                      value={selected.gmbAccountId}
                      onChange={e => {
                        getLocations(e.target.value)
                        setSelected({
                        ...selected,
                        gmbAccountId: e.target.value,
                        pageId: e.target.value
                      });
                    }}
                    >   
                      {accounts && Array.isArray(accounts) && accounts.map((ac) => (
                          <MenuItem value={ac.name}>{ac.accountName}</MenuItem>
                      ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{marginTop: "24px"}}>
                <FormControl variant="standard" fullWidth >
                    <InputLabel id="demo-simple-select-standard-label">
                        Location Name
                    </InputLabel>
                    <Select
                      label="Location"
                      className={classes.selct}
                      displayEmpty
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={selected.gmbLocation}
                      onChange={e => setSelected({
                        ...selected,
                        gmbLocation: locations[e.target.value].name,
                        name: locations[e.target.value].title
                      })}
                    >
                      {locations && Array.isArray(locations) && locations.map((lc, i) => (
                        <MenuItem value={i}>{lc.title}</MenuItem>
                      ))}
                    </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{marginTop: "24px"}}>
                <FormField
                  label="Link for your button"
                  name="link"
                  placeholder="Link for your button"
                  type="text"
                  value={selected.link}
                  onChange={e => setSelected({ ...selected, link: e.target.value })}
                />
              </Grid>
              <Grid item xs={12} style={{marginTop: "24px"}}>
                <FormControl variant="standard" fullWidth >
                        <InputLabel id="demo-simple-select-standard-label">
                          Add a button
                        </InputLabel>
                        <Select
                          label="Button"
                          className={classes.selct}
                          displayEmpty
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selected.btn}
                          onChange={e => setSelected({
                            ...selected,
                            btn: e.target.value
                          })}
                        >   
                          <MenuItem value="None">None</MenuItem>
                          <MenuItem value="BOOK">Book</MenuItem>
                          <MenuItem value="ORDER">Order Online</MenuItem>
                          <MenuItem value="SHOP">Buy</MenuItem>
                          <MenuItem value="LEARN_MORE">Learn More</MenuItem>
                          <MenuItem value="SIGN_UP">Sign Up</MenuItem>
                          <MenuItem value="CALL">Call Now</MenuItem>
                        </Select>
                    </FormControl>
              </Grid>
            </Grid> 
            <MDBox style={box} >
              <MDButton style={button} variant="contained" onClick={() => {
                submitHandle({ ...selected, custom: JSON.stringify({accountId: selected.gmbAccountId, locationId: selected.gmbLocation, btn: selected.btn, link: selected.link })});
                onClose();
              }}>
                  Save and Continue
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
