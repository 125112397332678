import { useEffect, useState } from 'react';
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React components
import MDBox from "./../../shared/components/MDBox";
import MDTypography from "./../../shared/components/MDTypography";
import MDButton from "./../../shared/components/MDButton";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import { makeStyles } from '@material-ui/styles';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FormField from "../../shared/components/FormField";
import Switch from "@mui/material/Switch";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
// Images
import burceMars from "./../../assets/images/image-profilepic.png";
import backgroundImage from "./../../assets/images/bg-profile.jpeg";
import BusinessEditDialog from './component/business-edit-dialog';
import Loading from './../../shared/Loading';
import { timezones } from './../../timezones';
import Autocomplete from "@mui/material/Autocomplete";
import { Typography } from '@mui/material';
import { Button } from '@mui/material';
import { ConfirmRegenerateContent } from './component/confirm-regenerate-content';

const useStyles = makeStyles((theme) => ({
    profileText: {
      fontWeight: "400",
      fontSize: "14px",
      color: "#7B809A",

    },
    chipText: {
        background: "rgba(76, 175, 80, 0.2) !important", 
        color: "#4CAF50 !important", 
        textTransform: "uppercase",
        fontWeight: "700",
        fontSize: "12px !important",
        marginLeft: "15px",
        //   [theme.breakpoints.down('lg')]: {
        //     fontSize: "20px !important",
        //   },
    },
}));

const typoText = {
    fontWeight: "700",
    fontSize: "14px",
    color: "#344767",
    display: "flex",
  }
const topName = {
    color: "#7B809A",
    fontSize: "14px",
    fontWeight: "400",
  }

export default function BusinessOverviewPage(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const { selectedAccount, isLoading, user, updateAccount, fetchCurrentUser, isSaved, getTopics, getIndustries, industries, topics, regenerateContent, isRegenerated } = props;
    const [followsMe, setFollowsMe] = useState(true);
    const [answersPost, setAnswersPost] = useState(false);
    const [mentionsMe, setMentionsMe] = useState(true);
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null)
    const [openConfirmContent, setOpenConfirmContent] = useState(false);
    

    const [values, setValues] = useState({
        name: selectedAccount && selectedAccount.name,
        industries: [],
        topics: [],
        email: selectedAccount && selectedAccount.email,
        phone: selectedAccount && selectedAccount.phone,
        location: selectedAccount && selectedAccount.location,
        cta: selectedAccount && selectedAccount.cta,
        hashtags: selectedAccount && selectedAccount.hashtags ? selectedAccount.hashtags.split(" ") : [],
      });

      const [errors, setErrors] = useState({});

      useEffect (() => {
        if(selectedAccount.id) {
            getIndustries();
            fetchTopics(selectedAccount && selectedAccount.industries);
        }
      }, [selectedAccount]);

      const fetchTopics = async (x) => {
          if(Array.isArray(x)){
            const z = await Promise.all(x.map(y => y.id))
            getTopics(z);
          }
      }

    useEffect(() => {
        if (isSaved) {
            fetchCurrentUser();
            setOpen(false);
            setSelected();
        }
    }, [isSaved]);

    const submitHandle = () => {
        setErrors(validate(values));
      };
      const validate = (values) => {
        let errorss = {};
        let isError = false;
        const regex = /\S+@\S+\.\S+/;
        if (!values.name) {
          isError = true;
          errorss.name = "Business name is required";
        }
        if (!values.industries) {
          isError = true;
          errorss.industries = "Industry is required";
        }
        if (!values.topics) {
          isError = true;
          errorss.topics = "Topic is required";
        }
        if (!values.email) {
          isError = true;
          errorss.email = "Email is required";
        } else if (!regex.test(values.email)) {
          isError = true;
          errorss.email = "This is not a valid email format";
        }
        if (!values.phone) {
          isError = true;
          errorss.phone = "Number is required";
        }
        if (!values.location) {
          isError = true;
          errorss.location = "Business location is required";
        }
        if (!isError) {
          updateAccount({ ...values, hashtags: values.hashtags.join(" "), industries: JSON.stringify(values.industries), topics: JSON.stringify(values.topics) }, selectedAccount && selectedAccount.id);
        }
        return errorss;
      };

      const onImageChange = (event) => {
        if (
          event &&
          event.target &&
          event.target.files &&
          event.target.files.length > 0
        )
        updateAccount({ logo: event.target.files[0] }, selectedAccount && selectedAccount.id);
      };

      async function getIndustriesTopicsByData(){
        const i = await selectedAccount && Array.isArray(selectedAccount.industries) && await Promise.all(selectedAccount.industries.map(x => { return { id: x.id, label: x.name }}));
        const t = await selectedAccount && Array.isArray(selectedAccount.topics) && await Promise.all(selectedAccount.topics.map(x => { return { id: x.id, label: x.name }}));
        setValues({ ...values, industries: i, topics: t });
      }

      const confirmRegenerateContent = () => {
        setOpenConfirmContent(true);
      }

      useEffect(() => {
        if(isRegenerated && openConfirmContent){
            setOpenConfirmContent(false);
            navigate("/content-library");
        }
      }, [isRegenerated]);

  return (
    <>
        <Loading loading={isLoading} />
        <ConfirmRegenerateContent
            open={openConfirmContent}
            selectedAccount={selectedAccount}
            onClose={() => {
                setOpenConfirmContent(false);
            }}
            regenerateContent={regenerateContent}
        />
        <DashboardLayout>
            <DashboardNavbar />
            <BusinessEditDialog
                open={open}
                setOpen={setOpen}
                values={values}
                setValues={setValues}
                errors={errors}
                submitHandle={submitHandle}
                fetchTopics={fetchTopics}
                topics={topics}
                industries={industries}
            />
            <MDBox position="relative" mb={5}>
                <MDBox sx={{ display: "flex", justifyContent: "flex-end", mx: 3 }}>
                    <MDButton
                    style={{
                        background: "linear-gradient(#09032B, #312B56)",
                        color: "#FFFFFF",
                        marginLeft: "16px",
                        fontWeight: "700",
                        fontSize: "12px",
                        textTransform: "capitalize",
                        height: "40px"
                        }}
                        onClick={() => confirmRegenerateContent()}
                    >Generate my content</MDButton>
                </MDBox>
                {/* <MDBox
                    display="flex"
                    alignItems="center"
                    position="relative"
                    minHeight="18.75rem"
                    borderRadius="xl"
                    sx={{
                    backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                        `${linearGradient(
                        rgba(gradients.info.main, 0.6),
                        rgba(gradients.info.state, 0.6)
                        )}, url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "50%",
                    overflow: "hidden",
                    }}
                /> */}
                <Card sx={{ position: "relative", mt: 2, mx: 3, py: 2, px: 2 }} >
                {/* <Card sx={{ position: "relative", mt: 8, mx: 3, py: 2, px: 2 }} > */}
                    <Grid container spacing={3} alignItems="center">
                        <Grid item xs={12}>
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                                <MDBox height="100%" mt={0.5} sx={{display: "flex"}}>
                                    <img width="50px" src={selectedAccount && selectedAccount.logo} alt="profile-image" />
                                    <div style={{ marginLeft: "10px" }}>
                                        <MDTypography variant="h5" fontWeight="medium">
                                            {selectedAccount && selectedAccount.name}
                                        </MDTypography>
                                        <label htmlFor="header-image" style={{
                                                cursor: "pointer", color: "#344767", fontWeight: "400", fontSize: "14px",
                                                textDecoration: "underline"
                                                }}>Update logo</label>
                                        <input
                                            accept="image/*"
                                            id="header-image"
                                            type="file"
                                            onChange={onImageChange}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                </MDBox>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={6} sx={{ px: "32px", my: "10px" }}>
                        <Grid item xs={12} md={6}>
                            <MDTypography style={typoText}>
                                Business Name:{" "}
                                <span className={classes.profileText}
                                    style={{marginLeft: "11px"}}>
                                    {selectedAccount && selectedAccount.name}
                                </span>
                                <Typography onClick={() => { setOpen(true);getIndustriesTopicsByData(); }}style={{marginLeft: "auto", cursor: "pointer", textDecoration: "underline", fontSize: "16px", fontWeight: "bold"}}>
                                    {/* <PersonAddAltIcon  /> */}
                                    Edit
                                </Typography>
                            </MDTypography>
                            <MDTypography style={typoText}
                                sx={{marginBottom: "14px"}} >
                                Phone:{" "}
                                <span className={classes.profileText}
                                    style={{marginLeft: "11px"}}>
                                    {selectedAccount && selectedAccount.phone}
                                </span>
                            </MDTypography>
                            <MDTypography style={typoText}
                                sx={{marginBottom: "14px"}} >
                                Email:{" "}
                                <span className={classes.profileText}
                                    style={{marginLeft: "11px"}}>
                                    {selectedAccount && selectedAccount.email}
                                </span>
                            </MDTypography>
                            <MDTypography style={typoText}
                                sx={{marginBottom: "14px"}} >
                                Location:{" "}
                                <span style={{marginLeft: "11px", fontWeight: "500", fontSize: "14px", color: "#344767"}}>
                                    {selectedAccount && selectedAccount.location}
                                </span>
                            </MDTypography>
                            <MDTypography style={typoText}
                                sx={{marginBottom: "14px"}} >
                                Industry:{" "}
                                <div>
                                    {selectedAccount && Array.isArray(selectedAccount.industries) && selectedAccount.industries.map(x => {
                                        return (<Chip className={classes.chipText} label={x.name} sx={{m: "5px"}} />)
                                    })}
                                </div>
                            </MDTypography>
                            <MDTypography style={typoText}
                                sx={{marginBottom: "14px"}} >
                                Topics:{" "}
                                <div>
                                    {selectedAccount && Array.isArray(selectedAccount.topics) && selectedAccount.topics.map(x => {
                                        return (<Chip className={classes.chipText} label={x.name} sx={{m: "5px"}} />)
                                    })}
                                </div>
                            </MDTypography>
                            <MDTypography style={{fontSize: "16px", fontWeight: "700", color: "#344767", marginTop: "40px"}}>Global hashtags
                                <Tooltip title="These hashtags will be included with all of your profile-specific ones">
                                    <IconButton>
                                        <HelpOutlinedIcon style={{marginLeft: "10px", color: "#534F6B"}} />
                                    </IconButton>
                                </Tooltip>
                            </MDTypography>
                            <MDTypography style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#7B809A",
                                marginTop: "10px"
                                }}>
                                    {selectedAccount && selectedAccount.hashtags && selectedAccount.hashtags.split(" ").map(x => <Chip label={x} />)}
                            </MDTypography>
                            <MDTypography style={{fontSize: "16px", fontWeight: "700", color: "#344767", marginTop: "40px"}}>Global caption suffix
                                <Tooltip title="This text will be added to the end of all of your captions" >
                                    <IconButton>
                                        <HelpOutlinedIcon style={{marginLeft: "10px", color: "#534F6B"}} />
                                    </IconButton>
                                </Tooltip>
                            </MDTypography>
                            <MDTypography style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#7B809A",
                                marginTop: "10px"
                                }}>
                                    {selectedAccount && selectedAccount.cta}
                            </MDTypography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MDTypography style={{
                                fontWeight: "700",
                                fontSize: "20px",
                                color: "#344767"
                            }}>Platform  Settings</MDTypography>
                            <MDBox component="form" style={{marginTop: "16px"}}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={10}>
                                    <Autocomplete
                                        options={timezones || []}
                                        value={selectedAccount && selectedAccount.timezone}
                                        onChange={(event, newValue) => {
                                            updateAccount({ timezone: newValue }, selectedAccount && selectedAccount.id);
                                        }}
                                        renderInput={(params) => (
                                        <FormField
                                            {...params}
                                            label="Timezone"
                                            name="timezone"
                                            placeholder="Timezone"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        )}
                                    />
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox lineHeight={1.25} style={{marginTop: "42px"}}>
                                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                COMMUNICATION
                                </MDTypography>
                                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                                    <MDBox mt={0.5}>
                                        <Switch
                                            checked={selectedAccount && selectedAccount.emailOnNew}
                                            onChange={() => updateAccount({ emailOnNew: selectedAccount && selectedAccount.emailOnNew == 1 ? 0 : 1 }, selectedAccount && selectedAccount.id)}
                                        />
                                    </MDBox>
                                    <MDBox width="80%" ml={0.5}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                        Email me when there is new content to approve
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                                    <MDBox mt={0.5}>
                                        <Switch
                                            checked={selectedAccount && selectedAccount.emailOnFail}
                                            onChange={() => updateAccount({ emailOnFail: selectedAccount && selectedAccount.emailOnFail == 1 ? 0 : 1 }, selectedAccount && selectedAccount.id)}
                                        />
                                    </MDBox>
                                    <MDBox width="80%" ml={0.5}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Email me when a post fails
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                            <MDBox lineHeight={1.25} style={{marginTop: "42px"}}>
                                <MDTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
                                CONTENT
                                </MDTypography>
                                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                                    <MDBox mt={0.5}>
                                        <Switch
                                            checked={selectedAccount && selectedAccount.allPostApproval}
                                            onChange={() => updateAccount({ allPostApproval: selectedAccount && selectedAccount.allPostApproval == 1 ? 0 : 1 }, selectedAccount && selectedAccount.id)}
                                        />
                                    </MDBox>
                                    <MDBox width="80%" ml={0.5}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            All posts require approval
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                                <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5}>
                                    <MDBox mt={0.5}>
                                        <Switch
                                            checked={selectedAccount && selectedAccount.monthlyContentDigest}
                                            onChange={() => updateAccount({ monthlyContentDigest: selectedAccount && selectedAccount.monthlyContentDigest == 1 ? 0 : 1 }, selectedAccount && selectedAccount.id)}
                                        />
                                    </MDBox>
                                    <MDBox width="80%" ml={0.5}>
                                        <MDTypography variant="button" fontWeight="regular" color="text">
                                            Monthly content digest
                                        </MDTypography>
                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Grid>
                    </Grid>
                </Card>
            </MDBox>
        </DashboardLayout>
    </>
  );
}
