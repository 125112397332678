import BusinessOverviewPage from './business-overview.component';
import { connect } from 'react-redux';
import { fetchCurrentUser } from './../../api/user/userAction';
import { updateAccount } from "./../../api/account/accountAction";
import { getTopics } from './../../api/topic/topicAction';
import { getIndustries } from './../../api/industry/industryAction';
import { regenerateContent } from './../../api/content/contentAction';

const mapDispatchToProps = {
    updateAccount,
    fetchCurrentUser,
    getTopics,
    getIndustries,
    regenerateContent
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    user: state.userPage.loggedUser,
    isSaved: state.accountPage.isSaved,
    topics: state.topicPage.topics,
    industries: state.industryPage.industries,
    selectedAccount: state.userPage.selectedAccount,
    isRegenerated: state.contentPage.isRegenerated
});

export const BusinessOverviewPageContainer = connect(mapStateToProps, mapDispatchToProps)(BusinessOverviewPage);