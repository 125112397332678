import UserDetailsPage from './user-details.component';
import { connect } from 'react-redux';
// import { userRegister } from './../../api/user/userAction';

// const mapDispatchToProps = {
//     userRegister
// }

// const mapStateToProps = state => ({
//     isLoggedIn: state.userPage.isLoggedIn,
//     user: state.userPage.loggedUser,
//     isLoading: state.userPage.isLoading,
// });

export const UserDetailsPageContainer = connect(null)(UserDetailsPage);