import React, { useState, useEffect } from "react";
// import { Chart } from 'node_modules/chart.js';
import Chart from "chart.js/auto";
import { Link } from "react-router-dom";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
// import { Datepicker } from '@mobiscroll/react';

// @mui material components
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";

// import Card from "@mui/material/Card";

// icon
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import StorefrontTwoTone from "@mui/icons-material/StorefrontTwoTone";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

// Material Dashboard 2 PRO React components
import DefaultStatisticsCard from "./../../shared/components/DefaultStatisticsCard";
import defaultLineChartData from "./data/defaultLineChartData";
// import SalesTable from "./../../shared/components/SalesTable";
// import salesTableData from "./data/salesTableData";
import DefaultLineChart from "./../../shared/components/DefaultLineChart";
import MDBox from "./../../shared/components/MDBox";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDButton from "./../../shared/components/MDButton";
import MDBadgeDot from "./../../shared/components/MDBadgeDot";
import MDTypography from "./../../shared/components/MDTypography";

// img
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SendIcon from '@mui/icons-material/Send';
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Loading from './../../shared/Loading';
import { DeleteConfirm } from './../allContent/component/delete-confirm';
import { UpsertTagDialog } from './../allContent/component/upsert-tag-dialog';
import { CollectionPage } from './../allContent/component/collection-page';
import { createApi } from 'unsplash-js';
import { MediaLibraryPage } from "./../../shared/media-library/media-library.component";
import ContentEditorDialog from '../../shared/content-editor-dialog';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


// @react-jvectormap components
import { VectorMap } from "@react-jvectormap/core";
import { worldMerc } from "@react-jvectormap/world";
import { Card } from "@themesberg/react-bootstrap";


const mdBox = {
  background: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "12px",
  width: "auto",
};
const iconText = {
  color: "#7B879A",
  width: "15px",
  height: "15px",
  margin: "2px 6px",
};
const topText = {
  fontWeight: "400",
  fontSize: "13px",
  color: "#5E6D84",
  display: "-webkit-box",
  WebkitLineClamp: 3,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
};
const box = {
  backgroundColor: "#F0F2F5",
  height: "28px",
  width: "28px",
  borderRadius: "6px",
};
const cardText = {
  width: "100%",
  margin: 0,
  boxShadow: "inset 0px 12px 40px rgba(0, 0, 0, 0.2)",
  objectFit: "cover",
  objectPosition: "center",
  height: "auto",
  borderRadius: "12px 12px 0px 0px"
};

export default function SocialOverviewPage(props) {
  const {
    getDashboard,
    dashboard,
    isLoading,
    selectedAccount,
    uploadBG,
    addContent,
    updateContent,
    deleteContent,
    fetchLayouts,
    getTags,
    addTags,
    getMedias,
    addMedias,
    deleteMedia,
    tags,
    tagSaved,
    medias,
    mediaSaved,
    mediaLoading,
    bgImg,
    layouts,
    accounts,
    user,
    approveContent
  } = props;
  const [openTag, setOpenTag] = useState(false);
  const [accountOptions, setAccountOptions] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [mediaOpen, setMediaOpen] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [imageOpen, setImageOpen] = useState(false);
  const [collection, setCollection] = React.useState(selectedAccount && selectedAccount.collection && JSON.parse(selectedAccount.collection));
  const [loading, setLoading] = useState(false);
  const [selectedTag, setSelectedTag] = useState({ name: "", status: 1 });
  const [search, setSearch] = React.useState({});
  
  const emptyContent = {
    AccountId: selectedAccount && selectedAccount.id,
    ProfileIds: [],
    content: {all: "", facebook: "", insta: "", linkedin: "", gmb: ""},
    layouts: "",
    images: undefined,
    tags: [],
    hashtags: {
      all: selectedAccount && selectedAccount.hashtags,
      facebook: selectedAccount && selectedAccount.hashtags,
      insta: selectedAccount && selectedAccount.hashtags,
      linkedin: selectedAccount && selectedAccount.hashtags,
      gmb: selectedAccount && selectedAccount.hashtags,
    },
    scTime: null,
    status: 1
  }

  const [selected, setSelected] = React.useState(emptyContent);
  const [profiles, setProfiles] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dynamicFields, setDynamicFields] = React.useState({});

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  const X = 34;
  const Y = -4;
  const translat = {
    transform: `translate(${X}px, ${Y}px)`,
    width: "18px",
    height: "18px",
    color: "#41B853",
  };

  useEffect(async () => {
    if (accounts.length > 0) {
      const x = await Promise.all(accounts.map(y => {
        return {id: y.id, label: y.name}
      }))
      setAccountOptions(x);
    }
  }, [accounts]);

  useEffect(() => {
    getDashboard({ accountId: selectedAccount.id });
    getProfiles();
    getTags();
    getMedias({ id: selectedAccount && selectedAccount.id, limit: 24, offset: 0, orderBy: "id", orderType: "DESC" });
    fetchLayouts(selectedAccount && selectedAccount.ThemeId);
  }, []);

  useEffect(() => {
    if (tagSaved) {
      getTags();
      setSelectedTag({ name: "", status: 1 });
      setOpenTag(false);
    }
  }, [tagSaved]);

  useEffect(() => {
    if (mediaSaved) {
      getMedias({ id: selectedAccount && selectedAccount.id, limit: 9, offset: 0, orderBy: "id", orderType: "DESC" });
    }
  }, [mediaSaved]);

  useEffect(() => {
    if(bgImg) {
      setLoading(true);
      setDynamics("Image Library", bgImg);
      setTimeout(() => setLoading(false), 3000);
    }
  }, [bgImg]);

  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  function getCollectionById () {
    api.collections.getPhotos({ collectionId: collection && collection.id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }

  function setDynamics(x, v) {
    let obj = {};
    obj[x] = v;
    setDynamicFields({ ...dynamicFields, ...obj });
  }

  function getContentByStatus(status) {
    if(dashboard && dashboard.contentByStatus) {
      const d = dashboard.contentByStatus.filter(x => x.status == status);
      if(d.length > 0) {
        return d[0].count;
      }
      return 0;
    }
    return 0;
  }

  const checkPr = (x, type) => {
    if (Array.isArray(x)) {
      const y = x.filter(z => z.type == type);
      return y.length > 0;
    }
    return false;
  }

  const getProfiles = async () => {
    if (selectedAccount &&
      Array.isArray(selectedAccount.Profiles)) {
        const y = await Promise.all(selectedAccount.Profiles.map(x => {
                    return { 
                      id: x.id,
                      type: x.type == "fb" ? "facebook" : x.type,
                      label: `${x.type} (${x.name})`,
                      tags: x.hashtags
                    }
                  }));
      setProfiles(y);
      return;
    }
    setProfiles([]);
    return;
  }

  function returnProfileHashtags(type, tags) {
    if (profiles && Array.isArray(profiles)) {
      const x = profiles.filter(y => y.type == type);
      if (x.length > 0) {
        return `${tags} ${x[0].tags ? x[0].tags : ""}`.split(" ");
      }
      return tags.split(" ");
    }
    return tags.split(" ");
  }

  async function setHashtags() {
    setSelected({
      ...selected,
      hashtags: {
        ...selected.hashtags,
        all: await returnProfileHashtags("all", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        facebook: await returnProfileHashtags("facebook", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        insta: await returnProfileHashtags("insta", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        linkedin: await returnProfileHashtags("linkedin", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
        gmb: await returnProfileHashtags("gmb", selected.hashtags && selected.hashtags.all ? selected.hashtags.all : ""),
      }
    });
    setOpen(true);
  }

  const isProfile = (type) => {
    if (selectedAccount && selectedAccount.Profiles && Array.isArray(selectedAccount.Profiles)) {
      const x = selectedAccount.Profiles.filter(y => y.type == type);
      return x.length > 0; 
    }
    return false;
  }

  return (
    <DashboardLayout>
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaOpen}
        onClose={() => setMediaOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={setDynamics}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <CollectionPage
        images={images}
        open={imageOpen}
        onClose={() => {
          setImages([]);
          setImageOpen(false);
        }}
        setSelected={setSelected}
        selected={selected}
        setDynamics={setDynamics}
        setLoading={setLoading}
      />
      <UpsertTagDialog
        addTags={addTags}
        open={openTag}
        onClose={() => {
          setSelectedTag({ name: "", status: 1 });
          setOpenTag(false);
        }}
        selected={selectedTag}
        setSelected={setSelectedTag}
      />
      <DeleteConfirm
        deleteContent={deleteContent}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected(emptyContent);
          setDeleteOpen(false);
        }}
      />
      <ContentEditorDialog
        setOpenTag={setOpenTag}
        tags={tags}
        selected={selected}
        setSelected={setSelected}
        open={open}
        onClose={() => {
          setSelected(emptyContent);
          setDynamicFields({});
          setOpen(false);
        }}
        accounts={accountOptions}
        uploadBG={uploadBG}
        addContent={addContent}
        updateContent={updateContent}
        profilesOpt={profiles}
        layouts={layouts}
        openUnsplash={() => {
          getCollectionById();
          setImageOpen(true);
        }}
        selectedAccount={selectedAccount}
        dynamicFields={dynamicFields}
        setDynamics={setDynamics}
        setDynamicFields={setDynamicFields}
        setMediaOpen={setMediaOpen}
      />
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <MDBox style={{display: "flex", marginBottom: "32px"}}>
          {isProfile("insta") ? (
            <MDBox
              style={{
                background: "#1B172E",
                height: "52px",
                width: "52px",
                borderRadius: "100px",
                marginRight: "16px"
              }}
            >
              <InstagramIcon
                style={{
                  width: "28px",
                  height: "28px",
                  margin: "0px 12px -17px",
                  color: "#FFFFFF"
                }}
              />
              {isProfile("insta") && <CheckCircleIcon style={translat} />}
            </MDBox>
          ) : (
            <MDBox
              style={{
                background: "#fff",
                height: "52px",
                width: "52px",
                borderRadius: "100px",
                marginRight: "16px"
              }}
            >
              <InstagramIcon
                style={{
                  width: "28px",
                  height: "28px",
                  margin: "0px 12px -17px",
                  color: "#000"
                }}
              />
            </MDBox>
          )}
          {isProfile("linkedin") ? (
            <MDBox
            style={{
              background: "#1B172E",
              height: "52px",
              width: "52px",
              borderRadius: "100px",
              marginRight: "16px"
            }}
          >
            <LinkedInIcon
              style={{
                width: "28px",
                height: "28px",
                margin: "0px 12px -17px",
                color: "#fff"
              }}
            />
            <CheckCircleIcon style={translat} />
          </MDBox>
          ) : (
            <MDBox
              style={{
                background: "#FFFFFF",
                height: "52px",
                width: "52px",
                borderRadius: "100px",
                marginRight: "16px"
              }}
            >
              <LinkedInIcon
                style={{
                  width: "28px",
                  height: "28px",
                  margin: "0px 12px -17px",
                  color: "#5E6D84"
                }}
              />
            </MDBox>
          )}
          {isProfile("fb") ? (
            <MDBox
            style={{
              background: "#1B172E",
              height: "52px",
              width: "52px",
              borderRadius: "100px",
              marginRight: "16px"
            }}
          >
            <FacebookRoundedIcon
              style={{
                width: "28px",
                height: "28px",
                margin: "0px 12px -17px",
                color: "#fff"
              }}
            />
            <CheckCircleIcon style={translat} />
          </MDBox>
          ) : (
            <MDBox
            style={{
              background: "#FFFFFF",
              height: "52px",
              width: "52px",
              borderRadius: "100px",
              marginRight: "16px"
            }}
          >
            <FacebookRoundedIcon
              style={{
                width: "28px",
                height: "28px",
                margin: "0px 12px -17px",
                color: "#5E6D84"
              }}
            />
          </MDBox>
          )}
          {isProfile("gmb") ? (
            <MDBox
            style={{
              background: "#1B172E",
              height: "52px",
              width: "52px",
              borderRadius: "100px",
            }}
          >
            <StorefrontTwoTone
              style={{
                width: "28px",
                height: "28px",
                margin: "0px 12px -17px",
                color: "#fff"
              }}
            />
            <CheckCircleIcon style={translat} />
          </MDBox>
          ) : (
            <MDBox
              style={{
                background: "#FFFFFF",
                height: "52px",
                width: "52px",
                borderRadius: "100px",
              }}
            >
              <StorefrontTwoTone
                style={{
                  width: "28px",
                  height: "28px",
                  margin: "0px 12px -17px",
                  color: "#5E6D84"
                }}
              />
            </MDBox>
          )}
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Total Posts"
                count={dashboard.totalPost}
                percentage={{
                  color: "success",
                  value: dashboard.totalPost > 0 ? "You published more posts since last month" : "You published 0 posts since last month",
                  label: "",
                }}
                // dropdown={{
                //   action: openSalesDropdown,
                //   menu: renderMenu(salesDropdown, closeSalesDropdown),
                //   value: salesDropdownValue,
                // }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Total likes"
                count="0"
                percentage={{
                  color: "warning",
                  value: "0 engagement in last 30 days",
                  label: "",
                }}
                // dropdown={{
                //   action: openCustomersDropdown,
                //   menu: renderMenu(customersDropdown, closeCustomersDropdown),
                //   value: customersDropdownValue,
                // }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Post Comments"
                count="0"
                percentage={{
                  color: "success",
                  value: "0 comment since last month",
                  label: "",
                }}
                // dropdown={{
                //   action: openRevenueDropdown,
                //   menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                //   value: revenueDropdownValue,
                // }}
              />
            </Grid>
          </Grid>
        </MDBox>
          <Grid container spacing={1}>
            <Grid item sm={12} md={7} style={{ overflow: "auto" }}>
              <DefaultLineChart
                title="Tracking social growth"
                chart={defaultLineChartData}
                description={
                  <MDBox display="flex" justifyContent="space-between">
                    <MDBox display="flex" ml={-1}>
                      <MDBadgeDot
                        color="info"
                        size="sm"
                        badgeContent="Facebook"
                      />
                      <MDBadgeDot
                        color="error"
                        size="sm"
                        badgeContent="Instagram"
                      />
                      <MDBadgeDot
                        color="info"
                        size="sm"
                        badgeContent="Linkedin"
                      />
                    </MDBox>
                  </MDBox>
                }
              />
            </Grid>
            <Grid item sm={12} md={5} >
              <MDBox py={2} pr={1} pl={1} style={{
                background: "#FFFFFF",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                borderRadius: "12px",
                }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <MDBox style={{
                      padding: "22px 20px",
                      border: "1px solid #DCDDE1",
                      borderRadius: "12px",
                      background: "#FFFFFF",
                      }}
                    >
                      <AccessTimeIcon style={{color: "#FB8C00", width: "35px", height: "35px"}} />
                      <MDTypography style={{
                        fontWeight: "700",
                        fontSize: "19px", 
                        color: "#1B1C29"

                        }}>
                          Scheduled <span style={{fontWeight: "400", fontSize: "16px", color: "#1B1C29"}}>
                            ({getContentByStatus(5)})
                          </span>
                      </MDTypography>
                      {/* <MDTypography style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C29",
                        marginTop: "12px"
                        }}>Link to Insight
                        <ArrowForwardIcon style={{marginLeft: "12px", marginBottom: "-3px"}} />
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox style={{
                      padding: "22px 20px",
                      border: "1px solid #DCDDE1",
                      borderRadius: "12px",
                      background: "#FFFFFF",
                      }}
                    >
                      <SendIcon style={{color: "#41B853", width: "35px", height: "35px"}} />
                      <MDTypography style={{
                        fontWeight: "700",
                        fontSize: "19px", 
                        color: "#1B1C29"

                        }}>Published <span style={{fontWeight: "400", fontSize: "16px", color: "#1B1C29"}}>({getContentByStatus(6)})</span>
                      </MDTypography>
                      {/* <MDTypography style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C29",
                        marginTop: "12px"
                        }}>Link to Insight
                        <ArrowForwardIcon style={{marginLeft: "12px", marginBottom: "-3px"}} />
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox style={{
                      padding: "20px 22px",
                      border: "1px solid #DCDDE1",
                      borderRadius: "12px",
                      background: "#FFFFFF",
                      }}
                    >
                      <HighlightOffIcon style={{color: "#E71D36", width: "35px", height: "35px"}} />
                      <MDTypography style={{
                        fontWeight: "700",
                        fontSize: "19px", 
                        color: "#1B1C29"

                        }}>Failed <span style={{fontWeight: "400", fontSize: "16px", color: "#1B1C29"}}>({getContentByStatus(7)})</span>
                      </MDTypography>
                      {/* <MDTypography style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C29",
                        marginTop: "12px"
                        }}>Link to Insight
                        <ArrowForwardIcon style={{marginLeft: "12px", marginBottom: "-3px"}} />
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDBox style={{
                      padding: "22px 20px",
                      border: "1px solid #DCDDE1",
                      borderRadius: "12px",
                      background: "#FFFFFF",
                      }}
                    >
                      <ErrorOutlineIcon style={{color: "#3672E7", width: "35px", height: "35px"}} />
                      <MDTypography style={{
                        fontWeight: "700",
                        fontSize: "19px", 
                        color: "#1B1C29"

                        }}>Pending approval 
                        {/* <span style={{fontWeight: "400", fontSize: "16px", color: "#1B1C29"}}>({getContentByStatus(2)})</span> */}
                      </MDTypography>
                      {/* <MDTypography style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#1B1C29",
                        marginTop: "12px"
                        }}>Link to approval
                        <ArrowForwardIcon style={{marginLeft: "12px", marginBottom: "-3px"}} />
                      </MDTypography> */}
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
      </MDBox>
      <MDBox style={{display: "flex"}}>
        <MDTypography style={{fontWeight: "600", fontSize: "24px", color: "#1B1C29"}}>Upcoming Posts</MDTypography>
        <MDTypography component={Link} to="/content-library" style={{fontWeight: "400", fontSize: "14px", color: "#1B1C29", marginLeft: "auto"}}>See All Posts
          <ArrowForwardIcon style={{marginLeft: "12px", marginBottom: "-2px"}} />
        </MDTypography>
      </MDBox>
      <Grid container spacing={2} style={{marginTop: "16px"}}>
        {dashboard.nextContent && Array.isArray(dashboard.nextContent) && dashboard.nextContent.map(x => {
        return (
          <Grid item xs={12} md={3}>
            <MDBox style={mdBox}>
              <MDBox
                position="relative"
                shadow="xl"
                borderRadius="xl"
              >
                <div style={{ position: "absolute", top: "10px", left: "10px", padding: "4px 8px", lineHeight: "100%", background: "#E4FFE6", borderRadius: "8px" }}>
                  <span style={{ fontWeight: 700, fontSize: "12px", color: "#4CAF50" }}>Social Post</span>
                </div>
                <div style={{ position: "absolute", top: "10px", right: "10px", padding: "2px",lineHeight: "100%", background: "#E4FFE6", borderRadius: "8px" }}>
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "insta") && (
                    <InstagramIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "facebook") && (
                    <FacebookRoundedIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}  
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "linkedin") && (
                    <LinkedInIcon
                      style={{
                        borderRadius: "4px",
                        margin: "2px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                  {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "gmb") && (
                    <StorefrontRoundedIcon
                      style={{
                        borderRadius: "4px",
                        width: "21px",
                        height: "21px",
                        color: "#6B6880"
                      }}
                    />
                  )}
                </div>
                <CardMedia style={cardText} src={x.images && Array.isArray(JSON.parse(x.images)) && JSON.parse(x.images).length > 0 && JSON.parse(x.images)[0].image} component="img" />
              </MDBox>
              <MDBox style={{padding: "16px"}}>
                <MDTypography style={topText}>
                  {JSON.parse(x.content).all}
                </MDTypography>
                <MDBox style={{ display: "flex", marginTop: "23px" }}>
                  <Stack direction="row" spacing="1">
                  { x.status == 1 ? 
                    (
                      <FolderOutlinedIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#FB8C00",
                          marginRight: "4px",
                        }}
                      />
                    ) : x.status == 6 ? 
                    (
                      <SendIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#41B853",
                          marginRight: "4px",
                          transform: "rotate(-45deg)"
                        }}
                      />
                    ) : x.status == 4 ? 
                    (<InfoOutlinedIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#3672E7",
                        marginRight: "4px",
                      }}
                    />) : (
                      <AccessTimeIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#FB8C00",
                          marginRight: "4px",
                        }}
                      />
                    ) }
                    
                    <MDTypography
                      style={{
                        // whiteSpace: "nowrap",
                        // textOverflow: "ellipsis",
                        color: "#6B6880",
                        fontSize: "12px",
                        fontWeight: "400"
                      }}
                    >
                      { x.status == 1 ? "Draft" : x.status == 6 ? "Published" : x.status == 4 ? "In Queue" : x.status == 5 ? "Scheduled" : x.status == 7 ? "Failed" : "In Review" }
                    </MDTypography>
                  </Stack>
                  {x.status == 6 ? (
                    <MDBox
                    style={box}
                    sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                  >
                    <ContentCopyIcon style={iconText} onClick={() => {
                      setSelected({
                        AccountId: x.AccountId,
                        ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                        content: x.content && JSON.parse(x.content),
                        layouts: x.layouts && JSON.parse(x.layouts),
                        images: JSON.parse(x.images)[0].image,
                        hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                        scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                        scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                        status: x.status,
                        dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                      });
                      setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                      setOpen(true);
                    }}/>
                  </MDBox>
                  ) : x.status == 2 ? (
                    <MDBox
                        sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                      >
                    <Button
                      variant="contained"
                      sx={{color: "#fff", backgroundColor: "#000"}}
                      onClick={() => approveContent(x.id)}
                    >Approve</Button>
                    </MDBox>
                  ) : (
                    <>
                      <MDBox
                        style={box}
                        sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                      >
                        <ModeEditOutlinedIcon style={iconText} onClick={() => {
                          setSelected({
                            id: x.id,
                            AccountId: x.AccountId,
                            ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                            content: x.content && JSON.parse(x.content),
                            layouts: x.layouts && JSON.parse(x.layouts),
                            images: JSON.parse(x.images)[0].image,
                            hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                            scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                            scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                            status: x.status,
                            dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                          });
                          setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                          setOpen(true);
                        }}/>
                      </MDBox>
                      <MDBox style={box}>
                        <DeleteOutlineIcon
                          style={iconText}
                          onClick={() => {
                            setSelected({
                              id: x.id,
                              AccountId: x.AccountId,
                              ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                              content: JSON.parse(x.content),
                              layouts: JSON.parse(x.layouts),
                              images: x.images,
                              hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].hashtags),
                              scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                              scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scTime,
                              status: x.status,
                              scheduledId: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].id
                            });
                            setDeleteOpen(true);
                          }}
                        />
                      </MDBox>
                    </>
                  )}
                </MDBox>
              </MDBox>
            </MDBox>
          </Grid>
        )
      })}
      </Grid>
    </DashboardLayout>
  );
}
