import { useEffect, useState } from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import { Link } from "react-router-dom";

// mui
import Grid from "@mui/material/Grid";
// MD
import MDBox from "../../shared/components/MDBox";
// import MDInput from "../../shared/components/MDInput";
import MDTypography from "../../shared/components/MDTypography";
import MDAvatar from "../../shared/components/MDAvatar";
import MDButton from "../../shared/components/MDButton";
import breakpoints from "./../../assets/theme/base/breakpoints";

// img
import smassign from "./../../assets/images/panel.png";
import Sociallife from "./../../assets/images/Frame.png";

export default function PostPannelPage() {
  // tab
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /** 
       The event listener that's calling the handleTabsOrientation function when resizing the window.
      */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  // tab

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container>
        <Grid item sm={12}
          style={{
            marginTop: "31px",
            backgroundColor: "#fff",
            borderRadius: "12px",
            height: "798px"
          }}
        >
          <MDBox style={{ marginTop: "135px" }}>
            <MDAvatar
              variant="square"
              style={{ width: "111px", height: "89px", margin: "0px auto" }}
              src={smassign}
            />
          </MDBox>
          <MDAvatar
            variant="square"
            style={{ width: "269px", height: "189px", margin: "0px auto", marginTop: "-40px" }}
            src={Sociallife}
          />
          <MDBox style={{ textAlign: "center", marginTop: "7px" }}>
            <MDTypography
              style={{ color: "#344767", fontSize: "20px", fontWeight: "700" }}
            >
              Start Posting From Today
            </MDTypography>
            <MDTypography
              style={{ color: "#7B809A", fontSize: "14px", fontWeight: "400" }}
            >
              Here you can setup and manage your integration settings.
            </MDTypography>
          </MDBox>
          <MDBox style={{ textAlign: "center", marginTop: "24px" }}>
            <MDButton
              component={Link}
              to="/content-library"
              variant="contained"
              style={{
                backgroundColor: "#09032B",
                fontSize: "14px",
                fontWeight: "500",
                color: "#FFFFFF",
              }}
            >
              Create Content
            </MDButton>
            <MDButton
              variant="outlined"
              style={{
                backgroundColor: "#E6E6EA",
                fontSize: "14px",
                fontWeight: "500",
                color: "#09032B",
                marginLeft: "10px",
                textTransform: "inherit"
              }}
            >
              Upload bulk post
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
