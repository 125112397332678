import { connect } from 'react-redux';
import { GoogleSuccessPage } from './google-success.component';

// const mapDispatchToProps = {
//     fetchCurrentUser
// };

// const mapStateToProps = state => ({
//   selectedAccount: state.userPage.selectedAccount
// });

export const GoogleSuccessPageContainer = connect(null)(GoogleSuccessPage);
