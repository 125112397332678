import InsightPage from './insight.component';
import { connect } from 'react-redux';
import { getInsights } from "./../../api/insight/insightAction";
import { uploadBG, fetchLayouts } from './../../api/theme/themeAction';
import { addContent, getContent, updateContent, deleteContent } from './../../api/content/contentAction';
import { getTags, addTags } from './../../api/tag/tagAction';
import { getMedias, addMedias, deleteMedia } from './../../api/media/mediaAction';

const mapDispatchToProps = {
    getInsights,
    uploadBG,
    addContent,
    getContent,
    updateContent,
    deleteContent,
    fetchLayouts,
    getTags,
    addTags,
    getMedias,
    addMedias,
    deleteMedia
}

const mapStateToProps = state => ({
    isLoading: state.profilePage.isLoading,
    isError: state.profilePage.isError,
    insights: state.insightPage.insights,
    selectedAccount: state.userPage.selectedAccount,
    user: state.userPage.loggedUser,
    bgImg: state.themePage.bgImg,
    layouts: state.themePage.layouts,
    content: state.contentPage.content,
    isSaved: state.contentPage.isSaved,
    tags: state.tagPage.tags,
    tagSaved: state.tagPage.isSaved,
    selectedAccount: state.userPage.selectedAccount,
    medias: state.mediaPage.medias,
    mediaSaved: state.mediaPage.isSaved,
    mediaLoading: state.mediaPage.isLoading,
    accounts: state.accountPage.accounts,
});

export const InsightPageContainer = connect(mapStateToProps, mapDispatchToProps)(InsightPage);