import { TeamPage } from './team-mates.component';
import { connect } from 'react-redux';
import {  fetchTeam, sendInvite, deleteTeam } from './../../api/user/userAction';

const mapDispatchToProps = {
    fetchTeam,
    sendInvite,
    deleteTeam
}

const mapStateToProps = state => ({
    user: state.userPage.loggedUser,
    isLoading: state.userPage.isLoading,
    teams: state.userPage.teams,
    teamDeleted: state.userPage.teamDeleted,
    selectedAccount: state.userPage.selectedAccount
});

export const TeamPageContainer = connect(mapStateToProps, mapDispatchToProps)(TeamPage);