import ResponsiveDrawer from './sidenav';
import { connect } from 'react-redux';
import { selectAccount } from './../../../api/user/userAction';

const mapDispatchToProps = {
    selectAccount
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    isError: state.userPage.isError,
    user: state.userPage.loggedUser,
    selectedAccount: state.userPage.selectedAccount
});

export const SidenavPageContainer = connect(mapStateToProps, mapDispatchToProps)(ResponsiveDrawer);