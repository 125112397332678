import * as actionTypes from "./subscriptionActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
const baseUrl = process.env.REACT_APP_API_URL;

export const createSession = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(createSessionInit());
        axios.post(`${baseUrl}/subscription/create-session`, data, config)
        .then((response)=>{
            dispatch(createSessionSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(createSessionFailure());
        })
    };
};

export const subscribe = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(subscribeInit());
        axios.post(`${baseUrl}/api/subscription/subscribe`, data, config)
        .then((response)=>{
            dispatch(subscribeSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(subscribeFailure());
        })
    };
};

export const cancelSubscription = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(cancelSubscriptionInit());
        axios.post(`${baseUrl}/subscription/cancel-subscription`, data, config)
        .then((response)=>{
            dispatch(cancelSubscriptionSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(cancelSubscriptionFailure());
        })
    };
};

export const fetchInvoices = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(fetchInvoicesInit());
        axios.get(`${baseUrl}/api/subscription/invoices`, config)
        .then((response)=>{
            dispatch(fetchInvoicesSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(fetchInvoicesFailure());
        })
    };
};

export const fetchPaymentMethods = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(fetchPaymentMethodsInit());
        axios.get(`${baseUrl}/api/subscription/payment-methods`, config)
        .then((response)=>{
            dispatch(fetchPaymentMethodsSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(fetchPaymentMethodsFailure());
        })
    };
};

export const updatePaymentMethod = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(updatePaymentMethodInit());
        axios.post(`${baseUrl}/api/subscription/update-payment-method`, data, config)
        .then((response)=>{
            dispatch(updatePaymentMethodSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(updatePaymentMethodFailure());
        })
    };
};

export const detachPaymentMethod = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(detachPaymentMethodInit());
        axios.post(`${baseUrl}/api/subscription/detach-payment-method`, data, config)
        .then((response)=>{
            dispatch(detachPaymentMethodSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(detachPaymentMethodFailure());
        })
    };
};

export const fetchSubscriptions = (stripeId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(fetchSubscriptionsInit());
        axios.post(`${baseUrl}/subscription/subscriptions`, { stripeId }, config)
        .then((response)=>{
            console.log("Data Subscription: ", response.data.data);
            dispatch(fetchSubscriptionsSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(fetchSubscriptionsFailure());
        })
    };
};

export const fetchUserSubscription = (sessionId, accountId, packageId) => {
    console.log("PackageId: ", packageId);
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(fetchUserSubscriptionInit());
        sessionStorage.removeItem("sessionId");
        // sessionStorage.removeItem("packageId");
        axios.post(`${baseUrl}/subscription/fetch-session`, { sessionId, accountId, packageId }, config)
        .then((response)=>{
            dispatch(fetchUserSubscriptionSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(fetchUserSubscriptionFailure());
        })
    };
};

export const fetchPackages = (accountId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem('accessToken')}` } };
      return (dispatch) => {
        dispatch(fetchPackagesInit());
        axios.get(`${baseUrl}/package`, config)
        .then((response)=>{
            dispatch(fetchPackagesSuccess(response.data.data));
        })
        .catch(function (error) {
            toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
            dispatch(fetchPackagesFailure());
        })
    };
};

export const fetchPackagesInit = () => ({
    type: actionTypes.FETCH_PACKAGES
});

export const fetchPackagesFailure = data => ({
    type: actionTypes.FETCH_PACKAGES_FAILURE
});

export const fetchPackagesSuccess = data => ({
    type: actionTypes.FETCH_PACKAGES_SUCCESS,
    payload: { data }
});

export const fetchSubscriptionsInit = () => ({
    type: actionTypes.FETCH_SUBSCRIPTION
});

export const fetchSubscriptionsFailure = data => ({
    type: actionTypes.FETCH_SUBSCRIPTION_FAILURE
});

export const fetchSubscriptionsSuccess = data => ({
    type: actionTypes.FETCH_SUBSCRIPTION_SUCCESS,
    payload: { data }
});

export const fetchUserSubscriptionInit = () => ({
    type: actionTypes.FETCH_USER_SUBSCRIPTION
});

export const fetchUserSubscriptionFailure = data => ({
    type: actionTypes.FETCH_USER_SUBSCRIPTION_FAILURE
});

export const fetchUserSubscriptionSuccess = data => ({
    type: actionTypes.FETCH_USER_SUBSCRIPTION_SUCCESS,
    payload: { data }
});

export const fetchInvoicesInit = () => ({
    type: actionTypes.FETCH_INVOICES
});

export const fetchInvoicesFailure = data => ({
    type: actionTypes.FETCH_INVOICES_FAILURE
});

export const fetchInvoicesSuccess = data => ({
    type: actionTypes.FETCH_INVOICES_SUCCESS,
    payload: { data }
});

export const fetchPaymentMethodsInit = () => ({
    type: actionTypes.FETCH_PAYMENT_METHODS
});

export const fetchPaymentMethodsFailure = data => ({
    type: actionTypes.FETCH_PAYMENT_METHODS_FAILURE
});

export const fetchPaymentMethodsSuccess = data => ({
    type: actionTypes.FETCH_PAYMENT_METHODS_SUCCESS,
    payload: { data }
});

export const updatePaymentMethodInit = () => ({
    type: actionTypes.UPDATE_PAYMENT_METHOD
});

export const updatePaymentMethodFailure = data => ({
    type: actionTypes.UPDATE_PAYMENT_METHOD_FAILURE
});

export const updatePaymentMethodSuccess = data => ({
    type: actionTypes.UPDATE_PAYMENT_METHOD_SUCCESS,
    payload: { data }
});

export const detachPaymentMethodInit = () => ({
    type: actionTypes.DETACH_PAYMENT_METHOD
});

export const detachPaymentMethodFailure = data => ({
    type: actionTypes.DETACH_PAYMENT_METHOD_FAILURE
});

export const detachPaymentMethodSuccess = data => ({
    type: actionTypes.DETACH_PAYMENT_METHOD_SUCCESS,
    payload: { data }
});

export const subscribeInit = () => ({
    type: actionTypes.SUBSCRIBE
});

export const subscribeFailure = data => ({
    type: actionTypes.SUBSCRIBE_FAILURE
});

export const subscribeSuccess = data => ({
    type: actionTypes.SUBSCRIBE_SUCCESS,
    payload: { data }
});

export const createSessionInit = () => ({
    type: actionTypes.CREATE_SESSION
});

export const createSessionFailure = data => ({
    type: actionTypes.CREATE_SESSION_FAILURE
});

export const createSessionSuccess = data => ({
    type: actionTypes.CREATE_SESSION_SUCCESS,
    payload: { data }
});

export const cancelSubscriptionInit = () => ({
    type: actionTypes.CANCEL_SUBSCRIPTION
});

export const cancelSubscriptionFailure = data => ({
    type: actionTypes.CANCEL_SUBSCRIPTION_FAILURE
});

export const cancelSubscriptionSuccess = data => ({
    type: actionTypes.CANCEL_SUBSCRIPTION_SUCCESS,
    payload: { data }
});