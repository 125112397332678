import React, { useEffect} from 'react';
import Loading from './../../shared/Loading';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function LinkedinSuccessPage(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const lrt = searchParams.get("lrt");
  const lat = searchParams.get("lat");
  const navigate = useNavigate();
  sessionStorage.setItem("lrt", lrt);
  sessionStorage.setItem("lat", lat);
  const redirectUrl = sessionStorage.getItem("redirectUrl") != null ? sessionStorage.getItem("redirectUrl") : "/social-profiles";
  useEffect(() => {
    if (lrt && lat) {
      sessionStorage.removeItem("redirectUrl");
      navigate(redirectUrl);
    }
  }, [lrt, lat]);
  // const navigate = useNavigate();
  // const { token } = useParams();
  // const redirectUrl = sessionStorage.getItem("redirectUrl") != null ? sessionStorage.getItem("redirectUrl") : "/social-profiles";
  // const { createLinkedin, fetchCurrentUser, linkedInCreated, selectedAccount } = props;
  // useEffect(() => {
  //   if(selectedAccount.id){
  //     createLinkedin({ AccountId: selectedAccount.id, accessToken: token });
  //   }
  // }, [selectedAccount]);

  // useEffect(() => {
  //   if (linkedInCreated) {
  //     fetchCurrentUser();
  //     sessionStorage.removeItem("redirectUrl");
  //     navigate(redirectUrl);
  //   }
  // }, [linkedInCreated]);

  return (
    <div>
      <Loading loading={true} />
    </div>
  );
}