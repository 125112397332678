/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React from "react";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 PRO React components
import MDBox from "./../../../../shared/components/MDBox";
import MDButton from "./../../../../shared/components/MDButton";
// import MDTypography from "./../../../../shared/components/MDTypography";
// import MDAvatar from "components/MDAvatar";

// MUI
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Checkbox from "@mui/material/Checkbox";

// Image
// import team1 from "assets/images/team-1.jpg";
// import team2 from "assets/images/team-2.jpg";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";
// import team5 from "assets/images/team-5.jpg";

function Header() {
  // menu
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>
        Select All <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu}>
        Scheduled <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Piblished <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Failed <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Draft <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  // const avatarStyles = {
  //   border: ({ borders: { borderWidth }, palette: { white } }) =>
  //     `${borderWidth[2]} solid ${white.main}`,
  //   cursor: "pointer",
  //   position: "relative",
  //   ml: -1.5,

  //   "&:hover, &:focus": {
  //     zIndex: "10",
  //   },
  // };

  return (
    <MDBox display="flex" alignItems="center">
      <MDBox mt={0.5} pr={1}>
        {/* <MDBox mb={1} ml={-1.25} lineHeight={0}>
          <MDTypography variant="button" color="secondary">
            Team members:
          </MDTypography>
        </MDBox> */}
        <MDBox display="flex">
          <MDButton
            variant={menu ? "contained" : "outlined"}
            color="dark"
            onClick={openMenu}
          >
            Status&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </MDBox>
      </MDBox>
      <MDBox height="75%" alignSelf="flex-end">
        {/* <Divider orientation="vertical" /> */}
      </MDBox>
      <MDBox pl={1}>
        <MDButton
          sx={{
            marginLeft: "16px",
            width: "157px",
            height: "41px",
            background: "linear-gradient(#09032B, #312B56)",
            color: "#fff",
          }}
        >
          CREATE CONTENT
        </MDButton>
      </MDBox>
    </MDBox>
  );
}

export default Header;
