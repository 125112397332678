export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const SEND_VERIFY = "SEND_VERIFY";
export const SEND_VERIFY_FAILURE = "SEND_VERIFY_FAILURE";
export const SEND_VERIFY_SUCCESS = "SEND_VERIFY_SUCCESS";

export const GET_ACCOUNT = "GET_ACCOUNT";
export const GET_ACCOUNT_FAILURE = "GET_ACCOUNT_FAILURE";
export const GET_ACCOUNT_SUCCESS = "GET_ACCOUNT_SUCCESS";

export const GET_LOCATION = "GET_LOCATION";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";
export const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";

export const RESEND_VERIFY = "RESEND_VERIFY";
export const RESEND_VERIFY_FAILURE = "RESEND_VERIFY_FAILURE";
export const RESEND_VERIFY_SUCCESS = "RESEND_VERIFY_SUCCESS";

export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_FAILURE = "FORGET_PASSWORD_FAILURE";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";

export const USER_AUTH = "USER_AUTH";
export const USER_AUTH_FAILURE = "USER_AUTH_FAILURE";
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";

export const FETCH_TEAM = "FETCH_TEAM";
export const FETCH_TEAM_FAILURE = "FETCH_TEAM_FAILURE";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";

export const GET_LINKEDIN_PAGE = "GET_LINKEDIN_PAGE";
export const GET_LINKEDIN_PAGE_FAILURE = "GET_LINKEDIN_PAGE_FAILURE";
export const GET_LINKEDIN_PAGE_SUCCESS = "GET_LINKEDIN_PAGE_SUCCESS";

export const GOOGLE_ACCOUNT = "GOOGLE_ACCOUNT";
export const GOOGLE_ACCOUNT_FAILURE = "GOOGLE_ACCOUNT_FAILURE";
export const GOOGLE_ACCOUNT_SUCCESS = "GOOGLE_ACCOUNT_SUCCESS";

export const GOOGLE_LOCATION = "GOOGLE_LOCATION";
export const GOOGLE_LOCATION_FAILURE = "GOOGLE_LOCATION_FAILURE";
export const GOOGLE_LOCATION_SUCCESS = "GOOGLE_LOCATION_SUCCESS";

export const DELETE_TEAM = "DELETE_TEAM";
export const DELETE_TEAM_FAILURE = "DELETE_TEAM_FAILURE";
export const DELETE_TEAM_SUCCESS = "DELETE_TEAM_SUCCESS";

export const SEND_INVITE = "SEND_INVITE";
export const SEND_INVITE_FAILURE = "SEND_INVITE_FAILURE";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";

export const INVITE_USER = "INVITE_USER";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";

export const CREATE_LINKEDIN = "CREATE_LINKEDIN";
export const CREATE_LINKEDIN_FAILURE = "CREATE_LINKEDIN_FAILURE";
export const CREATE_LINKEDIN_SUCCESS = "CREATE_LINKEDIN_SUCCESS";

export const GET_JWT_DETAILS = "GET_JWT_DETAILS";
export const GET_JWT_DETAILS_FAILURE = "GET_JWT_DETAILS_FAILURE";
export const GET_JWT_DETAILS_SUCCESS = "GET_JWT_DETAILS_SUCCESS";

export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const FETCH_CURRENT_USER_FAILURE = "FETCH_CURRENT_USER_FAILURE";
export const FETCH_CURRENT_USER_SUCCESS = "FETCH_CURRENT_USER_SUCCESS";

export const SELECT_ACCOUNT = "SELECT_ACCOUNT";
export const SELECT_ACCOUNT_SUCCESS = "SELECT_ACCOUNT_SUCCESS";