import * as actionType from "./industryActionType";

const initialState = {
    isError: false,
    isLoading: false,
    industries: [],
}

export const industryReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_INDUSTRIES:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.GET_INDUSTRIES_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false,
        };
    case actionType.GET_INDUSTRIES_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            industries: action.payload ? action.payload.data : [],
        };
        default: 
            return state
    }
}