import ActiveSocialPage from './activeSocial.component';
import { connect } from 'react-redux';
// import { userRegister } from './../../../api/user/userAction';

// const mapDispatchToProps = {
//     fetchReviews
// }

// const mapStateToProps = state => ({
//     isLoading: state.userPage.isLoading,
//     isError: state.userPage.isError,
//     reviews: state.userPage.reviews,
// });

export const ActiveSocialPageContainer = connect(null)(ActiveSocialPage);