import { SubscriptionPage } from './subscription-billing.component';
import { connect } from 'react-redux';
import { subscribe, createSession, fetchSubscriptions, cancelSubscription } from './../../api/subscription/subscriptionAction';
import { fetchPackages } from './../../api/subscription/subscriptionAction';

const mapDispatchToProps = {
    subscribe,
    createSession,
    fetchSubscriptions,
    cancelSubscription,
    fetchPackages
}

const mapStateToProps = state => ({
    subscriptionSuccess: state.subscribePage.subscriptionSuccess,
    sessionId: state.subscribePage.sessionId,
    isLoading: state.subscribePage.isLoading,
    selectedAccount: state.userPage.selectedAccount,
    subscriptions: state.subscribePage.subscriptions,
    packages: state.subscribePage.packages
});

export const SubscriptionPageContainer = connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);