/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";
// @mui material components
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
// import MDBox from "./../../../../shared/components/MDBox";
import MDButton from "./../../../../shared/components/MDButton";
import MDTypography from "./../../../../shared/components/MDTypography";

import DefaultProjectCard from "./../../../../shared/components/DefaultProjectCard";

// img
import homeDecor1 from "./../../../../assets/images/home-decor-1.jpg";
import homeDecor2 from "./../../../../assets/images/home-decor-2.jpg";
import homeDecor3 from "./../../../../assets/images/home-decor-3.jpg";
import homeDecor4 from "./../../../../assets/images/home-decor-4.jpeg";

// icon
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DraftsIcon from "@mui/icons-material/Drafts";

// Material Dashboard 2 PRO React examples
// import DefaultItem from "examples/Items/DefaultItem";

function NextEvents() {
  // menu
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>
        All Topics <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu}>
        Social Posts <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        RSS Feed <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Blog posts <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );

  return (
    <Card sx={{ height: "100%", background: "none", boxShadow: "none" }}>
      <Grid container>
        <Grid item sm={12} md={6} lg={6} pt={2} px={0}>
          <MDTypography variant="h6" fontWeight="medium">
            Draft Contents
          </MDTypography>
        </Grid>
        <Grid item sm={12} md={6} lg={6} pt={1} px={2}>
          <MDButton
            variant={menu ? "contained" : "outlined"}
            color="dark"
            onClick={openMenu}
          >
            All Topics&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </Grid>
      </Grid>
      <Grid container spacing={1}style={{ marginTop: "24px" }}>
        <Grid item sm={12} md={6} lg={6}>
          <DefaultProjectCard
            image={homeDecor1}
            label="CAPTION"
            description="Lorem ipsum dolor sit am Business owners! Tell us, can imagine ever ..."
            action={{
              type: "internal",
              route: "",
              color: "secondary",
              label: (
                <MDTypography
                  style={{
                    color: "#6B6880",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <DraftsIcon
                    style={{
                      color: "#FB8C00",
                      height: "18px",
                      width: "18px",
                      marginBottom: "-5px",
                    }}
                  />{" "}
                  Draft
                </MDTypography>
              ),
            }}
            authors={[
              { image: <EditIcon />, name: "Edit" },
              { image: <DeleteIcon />, name: "Delete" },
            ]}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <DefaultProjectCard
            image={homeDecor2}
            label="CAPTION"
            description="Lorem ipsum dolor sit am Business owners! Tell us, can imagine ever ..."
            action={{
              type: "internal",
              route: "",
              color: "secondary",
              label: (
                <MDTypography
                  style={{
                    color: "#6B6880",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <DraftsIcon
                    style={{
                      color: "#FB8C00",
                      height: "18px",
                      width: "18px",
                      marginBottom: "-5px",
                    }}
                  />{" "}
                  Draft
                </MDTypography>
              ),
            }}
            authors={[
              { image: <EditIcon />, name: "Edit" },
              { image: <DeleteIcon />, name: "Delete" },
            ]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: "16px" }}>
        <Grid item sm={12} md={6} lg={6}>
          <DefaultProjectCard
            image={homeDecor3}
            label="CAPTION"
            description="Lorem ipsum dolor sit am Business owners! Tell us, can imagine ever ..."
            action={{
              type: "internal",
              route: "",
              color: "secondary",
              label: (
                <MDTypography
                  style={{
                    color: "#6B6880",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <DraftsIcon
                    style={{
                      color: "#FB8C00",
                      height: "18px",
                      width: "18px",
                      marginBottom: "-5px",
                    }}
                  />{" "}
                  Draft
                </MDTypography>
              ),
            }}
            authors={[
              { image: <EditIcon />, name: "Edit" },
              { image: <DeleteIcon />, name: "Delete" },
            ]}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <DefaultProjectCard
            image={homeDecor4}
            label="CAPTION"
            description="Lorem ipsum dolor sit am Business owners! Tell us, can imagine ever ..."
            action={{
              type: "internal",
              route: "",
              color: "secondary",
              label: (
                <MDTypography
                  style={{
                    color: "#6B6880",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <DraftsIcon
                    style={{
                      color: "#FB8C00",
                      height: "18px",
                      width: "18px",
                      marginBottom: "-5px",
                    }}
                  />{" "}
                  Draft
                </MDTypography>
              ),
            }}
            authors={[
              { image: <EditIcon />, name: "Edit" },
              { image: <DeleteIcon />, name: "Delete" },
            ]}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

export default NextEvents;
