import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { EmailVerifyContainer } from './../views/email-verify/email-verify.container';
import { SaveBusinessPageContainer } from "./../views/business/saveBusiness/saveB.container";

const ProtectedPage = ({ children, isLoggedIn, user }) => {
    // const redirectPath = !isLoggedIn ? '/login' : user.emailVerified == 1 ? false : "/email-verify";
    function checkIsVerified() {
        return user.emailVerified == 1 && user.accounts && Array.isArray(user.accounts) && user.accounts.length > 0;
    }

    function checkEmailVerified() {
        return user.emailVerified == 1;
    }

    return (
        isLoggedIn ? (checkIsVerified() ? children : checkEmailVerified() ? <SaveBusinessPageContainer /> : <EmailVerifyContainer />) : <Navigate to="/login" />
    )
}

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser
});

export const ProtectedRoute = connect(mapStateToProps, {})(ProtectedPage);