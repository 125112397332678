import * as actionTypes from "./mediaActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const getMedias = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` }, params: data };
      return (dispatch) => {
        dispatch(getMediasInit());
        axios.get(`${baseUrl}/media/account/${data.id}`, config)
        .then((response)=>{
            dispatch(getMediasSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getMediasFailure());
        })
    };
};


export const getMediasInit = () => ({
    type: actionTypes.GET_MEDIAS
});

export const getMediasFailure = data => ({
    type: actionTypes.GET_MEDIAS_FAILURE
});

export const getMediasSuccess = data => ({
    type: actionTypes.GET_MEDIAS_SUCCESS,
    payload: { data }
});


export const addMedias = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(addMediasInit());
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        axios.post(`${baseUrl}/media/store`, params, config)
        .then((response)=>{
            dispatch(addMediasSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addMediasFailure());
        })
    };
};


export const addMediasInit = () => ({
    type: actionTypes.ADD_MEDIA
});

export const addMediasFailure = data => ({
    type: actionTypes.ADD_MEDIA_FAILURE
});

export const addMediasSuccess = data => ({
    type: actionTypes.ADD_MEDIA_SUCCESS,
    payload: { data }
});

export const deleteMedia = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(deleteMediaInit());
        axios.delete(`${baseUrl}/media/${id}`, config)
        .then((response)=>{
            dispatch(deleteMediaSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMediaFailure());
        })
    };
};


export const deleteMediaInit = () => ({
    type: actionTypes.DELETE_MEDIA
});

export const deleteMediaFailure = data => ({
    type: actionTypes.DELETE_MEDIA_FAILURE
});

export const deleteMediaSuccess = data => ({
    type: actionTypes.DELETE_MEDIA_SUCCESS,
    payload: { data }
});