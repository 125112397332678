import * as actionTypes from "./industryActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const getIndustries = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
      return (dispatch) => {
        dispatch(getIndustriesInit());
        axios.get(`${baseUrl}/industry/options`, config)
        .then((response)=>{
            dispatch(getIndustriesSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getIndustriesFailure());
        })
    };
};


export const getIndustriesInit = () => ({
    type: actionTypes.GET_INDUSTRIES
});

export const getIndustriesFailure = data => ({
    type: actionTypes.GET_INDUSTRIES_FAILURE
});

export const getIndustriesSuccess = data => ({
    type: actionTypes.GET_INDUSTRIES_SUCCESS,
    payload: { data }
});




