import * as actionType from "./accountActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    accounts: []
}

export const accountReducer = (state = initialState, action) => {
    switch (action.type) {
    case actionType.USER_ACCOUNT:
        return {
            ...state,
            isError: false,
            isLoading: true,
            isSaved: false
        };
    case actionType.USER_ACCOUNT_FAILURE:
        return {
            ...state,
            isError: true,
            isSaved: false,
            isLoading: false
        };
    case actionType.USER_ACCOUNT_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            isSaved: true
        };
    case actionType.FETCH_ACCOUNTS:
        return {
            ...state,
            isError: false,
            isLoading: true
        };
    case actionType.FETCH_ACCOUNTS_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false
        };
    case actionType.FETCH_ACCOUNTS_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            accounts: action.payload ? action.payload.data : [],
        };
        case actionType.UPDATE_USER_ACCOUNT:
            return {
                ...state,
                isError: false,
                isLoading: true,
                isSaved: false
            };
        case actionType.UPDATE_USER_ACCOUNT_FAILURE:
            return {
                ...state,
                isError: true,
                isSaved: false,
                isLoading: false
            };
        case actionType.UPDATE_USER_ACCOUNT_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                isSaved: true
            };
        default: 
            return state
    }
}