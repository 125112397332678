import React from "react";
import MDBox from "./components/MDBox";
import MDTypography from "./components/MDTypography";
// import { makeStyles } from "@material-ui/styles";
import MDButton from "./components/MDButton";

export function UpgradeProPage(props) {
  const { onButtonClick } = props;
  return (
    <MDBox sx={{ width: "294px", py: "29px", backgroundColor: "#1B1C29", textAlign: "center", borderRadius: "6px" }}>
      <MDTypography sx={{ fontWight: 600, fontSize: "24px", color: "#fff", mb: "10px" }}>Upgrade to pro</MDTypography>
      <MDTypography sx={{ fontWight: 400, fontSize: "12px", color: "#fff", mb: "20px" }}>To access this feature upgrade to pro</MDTypography>
      <MDButton onClick={() => onButtonClick()}>Upgrade</MDButton>
    </MDBox>
  );
}
