import * as actionType from "./topicActionType";

const initialState = {
    isError: false,
    isLoading: false,
    topics: []
}

export const topicReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_TOPICS:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
    case actionType.GET_TOPICS_FAILURE:
        return {
            ...state,
            isError: true,
            isLoading: false,
        };
    case actionType.GET_TOPICS_SUCCESS:
        return {
            ...state,
            isError: false,
            isLoading: false,
            topics: action.payload ? action.payload.data : [],
        };
        default: 
            return state
    }
}