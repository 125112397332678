import React, { useMemo, useState, useEffect } from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import AddIcon from '@mui/icons-material/Add';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Grid from "@mui/material/Grid";
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Divider from '@mui/material/Divider';
import CardMedia from "@mui/material/CardMedia";
import { Stack } from "@mui/material";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import MDButton from "../../shared/components/MDButton";
import { DateTime, Settings } from 'luxon';
import ContentEditorDialog from '../../shared/content-editor-dialog';
import { MediaLibraryPage } from "../../shared/media-library/media-library.component";
import { CollectionPage } from "../allContent/component/collection-page";
import { UpsertTagDialog } from "../allContent/component/upsert-tag-dialog";
import { createApi } from 'unsplash-js';
import { DeleteConfirm } from "../allContent/component/delete-confirm";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import { preetyTime } from "../../helper";
import { AttractionsOutlined } from "@mui/icons-material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const button = {
    textTransform: "capitalize",
    background: "#FFFFFF",
    border: "1px solid #CECDD5",
    color: "#7B809A",
    borderRadius: "8px",
  };
const mdBox = {
    background: "#FFFFFF",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    borderRadius: "12px",
    width: "auto",
  };
const iconText = {
    color: "#000",
    width: "15px",
    height: "15px",
    margin: "2px 6px",
  };
const topText = {
    fontWeight: "400",
    fontSize: "14px",
    color: "#344767",
    display: "-webkit-box",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  };
const box = {
    backgroundColor: "#F0F2F5",
    height: "28px",
    width: "28px",
    borderRadius: "6px",
  };
const cardText = {
    width: "100%",
    margin: 0,
    boxShadow: "inset 0px 12px 40px rgba(0, 0, 0, 0.2)",
    objectFit: "cover",
    objectPosition: "center",
    height: "auto",
    borderRadius: "12px 12px 0px 0px"
  };

export default function SchedulePage(props) {
  const {
    getSchedules,
    schedules,
    user,
    content,
    fetchAccounts,
    accounts,
    uploadBG,
    bgImg,
    addContent,
    getContent,
    isSaved,
    updateContent,
    deleteContent,
    fetchLayouts,
    layouts,
    getTags,
    addTags,
    tags,
    tagSaved,
    selectedAccount,
    getMedias,
    addMedias,
    medias,
    mediaSaved,
    mediaLoading,
    updateSchedule,
    updateOnlySchedule,
    reload,
    deleteMedia
  } = props;
  const emptyContent = {
    AccountId: selectedAccount && selectedAccount.id,
    ProfileIds: [],
    content: {all: "", facebook: "", insta: "", linkedin: "", gmb: ""},
    layouts: "",
    images: undefined,
    tags: [],
    hashtags: {
      all: selectedAccount && selectedAccount.hashtags,
      facebook: selectedAccount && selectedAccount.hashtags,
      insta: selectedAccount && selectedAccount.hashtags,
      linkedin: selectedAccount && selectedAccount.hashtags,
      gmb: selectedAccount && selectedAccount.hashtags,
    },
    scTime: null,
    status: 1
  }
  const [open, setOpen] = React.useState(false);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = React.useState({});
  const [openTag, setOpenTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState({ name: "", status: 1 });
  const [accountOptions, setAccountOptions] = React.useState(true);
  const [selected, setSelected] = React.useState(emptyContent);
  const [profiles, setProfiles] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dynamicFields, setDynamicFields] = React.useState({});
  const [collection, setCollection] = React.useState(selectedAccount && JSON.parse(selectedAccount.collection));
  const [mediaOpen, setMediaOpen] = React.useState(false);
  const [mediaBlankOpen, setMediaBlankOpen] = React.useState(false);
  const [mediaBlank, setMediaBlank] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [imageOpen, setImageOpen] = useState(false);
  const [menu1, setMenu1] = useState(null);
  const [selectedLayout, setSelectedLayout] = React.useState("");
  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);

  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem
        onClick={() => {
          setSearch({ ...search, status: null })
          closeMenu1();
        }}
        checked={!search.status}
        >Select All <Checkbox sx={{ ml: "auto" }}/></MenuItem>
      <Divider />
      <MenuItem
      onClick={() => {
        setSearch({ ...search, status: 5 })
        closeMenu1();
      }}
        >Scheduled <Checkbox checked={search.status == 5} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem
      onClick={() => {
          setSearch({ ...search, status: 5 })
          closeMenu1();
        }}
        >Published <Checkbox checked={search.status == 5} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 7 })
          closeMenu1();
        }}
        >Failed <Checkbox checked={search.status == 7} sx={{ ml: "auto" }}/></MenuItem>
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 1 })
          closeMenu1();
        }}
        >Draft <Checkbox checked={search.status == 1} sx={{ ml: "auto" }}/></MenuItem>
      <Divider />
      <MenuItem 
      onClick={() => {
          setSearch({ ...search, status: 2 })
          closeMenu1();
        }}
        >Ready For Review <Checkbox checked={search.status == 2} sx={{ ml: "auto" }}/></MenuItem>
    </Menu>
  );

  const X = 34;
  const Y = -28;
  const translat = {
    transform: `translate(${X}px, ${Y}px)`,
    color: "#7B879A",
  };

  const getProfiles = async () => {
    if (selectedAccount &&
      Array.isArray(selectedAccount.Profiles)) {
        const y = await Promise.all(selectedAccount.Profiles.map(x => {
                    return { 
                      id: x.id,
                      type: x.type,
                      label: `${x.type} (${x.name})`,
                      tags: x.hashtags
                      }
                  }));
      setProfiles(y);
      return;
    }
    setProfiles([]);
    return;
  }

  useEffect(async () => {
    if (Array.isArray(schedules) && schedules.length > 0) {
      const z = await Promise.all(schedules.map(async(x) => {
        console.log("X:: ", x);
        const profiles = await JSON.parse(x.ProfileIds).map(x => x.type);
        return {
          id: x.id,
          title: JSON.parse(x.Content.content).all,
          date: new Date(x.scTime),
          extendedProps: {
            image: JSON.parse(x.Content.images)[0].image,
            profiles: profiles
          }
        }
      }));
      setEvents(z);
    }
  }, [schedules]);

  useEffect(() => {
    if(reload) {
      getContent(selectedAccount && selectedAccount.id, { limit: 4, orderBy: "id", orderType: "DESC", offset: 0, status: 1 });
    }
  }, [reload]);

useEffect(() => {
    if(search){
      getContent(selectedAccount && selectedAccount.id, search);
    }
  }, [search]);

  useEffect(() => {
    if (tagSaved) {
      getTags();
      setSelectedTag({ name: "", status: 1 });
      setOpenTag(false);
    }
  }, [tagSaved]);

  useEffect(() => {
    if (isSaved) {
      getContent(selectedAccount && selectedAccount.id, { limit: 4, orderBy: "id", orderType: "DESC", offset: 0, status: 1 });
      setSelected(emptyContent);
      setDeleteOpen(false);
      setOpen(false);
    }
  }, [isSaved]);

  useEffect(() => {
    if (mediaSaved) {
      getMedias({ id: selectedAccount && selectedAccount.id, limit: 9, offset: 0, orderBy: "id", orderType: "DESC" });
    }
  }, [mediaSaved]);

  useEffect(async () => {
    if (accounts.length > 0) {
      const x = await Promise.all(accounts.map(y => {
        return {id: y.id, label: y.name}
      }))
      setAccountOptions(x);
    }
  }, [accounts]);

  useEffect(() => {
    if(bgImg) {
      setDynamics("Image Library", bgImg);
    }
  }, [bgImg]);

  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  function getCollectionById () {
    api.collections.getPhotos({ collectionId: collection && collection.id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }

  function setDynamics(x, v) {
    let obj = {};
    obj[x] = v;
    setDynamicFields({ ...dynamicFields, ...obj });
  }

  useEffect(() => {
      getSchedules(selectedAccount && selectedAccount.id);
      getContent(selectedAccount && selectedAccount.id, { limit: 4, orderBy: "id", orderType: "DESC", offset: 0, status: 1 });
      getProfiles();
      getTags();
      getMedias({ id: selectedAccount && selectedAccount.id, limit: 24, offset: 0, orderBy: "id", orderType: "DESC" });
  }, []);

  function returnProfileHashtags(type, tags) {
    if (profiles && Array.isArray(profiles)) {
      const x = profiles.filter(y => y.type == type);
      if (x.length > 0) {
        return `${tags} ${x[0].tags ? x[0].tags : ""}`.split(" ");
      }
      return tags.split(" ");
    }
    return tags.split(" ");
  }

  function setHashtags() {
    fetchLayouts(selectedAccount && selectedAccount.ThemeId);
    setSelected({
      ...selected,
      hashtags: {
        ...selected.hashtags,
        all: returnProfileHashtags("all", selected.hashtags.all ? selected.hashtags.all : ""),
        facebook: returnProfileHashtags("facebook", selected.hashtags.all ? selected.hashtags.all : ""),
        insta: returnProfileHashtags("insta", selected.hashtags.all ? selected.hashtags.all : ""),
        linkedin: returnProfileHashtags("linkedin", selected.hashtags.all ? selected.hashtags.all : ""),
        gmb: returnProfileHashtags("gmb", selected.hashtags.all ? selected.hashtags.all : ""),
      }
    });
    setOpen(true);
  }

  async function handleEventReceive(info) {
    console.log("Info: ", info);
    const data = info.draggedEl.getAttribute("data");
    const content = JSON.parse(data);
    const apiData = {
      ContentId: content.id,
      scTime: new Date(info.event._instance.range.start.setHours(12)),
      AccountId: selectedAccount && selectedAccount.id,
      ProfileIds: Array.isArray(content.Schedules) && content.Schedules.length > 0 ? content.Schedules[0].ProfileIds : JSON.stringify([]),
      hashtags: Array.isArray(content.Schedules) && content.Schedules.length > 0 ? content.Schedules[0].hashtags : JSON.stringify({})
    }
    // const profiles = Array.isArray(content.Schedules) && content.Schedules.length > 0 && await Promise.all(JSON.parse(content.Schedules[0].ProfileIds).map(x => x.type));
    // const ev = {
    //   id: content.id,
    //   title: JSON.parse(content.content).all,
    //   date: new Date(info.event._instance.range.start.setHours(12)),
    //   extendedProps: {
    //     image: JSON.parse(content.images)[0].image,
    //     profiles: profiles
    //   }
    // }
    updateSchedule(apiData);
    // setEvents(events.concat([ev]));
  }

  async function handleEventDrop(info) {
    console.log("Info: ", info);
    const apiData = {
      id: info.event._def.publicId,
      scTime: new Date(info.event._instance.range.start.setHours(12)),
    }
    updateOnlySchedule(apiData);
  }

  useEffect(async () => {
    let draggableEl = document.getElementById("external-events");
    new Draggable(draggableEl, {
      itemSelector: ".fc-event",
      create: false,
      eventData: async function(eventEl) {
        let id = eventEl.getAttribute("contentId");
        let data = eventEl.getAttribute("data");
        const x = JSON.parse(data);
        const profiles = Array.isArray(x.Schedules) && x.Schedules.length > 0 && await Promise.all(JSON.parse(x.Schedules[0].ProfileIds).map(y => y.type));
        return {
          id: x.id,
          title: JSON.parse(x.content).all,
          extendedProps: {
            image: JSON.parse(x.images)[0].image,
            profiles: profiles
          },
          create: false
        }
      }
    });
  }, []);

  const checkPr = (x, type) => {
    if (Array.isArray(x)) {
      const y = x.filter(z => z.type == type);
      return y.length > 0;
    }
    return false;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteConfirm
          deleteContent={deleteContent}
          open={deleteOpen}
          selected={selected}
          onClose={() => {
          setSelected(emptyContent);
          setDeleteOpen(false);
          }}
      />
      <ContentEditorDialog
          setOpenTag={setOpenTag}
          tags={tags}
          selected={selected}
          setSelected={setSelected}
          open={open}
          onClose={() => {
          setSelected(emptyContent);
          setDynamicFields({});
          setOpen(false);
          }}
          accounts={accountOptions}
          uploadBG={uploadBG}
          addContent={addContent}
          updateContent={updateContent}
          profilesOpt={profiles}
          layouts={layouts}
          openUnsplash={() => {
          getCollectionById();
          setImageOpen(true);
          }}
          selectedAccount={selectedAccount}
          dynamicFields={dynamicFields}
          setDynamics={setDynamics}
          setDynamicFields={setDynamicFields}
          setMediaOpen={setMediaOpen}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          setMediaBlank={setMediaBlank}
          setMediaBlankOpen={setMediaBlankOpen}
          mediaBlank={mediaBlank}
      />
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaOpen}
        onClose={() => setMediaOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={setDynamics}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaBlankOpen}
        onClose={() => setMediaBlankOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={(a, b) => {
          setSelected({
            ...selected,
            layouts: ""
          });
          setDynamicFields({});
          setSelectedLayout("");
          setSelected({ ...selected, images: b });
          setMediaBlank(b);
        }}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <CollectionPage
          images={images}
          open={imageOpen}
          onClose={() => {
          setImages([]);
          setImageOpen(false);
          }}
          setSelected={setSelected}
          selected={selected}
          setDynamics={setDynamics}
      />
      <UpsertTagDialog
          addTags={addTags}
          open={openTag}
          onClose={() => {
          setSelectedTag({ name: "", status: 1 });
          setOpenTag(false);
          }}
          selected={selectedTag}
          setSelected={setSelectedTag}
      />
      <MDBox style={{display: "flex", marginTop: "32px"}}>
          <MDBox
              style={{
                  background: "#1B172E",
                  borderRadius: "100px",
                  height: "52px",
                  width: "52px",
                  marginRight: "24px"
              }}
          >
              <MDTypography style={{fontWeight: "500", fontSize: "12px", color: "#FFFFFF", margin: "18px"}}>All</MDTypography>
          </MDBox>
          <MDBox
              style={{
              background: "#FFFFFF",
              borderRadius: "100px",
              height: "52px",
              width: "52px",
              marginRight: "24px"
              }}
          >
              <LinkedInIcon
                  style={{
                  height: "28px",
                  width: "28px",
                  margin: "12px",
                  color: "#7B879A",
                  }}
              />
          </MDBox>
          <MDBox
              style={{
                  background: "#FFFFFF",
                  borderRadius: "100px",
                  height: "52px",
                  width: "52px",
                  marginRight: "24px"
              }}
          >
              <FacebookRoundedIcon
                  style={{
                  height: "28px",
                  width: "28px",
                  margin: "12px",
                  color: "#7B879A",
                  }}
              />
          </MDBox>
          <MDBox
              style={{
                  background: "#FFFFFF",
                  borderRadius: "100px",
                  height: "52px",
                  width: "52px",
              }}
          >
              <StorefrontRoundedIcon
              style={{
                  height: "28px",
                  width: "28px",
                  margin: "12px",
                  color: "#7B879A",
              }}
              />
          </MDBox>
          <MDBox style={{marginLeft: "auto"}}>
              <MDButton
                  style={button}
                  sx={{ marginLeft: "16px" }}
                  variant={menu1 ? "contained" : "outlined"}
                  onClick={openMenu1}
              >
                  Status&nbsp;
                  <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu1}
              {/* <FormControl sx={{ m: 1, width: 91, mt: 3}}>
                  <Select sx={{
                      "& .css-1kvvgjv-MuiSvgIcon-root-MuiSelect-icon": {
                          display: "block",
                      },
                  }}
                      displayEmpty
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                          if (selected.length === 0) {
                          return <em>Status</em>;
                          }

                          return selected.join(', ');
                      }}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-label': 'Without label' }}
                  >
                      {names.map((name) => (
                          <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl> */}
              <MDButton
                  style={{
                  background: "linear-gradient(#09032B, #312B56)",
                  color: "#FFFFFF",
                  marginLeft: "16px",
                  fontWeight: "700",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  }}
                  onClick={() => setHashtags()}
              >   <AddIcon style={{marginRight: "10px"}} />
                  Create content
              </MDButton>
          </MDBox>
      </MDBox>
      <Grid container spacing={2} style={{marginTop: "25px"}}>
          <Grid item xs={12} md={8}>
          {/* <div className="height600"> */}
          <FullCalendar
            plugins={[dayGridPlugin, interactionPlugin]}
            events={events}
            eventContent={renderEventContent}
            eventDurationEditable={false}
            editable={true}
            droppable={true}
            rerenderDelay={10}
            eventDrop={handleEventDrop}
            eventReceive={handleEventReceive}
          />
          {/* </div> */}
          </Grid>
          <Grid item xs={12} md={4}>
              <MDBox style={{display: "flex", justifyContent: "space-between"}}>
                  <MDTypography style={{
                      fontWeight: 600,
                      fontSize: "18px",
                      color: "#1B1C29"
                  }}>Draft Contents</MDTypography>
                  {/* <MDButton
                      style={button}
                      sx={{ marginLeft: "16px" }}
                      variant={menu1 ? "contained" : "outlined"}
                      onClick={openMenu1}
                  >
                      Topic&nbsp;
                      <Icon>keyboard_arrow_down</Icon>
                  </MDButton>
                  {renderMenu1} */}
              </MDBox> 
              <Grid container spacing={2} style={{marginTop: "16px"}} id="external-events">
                  {content && Array.isArray(content) && content.map(x => {    
                      return (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className="fc-event"
                          contentId={x.id}
                          data={JSON.stringify(x)}
                        >
                          <MDBox style={mdBox}>
                            <MDBox
                              position="relative"
                              shadow="xl"
                              borderRadius="xl"
                            >
                              <div style={{ position: "absolute", top: "10px", left: "10px", padding: "4px 8px", lineHeight: "100%", background: "#E4FFE6", borderRadius: "8px" }}>
                                <span style={{ fontWeight: 700, fontSize: "12px", color: "#4CAF50" }}>Social Post</span>
                              </div>
                              <div style={{ position: "absolute", top: "10px", right: "10px", padding: "2px",lineHeight: "100%", background: "#E4FFE6", borderRadius: "8px" }}>
                                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "insta") && (
                                  <InstagramIcon
                                    style={{
                                      borderRadius: "4px",
                                      margin: "2px",
                                      width: "21px",
                                      height: "21px",
                                      color: "#6B6880"
                                    }}
                                  />
                                )}
                                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "facebook") && (
                                  <FacebookRoundedIcon
                                    style={{
                                      borderRadius: "4px",
                                      margin: "2px",
                                      width: "21px",
                                      height: "21px",
                                      color: "#6B6880"
                                    }}  
                                  />
                                )}
                                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "linkedin") && (
                                  <LinkedInIcon
                                    style={{
                                      borderRadius: "4px",
                                      margin: "2px",
                                      width: "21px",
                                      height: "21px",
                                      color: "#6B6880"
                                    }}
                                  />
                                )}
                                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "gmb") && (
                                  <StorefrontRoundedIcon
                                    style={{
                                      borderRadius: "4px",
                                      width: "21px",
                                      height: "21px",
                                      color: "#6B6880"
                                    }}
                                  />
                                )}
                              </div>
                              <CardMedia style={cardText} src={x.images && Array.isArray(JSON.parse(x.images)) && JSON.parse(x.images).length > 0 && JSON.parse(x.images)[0].image} component="img" />
                            </MDBox>
                            <MDBox style={{padding: "16px"}}>
                              <MDTypography style={topText}>
                                {JSON.parse(x.content).all}
                              </MDTypography>
                              <MDBox style={{ display: "flex", marginTop: "23px" }}>
                                <Stack direction="row" spacing="1">
                                { x.status == 1 ? 
                                  (
                                    <FolderOutlinedIcon
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#FB8C00",
                                        marginRight: "4px",
                                      }}
                                    />
                                  ) : x.status == 6 ? 
                                  (
                                    <SendIcon
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#41B853",
                                        marginRight: "4px",
                                        transform: "rotate(-45deg)"
                                      }}
                                    />
                                  ) : x.status == 4 ? 
                                  (<InfoOutlinedIcon
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      color: "#3672E7",
                                      marginRight: "4px",
                                    }}
                                  />) : (
                                    <AccessTimeIcon
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        color: "#FB8C00",
                                        marginRight: "4px",
                                      }}
                                    />
                                  ) }
                                  
                                  <MDTypography
                                    style={{
                                      // whiteSpace: "nowrap",
                                      // textOverflow: "ellipsis",
                                      color: "#6B6880",
                                      fontSize: "12px",
                                      fontWeight: "400"
                                    }}
                                  >
                                    { x.status == 1 ? "Draft" : x.status == 6 ? "Published" : x.status == 4 ? "In Queue" : x.status == 5 ? "Scheduled" : x.status == 7 ? "Failed" : "In Review" }
                                  </MDTypography>
                                </Stack>
                                {x.status == 6 ? (
                                  <MDBox
                                  style={box}
                                  sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                                >
                                  <ContentCopyIcon style={iconText} onClick={() => {
                                    setSelected({
                                      AccountId: x.AccountId,
                                      ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                                      content: x.content && JSON.parse(x.content),
                                      layouts: x.layouts && JSON.parse(x.layouts),
                                      images: JSON.parse(x.images)[0].image,
                                      hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                                      scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                                      scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                                      status: x.status,
                                      dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                                    });
                                    setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                                    setOpen(true);
                                  }}/>
                                </MDBox>
                                ) : x.status == 2 ? (
                                  <MDBox
                                      sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                                    >
                                  <Button
                                    variant="contained"
                                    sx={{color: "#fff", backgroundColor: "#000"}}
                                    onClick={() => alert("Check on content Page.")}
                                  >Approve</Button>
                                  </MDBox>
                                ) : (
                                  <>
                                    <MDBox
                                      style={box}
                                      sx={{ marginRight: "5px", marginLeft: "auto", cursor: "pointer" }}
                                    >
                                      <ModeEditOutlinedIcon style={iconText} onClick={() => {
                                        setSelected({
                                          id: x.id,
                                          AccountId: x.AccountId,
                                          ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                                          content: x.content && JSON.parse(x.content),
                                          layouts: x.layouts && JSON.parse(x.layouts),
                                          images: JSON.parse(x.images)[0].image,
                                          hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                                          scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                                          scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                                          status: x.status,
                                          dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                                        });
                                        setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                                        setOpen(true);
                                      }}/>
                                    </MDBox>
                                    <MDBox style={box}>
                                      <DeleteOutlineIcon
                                        style={iconText}
                                        onClick={() => {
                                          setSelected({
                                            id: x.id,
                                            AccountId: x.AccountId,
                                            ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                                            content: JSON.parse(x.content),
                                            layouts: JSON.parse(x.layouts),
                                            images: x.images,
                                            hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].hashtags),
                                            scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                                            scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scTime,
                                            status: x.status,
                                            scheduledId: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].id
                                          });
                                          setDeleteOpen(true);
                                        }}
                                      />
                                    </MDBox>
                                  </>
                                )}
                              </MDBox>
                            </MDBox>
                          </MDBox>
                        </Grid>
                      )
                  })}
              </Grid>
          </Grid>
      </Grid>
    </DashboardLayout>
  );
}


function renderEventContent(eventInfo) {
  return (
      <div style={eventInfo.event.title ? { width: "100%", padding: "1px 3px" } : {display: "none"}}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ width: "100%" }}>
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("insta") !== -1 && <img src="/instagram_white.svg" width="10px" height="10px"/>}
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("facebook") !== -1 && <FacebookRoundedIcon sx={{ width: "10px", height: "10px", color: "#fff" }} />}
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("linkedin") !== -1 && <LinkedInIcon sx={{ width: "10px", height: "10px", color: "#fff" }} />}
            {eventInfo.event.extendedProps.profiles && eventInfo.event.extendedProps.profiles.indexOf("gmb") !== -1 && <StorefrontRoundedIcon sx={{ width: "10px", height: "10px", color: "#fff" }} />}
          </div>
          <span style={{ fontSize: "8px", color: "#fff", lineHeight: "14px" }}>{eventInfo.timeText}</span>
        </div>
        <img src={eventInfo.event.extendedProps.image} style={{ width: "100%", height: "51px", objectFit: "cover", border: "1px solid transparent", borderRadius: "4px" }}/>
        <div style={{ paddingBottom: "3px" }}>
          <p style={{ fontSize: "10px", color: "#fff", lineHeight: "14px", display: "-webkit-box", WebkitLineClamp: 1, WebkitBoxOrient: "vertical", overflow: "hidden", wordBreak: "break-all" }}>
            {eventInfo.event.title}
          </p>
        </div>
      </div>
  )
}
