import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../../shared/components/MDBox";
import MDTypography from "../../../../shared/components/MDTypography";
import { makeStyles } from '@material-ui/styles';
import ClearIcon from "@mui/icons-material/Clear";
import Header from "../component/Header";
import PricingCards from "../component/PricingCards";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
  });

  const topHead = {
    fontWeight: "700",
    fontSize: "36px",
    color: "#09032B",
  }

export default function SubscribePagesDialog(props) {
  const classes = useStyles();
  const { open, setOpen } = props;
  const [tabValue, setTabValue] = React.useState(0);
  const [prices, setPrices] = React.useState(["59", "89", "99"]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    if (event.currentTarget.id === "annual") {
      setPrices(["119", "159", "399"]);
    } else {
      setPrices(["59", "89", "99"]);
    }
  };
  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{textAlign: "center"}}>
              <MDTypography style={topHead} >
                Upgrade Your Account
              </MDTypography>
            </MDBox>
            <MDBox style={{float: "right", marginTop: "-40px"}}>
              <ClearIcon  onClick={() => setOpen(false)}
                style={{ width: "32px", height: "32px" }} 
              />
            </MDBox>
            <Header tabValue={tabValue} tabHandler={handleSetTabValue}>
              <PricingCards prices={prices} />
            </Header>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
