import { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import MDAvatar from "../../shared/components/MDAvatar";
import MDBox from "../../shared/components/MDBox";
import MDButton from "../../shared/components/MDButton";
import MDInput from "../../shared/components/MDInput";
import MDTypography from "../../shared/components/MDTypography";
import FormField from "../../shared/components/FormField";
import IconButton from '@mui/material/IconButton';
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from "react-router-dom";

// icon
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import phone from "./../../assets/images/phone.png";
import choooseImage from "./../../assets/images/chooose.png";
import selectData from "./data";

import { createApi } from 'unsplash-js';
import { ChromePicker } from 'react-color'
import Loading from './../../shared/Loading';
import { CollectionPage } from './component/collection-page';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SaveIcon from '@mui/icons-material/Save';
import { UpgradeProPage } from "./../../shared/upgrade-pro";
import { SubscriptionDialogePage } from "./../../shared/subscription-dialoge";
import { fontList } from "./../../helper";
const bgcolor = {
    background: "none",
    boxShadow: "none",
    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-active": {
      background: "#1B1C29",
      fill: "#1B1C29",
      stroke: "#1B1C29",
      borderColor: "#1B1C29",
      height: "16px",
      width: "16px",
    },
    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-completed": {
      background: "#1B1C29",
      fill: "#1B1C29",
      stroke: "#1B1C29",
      borderColor: "#1B1C29",
      height: "16px",
      width: "16px",
    },
    "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root": {
      background: "#9B9CA3",
      fill: "#9B9CA3",
      stroke: "#9B9CA3",
      borderColor: "#9B9CA3",
      height: "16px",
      width: "16px",
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
      color: "#9B9CA3 !important",
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-active": {
      color: "#1B1C29 !important",
    },
    "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-completed": {
      color: "#1B1C29 !important",
    },
    "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
        color: "#1B1C29 !important",
    },
    "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel": {
      color: "#9B9CA3 !important",
    },
    "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel": {
      color: "#9B9CA3 !important",
    },
    "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active": {
      color: "#1B1C29 !important",
    },
    "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed": {
      color: "#1B1C29 !important",
    },
};

// const backdrop = {
//     "&::backdrop": {
//         background-color: darkorange;
//     }
// }

function getSteps() {
  return ["Add Business", "Select Theme", "Socials"];
}

export default function SelectThemePage(props) {
  const steps = getSteps();
  const {
      selectedAccount,
      user,
      isLoading,
      updateAccount,
      isSaved,
      fetchCurrentUser,
      content,
      getMasterContent,
      fetchThemes,
      themes,
      fetchPackages,
      packages
    } = props;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [followsMe, setFollowsMe] = useState(true);
  const [collections, setCollections] = React.useState([]);
  const [textOn, setTextOn] = React.useState("");
  const [color, setColor] = React.useState("");
  const [colorType, setColorType] = React.useState("");
  const [collection, setCollection] = React.useState(selectedAccount && selectedAccount.collection != null && JSON.parse(selectedAccount.collection));
  const [images, setImages] = React.useState([]);
  const [subscriptionOpen, setSubscriptionOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [search,setSearch] = useState("");
  const [imageOpen, setImageOpen] = useState(false);
  const [themeS, setThemeS] = useState("");
  const [showLayout, setShowLayout] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState(selectedAccount && selectedAccount.ThemeId);
  const navigate = useNavigate();
  
  const onSubscribeButtonClick = () => {
    setSubscriptionOpen(true);
    setOpen1(false);
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  useEffect(() => {
      if (isSaved) {
        setShowLayout(false);
        fetchCurrentUser();
        setOpen(false);
        setOpen1(false);
        getSelectedTheme();
        setTimeout(() => setColor(""), 1000);
        setTimeout(() => setColorType(""), 1000);
    }
  }, [isSaved]);

  function getCollectionById (id) {
    api.collections.getPhotos({ collectionId: id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }

  function getSBCCCollection () {
      api.users.getCollections({
        username: 'socialbrokerapp',
        page: 1,
        perPage: 24,
      })
      .then(result => {
        setCollections(result);
      })
      .catch(() => {
        console.log("something went wrong!");
      });
  }

  function unsplashSearch (x) {
    api.search
      .getCollections({ query: x, page: 1, perPage: 15})
      .then(result => {
        setCollections(result);
      })
      .catch(() => {
        console.log("something went wrong!");
      });
  }
  
  useEffect(() => {
    getMasterContent(selectedAccount.id);
    fetchPackages(selectedAccount.id);
    // unsplashSearch("interior design");
    getSBCCCollection();
    fetchThemes();
  }, []);

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    updateAccount({ logo: event.target.files[0] }, selectedAccount && selectedAccount.id);
  };

  function getSelectedTheme () {
    let x = themes.filter(y => y.id == selectedTheme);
    return x.length > 0 ? setThemeS(x[0]) : setThemeS({});
  }

  useEffect(() => {
      if (Array.isArray(themes)) {
        getSelectedTheme();
      }
  }, [themes]);

  function loadIframe(event, id, fields) {   
      console.log("Id: ", id);
    const { accentColor, darkColor, lightColor, textOnImage } = selectedAccount
    let css = `
    div.grid {
        position: absolute;
        top: 15%;
        left: 15%;
        width: 70%;
        height: calc(100vh - 30%);
        border: 2px dashed #bababa;
        display: none;
      }
      div:hover~.grid {
          display: block;
      }
      .colorAccent {
        color: ${accentColor}
      }
      .colorDark {
          color: ${darkColor}
      }
      .colorLight {
          color: ${lightColor}
      }
      .bgAccent {
          background-color: ${accentColor}
      }
      .bgDark {
          background-color: ${darkColor}
      }
      .bgLight {
          background-color: ${lightColor}
      }
      .hcenter {
        position: absolute;
        left: 50%!important;
        transform: translate(-50%, 0);
      }
      .hleft {
          position: absolute;
          left: 0px!important;
      }
      .hright {
          position: absolute;
          right: 0px!important;
      }
      .vcenter {
          position: absolute;
          top: 50%!important;
          transform: translate(-50%);
      }
      .vtop {
          position: absolute;
          top: 0px!important;
      }
      .vbottom {
          position: absolute;
          top: 100%!important;
          transform: translate(0, -100%);
      }
      .imageItem {
          background-image: url("/logo512.png");
          background-size: cover;
      }
      .delete-icon {
        display: none;
      }
      .layerSelect {
        background-color: #eaeaea;
        color: red;
      }`;

      if (themeS != "" && themeS.Layouts) {
          const selectedLayout = Array.isArray(themeS.Layouts) && themeS.Layouts.filter(x => x.MasterLayoutId == id);
          console.log("Selected Layout: ", selectedLayout);
          const { contentWindow } = event.target;
          const main = contentWindow.document.body;
          if(selectedLayout.length > 0) {
            main.innerHTML = `${JSON.parse(selectedLayout[0].data)[0].html}`;
            let head = contentWindow.document.head || contentWindow.document.getElementsByTagName('head')[0],
            style = contentWindow.document.createElement('style');
            head.appendChild(style);
            style.type = 'text/css';
            if (style.styleSheet){
                style.styleSheet.cssText = css;
            } else {
                style.appendChild(document.createTextNode(css));
            }
            if(fields){
                for (var key in fields) {
                    if (fields.hasOwnProperty(key) && key != "Image Library") {
                        if(fields[key] != ""){
                            let a = contentWindow.document.querySelector('[name="' + key + '"] .textItem').innerHTML = fields[key];
                        }
                    }
                    if (fields.hasOwnProperty(key) && key == "Image Library") {
                        if(fields["Image Library"]){
                            const mainEle = contentWindow.document.getElementById("main");
                            if(mainEle) {
                                mainEle.style["background-image"] = "url(" + fields["Image Library"] + ")";
                                mainEle.style["background-position"] = "center";
                                mainEle.style["background-repeat"] = "no-repeat";
                                mainEle.style["background-size"] = "cover";
                            }
                        }
                    }
                }
            }
            let replaces = main.querySelectorAll(".businessItem");
        [].forEach.call(replaces, function(replace) {
            let replaceable = replace.parentNode.parentNode.getAttribute("data-dynamic-field");
            if(replaceable !== ""){
              replace.innerHTML = selectedAccount[replaceable];
              console.log("Replaceable: ", replaceable);
              console.log("selectedAccount[replaceable]: ", selectedAccount[replaceable]);
            }
          });
        
        let replacess = main.querySelectorAll(".textItem");
        [].forEach.call(replacess, function(replace) {
            let replaceable = replace.parentNode.parentNode.getAttribute("data-dynamic-field");
            if(replaceable !== ""){
              replace.innerHTML = selectedAccount[replaceable];
              console.log("Replaceable: ", replaceable);
              console.log("selectedAccount[replaceable]: ", selectedAccount[replaceable]);
            }
          });
          }
      }
  }

  useEffect(() => {
      if (selectedAccount.id){
        if(!content || (content && Array.isArray(content) && content.length == 0)){
            getMasterContent(selectedAccount.id);
        }
        setShowLayout(true);
      }
  }, [selectedAccount]);

  return (
    <>
        <Loading loading={isLoading} />
        <SubscriptionDialogePage
            open={subscriptionOpen}
            onClose={() => {
                setSubscriptionOpen(false);
            }}
            packages={packages}
        />
        <CollectionPage
            images={images}
            open={imageOpen}
            onClose={() => {
                setImages([]);
                setImageOpen(false);
            }}
        />
        
        <Grid container justifyContent="center">
            <Grid item sm={12} md={5} lg={5}>
                <MDBox style={{textAlign: "center", marginTop: "64px"}}>
                    <Stepper sx={bgcolor} activeStep={1} alternativeLabel>
                        {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                        ))}
                    </Stepper>
                </MDBox>
            </Grid>
        </Grid>
        <Grid container >
            <Grid item xs={12}>
            <MDBox style={{ textAlign: "center" }}>
            <MDTypography
            style={{
                color: "#1B1C29",
                fontSize: "36px",
                fontWeight: "700",
                marginTop: "20px",
            }}
            >
            Select Your Theme
            </MDTypography>
        </MDBox>
            </Grid>
        </Grid>

        <Grid container spacing={2} style={{padding: "50px 64px 50px 64px"}}>
            <Grid item sm={12} md={5} lg={5}>
                <Card
                    style={{
                    background: "#FFFFFF",
                    padding: "24px 24px",
                    }}
                >
                    <MDTypography
                    style={{ color: "#1B1C29", fontSize: "16px", fontWeight: "700" }}
                    >
                        Text on Image
                        <Tooltip title="This could be your business name, phone number or website displayed on every post - leave blank 
                            if you don’t want it displayed">
                            <IconButton>
                                <HelpOutlinedIcon style={{color: "#7B879A"}} />
                            </IconButton>
                        </Tooltip>
                    </MDTypography>
                    <Grid item xs={8}>
                        <FormField
                            label="yourwebsite.com.au"
                            name="yourwebsite.com.au"
                            defaultValue={selectedAccount ? selectedAccount.textOnImage : ""}
                            onChange={e => setTextOn(e.target.value)}
                            type="text"
                            placeholder="yourwebsite.com.au"
                        />
                        {textOn === selectedAccount.textOnImage || textOn == "" ? (
                            <EditOutlinedIcon
                                style={{
                                    position: "relative",
                                    zIndex: 2,
                                    marginLeft: "auto",
                                    display: "flex",
                                    marginTop: "-26px",
                                    color: "#7B879A"
                                }}
                        />
                        ) : (
                            <SaveIcon
                                style={{
                                    position: "relative",
                                    zIndex: 2,
                                    marginLeft: "auto",
                                    display: "flex",
                                    marginTop: "-26px",
                                    color: "#7B879A",
                                    cursor: "pointer"
                                }}
                                onClick={() => updateAccount({textOnImage: textOn}, selectedAccount && selectedAccount.id)}
                            />
                        )}
                        
                    </Grid>
                    <MDTypography style={{fontWeight: "700", fontSize: "16px", color: "#1B1C29", marginTop: "48px"}}>Logo</MDTypography>
                    <MDTypography style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#5E6D84"
                        }}>
                            Your preferred logo to appear on posts.
                    </MDTypography>
                    <MDBox style={{ display: "flex", marginTop: "36px" }}>
                        <img src={selectedAccount ? selectedAccount.logo : ""} style={{ height: "50px" }} />
                        <label
                            htmlFor="header-image"
                            variant="outlined"
                            style={{ cursor: "pointer", textTransform: "capitalize", border: "1px solid #344767", padding: "7px", borderRadius: "8px", color: "#344767",
                                    fontSize: "12px", fontWeight: "700", margin: "10px 22px" }}
                        >
                            Upload logo
                        </label>
                        <input
                            accept="image/*"
                            id="header-image"
                            type="file"
                            onChange={onImageChange}
                            style={{ display: "none" }}
                            />
                    </MDBox>
                    <MDTypography style={{fontWeight: "700", fontSize: "16px", color: "#1B1C29", marginTop: "56px"}}>Selected Font</MDTypography>
                    <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#5E6D84"}}>Select your preferred fonts.</MDTypography>
                    <Grid item xs={8} style={{marginTop: "20px"}}>
                    <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#7B809A"}}>Heading Font</MDTypography>
                    <Autocomplete
                        options={fontList}
                        defaultValue={selectedAccount ? selectedAccount.headingFont : "Roboto"}
                        onChange={(e, x) => updateAccount({headingFont: x.id}, selectedAccount && selectedAccount.id)}
                        renderInput={(params) => (
                        <MDInput
                            {...params}
                            variant="standard"
                        />
                        )}
                    />
                    </Grid>
                    <Grid item xs={8} style={{marginTop: "16px"}}>
                    <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#7B809A"}}>Paragraph Font</MDTypography>
                    <Autocomplete
                        options={fontList}
                        defaultValue={selectedAccount ? selectedAccount.paragraphFont : "Roboto"}
                        onChange={(e, x) => updateAccount({paragraphFont: x.id}, selectedAccount && selectedAccount.id)}
                        renderInput={(params) => (
                        <MDInput
                            {...params}
                            variant="standard"
                        />
                        )}
                    />
                    </Grid>
                    <MDBox style={{marginTop: "70px"}} >
                        <MDTypography
                        style={{
                            color: "#1B1C29",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        >
                        Colours
                        </MDTypography>
                        <MDTypography
                        style={{
                            color: "#5E6D84",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                        >
                        Your brand colours
                        </MDTypography>
                    </MDBox>
                    <MDBox style={{ display: "flex", marginTop: "32px" }}>
                    <MDBox
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            setColorType("accentColor");
                            setColor(selectedAccount && selectedAccount.accentColor);
                        }}
                    >
                        <MDBox
                        style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "8px",
                            background: colorType == "accentColor" ? color : selectedAccount && selectedAccount.accentColor,
                        }}
                        />
                        <MDTypography
                        style={{
                            color: "#1B1C29",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                        >
                        Accent
                        </MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setColorType("darkColor");
                            setColor(selectedAccount && selectedAccount.darkColor);    
                        }}
                        sx={{ cursor: "pointer" }}
                        style={{ marginLeft: "19px" }}>
                        <MDBox
                        style={{
                            height: "80px",
                            width: "80px",
                            borderRadius: "8px",
                            background: colorType == "darkColor" ? color : selectedAccount && selectedAccount.darkColor,
                        }}
                        />
                        <MDTypography
                        style={{
                            color: "#1B1C29",
                            fontSize: "16px",
                            fontWeight: "500",
                        }}
                        >
                        Dark
                        </MDTypography>
                    </MDBox>
                    <MDBox
                        onClick={() => {
                            setColorType("lightColor");
                            setColor(selectedAccount && selectedAccount.lightColor);    
                        }} 
                        style={{ marginLeft: "19px" }}
                        sx={{ cursor: "pointer" }}
                    >
                        <MDBox
                            style={{
                                height: "80px",
                                width: "80px",
                                borderRadius: "8px",
                                background: colorType == "lightColor" ? color : selectedAccount && selectedAccount.lightColor,
                            }}
                        />
                            <MDTypography
                                style={{
                                    color: "#1B1C29",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                }}
                            >
                            Light
                            </MDTypography>
                        </MDBox>
                    </MDBox>
                    { colorType != "" && (
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <div
                            style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                            onClick={ () => {
                                if (colorType === "accentColor") {
                                    updateAccount({ accentColor : color }, selectedAccount && selectedAccount.id);
                                } else if (colorType === "darkColor") {
                                    updateAccount({ darkColor : color }, selectedAccount && selectedAccount.id);
                                } else if (colorType === "lightColor") {
                                    updateAccount({ lightColor : color }, selectedAccount && selectedAccount.id);
                                } else {
                                    console.log("Invalid color type");
                                }
                            }}
                        />
                        <ChromePicker color={ color } onChange={e => setColor(e.hex) } />
                    </div> )}
                    <MDBox style={{display: "flex", marginTop: "48px"}}>
                    <MDBox>
                        <MDTypography
                        style={{
                            color: "#1B1C29",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        >
                        Select Your Theme
                        </MDTypography>
                        <MDTypography
                        style={{
                            color: "#5E6D84",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                        >
                        Here you can manage your theme.
                        </MDTypography>
                    </MDBox>
                    <MDBox style={{marginLeft: "auto"}}>
                        <MDButton
                        onClick={handleClickOpen1}
                        variant="contained"
                        style={{
                            background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
                            color: "#FFFFFF",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                            textTransform: "capitalize",
                            borderRadius: "8px",
                            fontWeight: "700",
                            fontSize: "12px"
                        }}
                        >
                        Change Theme
                        </MDButton>
                    </MDBox>
                    </MDBox>
                    {themeS && themeS != "" && Object.keys(themeS).length > 0 && (
                        <MDBox style={{ marginTop: "32px" }}>
                            <MDAvatar
                                variant="square"
                                src={themeS && themeS.htmlData ? themeS.htmlData : phone}
                                style={{
                                width: "252px",
                                height: "252px",
                                border: "1px solid #000",
                                borderRadius: "8px",
                                }}
                            />
                            <MDTypography
                                style={{
                                color: "#1B1C29",
                                fontSize: "16px",
                                fontWeight: "500",
                                marginTop: "16px",
                                }}
                            >
                                {themeS.name}
                            </MDTypography>
                            <MDBox style={{display: "flex", marginTop: "10px"}}>
                                {themeS.tags && Array.isArray(JSON.parse(themeS.tags)) && JSON.parse(themeS.tags).map(x => {
                                    return (
                                        <MDTypography
                                            style={{
                                                color: "#4CAF50",
                                                fontSize: "12px",
                                                fontWeight: "700",
                                                background: "rgba(76, 175, 80, 0.2)",
                                                borderRadius: "8px",
                                                padding: "4px 8px",
                                                height: "25px",
                                                marginRight: "4px"

                                            }}
                                        >
                                            {x}
                                        </MDTypography>
                                    )
                                })}
                            </MDBox>
                        </MDBox>
                    )}
                    <MDBox style={{display: "flex", marginTop: "48px"}}>
                    <MDBox>
                        <MDTypography
                        style={{
                            color: "#1B1C29",
                            fontSize: "16px",
                            fontWeight: "700",
                        }}
                        >
                        Select Your Image Collection
                        </MDTypography>
                        <MDTypography
                        style={{
                            color: "#5E6D84",
                            fontSize: "14px",
                            fontWeight: "400",
                        }}
                        >
                        Choose which collection to use for your content.
                        </MDTypography>
                    </MDBox>
                    <MDBox style={{marginLeft: "auto"}}>
                        <MDButton
                        onClick={handleClickOpen}
                        variant="contained"
                        style={{
                            background:
                            "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
                            color: "#FFFFFF",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                            textTransform: "capitalize",
                            borderRadius: "8px",
                            fontSize: "12px",
                            fontWeight: "700"
                        }}
                        >
                        Change Collection
                        </MDButton>
                    </MDBox>
                    </MDBox>
                    {collection && (
                    <>
                        <MDAvatar
                        variant="square"
                        src={collection && collection.cover_photo.urls.small}
                        style={{ width: "252px", height: "252px", marginTop: "32px" }}
                        />
                        <MDBox onClick={() => getCollectionById(collection.id)} style={{ display: "flex", marginTop: "16px", maxWidth: "252px" }}>
                            <MDTypography
                                style={{
                                fontWeight: "600",
                                fontSize: "15px",
                                color: " #5E6D84",
                                }}
                            >
                                {collection && collection.title}
                            </MDTypography>
                            <MDBox style={{display: "flex", marginLeft: "auto", cursor: "pointer"}}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 700 }}>View All</Typography>
                                <ArrowForwardIcon style={{ color: "#7B879A" }} /> 
                            </MDBox>
                        </MDBox>
                    </>
                    )}
                    <MDButton
                        variant="contained"
                        onClick={() => {
                            updateAccount({textOnImage: textOn}, selectedAccount && selectedAccount.id);
                            navigate("/add-social");
                        }}
                        style={{
                            background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                            borderRadius: "8px",
                            color: "#FFFFFF",
                            fontWeight: "700",
                            fontSize: "12px",
                            marginTop: "52px",
                            textTransform: "capitalize",
                            maxWidth: "295px",
                            height: "50px"
                        }}
                    >
                        Save and Continue
                    </MDButton>
                </Card>
            </Grid>
            <Grid item sm={12} md={7} lg={7}>
                <Paper
                    style={{
                    background: "#fff",
                    margin: "0px auto",
                    padding: "24px 24px",
                    width: "100%"
                    }}
                >
                    <MDTypography
                        style={{
                            color: "The Post Look Like",
                            fontSize: "28px",
                            fontWeight: "700",
                            color: "#1B1C29"
                        }}
                        >
                        A sneak peek of your content
                    </MDTypography>
                    <Grid container spacing={2} style={{marginTop: "32px"}}>
                        {showLayout && content && Array.isArray(content) && content.map(x => {
                            if(themeS != ""){
                                const selectedLayout = Array.isArray(themeS.Layouts) && themeS.Layouts.filter(y => y.MasterLayoutId == x.MasterLayoutId);
                                let w = 1200;
                                let h = 1200;
                                let z = .25;
                                let m = 1200;
                                if(selectedLayout.length > 0) {
                                    const s = JSON.parse(selectedLayout[0].data)[0];
                                    w = s.width;
                                    h = s.height;
                                    m = s.width > s.height ? s.width : s.height;
                                    z = (100 / (m / 300) / 100);
                                    return (
                                        <Grid item xs={12} md={6}>
                                            <div style={{ width: "100%", height: "300px", overflow: "hidden" }}>
                                                <iframe
                                                    frameBorder="0"
                                                    src={`${process.env.REACT_APP_BASE_URL}/#/layout-frame`}
                                                    width={`${w}px`}
                                                    height={`${h}px`}
                                                    id="selected-layout"
                                                    style={{
                                                        transform: `scale(${z})`,
                                                        MsTransform: `scale(${z})`,
                                                        OTransform: `scale(${z})`,
                                                        MozTransform: `scale(${z})`,
                                                        WebkitTransform: `scale(${z})`,
                                                        transformOrigin: "0 0",
                                                        MsTransformOrigin: "0 0",
                                                        OTransformOrigin: "0 0",
                                                        WebkitTransformOrigin: "0 0"
                                                    }}
                                                    onLoad={(event) => {
                                                        loadIframe(event, x.MasterLayoutId, JSON.parse(x.dynamicFields));
                                                    }}
                                                />
                                            </div>
                                                <p dangerouslySetInnerHTML={{ __html: x.content.replace(/\n/g, "<br />") }}
                                                    style={{ color: "#5E6D84", fontSize: "16px", fontWeight: "400", marginTop: "15px" }}
                                                ></p>
                                            
                                        </Grid>
                                    )
                                }
                            }
                        })}
                        
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
        <Dialog
            fullScreen={fullScreen}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item xs={10}>
                <DialogTitle id="responsive-dialog-title">
                    See and save our vast image library
                </DialogTitle>
                <MDTypography
                    style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "18px",
                    marginTop: "-16px",
                    }}
                >
                    Here you can setup and manage your integration settings.
                </MDTypography>
                </Grid>
                <Grid item xs={2}>
                <MDBox
                    onClick={handleClose}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
                <Grid item xs={5}>
                    <FormField
                        label="Search Image"
                        placeholder="search Image"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MDButton
                        style={{
                            background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                            color: "#fff"
                        }}
                        variant="contained"
                        onClick={() => unsplashSearch(search)}
                    >
                        Search
                    </MDButton>
                </Grid>
                <Grid item xs={4}>
                    <MDButton
                        style={{
                            background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                            color: "#fff",
                            minWidth: "295px",
                        }}
                        variant="contained"
                        onClick={() => getSBCCCollection()}
                    >
                        Social broker collection
                    </MDButton>
                </Grid>
                {collections && !collections.errors && collections.response && collections.response.results.map(x => {
                return (
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                    <img onClick={() => {
                            setCollection(x);
                        }} src={ x.cover_photo.urls.small } style={{ width: "100%", height: "300px", objectFit:"cover" }} width="100%" />
                    {collection != null && collection.id == x.id && <img style={{ position: "relative", left: "calc(50% - 32px)", top: "-200px" }} src={choooseImage} />}
                    <Grid item>
                        <MDBox style={collection != null && collection.id == x.id ? { display: "flex", marginTop: "-64px" } : { display: "flex" }}>
                        <MDTypography
                            style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            color: " #534F6B",
                            }}
                        >
                            {x.title}
                        </MDTypography>
                        <MDBox sx={{ ml: "auto", display: 'flex', cursor: "pointer" }} onClick={() => getCollectionById(x.id)}>
                            <Typography sx={{ fontSize: '12px', fontWeight: 700 }}>View All</Typography>
                            <ArrowForwardIcon style={{color: "#09032B", marginBottom: "-2px"}} />
                        </MDBox>
                        </MDBox>
                    </Grid>
                    </Grid>
                )
                })}
            </Grid>
            
            <MDBox style={{ marginTop: "15px" }}>
                <MDButton
                    onClick={() => {
                        updateAccount({ collection : JSON.stringify(collection) }, selectedAccount && selectedAccount.id);
                        handleClose();
                    }}
                    variant="contained"
                    style={{
                        background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                        color: "#fff",
                        minWidth: "295px",
                    }}
                >
                    Save and continue
                </MDButton>
            </MDBox>
            </MDBox>
        </Dialog>
        <Dialog
            fullScreen={fullScreen}
            open={open1}
            onClose={handleClose1}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item sm={6} md={6} lg={6}>
                <DialogTitle id="responsive-dialog-title">
                    {"Select Your Theme"}
                </DialogTitle>
                <MDTypography
                    style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "18px",
                    marginTop: "-16px",
                    }}
                >
                    Here you can setup and manage your integration settings.
                </MDTypography>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                <MDBox
                    onClick={handleClose1}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {themes && Array.isArray(themes) && themes.filter(y => y.premium == 0).map(x => {
                    return (
                        <Grid item xs={12} md={4}>
                            <MDBox style={{ cursor: "pointer" }} onClick={() => setSelectedTheme(x.id)}>
                                {selectedTheme != null && selectedTheme == x.id && <img style={{ position: "relative", top: "32px", left: "calc(50% - 32px)", zIndex: 1 }} src={choooseImage} />}
                                <img
                                    width="100%"
                                    src={x.htmlData ? x.htmlData : phone}
                                    style={
                                        selectedTheme != null && selectedTheme == x.id ? {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                        marginTop: "-73px"
                                    } : {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                    }}
                                />
                            </MDBox>
                            <MDTypography
                                style={{
                                    color: "#09032B",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                }}
                            >
                                {x.name}
                            </MDTypography>
                        </Grid>
                    )
                })}
            </Grid>
            {/* {selectedAccount.subscription && selectedAccount.subscription.themeAcess != 1 && ( */}
            <div style={{ position: "relative", left: "calc(50% - 147px)", top: "100px", zIndex: 100 }}>
                <UpgradeProPage
                    onButtonClick={() => onSubscribeButtonClick()}
                />
            </div>
                 {/* )} */}
            <Grid container spacing={3} style={{ position: "relative", top: "-100px" }} sx={selectedAccount.subscription && selectedAccount.subscription.themeAcess === 1 ? { opacity: 1 } : { opacity: .3 }}>
                {themes && Array.isArray(themes) && themes.filter(y => y.premium == 1).map(x => {
                    return (
                        <Grid item xs={12} md={4}>
                            <MDBox
                                style={selectedAccount.subscription && selectedAccount.subscription.themeAcess === 1 ? { cursor: "pointer" } : {}}
                                onClick={() => {
                                    if(selectedAccount.subscription && selectedAccount.subscription.themeAcess === 1) {
                                        setSelectedTheme(x.id)
                                    }
                                }}
                            >
                                {selectedTheme != null && selectedTheme == x.id && <img style={{ position: "relative", top: "32px", left: "calc(50% - 32px)", zIndex: 1 }} src={choooseImage} />}
                                <img
                                    width="100%"
                                    src={x.htmlData ? x.htmlData : phone}
                                    style={
                                        selectedTheme != null && selectedTheme == x.id ? {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                        marginTop: "-73px"
                                    } : {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                    }}
                                />
                            </MDBox>
                            <MDTypography
                                style={{
                                    color: "#09032B",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                }}
                            >
                                {x.name}
                            </MDTypography>
                        </Grid>
                    )
                })}
            </Grid>
            {/* <Grid container spacing={3} style={{ marginTop: "15px", position: "relative", top: "-200px" }}>
                
                <Grid item xs={12} >
                    <Grid container spacing={3}>    
                        {themes && Array.isArray(themes) && themes.map(x => {
                            return (
                                <Grid item xs={12} md={6}>
                                    <MDBox
                                        style={selectedAccount.subscription && selectedAccount.subscription.themeAcess == 1 ? { cursor: "pointer" } : {}}
                                        onClick={() => {
                                            if(selectedAccount.subscription && selectedAccount.subscription.themeAcess == 1) {
                                                setSelectedTheme(x.id)
                                            }
                                        }}
                                    >
                                        {selectedTheme != null && selectedTheme == x.id && <img style={{ position: "relative", top: "32px", left: "calc(50% - 32px)", zIndex: 1 }} src={choooseImage} />}
                                        <img
                                            width="100%"
                                            src={x.htmlData ? x.htmlData : phone}
                                            style={
                                                selectedTheme != null && selectedTheme == x.id ? {
                                                border: "1px solid #000",
                                                borderRadius: "8px",
                                                marginTop: "-73px"
                                            } : {
                                                border: "1px solid #000",
                                                borderRadius: "8px",
                                            }}
                                        />
                                    </MDBox>
                                    <MDTypography
                                        style={{
                                            color: "#09032B",
                                            fontSize: "16px",
                                            fontWeight: "500",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {x.name}
                                    </MDTypography>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid> */}
            <MDBox style={{ marginTop: "15px" }}>
                <MDButton
                variant="contained"
                style={{
                    background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                    color: "#fff",
                    minWidth: "295px",
                }}
                onClick={() => {
                    updateAccount({ ThemeId: selectedTheme }, selectedAccount && selectedAccount.id);
                    handleClose();
                }}
                >
                Save and continue
                </MDButton>
            </MDBox>
            </MDBox>
        </Dialog>
    </>
  );
}
