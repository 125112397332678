import * as actionTypes from "./masterContentActionType";
import axios from 'axios';
import { checkError } from './../error';
const baseUrl = process.env.REACT_APP_API_URL;

export const addMasterContent = (data) => {
    return (dispatch) => {
        dispatch(addMasterContentInit());
        axios.post(`${baseUrl}/content/store`, data)
        .then((response)=>{
            dispatch(addMasterContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addMasterContentFailure());
        })
    };
};

export const addMasterContentInit = () => ({
    type: actionTypes.ADD_MASTER_CONTENT
});

export const addMasterContentFailure = data => ({
    type: actionTypes.ADD_MASTER_CONTENT_FAILURE
});

export const addMasterContentSuccess = data => ({
    type: actionTypes.ADD_MASTER_CONTENT_SUCCESS,
    payload: { data }
});

export const updateMasterContent = (data) => {
    return (dispatch) => {
        dispatch(updateMasterContentInit());
        axios.put(`${baseUrl}/content/${data.id}`, data)
        .then((response)=>{
            dispatch(updateMasterContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateMasterContentFailure());
        })
    };
};

export const updateMasterContentInit = () => ({
    type: actionTypes.UPDATE_MASTER_CONTENT
});

export const updateMasterContentFailure = data => ({
    type: actionTypes.UPDATE_MASTER_CONTENT_FAILURE
});

export const updateMasterContentSuccess = data => ({
    type: actionTypes.UPDATE_MASTER_CONTENT_SUCCESS,
    payload: { data }
});

export const deleteMasterContent = (contentId, scheduleId) => {
    return (dispatch) => {
        dispatch(deleteMasterContentInit());
        axios.delete(`${baseUrl}/content/${contentId}/${scheduleId}`, {})
        .then((response)=>{
            dispatch(deleteMasterContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteMasterContentFailure());
        })
    };
};

export const deleteMasterContentInit = () => ({
    type: actionTypes.DELETE_MASTER_CONTENT
});

export const deleteMasterContentFailure = data => ({
    type: actionTypes.DELETE_MASTER_CONTENT_FAILURE
});

export const deleteMasterContentSuccess = data => ({
    type: actionTypes.DELETE_MASTER_CONTENT_SUCCESS,
    payload: { data }
});

export const getMasterContent = (id) => {
    return (dispatch) => {
        dispatch(getMasterContentInit());
        axios.get(`${baseUrl}/master-content/account/${id}`, {})
        .then((response)=>{
            dispatch(getMasterContentSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getMasterContentFailure());
        })
    };
};

export const getMasterContentInit = () => ({
    type: actionTypes.GET_MASTER_CONTENT
});

export const getMasterContentFailure = data => ({
    type: actionTypes.GET_MASTER_CONTENT_FAILURE
});

export const getMasterContentSuccess = data => ({
    type: actionTypes.GET_MASTER_CONTENT_SUCCESS,
    payload: { data }
});