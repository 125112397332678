import { connect } from 'react-redux';
import { LinkedinSuccessPage } from './linkedin-success.component';
import { fetchCurrentUser, createLinkedin } from './../../api/user/userAction';

const mapDispatchToProps = {
    createLinkedin,
    fetchCurrentUser
};

const mapStateToProps = state => ({
  selectedAccount: state.userPage.selectedAccount,
  linkedInCreated: state.userPage.linkedInCreated,
});

export const LinkedinSuccessPageContainer = connect(mapStateToProps, mapDispatchToProps)(LinkedinSuccessPage);
