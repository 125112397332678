import React, { useEffect} from 'react';
import Loading from './../../shared/Loading';
import { useNavigate } from 'react-router-dom';

export function ThankYouPage(props) {
  const navigate = useNavigate();
  const { fetchUserSubscription, fetchCurrentUser, userSubscribed, selectedAccount } = props;
  useEffect(() => {
    if (sessionStorage.getItem('sessionId') !== null) {
      fetchUserSubscription(sessionStorage.getItem('sessionId'), selectedAccount.id, sessionStorage.getItem('packageId'));
    }
  }, []);

  useEffect(() => {
    if (userSubscribed) {
      fetchCurrentUser();
      navigate(`/billing-plan`);
    }
  }, [userSubscribed]);

  return (
    <div>
      <Loading loading={true} />
    </div>
  );
}