import SchedulePage from './schedule.component';
import { connect } from 'react-redux';
import { getSchedules, updateSchedule, updateOnlySchedule } from './../../api/schedule/scheduleAction';
import { uploadBG, fetchLayouts } from './../../api/theme/themeAction';
import { addContent, getContent, updateContent, deleteContent } from './../../api/content/contentAction';
import { getTags, addTags } from './../../api/tag/tagAction';
import { getMedias, addMedias, deleteMedia } from './../../api/media/mediaAction';

const mapDispatchToProps = {
    getSchedules,
    uploadBG,
    addContent,
    getContent,
    updateContent,
    deleteContent,
    fetchLayouts,
    getTags,
    addTags,
    getMedias,
    addMedias,
    updateSchedule,
    updateOnlySchedule,
    deleteMedia
}

const mapStateToProps = state => ({
    isLoading: state.schedulePage.isLoading,
    isError: state.schedulePage.isError,
    schedules: state.schedulePage.schedules,
    reload: state.schedulePage.reload,
    user: state.userPage.loggedUser,
    bgImg: state.themePage.bgImg,
    layouts: state.themePage.layouts,
    content: state.contentPage.content,
    isSaved: state.contentPage.isSaved,
    tags: state.tagPage.tags,
    tagSaved: state.tagPage.isSaved,
    selectedAccount: state.userPage.selectedAccount,
    medias: state.mediaPage.medias,
    mediaSaved: state.mediaPage.isSaved,
    mediaLoading: state.mediaPage.isLoading,
    accounts: state.accountPage.accounts,
    
});

export const SchedulePageContainer = connect(mapStateToProps, mapDispatchToProps)(SchedulePage);