import React from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
// MUI
import Grid from "@mui/material/Grid";
// import MDTypography from "./../../shared/components/MDTypography";
import MDButton from "./../../shared/components/MDButton";
import MDInput from "./../../shared/components/MDInput";
import MDBox from "./../../shared/components/MDBox";
import FormField from "./../../shared/components/FormField";
import MDTypography from "./../../shared/components/MDTypography";
import { Card } from "@mui/material";

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#534F6B"
}
const textHead = {
    fontWeight: "600",
    fontSize: "12px",
    color: "#3A3555",
    marginTop: "32px"
}
const button = {
    background: "#09032B",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.14), 0px 3px 4px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "8px",
    color: "#FFFFFF",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "capitalize",
    width: "160px"

}
const mdButton = {
    color: "#6B6880",
    marginRight: "22px",
    borderColor: "#6B6880",
    fontWeight: "700",
    fontSize: "12px",
    textTransform: "capitalize"
}


export default function SettingPage() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Grid container>
            <Grid item>
                <Card style={{width: "447px", padding: "32px 24px 32px 24px"}}>
                    <MDTypography style={topHead}>Account Setting</MDTypography>
                    <MDTypography style={textHead}>
                        Personal Details
                    </MDTypography>
                    <MDBox style={{ marginTop: "24px" }}>
                        <FormField
                            label="First name"
                            name="first name"
                            type="text"
                            placeholder="john@email.com"
                        />
                    </MDBox>
                    <MDBox style={{ marginTop: "24px" }}>
                        <FormField
                            label="Last name"
                            name="last name"
                            type="text"
                            placeholder="john@email.com"
                        />
                    </MDBox>
                    <MDBox style={{ marginTop: "24px" }}>
                        <FormField
                            label="Email"
                            name="email"
                            type="email"
                            placeholder="john@email.com"
                        />
                    </MDBox>
                    <MDBox>
                        <MDButton variant="text" style={{ color: "#3A3555", fontWeight: "600", fontSize: "14px", textTransform: "capitalize",
                            marginTop: "16px", textDecoration: "underline", marginLeft: "-24px" }}>
                        Change Password
                        </MDButton>
                    </MDBox>
                    <MDBox style={{display: "flex", marginTop: "40px"}}>
                        <MDButton variant="outlined" style={mdButton} >
                            Cancel
                        </MDButton>
                        <MDButton style={button} >
                            Save
                        </MDButton>
                    </MDBox>
                </Card>
          </Grid>
      </Grid>
    </DashboardLayout>
  );
}
