import { useState } from "react";
import { Grid } from "@mui/material";
// import DashboardLayout from "./../../../shared/components/DashboardLayout";
// import DashboardNavbar from "./../../../shared/components/DashboardNavbar";
import MDBox from "./../../../shared/components/MDBox";
import MDAvatar from "./../../../shared/components/MDAvatar";
import MDTypography from "./../../../shared/components/MDTypography";
import MDButton from "./../../../shared/components/MDButton";
import smassign from "./../../../assets/images/social-set.png";
import AddIcon from "@mui/icons-material/Add";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Link } from "react-router-dom";
// import { FacebookProvider, Login } from "react-facebook-sdk";
// import { FacebookProvider,Page } from 'react-facebook';
// import { LinkedIn } from "react-linkedin-login-oauth2";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Switch from "@mui/material/Switch";
import Tooltip from '@mui/material/Tooltip';
import Divider from "@mui/material/Divider";
import SelectPagesDialog from "./component/select-pages-dialog";
import GooglePagesDialog from "./component/google-business-dialog";
import InstaPagesDialog from "./component/insta-pages-dialog";
import SubscribePagesDialog from "./component/subscribe-pages-dialog";
import NewUser from "./../../../assets/images/new_theme.png";

const tooltipText = {
  background: "#35363E", 
  backdropFilter: "blur(240px)", 
  borderRadius: "8px",
  color: "rgba(255, 255, 255, 0.87)", 
  fontWeight: "400", 
  fontSize: "12px", 
  padding: "8px", 
  opacity: 0.9
}

export default function ActiveSocial() {
  const [open, setOpen] = useState(false);
  const [openG, setOpenG] = useState(false);
  const [openI, setOpenI] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [spotify2FA, setSpotify2FA] = useState(true);
  const handleSetSpotify2FA = () => setSpotify2FA(!spotify2FA);
  const [spotify2FA1, setSpotify2FA1] = useState(true);
  const handleSetSpotify2FA1 = () => setSpotify2FA1(!spotify2FA1);

  const x = 32;
  const y = 0;
  const box = {
    border: "1px solid #000",
    borderRadius: "100px",
    width: "21px",
    height: "21px",
    background: "#ffffff",
    transform: `translate(${x}px, ${y}px)`,
  };

  const icon = {
    width: "13px",
    height: "15px",
    margin: "1px 1px 5px 3px",
  };
  const handleResponse = (data) => {
    console.log("login result", data);
  };
  const handleError = (error) => {
    console.warn(error);
  };

  const [slack2FA, setSlack2FA] = useState(true);
  const handleSetSlack2FA = () => setSlack2FA(!slack2FA);
  return (
    <>
    <SelectPagesDialog open={open} setOpen={setOpen} />
    <GooglePagesDialog open={openG} setOpen={setOpenG} />
    <InstaPagesDialog open={openI} setOpen={setOpenI} />
    <SubscribePagesDialog open={openS} setOpen={setOpenS} />
      <MDBox style={{textAlign: "center", marginTop: "64px"}}>
        <img src={NewUser} alt="New user"  />
      </MDBox>
      <Grid container style={{ textAlign: "center", display: "grid" }}>
        <MDTypography
          style={{
            color: "#09032B",
            fontSize: "36px",
            fontWeight: "700",
            marginTop: "64px",
          }}
        >
          Connect a Social Set...
        </MDTypography>
        <Grid item
          style={{
            backgroundColor: "white",
            margin: "37px auto",
            borderRadius: "12px",
            marginTop: "32px",
            height: "auto",
          }}
          sx={{
            "@media (min-width: 320px)": {
              width: "320px",
              // height: "1015px",
            },
            "@media (min-width: 576px)": {
              width: "528px",
              // height: "805px",
            },
            "@media (min-width: 991px)": {
              width: "817px",
            },
          }}
        >
          <MDBox
            style={{
              display: "grid",
              justifyContent: "center",
              padding: "9% 0px",
            }}
          >
            <MDBox>
              <MDAvatar
                src={smassign}
                variant="square"
                style={{ width: "249px", height: "218px", margin: "0px auto" }}
              />
            </MDBox>
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "22px",
                  fontWeight: "700",
                  marginTop: "32px",
                }}
              >
                Connect more social profiles
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginTop: "3px",
                }}
              >
                Please add your social profiles here.
              </MDTypography>
            </MDBox>
            <MDBox
              style={{
                textAlign: "center",
                display: "flex",
                marginTop: "20px",
              }}
            >
              {/* <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID}>
                <Login
                  scope="email"
                  onResponse={handleResponse}
                  onError={handleError}
                >
                  <MDBox
                    style={{
                      background: "#1B172E",
                      height: "52px",
                      width: "52px",
                      borderRadius: "35px",
                      marginRight: "16px"
                    }}
                  >
                    <InstagramIcon
                      style={{
                        width: "23px",
                        height: "21px",
                        margin: "8px 2px -11px 0px",
                        color: "#fff",
                      }}
                    />
                    <MDBox style={box}>
                      <EditOutlinedIcon style={icon}
                        onClick={() => setOpenI(true)} />
                    </MDBox>
                  </MDBox>
                </Login>
              </FacebookProvider> */}
              {/* <LinkedIn
                clientId={process.env.REACT_APP_LINKEDIN_CLIENT_ID}
                redirectUri={`http://localhost:3000/callback`}
                scope={"r_emailaddress"}
                onSuccess={(code) => {
                  console.log("code:- ", code);
                }}
                onError={(error) => {
                  console.log(error);
                }}
              >
                {({ linkedInLogin }) => (
                  <MDBox
                    style={{
                      background: "#1B172E",
                      height: "52px",
                      width: "52px",
                      borderRadius: "35px",
                      margin: "0px 3px",
                    }}
                  >
                    <LinkedInIcon
                      onClick={linkedInLogin}
                      style={{
                        width: "23px",
                        height: "21px",
                        margin: "8px 0px -13px 1px",
                        color: "#fff",
                      }}
                    />
                    <MDBox style={box}>
                      <EditOutlinedIcon style={icon} />
                    </MDBox>
                  </MDBox>
                )}
              </LinkedIn> */}
              {/* <FacebookProvider appId={process.env.REACT_APP_FACEBOOK_APP_ID}>
                <Login
                  scope="email"
                  onResponse={handleResponse}
                  onError={handleError}
                >
                  <MDBox
                    style={{
                      background: "#E6E6EA",
                      height: "52px",
                      width: "52px",
                      borderRadius: "35px",
                      marginRight: "16px"
                    }}
                  >
                    <FacebookRoundedIcon
                      style={{
                        width: "23px",
                        height: "21px",
                        margin: "8px 0px -13px 1px",
                      }}
                    />
                    <MDBox style={box}>
                      <EditOutlinedIcon style={icon}
                      onClick={() => setOpen(true)} />
                    </MDBox>
                  </MDBox>
                </Login>
              </FacebookProvider> */}
              <MDBox
                // component={Link}
                // to="/add-social"
                style={{
                  background: "#E6E6EA",
                  height: "52px",
                  width: "52px",
                  borderRadius: "35px",
                  marginRight: "16px"
                }}
              >
                <Tooltip title={
                  <MDTypography style={tooltipText}>
                    Upgrade pricing plan to add more social profiles</MDTypography>
                }>
                  <StorefrontTwoToneIcon
                    style={{
                      width: "23px",
                      height: "21px",
                      margin: "8px 0px -13px 1px",
                    }}
                  />
                </Tooltip>
                <MDBox style={box}>
                  <AddIcon style={{ width: "13px", height: "15px", margin: "1px 1px 5px 3px" }}
                  onClick={() => setOpen(true)} />
                </MDBox>
              </MDBox>
            </MDBox>
          </MDBox>
          <MDBox pt={0} pb={3} px={3}>
            <MDBox style={{ textAlign: "left" }}>
              <MDTypography
                style={{
                  color: "#344767",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Social Connections
              </MDTypography>
              <MDTypography
                style={{
                  color: "#7b809a",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginBottom: "11px",
                }}
              >
                Setup and manage your social profile connections.
              </MDTypography>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox display="flex" alignItems="center">
                <MDBox
                  style={{
                    background: "#1B172E",
                    height: "34px",
                    width: "34px",
                    borderRadius: "100px",
                  }}
                >
                  <FacebookRoundedIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      margin: "0px 0px -6px",
                      color: "#FFFFFF"
                    }}
                  />
                </MDBox>
                <MDBox ml={2}>
                  <MDTypography
                    style={{
                      color: "#344767",
                      fontSize: "20px",
                      fontWeight: "700",
                    }}
                  >
                    Facebook
                  </MDTypography>
                  <MDBox display="flex" alignItems="flex-end">
                    <MDTypography
                      variant="button"
                      style={{
                        color: "#7B809A",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Show less{" "}
                      <KeyboardArrowUpIcon style={{ marginBottom: "-3px" }} />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                <MDBox lineHeight={0} mx={2}>
                  <MDTypography
                    variant="button"
                    style={{
                      color: "#534F6B",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {slack2FA ? "Enabled" : "Disabled"}
                  </MDTypography>
                </MDBox>
                <MDBox mr={1}>
                  <Switch
                    sx={{
                      "& .css-t7u51m-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                        {
                          backgroundColor: "#534F6B !important",
                        },
                    }}
                    checked={slack2FA}
                    onChange={handleSetSlack2FA}
                  />
                </MDBox>
                <MDBox>
                  <DeleteIcon style={{ marginBottom: "-3px", color: "#191919" }} />
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox ml={2} pl={6} pt={2} lineHeight={1}>
              <MDBox
                style={{ backgroundColor: "#F5F5F5" }}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                my={1}
                py={1}
                pl={{ xs: 1, sm: 2 }}
                pr={1}
              >
                <Grid container>
                  <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                    >
                      Post CTA
                    </MDTypography>
                  </Grid>
                  <Grid item sm={12} md={7} style={{ textAlign: "Left" }}>
                    <MDTypography
                      style={{
                        color: "#1B172E",
                        fontSize: "14px",
                        fontWeight: "700",
                        textDecoration: "underline",
                      }}
                    >
                      hello@creative-tim.com
                    </MDTypography>
                    <MDTypography
                      variant="button"
                      style={{
                        color: "#7B809A",
                        fontSize: "14px",
                        display: "flex",
                      }}
                    >
                      You haven&apos;t added your Slack yet or you aren&apos;t
                      authorized. Please add our Slack Bot to your account by
                      clicking on here. When you&apos;ve added
                    </MDTypography>
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <MDButton variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                      textDecoration: "underline" }}>
                      Edit
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox
                style={{ backgroundColor: "#F5F5F5" }}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
                my={1}
                py={1}
                pl={{ xs: 1, sm: 2 }}
                pr={1}
              >
                <Grid container style={{display: "flex", alignItems: "center"}}>
                  <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                    <MDTypography
                      variant="button"
                      fontWeight="medium"
                      color="text"
                    >
                      Hashtags
                    </MDTypography>
                  </Grid>
                  <Grid item sm={12} md={7} style={{ textAlign: "Left", display: "flex" }}>
                    <MDTypography
                      style={{
                        color: "#221C40",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      #Hastag_one
                  </MDTypography>
                  &nbsp;&nbsp;
                  <MDTypography
                    style={{
                      color: "#221C40",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    #Hastag_one
                  </MDTypography>
                  &nbsp;&nbsp;
                  <MDTypography
                    style={{
                      color: "#221C40",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    #Hastag_one
                  </MDTypography>
                  &nbsp;&nbsp;
                  <MDTypography
                    style={{
                      color: "#221C40",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    #Hastag_one
                  </MDTypography>
                  </Grid>
                  <Grid item sm={12} md={2}>
                    <MDButton variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                      textDecoration: "underline" }}>
                      Edit
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
            <Divider style={{ background: "#D4D4D4" }} />
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox display="flex" alignItems="center">
                <MDBox
                  style={{
                    background: "#1B172E",
                    height: "34px",
                    width: "34px",
                    borderRadius: "100px",
                  }}
                >
                  <InstagramIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      margin: "0px 0px -6px",
                      color: "#FFFFFF"
                    }}
                  />
                </MDBox>
                <MDBox ml={2} lineHeight={0}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Instagram
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="success"
                    style={{ fontWeight: "400", fontSize: "14px", float: "left" }}
                  >
                    Active
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                <MDBox lineHeight={0} mx={2}>
                  <MDTypography variant="button" color="text">
                    {spotify2FA ? "Enabled" : "Disabled"}
                  </MDTypography>
                </MDBox>
                <MDBox mr={1}>
                  <Switch checked={spotify2FA} onChange={handleSetSpotify2FA} />
                </MDBox>
                <MDBox>
                  <MoreVertIcon style={{ marginBottom: "-3px" }} />
                </MDBox>
              </MDBox>
            </MDBox>
            <Divider style={{ background: "#D4D4D4" }} />
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems={{ xs: "flex-start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <MDBox display="flex" alignItems="center">
                <MDBox
                  style={{
                    background: "#1B172E",
                    height: "34px",
                    width: "34px",
                    borderRadius: "100px",
                  }}
                >
                  <LinkedInIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      margin: "0px 0px -6px",
                      color: "#FFFFFF"
                    }}
                  />
                </MDBox>
                <MDBox ml={2} lineHeight={0}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Linkedin
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="success"
                    style={{ fontWeight: "400", fontSize: "14px", float: "left" }}
                  >
                    Active
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                alignItems="center"
                width={{ xs: "100%", sm: "auto" }}
                mt={{ xs: 1, sm: 0 }}
              >
                <MDBox lineHeight={0} mx={2}>
                  <MDTypography variant="button" color="text">
                    {spotify2FA1 ? "Enabled" : "Disabled"}
                  </MDTypography>
                </MDBox>
                <MDBox mr={1}>
                  <Switch checked={spotify2FA1} onChange={handleSetSpotify2FA1} />
                </MDBox>
                <MDBox>
                  <MoreVertIcon style={{ marginBottom: "-3px" }} />
                </MDBox>
              </MDBox>
            </MDBox>
            <Divider style={{ background: "#D4D4D4" }} />
            <MDBox style={{ textAlign: "left", marginTop: "27px" }}>
              <MDButton
                onClick={() => setOpenS(true)}
                variant="contained"
                style={{
                  background: "#09032B",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  fontWeight: "700",
                  width: "295px",
                  height: "50px",
                  textTransform: "inherit"
                }}
                
              >
                Save and Continue
              </MDButton>
            </MDBox>
          </MDBox>
        </Grid>
      </Grid>
    </>
  );
}
