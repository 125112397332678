import { useState, useEffect } from 'react';
import { Grid, Typography } from "@mui/material";
import MDAvatar from "../../shared/components/MDAvatar";
import MDBox from "../../shared/components/MDBox";
import MDButton from "../../shared/components/MDButton";
import MDInput from "../../shared/components/MDInput";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import MDTypography from "../../shared/components/MDTypography";
import ProfileImages from "./../../shared/components/profile-images.component";
import FormField from "../../shared/components/FormField";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import Slider from '@mui/material/Slider';
import { UpgradeProPage } from "./../../shared/upgrade-pro";
import { useNavigate } from "react-router-dom";
// icon
import BookTwoToneIcon from "@mui/icons-material/BookTwoTone";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

// img
import theme1 from "./../../assets/images/abstract.png";
import phone from "./../../assets/images/phone.png";
import choooseImage from "./../../assets/images/chooose.png";
import selectData from "./data";

import { createApi } from 'unsplash-js';

import { ChromePicker } from 'react-color';
import Loading from './../../shared/Loading';

import { CollectionPage } from './component/collection-page';
import StartIcon from "@mui/icons-material/Start";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { fontList } from "./../../helper";
import {ConfirmRegenerateContent} from "./../businessOverview/component/confirm-regenerate-content";

const iconDiv = {
  height: "72px",
  width: "72px",
  borderRadius: "8px",
  background: "#EEEEF1",
  padding: "10px 10px",
};
const iconBox = {
  height: "16px",
  width: "16px",
  background: "#fff",
  borderRadius: "4px",
};

export default function ThemePage(props) {
  const navigate = useNavigate();
  const { selectedAccount, user, isLoading, updateAccount, isSaved, fetchCurrentUser, fetchThemes, themes, regenerateContent, isRegenerated } = props;
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [followsMe, setFollowsMe] = useState(true);
  const [textOn, setTextOn] = React.useState("");
  const [color, setColor] = React.useState("");
  const [colorType, setColorType] = React.useState("");
  const [collections, setCollections] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [subscriptionOpen, setSubscriptionOpen] = React.useState(false);
  const [collection, setCollection] = React.useState(selectedAccount && selectedAccount.collection != null && JSON.parse(selectedAccount.collection));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [search,setSearch] = useState("interior design");
  const [imageOpen, setImageOpen] = useState(false);
  const [position, setPosition] = useState({position: "leftTop", scale: 10, opacity: 1});
  const [themeS, setThemeS] = useState("");
  const [selectedTheme, setSelectedTheme] = useState(selectedAccount && selectedAccount.ThemeId);
  const [openConfirmContent, setOpenConfirmContent] = useState(false);
  const onSubscribeButtonClick = () => {
    setSubscriptionOpen(true);
    setOpen1(false);
  }

  function setPos (type = "leftTop", scale = 10) {
    const positionCss = {
      leftTop: { position: "relative", left: "15px", top: "15px" },
      centerTop: { position: "relative", left: "50%", top: "15px", transform: "translate(-50%, 0)" },
      rightTop: { position: "relative", left: "100%", top: "15px", transform: "translate(calc(-15px + -100%), 0)"},
      leftCenter: { position: "relative", left: "15px", top: "50%", transform: "translate(0, -50%)"},
      rightcenter: { position: "relative", left: "100%", top: "50%", transform: "translate(calc(-15px + -100%), -50%)" },
      bottomLeft: { position: "relative", left: "15px", top: "100%", transform: "translate(0, calc(-15px + -100%))" },
      bottomCenter: { position: "relative", left: "50%", top: "100%", transform: "translate(-50%, calc(-15px + -100%))" },
      bottomRight: { position: "realtive", left: "100%", top: "100%", transform: "translate(calc(-15px + -100%), calc(-15px + -100%))" },
      center: { position: "relative", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }
    }
    return positionCss[type];
  }
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  
  const handleClose = () => {
    setOpen(false);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  function getSBCCCollection () {
    api.users.getCollections({
      username: 'socialbrokerapp',
      page: 1,
      perPage: 24,
    })
    .then(result => {
      setCollections(result);
    })
    .catch(() => {
      console.log("something went wrong!");
    });
}

  function unsplashSearch (x) {
    api.search
      .getCollections({ query: x, page: 1, perPage: 15})
      .then(result => {
        setCollections(result);
      })
      .catch(() => {
        console.log("something went wrong!");
      });
  }

  function getCollectionById (id) {
    api.collections.getPhotos({ collectionId: id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }
  
  useEffect(() => {
    // unsplashSearch("interior design");
    getSBCCCollection();
    fetchThemes();
    if (selectedAccount && JSON.parse(selectedAccount.position) != null && JSON.parse(selectedAccount.position).position) {
      setPosition(selectedAccount && JSON.parse(selectedAccount.position))
    }
  }, []);

  useEffect(() => {
    if (isSaved) {
        fetchCurrentUser();
        getSelectedTheme();
        setOpen(false);
        setOpen1(false);
    }
  }, [isSaved]);

  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    updateAccount({ logo: event.target.files[0] }, selectedAccount && selectedAccount.id);
  };

  function getSelectedTheme () {
    let x = themes.filter(y => y.id == selectedTheme);
    return x.length > 0 ? setThemeS(x[0]) : setThemeS({});
  }

  useEffect(() => {
      if (Array.isArray(themes)) {
        getSelectedTheme();
      }
  }, [themes]);

  function valuetext(value) {
    return `${value}%`;
  }

  const confirmRegenerateContent = () => {
    setOpenConfirmContent(true);
  }

  useEffect(() => {
    if(isRegenerated && openConfirmContent){
        setOpenConfirmContent(false);
        navigate("/content-library");
    }
  }, [isRegenerated]);

  const watermarkImage = collection && collection.cover_photo.urls.regular ? collection.cover_photo.urls.regular : theme1;
  return (
    <>
    <Loading loading={isLoading} />
    <ConfirmRegenerateContent
        open={openConfirmContent}
        selectedAccount={selectedAccount}
        onClose={() => {
            setOpenConfirmContent(false);
        }}
        regenerateContent={regenerateContent}
    />
    <CollectionPage
      images={images}
      open={imageOpen}
      onClose={() => {
        setImages([]);
        setImageOpen(false);
      }}
    />
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <MDButton
          style={{
              background: "linear-gradient(#09032B, #312B56)",
              color: "#FFFFFF",
              marginLeft: "16px",
              fontWeight: "700",
              fontSize: "12px",
              textTransform: "capitalize",
              height: "40px"
            }}
            onClick={() => confirmRegenerateContent()}
        >Generate my content</MDButton>
      </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Card
            style={{
              background: "#FFFFFF",
              padding: "24px 24px",
            }}
          >

            <MDBox style={{display: "flex"}}>
              <MDBox>
                <MDTypography
                  style={{
                    color: "#344767",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Business Colours
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "6px"
                  }}
                >
                  Your brand colours
                </MDTypography>
              </MDBox>
              <MDBox style={{marginLeft: "auto"}}>
              </MDBox>
            </MDBox>
            <MDBox style={{ display: "flex", marginTop: "32px" }}>
              <MDBox
                style={{ cursor: "pointer" }}
                onClick={() => {
                            setColorType("accentColor");
                            setColor(selectedAccount && selectedAccount.accentColor);
                        }}>
                <MDBox
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "8px",
                    background: colorType == "accentColor" ? color : selectedAccount && selectedAccount.accentColor,
                  }}
                />
                <MDTypography
                  style={{
                    color: "#09032B",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Accent
                </MDTypography>
              </MDBox>
              <MDBox
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setColorType("darkColor");
                  setColor(selectedAccount && selectedAccount.darkColor);    
              }}
              style={{ marginLeft: "19px" }}>
                <MDBox
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "8px",
                    background: colorType == "darkColor" ? color : selectedAccount && selectedAccount.darkColor,
                  }}
                />
                <MDTypography
                  style={{
                    color: "#09032B",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Dark
                </MDTypography>
              </MDBox>
              <MDBox
                onClick={() => {
                  setColorType("lightColor");
                  setColor(selectedAccount && selectedAccount.lightColor);    
                }} 
              style={{ marginLeft: "19px", cursor: "pointer" }}>
                <MDBox
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "8px",
                    background: colorType == "lightColor" ? color : selectedAccount && selectedAccount.lightColor,
                  }}
                />
                <MDTypography
                  style={{
                    color: "#09032B",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  Light
                </MDTypography>
              </MDBox>
            </MDBox>
            { colorType != "" && (
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <div
                            style={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                            onClick={ () => {
                                if (colorType === "accentColor") {
                                    updateAccount({ accentColor : color }, selectedAccount && selectedAccount.id);
                                } else if (colorType === "darkColor") {
                                    updateAccount({ darkColor : color }, selectedAccount && selectedAccount.id);
                                } else if (colorType === "lightColor") {
                                    updateAccount({ lightColor : color }, selectedAccount && selectedAccount.id);
                                } else {
                                    console.log("Invalid color type");
                                }
                                setColor("");
                                setColorType("");
                            }}
                        />
                        <ChromePicker color={ color } onChange={e => setColor(e.hex) } />
                    </div> )}
            <MDBox style={{display: "flex", marginTop: "90px"}}>
              <MDBox>
                <MDTypography
                  style={{
                    color: "#344767",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Business Theme
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "6px"
                  }}
                >
                  Select your theme
                </MDTypography>
              </MDBox>
              <MDBox style={{marginLeft: "auto"}}>
                <MDButton
                  onClick={handleClickOpen1}
                  variant="contained"
                  style={{
                    background:
                      "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                    color: "#FFFFFF",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                    textTransform: "capitalize"
                  }}
                >
                  Change Theme
                </MDButton>
              </MDBox>
            </MDBox>
            {themeS && themeS != "" && Object.keys(themeS).length > 0 && (
              <>
                <MDBox style={{ marginTop: "20px" }}>
                  <MDAvatar
                    variant="square"
                    src={themeS && themeS.htmlData ? themeS.htmlData : phone}
                    style={{
                      width: "288px",
                      height: "303px",
                      border: "1px solid #000",
                      borderRadius: "8px",
                    }}
                  />
                  <MDTypography
                    style={{
                      color: "#09032B",
                      fontSize: "16px",
                      fontWeight: "500",
                      marginTop: "12px",
                    }}
                  >
                    {themeS.name}
                  </MDTypography>
                  <MDBox style={{display: "flex", marginTop: "10px"}}>
                    {themeS.tags && Array.isArray(JSON.parse(themeS.tags)) && JSON.parse(themeS.tags).map(x => {
                      return (
                        <MDTypography
                            style={{
                                color: "#4CAF50",
                                fontSize: "12px",
                                fontWeight: "700",
                                background: "rgba(76, 175, 80, 0.2)",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                height: "25px",
                                marginRight: "4px"

                            }}
                        >
                          {x}
                        </MDTypography>
                      )
                    })}
                  </MDBox>
                </MDBox>
              </>
            )}
                <MDBox style={{display: "flex", marginTop: "48px"}}>
                  <MDBox>
                    <MDTypography
                      style={{
                        color: "#344767",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Image Collection
                    </MDTypography>
                    <MDTypography
                      style={{
                        color: "#848195",
                        fontSize: "14px",
                        fontWeight: "400",
                        marginTop: "6px"
                      }}
                    >
                      Choose the style of images you’d like included in your content.
                    </MDTypography>
                  </MDBox>
                  <MDBox style={{marginLeft: "auto"}}>
                    <MDButton
                      onClick={handleClickOpen}
                      variant="contained"
                      style={{
                        background:
                          "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                        color: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                        textTransform: "capitalize"
                      }}
                    >
                      Change Collection
                    </MDButton>
                  </MDBox>
                </MDBox>
            {collection && (
              <>
                <MDAvatar
                  variant="square"
                  src={collection && collection.cover_photo.urls.small}
                  style={{ width: "358px", height: "357px", marginTop: "36px" }}
                />
                <MDBox style={{ display: "flex", marginTop: "12px", maxWidth: "358px" }}>
                  <MDTypography
                    style={{
                      fontWeight: "600",
                      fontSize: "15px",
                      color: " #534F6B",
                    }}
                  >
                    {collection && collection.title}
                  </MDTypography>
                  <MDBox style={{marginLeft: "auto"}}>
                    <MDTypography
                      onClick={() => getCollectionById(collection.id)}
                      style={{fontWeight: "700", fontSize: "12px", color: "#09032B", cursor: "pointer"}}
                    >
                      View All
                      <ArrowForwardIcon style={{color: "#09032B", marginBottom: "-2px"}} />
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </>
            )}
          </Card>
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <Card
            style={{
              background: "#FFFFFF",
              padding: "24px 24px",
            }}
          >
            <MDBox style={{display: "flex"}}>
              <MDBox>
                <MDTypography
                  style={{
                    color: "#344767",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Watermarks and Logo Positioning
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginTop: "10px"
                  }}
                >
                  Choose how you’d like your logo to appear on your content.
                </MDTypography>
              </MDBox>
              <MDBox style={{marginLeft: "auto"}}>
                <MDBox display="flex" >
                  <Switch
                    checked={selectedAccount && selectedAccount.isWatermarkEnable}
                    onChange={() => updateAccount({ isWatermarkEnable: selectedAccount && selectedAccount.isWatermarkEnable == 1 ? 0 : 1 }, selectedAccount && selectedAccount.id)} />
                  <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#7B809A", marginTop: "7px"}}>
                    Enable Watermark
                  </MDTypography>
                </MDBox>
              </MDBox>
            </MDBox>
            <MDBox style={{ display: "flex", marginTop: "24px" }}>
              <img src={selectedAccount && selectedAccount.logo} style={{ height: "50px" }} />
                  <label
                      htmlFor="header-image"
                      variant="outlined"
                      style={{ cursor: "pointer", textTransform: "capitalize", border: "1px solid #344767", padding: "7px", borderRadius: "8px", color: "#344767",
                              fontSize: "12px", fontWeight: "700", margin: "10px 22px" }}
                  >
                      Upload your logo
                  </label>
                  <input
                      accept="image/*"
                      id="header-image"
                      type="file"
                      onChange={onImageChange}
                      style={{ display: "none" }}
                      />
            </MDBox>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={8}>
                <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Preview
                </MDTypography>
                <div style={{ width: "100%", height: "296px", backgroundImage: "url(" + watermarkImage + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
                  <img src={selectedAccount && selectedAccount.logo} style={Object.assign(setPos(position.position, position.scale), {opacity: position.opacity, width: position.scale })} />
                </div>
                
              </Grid>
              <Grid item xs={4}>
                <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Positioning
                </MDTypography>
                <MDBox style={iconDiv}>
      <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
        
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "leftTop" });
          updateAccount({position: JSON.stringify({ ...position, position: "leftTop" })}, selectedAccount && selectedAccount.id)
        }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(225deg)"
            }}
            style={position.position == "leftTop" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "centerTop" });
          updateAccount({position: JSON.stringify({ ...position, position: "centerTop" })}, selectedAccount && selectedAccount.id)
        }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(270deg)"
            }}
            style={position.position == "centerTop" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "rightTop" });
          updateAccount({position: JSON.stringify({ ...position, position: "rightTop" })}, selectedAccount && selectedAccount.id)
        }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(315deg)"
            }}
            style={position.position == "rightTop" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
      </MDBox>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2px",
        }}
      >
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "leftCenter" });
          updateAccount({position: JSON.stringify({ ...position, position: "leftCenter" })}, selectedAccount && selectedAccount.id)
        }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(180deg)"
            }}
            style={position.position == "leftCenter" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "center" });
          updateAccount({position: JSON.stringify({ ...position, position: "center" })}, selectedAccount && selectedAccount.id)
        }}>
          <RadioButtonUncheckedIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(270deg)"
            }}
            style={position.position == "center" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "rightcenter" });
          updateAccount({position: JSON.stringify({ ...position, position: "rightcenter" })}, selectedAccount && selectedAccount.id)
          }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px"
            }}
            style={position.position == "rightcenter" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
      </MDBox>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "2px",
        }}
      >
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "bottomLeft" });
          updateAccount({position: JSON.stringify({ ...position, position: "bottomLeft" })}, selectedAccount && selectedAccount.id);
          }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(135deg)"
            }}
            style={position.position == "bottomLeft" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
        <MDBox style={iconBox} onClick={() => {
          setPosition({ ...position, position: "bottomCenter" });
          updateAccount({position: JSON.stringify({ ...position, position: "bottomCenter" })}, selectedAccount && selectedAccount.id)
          }}>
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(90deg)"
            }}
            style={position.position == "bottomCenter" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
        <MDBox
          style={iconBox}
          onClick={() => {
            setPosition({...position, position: "bottomRight"});
            updateAccount({position: JSON.stringify({ ...position, position: "bottomRight" })}, selectedAccount && selectedAccount.id);
          }}
        >
          <StartIcon
            sx={{
              height: "8px",
              width: "8px",
              position: "absolute",
              marginLeft: "4px",
              marginTop: "4px",
              transform: "rotateZ(45deg)"
            }}
            style={position.position == "bottomRight" ? { color: "#000" } : { color: "#B5B3BF" }}
          />
        </MDBox>
      </MDBox>
    </MDBox>
                <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                  sx={{ mt: 2 }}
                >
                  Scale
                </MDTypography>
              <Slider
                  aria-label="Scale"
                  value={position.scale}
                  onChange={e => {
                    setPosition({ ...position, scale: e.target.value });
                    updateAccount({position: JSON.stringify({ ...position, scale: e.target.value })}, selectedAccount && selectedAccount.id)
                  }}
                  getAriaValueText={valuetext}
                  valueLabelDisplay="auto"
                  step={10}
                  marks
                  min={10}
                  max={100}
                  sx={{
                    color: 'rgba(0,0,0,0.87)',
                    height: 8,
                    '& .MuiSlider-track': {
                      backgroundColor: "#000",
                    },
                    '& .MuiSlider-thumb': {
                      width: 20,
                      height: 20,
                      background: "#000",
                      border: 0,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                      '&:before': {
                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                      },
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px rgb(0 0 0 / 16%)`,
                      },
                      '&.Mui-active': {
                        width: 20,
                        height: 20,
                      },
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.28,
                    },
                  }}
                />
              <MDTypography
                  style={{
                    color: "#848195",
                    fontSize: "14px",
                    fontWeight: "400",
                  }}
                >
                  Transparency
                </MDTypography>
              <Slider
                  aria-label="Transparency"
                  value={position.opacity}
                  onChange={e => {
                    setPosition({ ...position, opacity: e.target.value })
                    updateAccount({position: JSON.stringify({ ...position, opacity: e.target.value })}, selectedAccount && selectedAccount.id)
                  }}
                  getAriaValueText={valuetext}
                  valueLabelDisplay="auto"
                  step={.1}
                  marks
                  min={0}
                  max={1}
                  sx={{
                    color: 'rgba(0,0,0,0.87)',
                    height: 8,
                    '& .MuiSlider-track': {
                      backgroundColor: "#000",
                    },
                    '& .MuiSlider-thumb': {
                      width: 20,
                      height: 20,
                      background: "#000",
                      border: 0,
                      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                      '&:before': {
                        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                      },
                      '&:hover, &.Mui-focusVisible': {
                        boxShadow: `0px 0px 0px 8px rgb(0 0 0 / 16%)`,
                      },
                      '&.Mui-active': {
                        width: 20,
                        height: 20,
                      },
                    },
                    '& .MuiSlider-rail': {
                      opacity: 0.28,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Card>
          <Card
            style={{
              background: "#FFFFFF",
              padding: "24px 24px",
              marginTop: "24px"
            }}
          >
            <Grid container>
              <Grid item xs={12} md={8}>
                <MDTypography
                  style={{ color: "#09032B", fontSize: "16px", fontWeight: "700" }}
                >
                Text on Image
                <HelpOutlinedIcon style={{color: "#534F6B", marginBottom: "-3px"}} />
                </MDTypography>
                <MDBox>
                <FormField
                            label="yourwebsite.com.au"
                            name="yourwebsite.com.au"
                            defaultValue={selectedAccount && selectedAccount.textOnImage}
                            onChange={e => setTextOn(e.target.value)}
                            type="text"
                            placeholder="yourwebsite.com.au"
                        />
                        <EditOutlinedIcon
                            style={{
                                position: "relative",
                                zIndex: 2,
                                marginLeft: "auto",
                                display: "flex",
                                marginTop: "-26px",
                                color: "#7B879A",
                                cursor: "pointer"
                            }}
                            onClick={() => updateAccount({textOnImage: textOn}, selectedAccount && selectedAccount.id)}
                        />
                </MDBox>
                <MDTypography style={{fontWeight: "700", fontSize: "16px", color: "#09032B", marginTop: "30px"}}>Selected Font</MDTypography>
                <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#848195"}}>Select your preferred fonts.</MDTypography>
                </Grid>
                <Grid item xs={12} style={{marginTop: "18px"}}>
                  <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#7B809A"}}>Heading Font</MDTypography>
                  <Autocomplete
                        options={fontList}
                        defaultValue={selectedAccount && selectedAccount.headingFont}
                        onChange={(e, x) => updateAccount({headingFont: x.id}, selectedAccount && selectedAccount.id)}
                        renderInput={(params) => (
                        <MDInput
                            {...params}
                            variant="standard"
                        />
                        )}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: "18px"}}>
                  <MDTypography style={{fontWeight: "400", fontSize: "14px", color: "#7B809A"}}>Paragraph Font</MDTypography>
                  <Autocomplete
                        options={fontList}
                        defaultValue={selectedAccount && selectedAccount.paragraphFont}
                        onChange={(e, x) => updateAccount({paragraphFont: x.id}, selectedAccount && selectedAccount.id)}
                        renderInput={(params) => (
                        <MDInput
                            {...params}
                            variant="standard"
                        />
                        )}
                    />
                </Grid>
              </Grid> 
            </Card>
        </Grid>
      </Grid>
      <Dialog
            fullScreen={fullScreen}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item xs={10}>
                <DialogTitle id="responsive-dialog-title">
                    See and save our vast image library
                </DialogTitle>
                <MDTypography
                    style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "18px",
                    marginTop: "-16px",
                    }}
                >
                    Choose the style of images you’d like included in your content.
                </MDTypography>
                </Grid>
                <Grid item xs={2}>
                <MDBox
                    onClick={handleClose}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "5px" }}>
                <Grid item xs={5}>
                    <FormField
                        label="Search Image"
                        placeholder="search Image"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <MDButton
                        style={{
                            background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                            color: "#fff"
                        }}
                        variant="contained"
                        onClick={() => unsplashSearch(search)}
                    >
                        Search
                    </MDButton>
                </Grid>
                <Grid item xs={4}>
                    <MDButton
                        style={{
                            background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                            color: "#fff"
                        }}
                        variant="contained"
                        onClick={() => getSBCCCollection()}
                    >
                        Social broker collection
                    </MDButton>
                </Grid>
                {collections && !collections.errors && collections.response && collections.response.results.map(x => {
                return (
                    <Grid
                        item
                        xs={12}
                        md={4}
                      >
                    <img src={ x.cover_photo.urls.small } style={{ width: "100%", height: "300px", objectFit:"cover" }} width="100%"  onClick={() => {
                            setCollection(x);
                        }}/>
                    {collection != null && collection.id == x.id && <img style={{ position: "relative", left: "calc(50% - 32px)", top: "-200px" }} src={choooseImage} />}
                    <Grid item>
                        <MDBox style={collection != null && collection.id == x.id ? { display: "flex", marginTop: "-64px" } : { display: "flex" }}>
                        <MDTypography
                            style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            color: " #534F6B",
                            }}
                        >
                            {x.title}
                        </MDTypography>
                        <MDBox
                          sx={{ ml: "auto", display: 'flex', cursor: "pointer" }}
                          onClick={e => {
                            e.preventDefault();
                            getCollectionById(x.id);
                          }}
                        >
                            <Typography sx={{ fontSize: '12px', fontWeight: 700 }}>View All</Typography>
                            <ArrowForwardIcon style={{ color: "#7B809A" }} />
                        </MDBox>
                        </MDBox>
                    </Grid>
                    </Grid>
                )
                })}
            </Grid>
            
            <MDBox style={{ marginTop: "15px" }}>
                <MDButton
                    onClick={() => {
                        updateAccount({ collection : JSON.stringify(collection) }, selectedAccount && selectedAccount.id);
                        handleClose();
                    }}
                    variant="contained"
                    style={{
                        background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                        color: "#fff",
                        minWidth: "295px",
                    }}
                >
                    Save and continue
                </MDButton>
            </MDBox>
            </MDBox>
        </Dialog>
        <Dialog
            fullScreen={fullScreen}
            open={open1}
            onClose={handleClose1}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item sm={6} md={6} lg={6}>
                <DialogTitle id="responsive-dialog-title">
                    {"Select Your Theme"}
                </DialogTitle>
                <MDTypography
                    style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "18px",
                    marginTop: "-16px",
                    }}
                >
                    Here you can setup and manage your integration settings.
                </MDTypography>
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                <MDBox
                    onClick={handleClose1}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "15px" }}>
                {themes && Array.isArray(themes) && themes.filter(y => y.premium == 0).map(x => {
                    return (
                        <Grid item xs={12} md={4}>
                            <MDBox style={{ cursor: "pointer" }} onClick={() => setSelectedTheme(x.id)}>
                                {selectedTheme != null && selectedTheme == x.id && <img style={{ position: "relative", top: "32px", left: "calc(50% - 32px)", zIndex: 1 }} src={choooseImage} />}
                                <img
                                    width="100%"
                                    src={x.htmlData ? x.htmlData : phone}
                                    style={
                                        selectedTheme != null && selectedTheme == x.id ? {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                        marginTop: "-73px"
                                    } : {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                    }}
                                />
                            </MDBox>
                            <MDTypography
                                style={{
                                    color: "#09032B",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                }}
                            >
                                {x.name}
                            </MDTypography>
                        </Grid>
                    )
                })}
            </Grid>
            {/* {selectedAccount.subscription && selectedAccount.subscription.themeAcess != 1 && ( */}
            <div style={{ position: "relative", left: "calc(50% - 147px)", top: "100px", zIndex: 100 }}>
                <UpgradeProPage
                    onButtonClick={() => onSubscribeButtonClick()}
                />
            </div>
                 {/* )} */}
            <Grid container spacing={3} style={{ position: "relative", top: "-100px" }} sx={selectedAccount.subscription && selectedAccount.subscription.themeAcess === 1 ? { opacity: 1 } : { opacity: .3 }}>
                {themes && Array.isArray(themes) && themes.filter(y => y.premium == 1).map(x => {
                    return (
                        <Grid item xs={12} md={4}>
                            <MDBox
                                style={selectedAccount.subscription && selectedAccount.subscription.themeAcess === 1 ? { cursor: "pointer" } : {}}
                                onClick={() => {
                                    if(selectedAccount.subscription && selectedAccount.subscription.themeAcess === 1) {
                                        setSelectedTheme(x.id)
                                    }
                                }}
                            >
                                {selectedTheme != null && selectedTheme == x.id && <img style={{ position: "relative", top: "32px", left: "calc(50% - 32px)", zIndex: 1 }} src={choooseImage} />}
                                <img
                                    width="100%"
                                    src={x.htmlData ? x.htmlData : phone}
                                    style={
                                        selectedTheme != null && selectedTheme == x.id ? {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                        marginTop: "-73px"
                                    } : {
                                        border: "1px solid #000",
                                        borderRadius: "8px",
                                    }}
                                />
                            </MDBox>
                            <MDTypography
                                style={{
                                    color: "#09032B",
                                    fontSize: "16px",
                                    fontWeight: "500",
                                    marginTop: "5px",
                                }}
                            >
                                {x.name}
                            </MDTypography>
                        </Grid>
                    )
                })}
            </Grid>
            <MDBox style={{ marginTop: "15px" }}>
                <MDButton
                variant="contained"
                style={{
                    background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                    color: "#fff",
                    minWidth: "295px",
                }}
                onClick={() => {
                    updateAccount({ ThemeId: selectedTheme }, selectedAccount && selectedAccount.id);
                    handleClose();
                }}
                >
                Save and continue
                </MDButton>
            </MDBox>
            </MDBox>
        </Dialog>
    </DashboardLayout>
    </>
  );
}
