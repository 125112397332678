import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@material-ui/styles';
import Toolbar from '@mui/material/Toolbar';
import { Link } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Typography from '@mui/material/Typography';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import BarChartIcon from '@mui/icons-material/BarChart';
import Collapse from '@mui/material/Collapse';
import SettingsIcon from '@mui/icons-material/Settings';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import MDAvatar from "./../../components/MDAvatar";
import brand from "./../../../assets/images/logo-ct-dark.png";
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';
import InterestsIcon from '@mui/icons-material/Interests';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PaymentsIcon from '@mui/icons-material/Payments';

const drawerWidth = 250;

const useStyles = makeStyles({
  linkuu: {
    textDecoration: 'none',
    color: '#6a6a6a',
    '& li:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  linkText: {
    padding: '16px 7px 16px 30px',
    color: '#6a6a6a',
  },
  iconColorWhite: {
    color: '#6a6a6a',
  }, 
  listText: {
   '& .MuiTypography-root': {
    fontSize: '14px',
    color: '#6a6a6a'
   },
  },
  listText1: {
    '& .MuiTypography-root': {
     fontSize: '16px',
     color: '#6a6a6a',
     fontWeight: '500'
    },
   },
});

function ResponsiveDrawer(props) {
    const classes = useStyles();
    const [openS, setOpenS] = React.useState(false);
    const [openB, setOpenB] = React.useState(false);
    const { user, selectedAccount, selectAccount } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isreload, setIsreload] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
      if(selectedAccount && isreload) {
        window.location.reload();
      }
    }, [selectedAccount]);

  const drawer = (
    <div>
      <Box component={Link} to="/" display="flex" alignItems="center" style={{marginTop: '40px'}}>
        {brand && <Box component="img" src={brand} alt="Brand" width="1rem" style={{marginLeft: '16px'}} />}
        <Box
          style={{ marginLeft: "12px" }}
        >
          <Typography variant="button" style={{color: '#5a5a5a', fontWeight: '700', fontSize: '14px', lineHeight: '140%', textTransform: 'capitalize'}}>
            Social Broker
          </Typography>
        </Box>
      </Box>
      <ListItem button onClick={() => setOpenB(!openB)} style={{padding: '16px', color: '#5a5a5a', margin: '17px 0px 17px 0px'}}>
        <ListItemIcon><MDAvatar src={selectedAccount.logo} alt={selectedAccount.name} size="sm" /></ListItemIcon>
        <ListItemText primary={selectedAccount.name} className={classes.listText1} />
        {user && Array.isArray(user.accounts) && user.accounts.length > 1 ? (openB ? <ExpandLess /> : <ExpandMore />) : ""}
      </ListItem>
      {user && Array.isArray(user.accounts) && user.accounts.length > 1 && (
        <Collapse in={openB} timeout="auto" unmountOnExit>
          <List disablePadding style={{ background: '#3E3D43', borderRadius: '8px' }}>
            {user && Array.isArray(user.accounts) && user.accounts.map(x => {
              return (
                <ListItem onClick={() => { setIsreload(true); selectAccount(x) }} style={{padding: '16px 7px 16px 30px', cursor: "pointer"}}>
                  <ListItemIcon>
                  <MDAvatar src={x.logo} alt={x.name} size="sm" />
                  </ListItemIcon>
                  <ListItemText primary={x.name} className={classes.listText} />
                </ListItem>
              )
            })}
          </List>
        </Collapse>
      )}
      <List >
        <Link to={process.env.PUBLIC_URL + "/dashboard"} key='dashboard' className={classes.linkuu}>
            <ListItem button className={classes.linkText}>
                <ListItemIcon><DashboardIcon className={classes.iconColorWhite}/></ListItemIcon>
                <ListItemText primary='Dashboard' className={classes.listText} />
            </ListItem>
        </Link>
        <Typography style={{
          fontWeight: '700', 
          fontSize: '12px', 
          textTransform: 'uppercase', color: '#5a5a5a', padding: '0px 16px 0px 16px', margin: '12px 0px 11px 0px'}}>
            Social Manager
        </Typography>
        <Link to={process.env.PUBLIC_URL + "/social-profiles"} key='social-profiles' className={classes.linkuu}>
            <ListItem button className={classes.linkText}>
                <ListItemIcon><InterestsIcon className={classes.iconColorWhite}/></ListItemIcon>
                <ListItemText primary='Social Profiles' className={classes.listText} />
            </ListItem>
        </Link>
        <Link to={process.env.PUBLIC_URL + "/schedule"} key='schedule' className={classes.linkuu}>
            <ListItem button className={classes.linkText}>
                <ListItemIcon><CalendarMonthIcon className={classes.iconColorWhite}/></ListItemIcon>
                <ListItemText primary='Schedule' className={classes.listText} />
            </ListItem>
        </Link>
        <Link to={process.env.PUBLIC_URL + "/content-library"} key='content-library' className={classes.linkuu}>
            <ListItem button className={classes.linkText}>
                <ListItemIcon><PhotoLibraryIcon className={classes.iconColorWhite}/></ListItemIcon>
                <ListItemText primary='Content Library' className={classes.listText} />
            </ListItem>
        </Link>
        <Link to={process.env.PUBLIC_URL + "/insight-page"} key='insights' className={classes.linkuu}>
            <ListItem button className={classes.linkText}>
                <ListItemIcon><BarChartIcon className={classes.iconColorWhite}/></ListItemIcon>
                <ListItemText primary='Insights' className={classes.listText} />
            </ListItem>
        </Link>
      </List>
      <Divider />
        <List>
          <Typography style={{
            fontWeight: '700', 
            fontSize: '12px', 
            textTransform: 'uppercase', color: '#5a5a5a', padding: '0px 16px 0px 16px', margin: '12px 0px 11px 0px',}}>
              Content Generator
          </Typography>
            <Link to={process.env.PUBLIC_URL + "/preferences"} key='business-details' className={classes.linkuu}>
                  <ListItem style={{padding: '16px 7px 16px 30px'}}>
                    <ListItemIcon>
                      <SettingsSuggestIcon className={classes.iconColorWhite}/>
                    </ListItemIcon>
                    <ListItemText primary="Preferences" className={classes.listText} />
                  </ListItem>
                </Link>
                <Link to={process.env.PUBLIC_URL + "/design"} key='branding' className={classes.linkuu}>
                  <ListItem style={{padding: '16px 7px 16px 30px'}}>
                    <ListItemIcon>
                      <DesignServicesIcon className={classes.iconColorWhite}/>
                    </ListItemIcon>
                    <ListItemText primary="Design" className={classes.listText} />
                  </ListItem>
                </Link>
        </List>
      <Divider />
        <List>
          <Typography style={{
            fontWeight: '700', 
            fontSize: '12px', 
            textTransform: 'uppercase', color: '#5a5a5a', padding: '0px 16px 0px 16px', margin: '12px 0px 11px 0px',}}>
              Settings
          </Typography>
            <Link to={process.env.PUBLIC_URL + "/team"} key='team' className={classes.linkuu}>
              <ListItem style={{padding: '16px 7px 16px 30px'}}>
                <ListItemIcon>
                  <GroupAddIcon className={classes.iconColorWhite}/>
                </ListItemIcon>
                <ListItemText primary="Team" className={classes.listText} />
              </ListItem>
            </Link>
            <Link to={process.env.PUBLIC_URL + "/billing-plan"} key='billing-plan' className={classes.linkuu}>
                <ListItem button className={classes.linkText}>
                    <ListItemIcon><PaymentsIcon className={classes.iconColorWhite}/></ListItemIcon>
                    <ListItemText primary='Billing & Plan' className={classes.listText} />
                </ListItem>
            </Link>
        </List>



        {/* {}
        <ListItem button className={classes.linkText} onClick={() => setOpenS(!openS)}>
              <ListItemIcon><SettingsIcon className={classes.iconColorWhite}/></ListItemIcon>
              <ListItemText primary='Content Generator' className={classes.listText} />
              {openS ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openS} timeout="auto" unmountOnExit>
              <List disablePadding style={{ background: '#3E3D43', borderRadius: '8px' }}>
                <Link to={process.env.PUBLIC_URL + "/preferences"} key='business-details' className={classes.linkuu}>
                  <ListItem style={{padding: '16px 7px 16px 30px'}}>
                    <ListItemIcon>
                      <InfoIcon className={classes.iconColorWhite}/>
                    </ListItemIcon>
                    <ListItemText primary="Preferences" className={classes.listText} />
                  </ListItem>
                </Link>
                <Link to={process.env.PUBLIC_URL + "/design"} key='branding' className={classes.linkuu}>
                  <ListItem style={{padding: '16px 7px 16px 30px'}}>
                    <ListItemIcon>
                      <ColorLensOutlinedIcon className={classes.iconColorWhite}/>
                    </ListItemIcon>
                    <ListItemText primary="Design" className={classes.listText} />
                  </ListItem>
                </Link>
              </List>
            </Collapse> */}
    </div>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, background: '#fff',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
            borderRadius: '12px' },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default ResponsiveDrawer;