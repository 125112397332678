import SaveBusinessPage from './saveB.component';
import { connect } from 'react-redux';
import { createAccount } from './../../../api/account/accountAction';
import { getTopics } from './../../../api/topic/topicAction';
import { getIndustries } from './../../../api/industry/industryAction';
import { fetchCurrentUser } from './../../../api/user/userAction';

const mapDispatchToProps = {
    createAccount,
    getTopics,
    getIndustries,
    fetchCurrentUser
}

const mapStateToProps = state => ({
    isLoading: state.accountPage.isLoading,
    isSaved: state.accountPage.isSaved,
    topics: state.topicPage.topics,
    industries: state.industryPage.industries,
    user: state.userPage.loggedUser
});

export const SaveBusinessPageContainer = connect(mapStateToProps, mapDispatchToProps)(SaveBusinessPage);