import * as actionTypes from "./accountActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const createAccount = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
      return (dispatch) => {
        dispatch(createAccountInit());
        axios.post(`${baseUrl}/account/store`, params, config)
        .then((response)=>{
            dispatch(createAccountSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createAccountFailure());
        })
    };
};


export const createAccountInit = () => ({
    type: actionTypes.USER_ACCOUNT
});

export const createAccountFailure = data => ({
    type: actionTypes.USER_ACCOUNT_FAILURE
});

export const createAccountSuccess = data => ({
    type: actionTypes.USER_ACCOUNT_SUCCESS,
    payload: { data }
});

export const updateAccount = (data, id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
    Object.keys(data).forEach(key => {
        const value = data[key];
        params.append(key, value);
    });
      return (dispatch) => {
        dispatch(updateAccountInit());
        axios.put(`${baseUrl}/account/${id}`, params, config)
        .then((response)=>{
            dispatch(updateAccountSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(updateAccountFailure());
        })
    };
};


export const updateAccountInit = () => ({
    type: actionTypes.UPDATE_USER_ACCOUNT
});

export const updateAccountFailure = data => ({
    type: actionTypes.UPDATE_USER_ACCOUNT_FAILURE
});

export const updateAccountSuccess = data => ({
    type: actionTypes.UPDATE_USER_ACCOUNT_SUCCESS,
    payload: { data }
});

export const fetchAccounts = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(fetchAccountsInit());
        axios.get(`${baseUrl}/account/all-accounts`, config)
        .then((response)=>{
            dispatch(fetchAccountsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchAccountsFailure());
        })
    };
};


export const fetchAccountsInit = () => ({
    type: actionTypes.FETCH_ACCOUNTS
});

export const fetchAccountsFailure = data => ({
    type: actionTypes.FETCH_ACCOUNTS_FAILURE
});

export const fetchAccountsSuccess = data => ({
    type: actionTypes.FETCH_ACCOUNTS_SUCCESS,
    payload: { data }
});