import ThemePage from './theme.component';
import { connect } from 'react-redux';
import { updateAccount } from './../../api/account/accountAction';
import { fetchCurrentUser } from './../../api/user/userAction';
import { fetchThemes } from './../../api/theme/themeAction';
import { regenerateContent } from './../../api/content/contentAction';

const mapDispatchToProps = {
    updateAccount,
    fetchCurrentUser,
    fetchThemes,
    regenerateContent
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading || state.accountPage.isLoading,
    isError: state.userPage.isError,
    isSaved: state.accountPage.isSaved,
    user: state.userPage.loggedUser,
    themes: state.themePage.themes,
    selectedAccount: state.userPage.selectedAccount,
    isRegenerated: state.contentPage.isRegenerated
});

export const ThemePageContainer = connect(mapStateToProps, mapDispatchToProps)(ThemePage);