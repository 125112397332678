import React, { useEffect } from "react";
import Loading from './../../shared/Loading';
import { useParams } from "react-router-dom";


export default function AuthPage(props) {
  const { isLoggedIn, fetchCurrentUserr, user } = props;
  
  const { token } = useParams();
  useEffect(() => {
    console.log("Token: ", token);
    sessionStorage.setItem('accessToken', token);
    fetchCurrentUserr(token);
  }, []);

  if (isLoggedIn) {
    console.log("Logged In");
    if (user.emailVerified === 0) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/email-verify`;
    } else if (user.accounts && Array.isArray(user.accounts) && user.accounts.length > 0) {
      sessionStorage.setItem("selectedAccount", JSON.stringify(user.accounts[0]));
      window.location.href = `${process.env.REACT_APP_BASE_URL}`;
    } else {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/create-business`;
    }
  }

  return (
    <React.Fragment>
      <Loading loading={true} />
    </React.Fragment>
  );
}
