import React from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
// MUI
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
// import MDTypography from "./../../shared/components/MDTypography";
import breakpoints from "./../../assets/theme/base/breakpoints";
import MDButton from "./../../shared/components/MDButton";
import DataTable from "./../../shared/components/DataTable";
import dataTableData from "./../../shared/components/DataTable/data/dataTableData";
// Icons
import WindowIcon from "@mui/icons-material/Window";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

const button = {
  textTransform: "capitalize",
  background: "#FFFFFF",
  border: "1px solid #CECDD5",
  color: "#7B809A",
  borderRadius: "8px",
};

export default function ContentTablePage() {
  // toggleButton
  const [alignment, setAlignment] = React.useState("right");

  const handleAlignment = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };
  // toggleButton

  // menu
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const [menu1, setMenu1] = React.useState(null);

  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>
        All Topics <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu}>
        Social Posts <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        RSS Feed <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Blog posts <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem onClick={closeMenu1}>
        Select All <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu1}>
        Scheduled <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Published <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Failed <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Draft <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  // menu
  // app bar
  const [tabsOrientation, setTabsOrientation] = React.useState("horizontal");
  const [tabValue, setTabValue] = React.useState(0);

  React.useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  // app bar
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid style={{ display: "flex", marginTop: "27px" }}>
        <Grid item>
          <MDButton
            style={button}
            variant={menu ? "contained" : "outlined"}
            onClick={openMenu}
          >
            All Posts&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
        </Grid>
        <Grid style={{ marginLeft: "auto" }}>
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            All Topics&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={button}
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            onClick={openMenu1}
          >
            Status&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <ToggleButtonGroup
            sx={{
              marginLeft: "16px",
              background: "#E6E6EA",
              borderRadius: "12px",
              padding: "6px 8px",
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root.Mui-selected":
                {
                  color: "#fff !important",
                  backgroundColor: "#09032B !important",
                  
                },
              "& .css-1c48ueu-MuiButtonBase-root-MuiToggleButton-root": {
                border: "none",
              },
            }}
            value={alignment}
            exclusive
            onChange={handleAlignment}
            aria-label="text alignment"
          >
            <ToggleButton
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
                marginRight: "8px",
              }} value="left" aria-label="left aligned">
              <WindowIcon />
            </ToggleButton>
            <ToggleButton
              style={{
                borderRadius: "8px",
                width: "30px",
                height: "30px",
              }} value="right" aria-label="right aligned">
              <FormatListBulletedIcon />
            </ToggleButton>
          </ToggleButtonGroup>
          <MDButton
            sx={{
              background: "linear-gradient(#09032B, #312B56)",
              color: "#fff",
              marginLeft: "16px",
            }}
          >
            CREATE CONTENT
          </MDButton>
        </Grid>
      </Grid>
      <Card style={{ marginTop: "39px" }}>
        <DataTable
          style={{ height: "858px" }}
          table={dataTableData}
          entriesPerPage={false}
          canSearch
        />
      </Card>
    </DashboardLayout>
  );
}
