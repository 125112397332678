import React from "react";
import { Routes, Route } from "react-router-dom";
import { ProtectedRoute } from "./shared/protected.route";
import { LoginPageContainer } from "./views/login/login.container";
import { SignupPageContainer } from "./views/signup/signup.container";
import { SignupIPageContainer } from "./views/signup-i/signup-i.container";
import { EmailVerifyContainer } from "./views/email-verify/email-verify.container";
import { CreateBusinessPageContainer } from "./views/business/createBusiness/createB.container";
import { SaveBusinessPageContainer } from "./views/business/saveBusiness/saveB.container";
import { AddSocialPageContainer } from "./views/socialMediaAssign/addSocial/addSocial.container";
import { ActiveSocialPageContainer } from "./views/socialMediaAssign/activeSocial/activeSocial.container";
import { AllContentPageContainer } from "./views/allContent/allcontent.container";
import { ContentTablePageContainer } from "./views/allContentTable/contentTable.container";
import { SocialOverviewPageContainer } from "./views/socialoverview/socialOverview.container";
import { ConnectedMediaPageContainer } from "./views/connectedMedia/connMedia.container";
import { ConnectMediaPageContainer } from "./views/connectMediaPage2/connectMedia.container";
import { PostPannelPageContainer } from "./views/postPannel/postPannel.container";
// import { SocialPageContainer } from "./views/socialV2/social.container";
import { SubscriptionPageContainer } from './views/subscription/subscription-billing.container';
import { TeamPageContainer } from './views/teamMates/team-mates.container';
import { SelectThemePageContainer } from './views/selectTheme/select-theme.container';
import { InsightPageContainer } from './views/insightPage/insight.container';
import { DraftsPageContainer } from './views/drafts/drafts.container';
import { WatermarkPageContainer } from './views/watermark/watermark.container';
import { ImgLibraryPageContainer } from './views/imgLibrary/imgLibrary.container';
import { ThemePageContainer } from './views/theme/theme.container';
import { BusinessAccountPageContainer } from './views/businessAccount/ba.container';
import { UserDetailsPageContainer } from './views/userDetails/user-details.container';
import { SettingPageContainer } from './views/setting/setting.container';
import { BusinessOverviewPageContainer } from "./views/businessOverview/business-overview.container";
import { ForgetPasswordContainer } from './views/forget-password/forget-password.container';
import { ResetPasswordContainer } from './views/reset-password/reset-password.container';
import { LayoutFrameContainer } from './views/allContent/layoutFrame';
import { SchedulePageContainer } from './views/schedule/schedule.container';
import { ThankYouContainer } from './views/thank-you/thank-you.container';
import { LinkedinSuccessPageContainer } from './views/linkedin-success/linkedin-success.container';
import { GoogleSuccessPageContainer } from './views/google-success/google-success.container';
import { AuthPageContainer } from "./views/auth/auth.container";

export const RoutePath = () => (
  <Routes>
    <Route path="/login" element={<LoginPageContainer />} />
    <Route path='/auth-user/:token' element={<AuthPageContainer />} />
    <Route path="/layout-frame" element={<LayoutFrameContainer />} />
    <Route path="/signup" element={<SignupPageContainer />} />
    <Route path="/invitation/:token" element={<SignupIPageContainer />} />
    <Route path="/forget-password" element={<ForgetPasswordContainer />} />
    <Route path="/reset-password/:token" element={<ResetPasswordContainer />} />
    <Route
      path="/thank-you"
      element={
        <ProtectedRoute><ThankYouContainer /></ProtectedRoute>
      }
    />
    <Route
      path="/linkedin-success"
      element={
        <ProtectedRoute><LinkedinSuccessPageContainer /></ProtectedRoute>
      }
    />
    <Route
      path="/google-success"
      element={
        <ProtectedRoute><GoogleSuccessPageContainer /></ProtectedRoute>
      }
    />
    <Route
      path="/email-verify"
      element={
        <ProtectedRoute>
          <EmailVerifyContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path="/email-confirm/:token"
      element={
        <EmailVerifyContainer />
      }
    />
    <Route
      path="/create-business"
      element={
        <ProtectedRoute>
          <CreateBusinessPageContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path="/save-business"
      element={
        <ProtectedRoute>
          <SaveBusinessPageContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path="/add-social"
      element={
        <ProtectedRoute>
          <AddSocialPageContainer />
        </ProtectedRoute>
      }
    />
    <Route
      path="/active-social"
      element={
        <ProtectedRoute>
          <ActiveSocialPageContainer />
        </ProtectedRoute>
      }
    />
    <Route path="/" element={<ProtectedRoute><SocialOverviewPageContainer /></ProtectedRoute>} />
    <Route path="/dashboard" element={<ProtectedRoute><SocialOverviewPageContainer /></ProtectedRoute>} />
    <Route path="/connected-media" element={<ProtectedRoute><ConnectedMediaPageContainer /></ProtectedRoute>} />
    <Route path="/social-profiles" element={<ProtectedRoute><ConnectMediaPageContainer /></ProtectedRoute>} />
    <Route path="/post-pannel" element={<ProtectedRoute><PostPannelPageContainer /></ProtectedRoute>} />
    <Route path="/content-library" element={<ProtectedRoute><AllContentPageContainer /></ProtectedRoute>} />
    <Route path="/schedule" element={<ProtectedRoute><SchedulePageContainer /></ProtectedRoute>} />
    <Route path="/content-table" element={<ProtectedRoute><ContentTablePageContainer /></ProtectedRoute>} />
    <Route path="/billing-plan" element={<ProtectedRoute><SubscriptionPageContainer /></ProtectedRoute>} />
    <Route path="/team" element={<ProtectedRoute><TeamPageContainer /></ProtectedRoute>} />
    <Route path="/insight-page" element={<ProtectedRoute><InsightPageContainer /></ProtectedRoute>} />
    <Route path="/drafts" element={<ProtectedRoute><DraftsPageContainer /></ProtectedRoute>} />
    <Route path="/change-watermark" element={<ProtectedRoute><WatermarkPageContainer /></ProtectedRoute>} />
    <Route path="/image-library" element={<ProtectedRoute><ImgLibraryPageContainer /></ProtectedRoute>} />
    <Route path="/design" element={<ProtectedRoute><ThemePageContainer /></ProtectedRoute>} />
    <Route path="/select-theme" element={<ProtectedRoute><SelectThemePageContainer /></ProtectedRoute>} />
    <Route path="/business-account" element={<ProtectedRoute><BusinessAccountPageContainer /></ProtectedRoute>} />
    <Route path="/user-detail" element={<ProtectedRoute><UserDetailsPageContainer /></ProtectedRoute>} />
    <Route path="/setting" element={<ProtectedRoute><SettingPageContainer /></ProtectedRoute>} />
    <Route path="/preferences" element={<ProtectedRoute><BusinessOverviewPageContainer /></ProtectedRoute>} />
  </Routes>
);
