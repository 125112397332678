import * as actionTypes from "./tagActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const getTags = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
    const params = new FormData();
      return (dispatch) => {
        dispatch(getTagsInit());
        axios.get(`${baseUrl}/tag/options`, config)
        .then((response)=>{
            dispatch(getTagsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getTagsFailure());
        })
    };
};


export const getTagsInit = () => ({
    type: actionTypes.GET_TAGS
});

export const getTagsFailure = data => ({
    type: actionTypes.GET_TAGS_FAILURE
});

export const getTagsSuccess = data => ({
    type: actionTypes.GET_TAGS_SUCCESS,
    payload: { data }
});


export const addTags = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(addTagsInit());
        axios.post(`${baseUrl}/tag/store`, data, config)
        .then((response)=>{
            dispatch(addTagsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(addTagsFailure());
        })
    };
};


export const addTagsInit = () => ({
    type: actionTypes.ADD_TAG
});

export const addTagsFailure = data => ({
    type: actionTypes.ADD_TAG_FAILURE
});

export const addTagsSuccess = data => ({
    type: actionTypes.ADD_TAG_SUCCESS,
    payload: { data }
});



