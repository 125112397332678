import AuthPage from './auth.component';
import { connect } from 'react-redux';
import { fetchCurrentUserr } from './../../api/user/userAction';

const mapDispatchToProps = {
    fetchCurrentUserr
}

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser,
    isLoading: state.userPage.isLoading,
});

export const AuthPageContainer = connect(mapStateToProps, mapDispatchToProps)(AuthPage);