// import { useState } from "react";
import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import logo from "./../../assets/images/logo-ct-dark.png";
import MDBox from "./../../shared/components/MDBox";
import bell from "./../../assets/images/bell.png";
import MDButton from "./../../shared/components/MDButton";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const button = {
  background: "#09032B",
  color: "#FFFFFF",
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
  borderRadius: "8px",
  width: "140px",
  textTransform: "capitalize"
}

export default function EmailVerifyPage(props) {
  const navigate = useNavigate();
  const { token } = useParams();

  const { sendVerifyEmail, user, isLoggedIn, resendVerifyEmail } = props;

  useEffect(() => {
    if (token) {
      sessionStorage.setItem("accessToken", token);
      sendVerifyEmail(token);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && user.emailVerified == 1) {
      navigate("/create-business");
    }
  }, [isLoggedIn]);

  return (
    <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
      <Grid
        item
        style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
      >
        <MDAvatar
          src={logo}
          variant="square"
          style={{ width: "13px", height: "22px", marginTop: "5px" }}
        />
        &nbsp;&nbsp;
        <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
      </Grid>
      <Grid item style={{ display: "flex", justifyContent: "center" }}>
        <MDBox
          style={{
            backgroundColor: "white",
            height: "362px",
            padding: "0px 25px",
            borderRadius: "12px",
            margin: "9% auto",
            justifyContent: "center",
            display: "grid",
          }}
          sx={{
            "@media (min-width: 320px)": {
              width: "320px !important",
            },
            "@media (min-width: 432px)": {
              width: "483px !important",
            },
          }}
        >
          <MDBox style={{display: "flex", justifyContent: "center"}} >
            <img src={bell} alt="Lock logo" />
          </MDBox>
          <MDBox style={{ textAlign: "center" }}>
            <MDTypography
              style={{
                color: "#2A3342",
                fontSize: "25px",
                fontWeight: "700",
              }}
            >
              We’ve sent you an email
            </MDTypography>
            <MDButton
              onClick={() => {
                sendVerifyEmail();
              }}
              style={{
                color: "#556987",
                fontSize: "16px",
                fontWeight: "400",
                textTransform: "inherit"
              }}
            >
              Click the link in the email to verify your account
            </MDButton>
          </MDBox>
          <MDBox style={{textAlign: "center"}}>
            <MDButton style={button} fullWidth onClick={() => resendVerifyEmail(user.token)}>
              Resend
            </MDButton>
          </MDBox> 
        </MDBox>
      </Grid>
      <ToastContainer style={{ fontSize: "14px", width: "auto" }} />
    </Grid>
  );
}
