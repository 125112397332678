import * as actionTypes from "./insightActionType";
import axios from 'axios';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;
export const getInsights = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(getInsightsInit());
        axios.get(`${baseUrl}/insight/account/${id}`, config)
        .then((response)=>{
            dispatch(getInsightsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getInsightsFailure());
        })
    };
};


export const getInsightsInit = () => ({
    type: actionTypes.GET_INSIGHTS
});

export const getInsightsFailure = data => ({
    type: actionTypes.GET_INSIGHTS_FAILURE
});

export const getInsightsSuccess = data => ({
    type: actionTypes.GET_INSIGHTS_SUCCESS,
    payload: { data }
});