import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import masterCardLogo from "../../assets/images/logos/mastercard.png";
import MDButton from "../../shared/components/MDButton";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import DefaultPricingCard from "../../shared/components//DefaultPricingCard";
import { useMaterialUIController } from "./../../context";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SubscriptionDialog from "./component/subscription-model-dialog";
import {loadStripe} from '@stripe/stripe-js';
const PUBLIC_KEY = "pk_live_isuxX03XHraBiEGcmwToqM2M00DBBirdo3";
// const PUBLIC_KEY = "pk_test_51LUO7bSAnwstcjS1qsq9GEpdgz4USdP856pahnODCrmQrXF6Hj2x6LOMCvDiTfK5REpoiWZINHkPKKSUqnJ3z7Xr00Q8eWiXCm";


const standerdplan = {
  backgroundColor: "#4CAE67",
  borderRadius: "12.5px",
  color: "#FFFFFF",
  width: "122px",
  height: "25px",
  paddingTop: "3px",
  fontSize: "12px",
  marginTop: "10px",
  textAlign: "center",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`fullwidth-tabpanel-${index}`}
      aria-labelledby={`fullwidth-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export function SubscriptionPage(props) {
  const {
    subscriptions,
    fetchSubscriptions,
    cancelSubscription,
    selectedAccount,
    subscribe,
    isLoggedIn,
    loggedUser,
    subscriptionSuccess,
    isLoading,
    createSession,
    sessionId,
    fetchPackages,
    packages
  } = props;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [tabValue, setTabValue] = React.useState("month");

  useEffect(() => {
    fetchSubscriptions(selectedAccount.stripeId);
    fetchPackages(selectedAccount.id);
    // cancelSubscription(selectedAccount.id);
  }, []);
  
  useEffect(async () => {
    if(sessionId !== null) {
      sessionStorage.setItem('sessionId', sessionId.id);
      const stripe = await loadStripe(PUBLIC_KEY);
      stripe.redirectToCheckout({sessionId: sessionId.id});
    }
  }, [sessionId]);

  function callCheckout(id){
    sessionStorage.setItem("packageId", id);
    createSession({ id, accountId: selectedAccount.id });
  }

  const tabHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SubscriptionDialog open={open} setOpen={setOpen} />
      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card
            sx={{
              background: "#221C40",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
              borderRadius: "9px",
              marginBottom: "24px",
            }}
          >
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "11px 8px 12px 32px",
              }}
            >
              <MDTypography
                sx={{ fontWeight: "600", fontSize: "15px", color: "#F8F9FA" }}
              >
                Update your Subscription Plan Now
              </MDTypography>
              <MDBox sx={{ ml: "auto" }}>
                {/* <MDButton
                  onClick={() => setOpen(true)}
                  variant="outlined"
                  style={{
                    backgroundColor: "#221C40",
                    color: "#FFFFFF",
                    borderRadius: "9px",
                    fontWeight: "500",
                    borderColor: "#FFFFFF",
                    folntSize: "14px",
                  }}
                  size="small"
                >
                  Update Plan Now
                </MDButton> */}
              </MDBox>
            </MDBox>
          </Card>
          {subscriptions && Array.isArray(subscriptions) && subscriptions.length > 0 ? (
            <Card
              id="subscription-billing"
              sx={{
                background: "#FFFFFF",
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                borderRadius: "9px",
              }}
            >
              <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                p={3}
              >
                <Grid container>
                  <Grid item xs={12} lg={5} md={5}>
                    <MDTypography
                      style={{
                        fontWeight: "700",
                        fontSize: "20px",
                        lineHeight: "140%",
                        color: "#344767",
                      }}
                    >
                      Subscription Details
                    </MDTypography>
                    <MDTypography
                      style={{
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#7B809A",
                        marginTop: "11px",
                      }}
                    >
                      Here you can setup and manage your subscription settings
                    </MDTypography>
                    <MDTypography
                      variant="h6"
                      fontWeight="medium"
                      style={{ marginTop: "42px" }}
                    >
                      Payment Method
                    </MDTypography>
                    <MDBox
                      style={{ marginTop: "11px", maxWidth: "356px" }}
                      borderRadius="lg"
                      display="flex"
                      alignItems="center"
                      p={2}
                      sx={{
                        border: ({ borders: { borderWidth, borderColor } }) =>
                          `${borderWidth[1]} solid ${borderColor}`,
                      }}
                    >
                      <MDBox
                        component="img"
                        src={masterCardLogo}
                        alt="master card"
                        width="10%"
                        mr={2}
                      />
                      <MDTypography variant="body2" fontWeight="medium">
                        ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;4242
                      </MDTypography>
                    </MDBox>
                  </Grid>
                  <Grid item xs={12} lg={7} md={7}>
                    <MDBox
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        maxWidth: "75%",
                      }}
                    >
                      <Grid item>
                        <MDTypography
                          style={{
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#7B809A",
                          }}
                        >
                          Ongoing subscription
                        </MDTypography>
                        <MDTypography style={standerdplan}>PREMIUM</MDTypography>
                      </Grid>
                      <Stack direction="row">
                        <MDTypography
                          style={{
                            color: "#414141",
                            fontWeight: "700",
                            fontSize: "18px",
                          }}
                        >
                          $
                        </MDTypography>
                        <MDTypography style={{ color: "#414141" }} variant="h1">
                          { subscriptions.data && Array.isArray(subscriptions.data) && Math.round(subscriptions.data[0].plan.amount/100) }
                        </MDTypography>
                        <MDTypography
                          style={{
                            marginTop: "25px",
                            color: "#414141",
                            fontWeight: "700",
                            fontSize: "18px",
                          }}
                          variant="body3"
                        >
                          /{ subscriptions.data && Array.isArray(subscriptions.data) && subscriptions.data[0].plan.interval }
                        </MDTypography>
                      </Stack>
                      <MDTypography
                        style={{
                          fontWeight: "700",
                          fontSize: "12px",
                          color: "#343338",
                          textDecorationLine: "underline",
                          marginTop: "10px",
                        }}
                      >
                        UPDATE SUBSCRIPTION
                        <ArrowForwardIcon style={{ marginLeft: "6px" }} />
                      </MDTypography>
                    </MDBox>
                    <MDBox>
                      <Grid item>
                        <MDTypography
                          style={{
                            marginTop: "41px",
                            fontWeight: "700",
                            fontSize: "16px",
                            color: "#344767",
                          }}
                        >
                          Cancel Subscription
                        </MDTypography>
                        <MDTypography
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "#7B809A",
                            marginTop: "5px",
                            maxWidth: "454px",
                          }}
                        >
                          The cancellation will take effect after the end of your
                          current billing period on 9 Sept 2022. Your data will be
                          permanently deleted 30 days after the cancellation date
                        </MDTypography>
                        <MDButton
                          variant="outlined"
                          style={{
                            backgroundColor: "#FFFFFF",
                            color: "#E71D36",
                            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                            borderRadius: "12px",
                            fontWeight: "700",
                            borderColor: "#E71D36",
                            marginTop: "10px",
                          }}
                          size="small"
                        >
                          Close Account{" "}
                          <ArrowForwardIcon style={{ marginLeft: "6px" }} />
                        </MDButton>
                      </Grid>
                    </MDBox>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          ) : ( "" )}
          <Grid container style={{marginTop: "31px", justifyContent: "center"}}>
          <Grid item xs={12} md={4}>
            <MDBox mx="auto">
              <AppBar position="static">
                <Tabs value={tabValue} onChange={tabHandler}>
                  <Tab
                    id="monthly"
                    value="month"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Monthly
                      </MDBox>
                    }
                  />
                  <Tab
                    id="annual"
                    value="year"
                    label={
                      <MDBox py={0.5} px={2} color="inherit">
                        Annual
                      </MDBox>
                    }
                  />
                </Tabs>
              </AppBar>
            </MDBox>
          </Grid>
        </Grid>
        <TabPanel value={tabValue} index="month">
            <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "35px" }}
            >
              {packages && Array.isArray(packages) && packages.filter(a => a.timeFrame == "Month").map(x => {
                return (
                  <Grid key={x.id} item xs={12} lg={4}>
                    <DefaultPricingCard
                      color={darkMode ? "dark" : "white"}
                      badge={{
                        color: "dark",
                        label: (
                          <MDTypography
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "700",
                              paddingTop: "5px",
                            }}
                          >
                            {x.name}
                          </MDTypography>
                        ),
                      }}
                      price={{ currency: "$", value: x.price, type: x.timeFrame }}
                      specifications={[
                        { label: `${x.platformAccess} social profile(s)`, includes: true },
                        { label: "Access to post composer and scheduler", includes: true },
                        { label: `${x.price == 0 ? "10GB" : "Unlimited"} media storage`, includes: true },
                        { label: `Access to premium themes and layouts`, includes: x.themeAcess == 1 },
                        { label: "Access to industry content", includes: x.industryAccess == 1 },
                        { label: `${x.contentNumber} content per month`, includes: true },
                      ]}
                      action={{
                        type: "internal",
                        route: () => callCheckout(x.id),
                        color: darkMode ? "warning" : "dark",
                        label: selectedAccount.Subsciption && selectedAccount.Subsciption.packageId == x.id ? "current" : "join",
                      }}
                      shadow={darkMode}
                    />
                  </Grid>
                )
              })}
            </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index="year">
          <Grid
              container
              spacing={3}
              justifyContent="center"
              style={{ marginTop: "35px" }}
            >
              {packages && Array.isArray(packages) && packages.filter(a => a.timeFrame == "Year").map(x => {
                return (
                  <Grid key={x.id} item xs={12} lg={4}>
                    <DefaultPricingCard
                      color={darkMode ? "dark" : "white"}
                      badge={{
                        color: "dark",
                        label: (
                          <MDTypography
                            style={{
                              color: "#fff",
                              fontSize: "12px",
                              fontWeight: "700",
                              paddingTop: "5px",
                            }}
                          >
                            {x.name}
                          </MDTypography>
                        ),
                      }}
                      price={{ currency: "$", value: x.price, type: x.timeFrame }}
                      specifications={[
                        { label: `${x.platformAccess} social profile(s)`, includes: true },
                        { label: "Access to post composer and scheduler", includes: true },
                        { label: `${x.price == 0 ? "10GB" : "Unlimited"} media storage`, includes: true },
                        { label: `Access to premium themes and layouts`, includes: x.themeAcess == 1 },
                        { label: "Access to industry content", includes: x.industryAccess == 1 },
                        { label: `${x.contentNumber} content per month`, includes: true },
                      ]}
                      action={{
                        type: "internal",
                        route: () => callCheckout(x.id),
                        color: darkMode ? "warning" : "dark",
                        label: selectedAccount.Subsciption && selectedAccount.Subsciption.packageId == x.id ? "current" : "join",
                      }}
                      shadow={darkMode}
                    />
                  </Grid>
                )
              })}
            </Grid>
        </TabPanel>
          {/* <Grid
            container
            spacing={3}
            justifyContent="center"
            style={{ marginTop: "35px" }}
          >
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                color={darkMode ? "dark" : "white"}
                badge={{
                  color: "dark",
                  label: (
                    <MDTypography
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "700",
                        paddingTop: "5px",
                      }}
                    >
                      STARTER
                    </MDTypography>
                  ),
                }}
                price={{ currency: "$", value: "0", type: "mo" }}
                specifications={[
                  { label: "2 team members", includes: true },
                  { label: "20GB Cloud storage", includes: true },
                  { label: "Integration help", includes: false },
                  { label: "Sketch Files", includes: false },
                  { label: "API Access", includes: false },
                  { label: "Complete documentation", includes: false },
                ]}
                action={{
                  type: "internal",
                  route: () => callCheckout("price_1LUOAjSAnwstcjS1B0nVDFbH"),
                  color: darkMode ? "warning" : "dark",
                  label: "join",
                }}
                shadow={darkMode}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                sx={{
                  "& .css-19m7ni6": {
                    background: "linear-gradient(-37deg, #2d2d40, #0a0a830) !important",
                  },
                }}
                color="dark"
                badge={{ color: "#E91F63", label: "premium" }}
                price={{ currency: "$", value: "59", type: "mo" }}
                specifications={[
                  { label: "10 team members", includes: true },
                  { label: "40GB Cloud storage", includes: true },
                  { label: "Integration help", includes: true },
                  { label: "Sketch Files", includes: true },
                  { label: "API Access", includes: false },
                  { label: "Complete documentation", includes: false },
                ]}
                action={{
                  type: "internal",
                  color: "primary",
                  route: () => callCheckout("price_1LUOAjSAnwstcjS1B0nVDFbH"),
                  label: "try premium",
                }}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <DefaultPricingCard
                color={darkMode ? "dark" : "white"}
                badge={{
                  color: "dark",
                  label: (
                    <MDTypography
                      style={{
                        color: "#fff",
                        fontSize: "12px",
                        fontWeight: "700",
                        paddingTop: "5px",
                      }}
                    >
                      ENTERPRISE
                    </MDTypography>
                  ),
                }}
                price={{ currency: "$", value: "160", type: "mo" }}
                specifications={[
                  { label: "Unlimited team members", includes: true },
                  { label: "100GB Cloud storage", includes: true },
                  { label: "Integration help", includes: true },
                  { label: "Sketch Files", includes: true },
                  { label: "API Access", includes: true },
                  { label: "Complete documentation", includes: true },
                ]}
                action={{
                  type: "internal",
                  color: darkMode ? "warning" : "dark",
                  route: () => callCheckout("price_1LUOAjSAnwstcjS1ITchl8Mx"),
                  label: "join",
                }}
                shadow={darkMode}
              />
            </Grid>
          </Grid> */}
        </MDBox>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

