export const ADD_CONTENT = "ADD_CONTENT";
export const ADD_CONTENT_FAILURE = "ADD_CONTENT_FAILURE";
export const ADD_CONTENT_SUCCESS = "ADD_CONTENT_SUCCESS";

export const APPROVE_CONTENT = "APPROVE_CONTENT";
export const APPROVE_CONTENT_FAILURE = "APPROVE_CONTENT_FAILURE";
export const APPROVE_CONTENT_SUCCESS = "APPROVE_CONTENT_SUCCESS";

export const REGENERATE_CONTENT = "REGENERATE_CONTENT";
export const REGENERATE_CONTENT_FAILURE = "REGENERATE_CONTENT_FAILURE";
export const REGENERATE_CONTENT_SUCCESS = "REGENERATE_CONTENT_SUCCESS";

export const GET_CONTENT = "GET_CONTENT";
export const GET_CONTENT_FAILURE = "GET_CONTENT_FAILURE";
export const GET_CONTENT_SUCCESS = "GET_CONTENT_SUCCESS";

export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const UPDATE_CONTENT_FAILURE = "UPDATE_CONTENT_FAILURE";
export const UPDATE_CONTENT_SUCCESS = "UPDATE_CONTENT_SUCCESS";

export const DELETE_CONTENT = "DELETE_CONTENT";
export const DELETE_CONTENT_FAILURE = "DELETE_CONTENT_FAILURE";
export const DELETE_CONTENT_SUCCESS = "DELETE_CONTENT_SUCCESS";
