import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from '@material-ui/styles';
import { Grid } from "@mui/material";
import MDButton from "../../../shared/components/MDButton";
import FormField from "./../../../shared/components/FormField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ClearIcon from "@mui/icons-material/Clear";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const mdText1 = {
  color: "red",
  marginTop: "-0px",
  marginBottom: "14px",
  fontSize: "12px",
  fontWeight: 400,
};

const useStyles = makeStyles({
  closeBtn: {
    position: "relative",
    cursor: "pointer",
    fontSize: "24px",
    color: "#AEAEAE",
  },
  selct: {
    '& .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
        padding: "0px!important",
    },
    '& .css-ynad2y-MuiSvgIcon-root-MuiSelect-icon': {
        display: "block",
    },
  },
});

const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      minWidth: "255px",
      marginLeft: "122px",

    },
  },
};

const names = ["Administrator", "Contributor", "Author", "Editor"];

export default function TeamMatesDialog(props) {
  const classes = useStyles();
  const { open, onClose, setSelected, selected, sendInvite, error, setError } = props;
  const roles = [{id: 1, name: "Owner"}, {id: 2, name: "Member"}];

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography
                  sx={{
                    fontWeight: "700",
                    fontSize: "20px",
                    color: "#344767",
                    marginBottom: "8px",
                  }}
                >
                  Add New member
                </MDTypography>
                <MDTypography
                  sx={{ fontWeight: "400", fontSize: "14px", color: "#7B809A" }}
                >
                  Create a brand new member and add them to this site.
                </MDTypography>
              </MDBox>
              <MDBox style={mdbox} >
                <ClearIcon  onClick={() => onClose()}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
            </MDBox>
            <MDBox component="form" pb={3} mt={2} pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormField
                    label="Email"
                    name="email"
                    placeholder="Add email here..."
                    type="email"
                    value={selected.emailId}
                    onChange={e => setSelected({
                      ...selected,
                      emailId: e.target.value
                    })}
                  />
                  {error.isTrue && error.type == "email" && (
                    <MDTypography style={mdText1}>{error.text}</MDTypography>
                  )}
                </Grid>
                <Grid item xs={12}>
                    <FormControl variant="standard" 
                        style={{paddingTop: "22px"}}
                        fullWidth
                    >
                        <InputLabel id="demo-simple-select-standard-label">
                            Member Role
                        </InputLabel>
                        <Select
                          className={classes.selct}
                          displayEmpty
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={selected.role}
                          onChange={e => setSelected({
                            ...selected,
                            role: e.target.value
                          })}
                          MenuProps={MenuProps}
                        >   
                          {roles.map((role) => (
                              <MenuItem key={role.id} value={role.id}>
                              <ListItemText sx={{
                                "& .MuiTypography-root": {
                                  fontSize: "16px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                  fontWeight: "400"
                                },
                              }} primary={role.name} />
                              </MenuItem>
                          ))}
                        </Select>
                    </FormControl>
                    {error.isTrue && error.type == "role" && (
                      <MDTypography style={mdText1}>{error.text}</MDTypography>
                    )}
                </Grid>
              </Grid>
              <MDBox sx={{ display: "flex", marginTop: "21px" }}>
                <MDTypography
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#534F6B",
                    marginRight: "45px",
                  }}
                >
                  Send User Notification
                </MDTypography>
                <FormControlLabel
                  sx={{ marginTop: "-8px" }}
                  control={<Switch />}
                />
              </MDBox>
              <MDButton
                style={{
                  background: "#09032B",
                  color: "#FFFFFF",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  width: "150px",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginTop: "39px",
                  textTransform: "capitalize"
                }}
                fullWidth
                onClick={() => {
                  if (selected.emailId.trim() == "") {
                    setError({ isTrue: true, type: "email", text: "Email is required" });
                    return;
                  }
                  if (selected.emailId.trim() == "") {
                    setError({ isTrue: true, type: "role", text: "Role is required" });
                    return;
                  }
                  sendInvite(selected);
                  onClose();
                }}
              >
                Add User
              </MDButton>
              <MDButton
                variant="text"
                style={{
                  color: "#6B6880",
                  fontWeight: "700",
                  fontSize: "12px",
                  marginTop: "39px",
                  textTransform: "capitalize"
                }}
                onClick={() => onClose()}
              >
                Cancel
              </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
