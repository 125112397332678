import React from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
import selectData from "./data";

// img
import team3 from "./../../assets/images/team3.jpg";

// icon
// import Icon from "@mui/material/Icon";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
// import DoneIcon from "@mui/icons-material/Done";

// mui
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
// import Stack from "@mui/material/Stack";

// MD
import MDBox from "../../shared/components/MDBox";
import MDInput from "../../shared/components/MDInput";
import MDTypography from "../../shared/components/MDTypography";
import MDAvatar from "../../shared/components/MDAvatar";
import MDButton from "../../shared/components/MDButton";

export default function BusinessAccountPage() {
  const topName = {
    color: "#7B809A",
    fontSize: "14px",
    fontWeight: "400",
  };
  //   const bottomName = {
  //     color: "#09032B",
  //     fontSize: "14px",
  //     fontWeight: "400",
  //   };
  // chip
  //   const handleClick = () => {
  //     console.info("You clicked the Chip.");
  //   };
  // chip
  const [values, setValues] = React.useState({
    industry: "",
    topic: "",
    location: "",
    timezone: "",
    brandColor: "",
    primaryColor: "",
    secondryColor: "",
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card style={{ marginTop: "30px" }}>
        <MDBox p={2}>
          <MDTypography
            style={{
              color: "#3a3555",
              fontSize: "24px",
              fontWeight: "600",
            }}
          >
            Business Account Settings
          </MDTypography>
        </MDBox>
        <MDBox p={2}>
          <Grid container>
            <Grid item sm={12} md={2} lg={2}>
              <MDAvatar size="xxl" src={team3} />
            </Grid>
            <Grid item sm={12} md={10} lg={10} style={{ display: "block" }}>
              <MDButton
                variant="contained"
                style={{
                  color: "#fff",
                  backgroundColor: "#09032B",
                  maxWidth: "160px",
                  height: "36px",
                  marginTop: "20px",
                }}
              >
                Change Logo
              </MDButton>
              <MDButton
                variant="text"
                style={{
                  maxWidth: "160px",
                  color: "#4b4764",
                  marginTop: "20px",
                }}
              >
                remove
              </MDButton>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px", maxWidth: "991px" }}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Business Name</MDTypography>
              <MDInput
                variant="standard"
                label="Company Y"
                style={{ width: "90%" }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Business Email</MDTypography>
              <MDInput
                variant="standard"
                label="john@email.com"
                style={{ width: "90%" }}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px", maxWidth: "991px" }}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>industry</MDTypography>
              <Autocomplete
                multiple
                options={selectData.industry}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    industry: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    multiple
                    style={{ width: "90%" }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Topic</MDTypography>
              <Autocomplete
                multiple
                options={selectData.topic}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    topic: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    multiple
                    style={{ width: "90%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px", maxWidth: "991px" }}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Business Phone</MDTypography>
              <MDInput
                variant="standard"
                type="number"
                label="+61 3 123 123"
                style={{ width: "90%" }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Business Location</MDTypography>
              <Autocomplete
                options={selectData.location}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    location: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    placeholder="Enter location here"
                    variant="standard"
                    style={{ width: "90%", marginTop: "16px" }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px", maxWidth: "991px" }}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Timezone</MDTypography>
              <Autocomplete
                options={selectData.timezone}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    timezone: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    placeholder="Enter timezone here"
                    variant="standard"
                    style={{ width: "90%" }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Brand Color</MDTypography>
              <Autocomplete
                options={selectData.brandColor}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    brandColor: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    placeholder="Enter Color here"
                    variant="standard"
                    style={{ width: "90%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px", maxWidth: "991px" }}>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Primary Color</MDTypography>
              <Autocomplete
                options={selectData.primaryColor}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    primaryColor: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    placeholder="Enter Color here"
                    variant="standard"
                    style={{ width: "90%" }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Secondary Color</MDTypography>
              <Autocomplete
                options={selectData.secondaryColor}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    secondaryColor: newValue,
                  });
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    placeholder="Enter Color here"
                    variant="standard"
                    style={{ width: "90%" }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              marginTop: "40px",
              marginBottom: "20px",
              maxWidth: "991px",
            }}
          >
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Custom Call To Action</MDTypography>
              <MDInput
                variant="standard"
                label="Call us for a free chat"
                style={{ width: "90%" }}
              />
            </Grid>
            <Grid
              item
              sm={12}
              md={6}
              lg={6}
              sx={{ "@media (max-width: 575px)": { width: "100%" } }}
            >
              <MDTypography style={topName}>Hashtags</MDTypography>
              <MDInput
                variant="standard"
                label="#businesstags #newone #anotherone"
                style={{ width: "90%" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            <Grid item sm={12} md={12} lg={12}>
              <MDBox style={{ float: "right" }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <MDButton
                      variant="outlined"
                      color="info"
                      style={{ color: "#6B6880" }}
                    >
                      Cancle
                    </MDButton>
                  </Grid>
                  <Grid item>
                    <MDButton
                      variant="contained"
                      sx={{
                        color: "#fff",
                        backgroundColor: "#09032B",
                        minWidth: "160px",
                      }}
                    >
                      Save
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
