import React, { useEffect, useState } from "react";
// import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import MDBox from "./../../shared/components/MDBox";
import MDButton from "./../../shared/components/MDButton";
import Footer from "./../../shared/components/Footer";
import FormField from "./../../shared/components/FormField";
import logo from "./../../assets/images/logo-ct-dark.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import googleImage from "./../../assets/images/googleLogo.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import { makeStyles } from '@material-ui/styles';
import Loading from './../../shared/Loading';

const useStyles = makeStyles({
  listText: {
    '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
      {
        color: "#333F51",
        fontWeight: "700",
        fontSize: "12px",
      },
  },
});

const googleBtn = {
  backgroundColor: "#F1F1F1", 
  borderRadius: "8px", 
  color: "#1B172E", 
  fontWeight: "700", 
  fontSize: "14px",
  textTransform: "inherit",
}
const forgotBtn = {
  fontWeight: "700", 
  fontSize: "12px", 
  color: "#09032B", 
  marginLeft: "auto", 
  textTransform: "inherit"
}

export default function LoginPage(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  const { userLogin, userAuth, user, isLoggedIn, isLoading, selectAccount } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const mdText2 = {
    color: "red",
    marginTop: "-14px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandle = (event) => {
    event.preventDefault();
    setErrors(validate(values));
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.username) {
      isError = true;
      errorss.username = "Email is required";
    } else if (!regex.test(values.username)) {
      isError = true;
      errorss.username = "This is not a valid email format";
    }
    if (!values.password) {
      isError = true;
      errorss.password = "Password is required";
    }
    if (!isError) {
      userLogin(values);
    }
    return errorss;
  };

  if (isLoggedIn) {
    if (user.emailVerified === 0) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/email-verify`;
    } else if (user.accounts && Array.isArray(user.accounts) && user.accounts.length > 0) {
      sessionStorage.setItem("selectedAccount", JSON.stringify(user.accounts[0]));
      window.location.href = `${process.env.REACT_APP_BASE_URL}`;
    } else {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/#/create-business`;
    }
  }

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const x = 0;
  const y = -11;
  const ortext = {
    transform: `translate(${x}px, ${y}px)`,
    backgroundColor: "white",
    width: "33px",
    margin: "0px auto",
    borderRadius: "25px",
    color: "#344767",
    fontSize: "14px",
    fontWeight: "700"
  };
  const orborder = {
    borderTop: "1px dashed #E4E4E4",
    textAlign: "center",
    marginTop: "24px",
  };

  useEffect(() => {
    if (code) {
      userAuth(code);
    }
  }, []);

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
        <Grid
          item
          style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
        >
          <MDAvatar
            src={logo}
            variant="square"
            style={{ width: "13px", height: "22px", marginTop: "5px" }}
          />
          &nbsp;&nbsp;
          <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <MDBox
            style={{
              backgroundColor: "white",
              width: "469px",
              height: "620px",
              padding: "0px 25px",
              borderRadius: "12px",
              marginTop: "149px",
            }}
            // onSubmit={handleSubmit(onSubmit)}
          >
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "25px",
                  fontWeight: "700",
                  marginTop: "40px",
                }}
              >
                Sign in to your account
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "5px",
                }}
              >
                Let’s get social
              </MDTypography>
            </MDBox>
            <form>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Email"
                  name="username"
                  placeholder="john@email.com"
                  type="email"
                  value={values.username}
                  onChange={handleChange}
                />
                {errors.username && (
                <MDTypography style={mdText1}>{errors.username}</MDTypography>
              )}
              </MDBox>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="****************"
                  value={values.password}
                  onChange={handleChange}
                />
              </MDBox>
              {errors.password && (
                <MDTypography style={mdText2}>{errors.password}</MDTypography>
              )}
              <MDBox style={{display: "flex", marginTop: "24px"}}>
                <List className={classes.listText} >
                  <FormGroup>
                      <FormControlLabel
                      control={<Checkbox />}
                      label="Remember me"
                      />
                  </FormGroup>
                </List>
                <MDButton style={forgotBtn} variant="text" 
                  component={Link}
                  to="/forget-password">
                  Forgot your password?
                </MDButton>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  onClick={submitHandle}
                  // onClick={() => validate()}
                  style={{ backgroundColor: "#09032B", color: "#F0FDF4", fontWeight: "700", fontSize: "16px", textTransform: "capitalize" }}
                  size="large"
                  fullWidth
                  type="submit"
                >
                  sign in
                </MDButton>
              </MDBox>
            </form>
            <MDBox mt={3} textAlign="center">
              <MDTypography variant="button" style={{fontWeight: "700", fontSize: "12px", color: "#333F51"}}>
                Don&apos;t have an account?{" "}
                <MDTypography style={{fontWeight: "700", fontSize: "12px", color: "#09032B"}}
                  component={Link}
                  to="/signup"
                  variant="button"
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox style={orborder}>
              <MDTypography style={ortext}>OR</MDTypography>
            </MDBox>
            <MDBox style={{ display: "flex" }}>
              <Button style={googleBtn} fullWidth
                onClick={() => window.location.href = `${process.env.REACT_APP_API_URL}/user/google-register-link`}>
                <img src={googleImage} alt="Lock logo" style={{marginRight: "10px"}} />
                Sign in with Google
              </Button>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item style={{ marginTop: "5%" }}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
