import * as actionTypes from "./userActionType";
import axios from 'axios';
import { toast } from 'react-toastify';
import { checkError } from './../error';

const baseUrl = process.env.REACT_APP_API_URL;

export const userRegister = (data) => {
    return (dispatch) => {
        dispatch(userRegisterInit());
        axios.post(`${baseUrl}/user/register`, data)
        .then((response)=>{
            sessionStorage.setItem("accessToken",response.data.data.token);
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            toast.error("Thank you for register.", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(userRegisterSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(userRegisterFailure());
        })
    };
};

export const userLogin = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(userLoginInit());
        axios.post(`${baseUrl}/user/authenticate`, data, config)
        .then((response)=>{
            sessionStorage.setItem("accessToken",response.data.data.token);
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            dispatch(userLoginSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(userLoginFailure());
        })
    };
};

export const googleAccount = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(googleAccountInit());
        axios.post(`${baseUrl}/user/google-accounts`, data, config)
        .then((response)=>{
            dispatch(googleAccountSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(googleAccountFailure());
        })
    };
};

export const googleLocation = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(googleLocationInit());
        axios.post(`${baseUrl}/user/google-locations`, data, config)
        .then((response)=>{
            dispatch(googleLocationSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(googleLocationFailure());
        })
    };
};

export const getAccount = (code = null, refreshToken = null) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(getAccountInit());
        axios.post(`${baseUrl}/user/code`, { code, refreshToken }, config)
        .then((response)=>{
            dispatch(getAccountSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getAccountFailure());
        })
    };
};

export const getLinkedinPage = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(getLinkedinPageInit());
        axios.post(`${baseUrl}/linkedin/get-pages`, data, config)
        .then((response)=>{
            dispatch(getLinkedinPageSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getLinkedinPageFailure());
        })
    };
};

export const getLocation = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("token")}` } };
      return (dispatch) => {
        dispatch(getLocationInit());
        axios.post(`${baseUrl}/user/code`, data, config)
        .then((response)=>{
            dispatch(getLocationSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getLocationFailure());
        })
    };
};

export const userAuth = (token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
      return (dispatch) => {
        dispatch(userAuthInit());
        axios.post(`${baseUrl}/user/google-auth`, {}, config)
        .then((response)=>{
            sessionStorage.setItem("accessToken", response.data.data.token);
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            dispatch(userAuthSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(userAuthFailure());
        })
    };
};

export const sendVerifyEmail = (token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
      return (dispatch) => {
        dispatch(sendVerifyInit());
        axios.get(`${baseUrl}/user/verify-email`, config)
        .then((response)=>{
            dispatch(sendVerifySuccess(response.data.data));
            toast.success(" Sign up successfully... ", {
                position: toast.POSITION.TOP_RIGHT
            });  
        })
        .catch(function (error) {
            checkError(error);
            dispatch(sendVerifyFailure());
        })
    };
};

export const resendVerifyEmail = (token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
      return (dispatch) => {
        dispatch(resendVerifyInit());
        axios.post(`${baseUrl}/user/resend-verify-email`, {}, config)
        .then((response)=>{
            toast.success("Email has been sent.", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(resendVerifySuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(resendVerifyFailure());
        })
    };
};

export const forgetPassword = (data) => {
      return (dispatch) => {
        dispatch(forgetPasswordInit());
        axios.post(`${baseUrl}/user/forget-password`, data)
        .then((response)=>{
            toast.success("Please check your email address to reset your password.", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(forgetPasswordSuccess(response.data.data)); 
        })
        .catch(function (error) {
            checkError(error);
            dispatch(forgetPasswordFailure());
        })
    };
};

export const resetPassword = (data, token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
    return (dispatch) => {
      dispatch(resetPasswordInit());
      axios.post(`${baseUrl}/user/reset-password`, data, config)
      .then((response)=>{
          toast.success("Success!! Password reset is done. Please login.", {
              position: toast.POSITION.TOP_RIGHT
          });
          dispatch(resetPasswordSuccess(response.data.data)); 
      })
      .catch(function (error) {
        checkError(error);
          dispatch(resetPasswordFailure());
      })
  };
};

export const fetchCurrentUser = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(fetchCurrentUserInit());
        axios.get(`${baseUrl}/user/current`, config)
        .then((response)=>{
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            dispatch(fetchCurrentUserSuccess(response.data.data));
            const preAccount = JSON.parse(sessionStorage.getItem("selectedAccount")) || {};
            if (preAccount.id) {
                const acc = response.data.data.accounts ? response.data.data.accounts.filter(x => x.id == preAccount.id)[0] : {};
                dispatch(selectAccount (acc));
            } else {
                const acc1 = response.data.data.accounts && response.data.data.accounts[0];
                dispatch(selectAccount (acc1));
            }
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchCurrentUserFailure());
        })
    };
};

export const fetchCurrentUserr = (token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
      return (dispatch) => {
        dispatch(fetchCurrentUserInit());
        axios.get(`${baseUrl}/user/current`, config)
        .then((response)=>{
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            dispatch(fetchCurrentUserSuccess(response.data.data));
            const preAccount = JSON.parse(sessionStorage.getItem("selectedAccount")) || {};
            if (preAccount.id) {
                const acc = response.data.data.accounts ? response.data.data.accounts.filter(x => x.id == preAccount.id)[0] : {};
                dispatch(selectAccount (acc));
            } else {
                const acc1 = response.data.data.accounts && response.data.data.accounts[0];
                dispatch(selectAccount (acc1));
            }
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchCurrentUserFailure());
        })
    };
};

export const fetchTeam = (id) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(fetchTeamInit());
        axios.get(`${baseUrl}/user/team/${id}`, config)
        .then((response)=> {
            dispatch(fetchTeamSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(fetchTeamFailure());
        })
    };
};

export const getDashboard = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(getDashboardInit());
        axios.post(`${baseUrl}/user/dashboard`, data, config)
        .then((response)=> {
            dispatch(getDashboardSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getDashboardFailure());
        })
    };
};

export const inviteUser = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(inviteUserInit());
        axios.post(`${baseUrl}/user/complete-invite`, data, config)
        .then((response)=> {
            sessionStorage.setItem("accessToken", response.data.data.token);
            sessionStorage.setItem("user", JSON.stringify(response.data.data));
            dispatch(inviteUserSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(inviteUserFailure());
        })
    };
};

export const sendInvite = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(sendInviteInit());
        axios.post(`${baseUrl}/user/send-invite`, data, config)
        .then((response)=> {
            toast.success("An invitation has been sent.", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(sendInviteSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(sendInviteFailure());
        })
    };
};

export const deleteTeam = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(deleteTeamInit());
        axios.post(`${baseUrl}/user/delete-team`, data, config)
        .then((response)=> {
            toast.success("Team mate has been removed.", {
                position: toast.POSITION.TOP_RIGHT
            });
            dispatch(deleteTeamSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(deleteTeamFailure());
        })
    };
};

export const createLinkedin = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("accessToken")}` } };
      return (dispatch) => {
        dispatch(createLinkedinInit());
        axios.post(`${baseUrl}/linkedin/create-profile`, data, config)
        .then((response)=> {
            console.log("Response: ", response.data.message);
            if (response.data.message == "Success"){
                dispatch(createLinkedinSuccess(response.data.data));
            } else {
                toast.error("Some error occure. Please try after some time.", {
                    position: toast.POSITION.TOP_RIGHT
                });
                dispatch(createLinkedinFailure());
            }
        })
        .catch(function (error) {
            checkError(error);
            dispatch(createLinkedinFailure());
        })
    };
};

export const getJWTDetails = (token) => {
    const config = { headers: { Authorization: `Bearer ${token}` } };
      return (dispatch) => {
        dispatch(getJWTDetailsInit());
        axios.get(`${baseUrl}/user/get-jwt-details`, config)
        .then((response)=> {
            dispatch(getJWTDetailsSuccess(response.data.data));
        })
        .catch(function (error) {
            checkError(error);
            dispatch(getJWTDetailsFailure());
        })
    };
};

export const selectAccount = (data) => {
    return (dispatch) => {
        dispatch(selectAccountInit());
        sessionStorage.setItem("selectedAccount", JSON.stringify(data));
        dispatch(selectAccountSuccess(data));
    }
}

export const selectAccountInit = () => ({
    type: actionTypes.SELECT_ACCOUNT
});

export const selectAccountSuccess = data => ({
    type: actionTypes.SELECT_ACCOUNT_SUCCESS,
    payload: { data }
});

export const sendInviteInit = () => ({
    type: actionTypes.SEND_INVITE
});

export const sendInviteFailure = data => ({
    type: actionTypes.SEND_INVITE_FAILURE
});

export const sendInviteSuccess = data => ({
    type: actionTypes.SEND_INVITE_SUCCESS,
    payload: { data }
});

export const getLinkedinPageInit = () => ({
    type: actionTypes.GET_LINKEDIN_PAGE
});

export const getLinkedinPageFailure = () => ({
    type: actionTypes.GET_LINKEDIN_PAGE_FAILURE
});

export const getLinkedinPageSuccess = data => ({
    type: actionTypes.GET_LINKEDIN_PAGE_SUCCESS,
    payload: { data }
});

export const deleteTeamInit = () => ({
    type: actionTypes.DELETE_TEAM
});

export const deleteTeamFailure = data => ({
    type: actionTypes.DELETE_TEAM_FAILURE
});

export const deleteTeamSuccess = data => ({
    type: actionTypes.DELETE_TEAM_SUCCESS,
    payload: { data }
});

export const getDashboardInit = () => ({
    type: actionTypes.GET_DASHBOARD
});

export const getDashboardFailure = data => ({
    type: actionTypes.GET_DASHBOARD_FAILURE
});

export const getDashboardSuccess = data => ({
    type: actionTypes.GET_DASHBOARD_SUCCESS,
    payload: { data }
});

export const createLinkedinInit = () => ({
    type: actionTypes.CREATE_LINKEDIN
});

export const createLinkedinFailure = data => ({
    type: actionTypes.CREATE_LINKEDIN_FAILURE
});

export const createLinkedinSuccess = data => ({
    type: actionTypes.CREATE_LINKEDIN_SUCCESS,
    payload: { data }
});

export const googleAccountInit = () => ({
    type: actionTypes.GOOGLE_ACCOUNT
});

export const googleAccountFailure = data => ({
    type: actionTypes.GOOGLE_ACCOUNT_FAILURE
});

export const googleAccountSuccess = data => ({
    type: actionTypes.GOOGLE_ACCOUNT_SUCCESS,
    payload: { data }
});

export const googleLocationInit = () => ({
    type: actionTypes.GOOGLE_LOCATION
});

export const googleLocationFailure = data => ({
    type: actionTypes.GOOGLE_LOCATION_FAILURE
});

export const googleLocationSuccess = data => ({
    type: actionTypes.GOOGLE_LOCATION_SUCCESS,
    payload: { data }
});

export const inviteUserInit = () => ({
    type: actionTypes.INVITE_USER
});

export const inviteUserFailure = data => ({
    type: actionTypes.INVITE_USER_FAILURE
});

export const inviteUserSuccess = data => ({
    type: actionTypes.INVITE_USER_SUCCESS,
    payload: { data }
});

export const getJWTDetailsInit = () => ({
    type: actionTypes.GET_JWT_DETAILS
});

export const getJWTDetailsFailure = data => ({
    type: actionTypes.GET_JWT_DETAILS_FAILURE
});

export const getJWTDetailsSuccess = data => ({
    type: actionTypes.GET_JWT_DETAILS_SUCCESS,
    payload: { data }
});

export const userRegisterInit = () => ({
    type: actionTypes.USER_REGISTER
});

export const userRegisterFailure = data => ({
    type: actionTypes.USER_REGISTER_FAILURE
});

export const userRegisterSuccess = data => ({
    type: actionTypes.USER_REGISTER_SUCCESS,
    payload: { data }
});

export const fetchTeamInit = () => ({
    type: actionTypes.FETCH_TEAM
});

export const fetchTeamFailure = data => ({
    type: actionTypes.FETCH_TEAM_FAILURE
});

export const fetchTeamSuccess = data => ({
    type: actionTypes.FETCH_TEAM_SUCCESS,
    payload: { data }
});

export const forgetPasswordInit = () => ({
    type: actionTypes.FORGET_PASSWORD
});

export const forgetPasswordFailure = data => ({
    type: actionTypes.FORGET_PASSWORD_FAILURE
});

export const forgetPasswordSuccess = data => ({
    type: actionTypes.FORGET_PASSWORD_SUCCESS,
    payload: { data }
});

export const resetPasswordInit = () => ({
    type: actionTypes.RESET_PASSWORD
});

export const resetPasswordFailure = data => ({
    type: actionTypes.RESET_PASSWORD_FAILURE
});

export const resetPasswordSuccess = data => ({
    type: actionTypes.RESET_PASSWORD_SUCCESS,
    payload: { data }
});

export const getAccountInit = () => ({
    type: actionTypes.GET_ACCOUNT
});

export const getAccountFailure = data => ({
    type: actionTypes.GET_ACCOUNT_FAILURE
});

export const getAccountSuccess = data => ({
    type: actionTypes.GET_ACCOUNT_SUCCESS,
    payload: { data }
});

export const getLocationInit = () => ({
    type: actionTypes.GET_LOCATION
});

export const getLocationFailure = data => ({
    type: actionTypes.GET_LOCATION_FAILURE
});

export const getLocationSuccess = data => ({
    type: actionTypes.GET_LOCATION_SUCCESS,
    payload: { data }
});

export const fetchCurrentUserInit = () => ({
    type: actionTypes.FETCH_CURRENT_USER
});

export const fetchCurrentUserFailure = data => ({
    type: actionTypes.FETCH_CURRENT_USER_FAILURE
});

export const fetchCurrentUserSuccess = data => ({
    type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
    payload: { data }
});

export const sendVerifyInit = () => ({
    type: actionTypes.SEND_VERIFY
});

export const sendVerifyFailure = data => ({
    type: actionTypes.SEND_VERIFY_FAILURE
});

export const sendVerifySuccess = data => ({
    type: actionTypes.SEND_VERIFY_SUCCESS,
    payload: { data }
});

export const resendVerifyInit = () => ({
    type: actionTypes.RESEND_VERIFY
});

export const resendVerifyFailure = data => ({
    type: actionTypes.RESEND_VERIFY_FAILURE
});

export const resendVerifySuccess = data => ({
    type: actionTypes.RESEND_VERIFY_SUCCESS,
    payload: { data }
});

export const userLoginInit = () => ({
    type: actionTypes.USER_LOGIN
});

export const userLoginFailure = data => ({
    type: actionTypes.USER_LOGIN_FAILURE
});

export const userLoginSuccess = data => ({
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: { data }
});

export const userAuthInit = () => ({
    type: actionTypes.USER_LOGIN
});

export const userAuthFailure = data => ({
    type: actionTypes.USER_LOGIN_FAILURE
});

export const userAuthSuccess = data => ({
    type: actionTypes.USER_LOGIN_SUCCESS,
    payload: { data }
});