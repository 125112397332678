import ResetPasswordPage from './reset-password.component';
import { connect } from 'react-redux';
import { resetPassword } from './../../api/user/userAction';

const mapDispatchToProps = {
    resetPassword
}

const mapStateToProps = state => ({
    isLoading: state.userPage.isLoading,
    passwordReseted: state.userPage.passwordReseted,
});

export const ResetPasswordContainer = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);