export const GET_SCHEDULES = "GET_SCHEDULES";
export const GET_SCHEDULES_FAILURE = "GET_SCHEDULES_FAILURE";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";

export const UPDATE_SCHEDULES = "UPDATE_SCHEDULES";
export const UPDATE_SCHEDULES_FAILURE = "UPDATE_SCHEDULES_FAILURE";
export const UPDATE_SCHEDULES_SUCCESS = "UPDATE_SCHEDULES_SUCCESS";

export const UPDATE_ONLY_SCHEDULES = "UPDATE_ONLY_SCHEDULES";
export const UPDATE_ONLY_SCHEDULES_FAILURE = "UPDATE_ONLY_SCHEDULES_FAILURE";
export const UPDATE_ONLY_SCHEDULES_SUCCESS = "UPDATE_ONLY_SCHEDULES_SUCCESS";
