import EmailVerifyPage from './email-verify.component';
import { connect } from 'react-redux';
import { sendVerifyEmail, resendVerifyEmail } from './../../api/user/userAction';

const mapDispatchToProps = {
    resendVerifyEmail,
    sendVerifyEmail
}

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser,
    isLoading: state.userPage.isLoading,
    isResend: state.userPage.isResend,
});

export const EmailVerifyContainer = connect(mapStateToProps, mapDispatchToProps)(EmailVerifyPage);