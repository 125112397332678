import { connect } from 'react-redux';
import { ThankYouPage } from './thank-you.component';
import { fetchUserSubscription } from './../../api/subscription/subscriptionAction';
import { fetchCurrentUser } from './../../api/user/userAction';

const mapDispatchToProps = {
    fetchUserSubscription,
    fetchCurrentUser
};

const mapStateToProps = state => ({
  userSubscribed: state.subscribePage.userSubscribed,
  sessionId: state.subscribePage.sessionId,
  isLoading: state.subscribePage.isLoading,
  selectedAccount: state.userPage.selectedAccount,
});

export const ThankYouContainer = connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
