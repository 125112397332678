import SignupIPage from './signup-i.component';
import { connect } from 'react-redux';
import { getJWTDetails, inviteUser } from './../../api/user/userAction';

const mapDispatchToProps = {
    getJWTDetails,
    inviteUser
}

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser,
    isLoading: state.userPage.isLoading,
    jwtDetails: state.userPage.jwtDetails
});

export const SignupIPageContainer = connect(mapStateToProps, mapDispatchToProps)(SignupIPage);