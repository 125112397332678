import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../shared/components/MDBox";
import MDTypography from "../../shared/components/MDTypography";
import { makeStyles } from '@material-ui/styles';
import ClearIcon from "@mui/icons-material/Clear";
import { Grid } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import MDButton from "../components/MDButton";
import DialogTitle from "@mui/material/DialogTitle";
import FormField from "../../shared/components/FormField";
import Typography from "@mui/material/Typography";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import choooseImage from "./../../assets/images/chooose.png";
import { CollectionPage } from "./collection-page";
import { createApi } from 'unsplash-js';

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
    selct: {
      '& .css-rjulde-MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select': {
          padding: "0px!important",
      },
      '& .css-ynad2y-MuiSvgIcon-root-MuiSelect-icon': {
          display: "block",
      },
    },
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const mdbox = {
    background: "#E6E8EB",
    height: "40px",
    width: "40px",
    borderRadius: "100px",
}
const box = {
    marginTop: "49px",
}

const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "inherit",
    fontSize: "12px",
    fontWeight: "700"
}

export function MediaLibraryPage(props) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [collection, setCollection] = useState("");
  const [collections, setCollections] = useState("");
  const [openCollections, setOpenCollections] = useState(false);
  const [search, setSearch] = useState("interior design");
  const [images, setImages] = useState([]);
  const [imageOpen, setImageOpen] = useState(false);
  const { open, accountId, medias, onClose, addMedias, getMedias, onSelect, mediaLoading, deleteMedia } = props;
  const onImageChange = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    )
    addMedias({ image: event.target.files[0], AccountId: accountId });
  };

  useEffect(() => {
    if(page){
      getMedias({id: accountId, offset: (page - 1) * 9, limit: 9, orderBy: "id", orderType: "DESC" });
    }
  }, [page]);

  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  function getCollectionById (id) {
    api.collections.getPhotos({ collectionId: id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }

  function unsplashSearch (x) {
    api.search
      .getCollections({ query: x, page: 1, perPage: 15})
      .then(result => {
        setCollections(result);
      })
      .catch(() => {
        console.log("something went wrong!");
      });
  }

  useEffect(() => {
    unsplashSearch("interior design");
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                 Media Library
                </MDTypography>
                <MDTypography style={topText} >
                  Choose from your uploaded ones
                </MDTypography>
              </MDBox>
              <MDBox>
                <ClearIcon  onClick={() => onClose()}
                  style={{ width: "32px", height: "32px" }} 
                />
              </MDBox>
            </MDBox>
            <MDBox>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Grid container spacing={2} sx={{ display: "flex", my: "20px" }}>
                    {medias && Array.isArray(medias.rows) && medias.rows.map(x => {
                      return (
                        <Grid item xs={4}>
                          <div
                            style={{ cursor: "pointer", width: "100%", height: "100px", backgroundImage: `url(${x.image})`, backgroundSize: "cover" }}
                          />
                          <div style={{ textAlign: "right" }}>
                            <IconButton size="small">
                              <DeleteForeverIcon color="primary" onClick={() => deleteMedia(x.id)}/>
                            </IconButton>
                            <IconButton size="small">
                              <ContentCopyIcon onClick={() => navigator.clipboard.writeText(x.image)}/>
                            </IconButton>
                            <IconButton size="small">
                              <CheckCircleIcon
                                color="success"
                                onClick={() => {
                                  onSelect("Image Library", x.image);
                                  onClose();
                                }}
                              />
                            </IconButton>
                          </div>
                        </Grid>
                      )
                    })}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <MDBox component="label" htmlFor="image-id" sx={{ p: "10px" }}>
                    <div style={{ width: "100%", height: "300px", display: "flex", justifyContent: "center", alignItems: "center",  border: "2px solid #eaeaea", borderRadius: "20px" }}>
                      {!mediaLoading ? <strong>Upload Image</strong> : <CircularProgress />}
                    </div>
                    <input style={{ display: "none" }} type="file" id="image-id" onChange={onImageChange} />
                  </MDBox>
                  <MDButton sx={{ mt: 1 }} color="primary" onClick={() => setOpenCollections(true)}>Unsplash Library</MDButton>
                </Grid>
              </Grid>
              <div style={{ textAlign: "right" }}>
                <IconButton size="small">
                  <ArrowBackIosIcon onClick={() => page !== 1 && setPage(page - 1)}/>
                </IconButton>
                <IconButton size="small">
                  <ArrowForwardIosIcon onClick={() => setPage(page + 1)}/>
                </IconButton>
              </div>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
      <CollectionPage
        images={images}
        open={imageOpen}
        onClose={() => {
            setImages([]);
            setImageOpen(false);
            setOpenCollections(false);
        }}
        onSelect={(files) => addMedias({ image: files, AccountId: accountId })}
      />
      <Dialog
            maxWidth="lg"
            fullWidth
            open={openCollections}
            onClose={() => {
              setOpenCollections(false);
              setCollections([]);
            }}
            aria-labelledby="responsive-dialog-title"
        >
            <MDBox
            style={{
                display: "grid",
                textAlign: "left",
                padding: "32px 12px",
            }}
            >
            <Grid container>
                <Grid item xs={10}>
                <DialogTitle id="responsive-dialog-title">
                    See and save our vast image library
                </DialogTitle>
                <MDTypography
                    style={{
                    color: "#7B809A",
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "18px",
                    marginTop: "-16px",
                    }}
                >
                    Here you can setup and manage your integration settings.
                </MDTypography>
                </Grid>
                <Grid item xs={2}>
                <MDBox
                    onClick={() => {
                      setOpenCollections(false);
                      setCollections([]);
                    }}
                    style={{
                    background: "#E6E8EB",
                    height: "40px",
                    width: "40px",
                    borderRadius: "100px",
                    float: "right",
                    marginTop: "24px",
                    marginRight: "24px",
                    }}
                >
                    <ClearIcon style={{ margin: "10px 10px" }} />
                </MDBox>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "5px" }}>
                <Grid item xs={8}>
                    <FormField
                        label="Search Image"
                        placeholder="search Image"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={4}>
                    <MDButton
                        style={{
                            background: "linear-gradient(180deg, #3E3D45 0%, #202020 100%)",
                            color: "#fff",
                            minWidth: "295px",
                        }}
                        variant="contained"
                        onClick={() => unsplashSearch(search)}
                    >
                        Search
                    </MDButton>
                </Grid>
                {collections && !collections.errors && collections.response && collections.response.results.map(x => {
                return (
                    <Grid
                        item
                        xs={12}
                        md={4}
                    >
                    <img onClick={() => {
                            setCollection(x);
                            getCollectionById(x.id);
                        }} src={ x.cover_photo.urls.small } style={{ width: "100%", height: "300px", objectFit:"cover" }} width="100%" />
                    {collection != null && collection.id == x.id && <img style={{ position: "relative", left: "calc(50% - 32px)", top: "-200px" }} src={choooseImage} />}
                    <Grid item>
                        <MDBox style={collection != null && collection.id == x.id ? { display: "flex", marginTop: "-64px" } : { display: "flex" }}>
                        <MDTypography
                            style={{
                            fontWeight: "600",
                            fontSize: "15px",
                            color: " #534F6B",
                            }}
                        >
                            {x.title}
                        </MDTypography>
                        </MDBox>
                    </Grid>
                    </Grid>
                )
                })}
            </Grid>
          </MDBox>
        </Dialog>
    </div>
  );
}
