import AddSocialPage from './addSocial.component';
import { connect } from 'react-redux';
import { addProfile, updateProfile, deleteProfile, getFBToken } from './../../../api/profile/profileAction';
import { fetchCurrentUser, getAccount, googleAccount, googleLocation, getLinkedinPage } from './../../../api/user/userAction';
import { fetchPackages } from './../../../api/subscription/subscriptionAction';

const mapDispatchToProps = {
    addProfile,
    fetchCurrentUser,
    updateProfile,
    deleteProfile,
    getAccount,
    getFBToken,
    googleAccount,
    googleLocation,
    getLinkedinPage,
    fetchPackages
}

const mapStateToProps = state => ({
    user: state.userPage.loggedUser,
    isLoading: state.profilePage.isLoading || state.userPage.isLoading,
    isSaved: state.profilePage.isSaved,
    isError: state.profilePage.isError,
    profiles: state.profilePage.profiles,
    fbToken: state.profilePage.fbToken,
    accounts: state.userPage.accounts,
    locations: state.userPage.locations,
    refreshToken: state.userPage.refreshToken,
    selectedAccount: state.userPage.selectedAccount,
    gmbAccounts: state.userPage.gmbAccounts,
    gmbLocations: state.userPage.gmbLocations,
    linkedinPages: state.userPage.linkedinPages,
    packages: state.subscribePage.packages
});

export const AddSocialPageContainer = connect(mapStateToProps, mapDispatchToProps)(AddSocialPage);