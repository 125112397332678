import { Grid } from "@mui/material";
import { useState } from "react";
// import DashboardNavbar1 from "./../../../shared/components/DashboardNavbar1";
import MDAvatar from "./../../../shared/components/MDAvatar";
import MDTypography from "./../../../shared/components/MDTypography";
import MDButton from "./../../../shared/components/MDButton";
import MDBox from "./../../../shared/components/MDBox";
import findPage from "./../../../assets/images/findPage.png";
import NewUser from "./../../../assets/images/New_user.png";
import { Link } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const bgcolor = {
  background: "none",
  boxShadow: "none",
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-active": {
    background: "#1B1C29",
    fill: "#1B1C29",
    stroke: "#1B1C29",
    borderColor: "#1B1C29",
    height: "16px",
    width: "16px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-completed": {
    background: "#1B1C29",
    fill: "#1B1C29",
    stroke: "#1B1C29",
    borderColor: "#1B1C29",
    height: "16px",
    width: "16px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root": {
    background: "#9B9CA3",
    fill: "#9B9CA3",
    stroke: "#9B9CA3",
    borderColor: "#9B9CA3",
    height: "16px",
    width: "16px",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-active": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-completed": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed": {
    color: "#1B1C29 !important",
  },
};

function getSteps() {
  return ["Add Business", "Select Theme", "Socials"];
}

export default function CreateBusinessPage() {
  const steps = getSteps();
  return (
    <Grid>
      <Grid
        item
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <MDBox sx={{ textAlign: "center", height: "auto", margin: "2% 0px" }}>
          <Stepper sx={bgcolor} activeStep={0} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <MDTypography style={{ color: "#09032b", fontSize: "36px", fontWeight: "700", marginTop: "122px" }}>Create Your First Workspace</MDTypography>
          <MDAvatar
            src={findPage}
            variant="square"
            style={{ height: "182px", width: "187px", margin: "0px auto", marginTop: "40px" }}
          />
          <MDTypography
            style={{
              color: "#2A3342",
              fontSize: "20px",
              fontWeight: "700",
              marginTop: "40px",
            }}
          >
            Tell us about your business to get started
          </MDTypography>
          <MDTypography
            style={{ color: "#556987", fontSize: "16px", fontWeight: "500" }}
          >
            This will help us create your social content 
          </MDTypography>
          <MDButton
            component={Link}
            to="/save-business"
            style={{
              background: "#09032B",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
              borderRadius: "8px",
              color: "#FFFFFF",
              textTransform: "capitalize",
              marginTop: "24px",
              fontWeight: "700", 
              fontSize: "12px"
            }}
            size="large"
          >
            Add Business
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );
}
