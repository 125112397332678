import React from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";

// img
import team3 from "./../../assets/images/team3.jpg";

// icon
import Icon from "@mui/material/Icon";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
// import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
// import DoneIcon from "@mui/icons-material/Done";

// mui
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
// import Stack from "@mui/material/Stack";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// MD
import MDBox from "../../shared/components/MDBox";
// import MDInput from "../../shared/components/MDInput";
import MDTypography from "../../shared/components/MDTypography";
import MDAvatar from "../../shared/components/MDAvatar";
import MDButton from "../../shared/components/MDButton";

export default function ConnectedMediaPage() {
  const topName = {
    color: "#7B809A",
    fontSize: "14px",
    fontWeight: "400",
  };
  const bottomName = {
    color: "#09032B",
    fontSize: "14px",
    fontWeight: "400",
  };
  // chip
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };
  // chip
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Account Settings</MenuItem>
      <Divider />
      <MenuItem onClick={closeMenu}>Edit Account Settings</MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card style={{ marginTop: "30px" }}>
        <MDBox p={2}>
          <Grid container>
            <Grid item sm={12} md={6} lg={6}>
              <MDTypography
                style={{
                  color: "#3a3555",
                  fontSize: "24px",
                  fontWeight: "600",
                }}
              >
                Business Account Settings
              </MDTypography>
            </Grid>
            <Grid item sm={12} md={6} lg={6}>
              <MDButton
                style={{ float: "right" }}
                variant={menu ? "contained" : "outlined"}
                color="dark"
                onClick={openMenu}
              >
                All Topics&nbsp;
                <Icon>keyboard_arrow_down</Icon>
              </MDButton>
              {renderMenu}
            </Grid>
          </Grid>
        </MDBox>
        <MDBox p={2}>
          <Grid container>
            <Grid item sm={12} md={2} lg={2}>
              <MDAvatar size="xxl" src={team3} />
            </Grid>
            <Grid item sm={12} md={10} lg={10} style={{ display: "block" }}>
              <MDButton
                variant="contained"
                style={{
                  color: "#fff",
                  backgroundColor: "#09032B",
                  maxWidth: "160px",
                  height: "36px",
                  marginTop: "20px",
                }}
              >
                Change Image
              </MDButton>
              <MDButton
                variant="text"
                style={{
                  maxWidth: "160px",
                  color: "#4b4764",
                  marginTop: "20px",
                }}
              >
                remove
              </MDButton>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item sm={12} md={4} lg={4}>
              <MDTypography style={topName}>Business Name</MDTypography>
              <MDTypography style={bottomName}>Company Y</MDTypography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <MDTypography style={topName}>Timezone</MDTypography>
              <MDTypography style={bottomName}>
                (GMT-04:00) Eastern Time - New York
              </MDTypography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item sm={12} md={4} lg={4}>
              <MDTypography style={topName}>Business Email</MDTypography>
              <MDTypography style={bottomName}>john@email.com</MDTypography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <MDTypography style={topName}>Brand Color</MDTypography>
              <MDBox style={{ display: "flex" }}>
                <MDBox
                  style={{
                    width: "27px",
                    height: "27px",
                    backgroundColor: "#e78231",
                    borderRadius: "12px",
                  }}
                />
                <MDTypography px={1} pt={0.5} style={bottomName}>
                  Yellow
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item sm={12} md={4} lg={4}>
              <MDTypography style={topName}>Industry</MDTypography>
              <MDBox style={{ display: "block" }}>
                <Chip label="Technology" onDelete={handleClick} />
                <Chip label="Design" onDelete={handleClick} />
              </MDBox>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <MDTypography style={topName}>Primary Color</MDTypography>
              <MDBox style={{ display: "flex" }}>
                <MDBox
                  style={{
                    width: "27px",
                    height: "27px",
                    backgroundColor: "#09032B",
                    borderRadius: "12px",
                  }}
                />
                <MDTypography px={1} pt={0.5} style={bottomName}>
                  Black
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item sm={12} md={4} lg={4}>
              <MDTypography style={topName}>Topic</MDTypography>
              <MDBox style={{ display: "block" }}>
                <Chip label="Technology" onDelete={handleClick} />
                <Chip label="Design" onDelete={handleClick} />
              </MDBox>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <MDTypography style={topName}>Custom Call To Action</MDTypography>
              <MDTypography style={bottomName}>
                Call us for a free chat
              </MDTypography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px" }}>
            <Grid item sm={12} md={4} lg={4}>
              <MDTypography style={topName}>Business Phone</MDTypography>
              <MDTypography style={bottomName}>+61 3 123 123</MDTypography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <MDTypography style={topName}>Brand Color</MDTypography>
              <MDBox style={{ display: "flex" }}>
                <MDBox
                  style={{
                    width: "27px",
                    height: "27px",
                    backgroundColor: "#3B82F6",
                    borderRadius: "12px",
                  }}
                />
                <MDTypography px={1} pt={0.5} style={bottomName}>
                  Blue
                </MDTypography>
              </MDBox>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "40px", marginBottom: "40px" }}>
            <Grid item sm={12} md={4} lg={4}>
              <MDTypography style={topName}>Business Location</MDTypography>
              <MDTypography style={bottomName}>
                1901 Thornridge Cir. Shiloh, Hawaii 81063
              </MDTypography>
            </Grid>
            <Grid item sm={12} md={8} lg={8}>
              <MDTypography style={topName}>Hashtags</MDTypography>
              <MDTypography style={bottomName}>
                #businesstags #newone #anotherone
              </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}
