import React, { useEffect} from 'react';
import Loading from './../../shared/Loading';
import { useNavigate, useSearchParams } from 'react-router-dom';

export function GoogleSuccessPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const rt = searchParams.get("rt");
  const at = searchParams.get("at");
  const navigate = useNavigate();
  sessionStorage.setItem("rt", rt);
  sessionStorage.setItem("at", at);
  const redirectUrl = sessionStorage.getItem("redirectUrl") != null ? sessionStorage.getItem("redirectUrl") : "/social-profiles";
  useEffect(() => {
    if (rt && at) {
      sessionStorage.removeItem("redirectUrl");
      navigate(redirectUrl);
    }
  }, [rt, at]);

  return (
    <div>
      <Loading loading={true} />
    </div>
  );
}