import React, { useEffect, useState } from "react";
import DashboardLayout from "./../../shared/components/DashboardLayout";
import DashboardNavbar from "./../../shared/components/DashboardNavbar";
// MUI
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
// MD
// import MDTypography from "./../../shared/components/MDTypography";
import breakpoints from "./../../assets/theme/base/breakpoints";
import MDButton from "./../../shared/components/MDButton";
import MDTypography from "../../shared/components/MDTypography";
// Image
import MDBox from "../../shared/components/MDBox";
// icons
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from '@mui/icons-material/Add';
import { preetyDate } from "./../../helper";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

//
import ContentEditorDialog from '../../shared/content-editor-dialog';
import { MediaLibraryPage } from "../../shared/media-library/media-library.component";
import { CollectionPage } from "../allContent/component/collection-page";
import { UpsertTagDialog } from "../allContent/component/upsert-tag-dialog";
import { createApi } from 'unsplash-js';
import { DeleteConfirm } from "../allContent/component/delete-confirm";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function InsightPage(props) {
  const {
    getInsights,
    insights,
    isLoading,
    selectedAccount,
    uploadBG,
    addContent,
    getContent,
    updateContent,
    deleteContent,
    fetchLayouts,
    getTags,
    addTags,
    getMedias,
    addMedias,
    deleteMedia,
    user,
    bgImg,
    layouts,
    content,
    isSaved,
    tags,
    tagSaved,
    medias,
    mediaSaved,
    mediaLoading,
    accounts
  } = props;
  const emptyContent = {
    AccountId: selectedAccount && selectedAccount.id,
    ProfileIds: [],
    content: {all: "", facebook: "", insta: "", linkedin: "", gmb: ""},
    layouts: "",
    images: undefined,
    tags: [],
    hashtags: {
      all: selectedAccount && selectedAccount.hashtags,
      facebook: selectedAccount && selectedAccount.hashtags,
      insta: selectedAccount && selectedAccount.hashtags,
      linkedin: selectedAccount && selectedAccount.hashtags,
      gmb: selectedAccount && selectedAccount.hashtags,
    },
    scTime: null,
    status: 1
  }
  const [datai, setDatai] = React.useState([]);
  // toggleButton

  // menu
  const [menu, setMenu] = React.useState(null);

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const [open, setOpen] = React.useState(false);
  const [menu1, setMenu1] = React.useState(null);
  const [openTag, setOpenTag] = useState(false);
  const [selectedTag, setSelectedTag] = useState({ name: "", status: 1 });
  const [accountOptions, setAccountOptions] = React.useState(true);
  const [selected, setSelected] = React.useState(emptyContent);
  const [profiles, setProfiles] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [dynamicFields, setDynamicFields] = React.useState({});
  const [collection, setCollection] = React.useState(selectedAccount && JSON.parse(selectedAccount.collection));
  const [mediaOpen, setMediaOpen] = React.useState(false);
  const [mediaBlankOpen, setMediaBlankOpen] = React.useState(false);
  const [mediaBlank, setMediaBlank] = React.useState(false);
  const [images, setImages] = React.useState([]);
  const [imageOpen, setImageOpen] = useState(false);
  const [selectedLayout, setSelectedLayout] = React.useState("");
  const openMenu1 = (event) => setMenu1(event.currentTarget);
  const closeMenu1 = () => setMenu1(null);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>
        Facebook <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Instagram <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Linkedin <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu}>
        Google Business <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  const renderMenu1 = (
    <Menu
      anchorEl={menu1}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu1)}
      onClose={closeMenu1}
      keepMounted
    >
      <MenuItem onClick={closeMenu1}>
        Newest <Checkbox sx={{ ml: "auto" }} />
      </MenuItem>
      <MenuItem onClick={closeMenu1}>
        Oldest <Checkbox defaultChecked sx={{ ml: "auto" }} />
      </MenuItem>
    </Menu>
  );
  // menu
  // app bar
  const [tabsOrientation, setTabsOrientation] = React.useState("horizontal");
  const [tabValue, setTabValue] = React.useState(0);

  function withOutTime(da) {
    var d = new Date(da);
    d.setHours(0, 0, 0, 0);
    return d.getTime();
  }

  React.useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    if(selectedAccount && selectedAccount.id){
      getInsights(selectedAccount.id);
    }
  }, [selectedAccount]);

  // useEffect(async () => {
  //   if(insights) {
  //     let dates = [];
  //     if(Array.isArray(insights.fb.insight.data)) {
  //       const a = await Promise.all(insights.fb.insight.data.map(async (x, i) => {
  //         return await Promise.all(x.values.map((y, j) => {
  //           const s = dates.filter(z => {
  //             return withOutTime(z.date) === withOutTime(y.end_time)
  //           })
  //           if(s.length === 0) {
  //             let val = {facebook: { profile: insights.fb.profile }, insta: {}};
  //             val["facebook"][x.name] = y.value
  //             dates = dates.concat([{date: y.end_time, val}]);
  //           } else {
  //             s[0]["val"]["facebook"][x.name] = y.value;
  //             let index = dates.findIndex( x => withOutTime(x.date) === withOutTime(y.end_time));
  //             dates[index] = s;
  //           }
  //           return true;
  //         }));
  //       }));
  //     }
  //     if(Array.isArray(insights.insta.insight.data)) {
  //       const b = await Promise.all(insights.insta.insight.data.map(async (xa, ia) => {
  //         return await Promise.all(xa.values.map((ya, ja) => {
  //           const sa = dates.filter(za => {
  //             return withOutTime(za.date) === withOutTime(ya.end_time)
  //           })
  //           if(sa.length === 0) {
  //             let val = {insta: { profile: insights.insta.profile }, facebook: {}};
  //             val["insta"][xa.name] = ya.value;
  //             dates = dates.concat([{date: ya.end_time, val}]);
  //           } else {
  //             sa[0]["val"]["insta"][xa.name] = ya.value;
  //             let index = dates.findIndex( xa => withOutTime(xa.date) === withOutTime(ya.end_time));
  //             dates[index] = sa;
  //           }
  //           return true;
  //         }));
  //       }))
  //     }
  //     // setDatai(dates);
  //   }
  // }, [insights]);
  // app bar

  useEffect(() => {
    if (mediaSaved) {
      getMedias({ id: selectedAccount && selectedAccount.id, limit: 9, offset: 0, orderBy: "id", orderType: "DESC" });
    }
  }, [mediaSaved]);

  useEffect(async () => {
    if (accounts.length > 0) {
      const x = await Promise.all(accounts.map(y => {
        return {id: y.id, label: y.name}
      }))
      setAccountOptions(x);
    }
  }, [accounts]);

  useEffect(() => {
    if(bgImg) {
      setDynamics("Image Library", bgImg);
    }
  }, [bgImg]);

  const api = createApi({
    accessKey: "r-_TgsNnHJJMn0xnfdCD0ZO1vqQM79WpS6_bq-D1ZzQ"
  });

  function getCollectionById () {
    api.collections.getPhotos({ collectionId: collection && collection.id, page: 1, perPage: 24 })
    .then(res => {
      setImages(res);
      setImageOpen(true);
    })
    .catch(() => {
      console.log("Something went wrong");
    })
  }

  function setDynamics(x, v) {
    let obj = {};
    obj[x] = v;
    setDynamicFields({ ...dynamicFields, ...obj });
  }
  const getProfiles = async () => {
    if (selectedAccount &&
      Array.isArray(selectedAccount.Profiles)) {
        const y = await Promise.all(selectedAccount.Profiles.map(x => {
                    return { 
                      id: x.id,
                      type: x.type,
                      label: `${x.type} (${x.name})`,
                      tags: x.hashtags
                      }
                  }));
      setProfiles(y);
      return;
    }
    setProfiles([]);
    return;
  }
  useEffect(() => {
      getProfiles();
      getTags();
      getMedias({ id: selectedAccount && selectedAccount.id, limit: 24, offset: 0, orderBy: "id", orderType: "DESC" });
  }, []);

  function returnProfileHashtags(type, tags) {
    if (profiles && Array.isArray(profiles)) {
      const x = profiles.filter(y => y.type == type);
      if (x.length > 0) {
        return `${tags} ${x[0].tags ? x[0].tags : ""}`.split(" ");
      }
      return tags.split(" ");
    }
    return tags.split(" ");
  }

  function setHashtags() {
    fetchLayouts(selectedAccount && selectedAccount.ThemeId);
    setSelected({
      ...selected,
      hashtags: {
        ...selected.hashtags,
        all: returnProfileHashtags("all", selected.hashtags.all ? selected.hashtags.all : ""),
        facebook: returnProfileHashtags("facebook", selected.hashtags.all ? selected.hashtags.all : ""),
        insta: returnProfileHashtags("insta", selected.hashtags.all ? selected.hashtags.all : ""),
        linkedin: returnProfileHashtags("linkedin", selected.hashtags.all ? selected.hashtags.all : ""),
        gmb: returnProfileHashtags("gmb", selected.hashtags.all ? selected.hashtags.all : ""),
      }
    });
    setOpen(true);
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        <Grid item style={{marginLeft: "auto"}} >
          <RefreshIcon
            style={{
              backgroundColor: "#E6E6EA",
              height: "30px",
              width: "30px",
              borderRadius: "8px",
              color: "#000",
              padding: "6px 6px",
              marginBottom: "-8px",
            }}
          />
          <MDButton sx={{ marginLeft: "16px" }}
            variant={menu ? "contained" : "outlined"}
            color="dark"
            onClick={openMenu}
          >
            Filter&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu}
          <MDButton
            sx={{ marginLeft: "16px" }}
            variant={menu1 ? "contained" : "outlined"}
            color="dark"
            onClick={openMenu1}
          >
            Sort By&nbsp;
            <Icon>keyboard_arrow_down</Icon>
          </MDButton>
          {renderMenu1}
          <MDButton
            style={{
              background: "linear-gradient(#09032B, #312B56)",
              color: "#fff",
              marginLeft: "16px",
              fontWeight: "700",
              fontSize: "12px",
              textTransform: "capitalize"
            }}
            onClick={() => setHashtags()}
          ><AddIcon style={{marginRight: "10px"}} />
            Create content
          </MDButton>
        </Grid>
      </Grid>
      <DeleteConfirm
          deleteContent={deleteContent}
          open={deleteOpen}
          selected={selected}
          onClose={() => {
          setSelected(emptyContent);
          setDeleteOpen(false);
          }}
      />
      <ContentEditorDialog
          setOpenTag={setOpenTag}
          tags={tags}
          selected={selected}
          setSelected={setSelected}
          open={open}
          onClose={() => {
          setSelected(emptyContent);
          setDynamicFields({});
          setOpen(false);
          }}
          accounts={accountOptions}
          uploadBG={uploadBG}
          addContent={addContent}
          updateContent={updateContent}
          profilesOpt={profiles}
          layouts={layouts}
          openUnsplash={() => {
          getCollectionById();
          setImageOpen(true);
          }}
          selectedAccount={selectedAccount}
          dynamicFields={dynamicFields}
          setDynamics={setDynamics}
          setDynamicFields={setDynamicFields}
          setMediaOpen={setMediaOpen}
          selectedLayout={selectedLayout}
          setSelectedLayout={setSelectedLayout}
          setMediaBlank={setMediaBlank}
          setMediaBlankOpen={setMediaBlankOpen}
          mediaBlank={mediaBlank}
      />
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaOpen}
        onClose={() => setMediaOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={setDynamics}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <MediaLibraryPage
        mediaLoading={mediaLoading}
        open={mediaBlankOpen}
        onClose={() => setMediaBlankOpen(false)}
        medias={medias}
        accountId={selectedAccount.id}
        addMedias={addMedias}
        onSelect={(a, b) => {
          setSelected({
            ...selected,
            layouts: ""
          });
          setDynamicFields({});
          setSelectedLayout("");
          setSelected({ ...selected, images: b });
          setMediaBlank(b);
        }}
        getMedias={getMedias}
        deleteMedia={deleteMedia}
      />
      <CollectionPage
          images={images}
          open={imageOpen}
          onClose={() => {
          setImages([]);
          setImageOpen(false);
          }}
          setSelected={setSelected}
          selected={selected}
          setDynamics={setDynamics}
      />
      <UpsertTagDialog
          addTags={addTags}
          open={openTag}
          onClose={() => {
          setSelectedTag({ name: "", status: 1 });
          setOpenTag(false);
          }}
          selected={selectedTag}
          setSelected={setSelectedTag}
      />
      {insights && insights.map((x, i) => {
          return (
            <Card style={{ marginTop: "24px", padding: "20px 20px" }}>
              <MDTypography
                style={{ color: "#000", fontSize: "20px", fontWeight: "700" }}
              >
                {preetyDate(x.updatedAt)}
              </MDTypography>
              <Grid container spacing={2} style={{marginTop: "24px"}}>
                <Grid item sm={12} md={2} lg={2}>
                  <img
                    style={{ width: "100%" }}
                    src={JSON.parse(x.images)[0].image}
                  />
                </Grid>
                <Grid item sm={12} md={8} lg={8}>
                  <MDBox display="flex">
                    <MDBox>
                      <MDTypography
                        style={{
                          color: "#7B809A",
                          fontSize: "10px",
                          fontWeight: "700",
                        }}
                      >
                        STATUS
                      </MDTypography>
                      <MDBox style={{ marginTop: "16px", marginLeft: "21px" }}>
                        <MDTypography
                          style={{
                            color: x.status == 6 ? "#534F6B" : "#e71d35",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                        >
                          {x.status == 6 ? (
                            <CheckCircleOutlineRoundedIcon
                            style={{
                              color: "#41B853",
                              height: "20px",
                              width: "20px",
                              marginLeft: "-23px",
                              marginBottom: "-15px",
                            }}
                          />
                          ) : (
                            <HighlightOffIcon
                            style={{
                              color: "#e71d35",
                              height: "20px",
                              width: "20px",
                              marginLeft: "-23px",
                              marginBottom: "-15px",
                            }}/>
                          )}
                          {" "}
                          {x.status == 6 ? 'Published' : 'Failed'}
                        </MDTypography>
                        <MDTypography
                          style={{
                            color: "#7B809A",
                            fontSize: "12px",
                            fontWeight: "400",
                          }}
                        >
                          {preetyDate(x.updatedAt)}
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox style={{ marginLeft: "50px" }}>
                      <MDTypography
                        style={{
                          color: "#7B809A",
                          fontSize: "10px",
                          fontWeight: "700",
                        }}
                      >
                        PLATFORMS
                      </MDTypography>
                      <MDBox display="flex" style={{ marginTop: "16px" }}>
                        {x.Insights && x.Insights.map(x => {
                          return (
                            <MDBox
                              style={{
                                backgroundColor: "#F3F3F3",
                                height: "40px",
                                width: "40px",
                                borderRadius: "100px",
                                marginRight: "2px"
                              }}
                            >
                              {x.type === "insta" ? (
                                <InstagramIcon
                                style={{
                                  color: "#000",
                                  height: "16px",
                                  width: "16px",
                                  margin: "12px 12px",
                                }}
                              />
                              ) : x.type === "facebook" ? (
                                <FacebookIcon
                                  style={{
                                    color: "#000",
                                    height: "17px",
                                    width: "17px",
                                    margin: "12px 12px",
                                  }}
                                />
                              ) : (
                                <LinkedInIcon
                                  style={{
                                    color: "#000",
                                    height: "17px",
                                    width: "17px",
                                    margin: "12px 12px",
                                  }}
                                />
                              )}
                            </MDBox>
                          )
                        })}
                      </MDBox>
                    </MDBox>
                  </MDBox>
                  <MDBox style={{ marginTop: "12px" }}>
                    <MDTypography
                      style={{
                        color: "#7b809a",
                        fontSize: "10px",
                        fontWeight: "700",
                      }}
                    >
                      CAPTION
                    </MDTypography>
                    <MDTypography
                      style={{
                        color: "#344767",
                        fontSize: "14px",
                        fontWeight: "400",
                        maxWidth: "390px",
                        marginTop: "12px",
                      }}
                    >
                      {JSON.parse(x.content).all}
                    </MDTypography>
                  </MDBox>
                </Grid>
                <Grid item sm={12} md={2} lg={2}>
                  
                </Grid>
              </Grid>
              {x.status == 6 && (
              <Grid container>
                <Grid item sm={12} md={12} lg={12} style={{ marginTop: "40px", overflow: "auto" }}>
                  <TableContainer component={Paper}>
                    <Table>
                        <TableRow>
                          <TableCell sx={{ width: "30%" }}>Account</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>Analytics</TableCell>
                        </TableRow>
                      <TableBody>
                        {x.Insights && x.Insights.map(x => {
                          return (
                            <TableRow>
                              <TableCell sx={{ width: "30%" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <MDBox
                                    style={{
                                      backgroundColor: "#F3F3F3",
                                      height: "40px",
                                      width: "40px",
                                      borderRadius: "100px",
                                      marginRight: "5px"
                                    }}
                                  >
                                    {x.type === "insta" ? (
                                      <InstagramIcon
                                      style={{
                                        color: "#000",
                                        height: "16px",
                                        width: "16px",
                                        margin: "12px 12px",
                                      }}
                                    />
                                    ) : x.type === "facebook" ? (
                                      <FacebookIcon
                                        style={{
                                          color: "#000",
                                          height: "17px",
                                          width: "17px",
                                          margin: "12px 12px",
                                        }}
                                      />
                                    ) : (
                                      <LinkedInIcon
                                        style={{
                                          color: "#000",
                                          height: "17px",
                                          width: "17px",
                                          margin: "12px 12px",
                                        }}
                                      />
                                    )}
                                  </MDBox>
                                  <MDTypography sx={{ fontSize: "16px", color: "#221C40" }}>{x.Profile.name}</MDTypography>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <MDTypography style={{ fontSize: "16px", color: "#41B853" }} >Published</MDTypography>&nbsp;|&nbsp;
                                  <a href="#" style={{ fontSize: "16px" }}>Live</a>
                                </div>
                              </TableCell>
                              <TableCell>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <MDTypography sx={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold", color: "#221C40" }}>0</MDTypography>
                                <ThumbUpOutlinedIcon sx={{ color: "#41B853", marginRight: "20px" }}/>
                                <MDTypography sx={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold", color: "#221C40" }}>0</MDTypography>
                                <ShareOutlinedIcon sx={{ color: "#FB8C00", marginRight: "20px" }}/>
                                <MDTypography sx={{ marginRight: "10px", fontSize: "16px", fontWeight: "bold", color: "#5E6D84" }}>0</MDTypography>
                                <ChatBubbleOutlineOutlinedIcon sx={{ color: "#FB8C00", marginRight: "20px" }} />
                              </div>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              )}
            </Card>
          )
      })}
      {/* <Grid
        container
        display="flex"
        style={{ margin: "20px" }} >
        <Grid item sm={12} md={6} lg={6}>
          <MDTypography
            style={{ color: "7b809a", fontSize: "14px", fontWeight: "400" }}
          >
            Showing 1 to 10 of 12 entries
          </MDTypography>
        </Grid>
        <Grid item sm={12} md={6} lg={6}  >
          <Pagination count={4} color="info" style={{float: "right"}} />
        </Grid>
      </Grid> */}
    </DashboardLayout>
  );
}
