import LoginPage from './login.component';
import { connect } from 'react-redux';
import { userLogin, userAuth, selectAccount } from './../../api/user/userAction';

const mapDispatchToProps = {
    userLogin,
    userAuth,
    selectAccount
}

const mapStateToProps = state => ({
    isLoggedIn: state.userPage.isLoggedIn,
    user: state.userPage.loggedUser,
    isLoading: state.userPage.isLoading,
});

export const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);