import * as actionType from "./themeActionType";

const initialState = {
    isError: false,
    isLoading: false,
    isSaved: false,
    bgImg: null,
    layouts: [],
    themes: []
}

export const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.UPLOAD_BG:
        return {
            ...state,
            isError: false,
            isLoading: true,
        };
        case actionType.UPLOAD_BG_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.UPLOAD_BG_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                bgImg: action.payload ? action.payload.data.image : null,
            };
        case actionType.FETCH_LAYOUTS:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_LAYOUTS_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_LAYOUTS_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                layouts: action.payload ? action.payload.data.rows : [],
            };
        case actionType.FETCH_THEMES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.FETCH_THEMES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.FETCH_THEMES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                themes: action.payload ? action.payload.data.rows : [],
            };
                default: 
            return state
    }
}