import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from '@material-ui/styles';
import MDButton from "../../../shared/components/MDButton";
import ringImage from "../../../assets/images/billing.png";
import ClearIcon from "@mui/icons-material/Clear";

const useStyles = makeStyles({
  closeBtn: {
    position: "relative",
    cursor: "pointer",
    fontSize: "24px",
    color: "#AEAEAE",
  },
});

const mdbox = {
  background: "#E6E8EB",
  height: "40px",
  width: "40px",
  borderRadius: "100px",
  float: "right"
}

export default function SubscriptionDialog(props) {
  const classes = useStyles();
  const { open, setOpen } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogContent>
            <MDBox p={2}>
              <MDBox style={mdbox} >
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ margin: "12px 12px" }} 
                />
              </MDBox>
                <MDBox
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "31px",
                    }}
                    >
                    <img src={ringImage} alt="Lock logo" />
                </MDBox>
                <MDBox
                    style={{
                        textAlign: "center",
                        marginTop: "51px",
                    }}
                    >
                    <MDTypography
                        style={{ fontWeight: "700", fontSize: "20px", color: "#344767" }}
                    >
                        Your Account is Locked
                    </MDTypography>
                    <MDTypography
                        style={{ fontWeight: "400", fontSize: "14px", color: "#7B809A", marginTop: "4px" }}
                    >
                        Your account has been locked for making 10 number of failed payments.
                    </MDTypography>
                </MDBox>
                <MDBox
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "24px",
                    }}
                    >
                    <MDButton
                        style={{
                        background: "#09032B",
                        color: "#FFFFFF",
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                        borderRadius: "8px",
                        width: "355px",
                        fontWeight: "700",
                        fontSize: "12px"
                        }}
                        fullWidth
                    >
                        Try with Another Account
                    </MDButton>
                </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
