import React, { useEffect, useState } from "react";
// import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import MDAvatar from "./../../shared/components/MDAvatar";
import MDTypography from "./../../shared/components/MDTypography";
import MDBox from "./../../shared/components/MDBox";
import MDButton from "./../../shared/components/MDButton";
import Footer from "./../../shared/components/Footer";
import FormField from "./../../shared/components/FormField";
import logo from "./../../assets/images/logo-ct-dark.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import googleImage from "./../../assets/images/googleLogo.png";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import { makeStyles } from '@material-ui/styles';
import Loading from './../../shared/Loading';

const useStyles = makeStyles({
  listText: {
    '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
      {
        color: "#333F51",
        fontWeight: "700",
        fontSize: "12px",
      },
  },
});

const googleBtn = {
  backgroundColor: "#F1F1F1", 
  borderRadius: "8px", 
  color: "#1B172E", 
  fontWeight: "700", 
  fontSize: "14px",
  textTransform: "inherit",
}
const forgotBtn = {
  fontWeight: "700", 
  fontSize: "12px", 
  color: "#09032B", 
  marginLeft: "auto", 
  textTransform: "inherit"
}

export default function ForgetPasswordPage(props) {
  const { forgetPassword, isLoading, mailSent } = props;
  const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const mdText2 = {
    color: "red",
    marginTop: "-14px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };
  const [values, setValues] = useState({
    username: ""
  });

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const submitHandle = (event) => {
    event.preventDefault();
    setErrors(validate(values));
  };
  const validate = (values) => {
    let errorss = {};
    let isError = false;
    const regex = /\S+@\S+\.\S+/;
    if (!values.username) {
      isError = true;
      errorss.username = "Email is required";
    } else if (!regex.test(values.username)) {
      isError = true;
      errorss.username = "This is not a valid email format";
    }
    if (!isError) {
      forgetPassword(values);
    }
    return errorss;
  };

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <Grid style={{ backgroundColor: "#101030f0", minHeight: "100vh" }}>
        <Grid
          item
          style={{ display: "flex", paddingLeft: "45px", paddingTop: "32px" }}
        >
          <MDAvatar
            src={logo}
            variant="square"
            style={{ width: "13px", height: "22px", marginTop: "5px" }}
          />
          &nbsp;&nbsp;
          <MDTypography style={{ color: "white" }}>Social Broker</MDTypography>
        </Grid>
        <Grid item style={{ display: "flex", justifyContent: "center" }}>
          <MDBox
            style={{
              backgroundColor: "white",
              width: "469px",
              height: "auto",
              padding: "0px 25px",
              borderRadius: "12px",
              marginTop: "149px",
            }}
            // onSubmit={handleSubmit(onSubmit)}
          >
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "25px",
                  fontWeight: "700",
                  marginTop: "40px",
                }}
              >
                Forgot Password?
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "16px",
                  fontWeight: "700",
                  marginTop: "5px",
                }}
              >
                Please enter your registered email id to reset your password
              </MDTypography>
            </MDBox>
            <form>
              <MDBox mb={2} style={{ marginTop: "24px" }}>
                <FormField
                  label="Email"
                  name="username"
                  placeholder="john@email.com"
                  type="email"
                  value={values.username}
                  onChange={handleChange}
                />
                {errors.username && (
                <MDTypography style={mdText1}>{errors.username}</MDTypography>
              )}
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  onClick={submitHandle}
                  style={{ marginBottom: "40px", backgroundColor: "#09032B", color: "#F0FDF4", fontWeight: "700", fontSize: "16px", textTransform: "capitalize" }}
                  size="large"
                  fullWidth
                  type="submit"
                >
                  Send Email
                </MDButton>
              </MDBox>
            </form>
          </MDBox>
        </Grid>
        <Grid item style={{ marginTop: "5%" }}>
          <Footer />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
