import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../shared/components/MDBox";
import MDTypography from "../../../shared/components/MDTypography";
import { makeStyles } from '@material-ui/styles';
import MDButton from "../../../shared/components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import FormField from "../../../shared/components/FormField";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
    form: {
      '& .MuiInputBase-adornedStart': {
        flexWrap: "wrap"
      }
    }
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const box = {
    marginTop: "50px",
}
const mdButton = {
    color: "#6B6880",
    marginRight: "16px",
    borderColor: "#6B6880",
    borderRadius: "8px",
    width: "120px",
    height: "36px",
    textTransform: "capitalize",
}
const button = {
    background: "linear-gradient(180.67deg, #09032B 2.43%, #312B56 101%)",
    width: "120px",
    height: "36px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "capitalize",
}
const mdText1 = {
    color: "red",
    marginTop: "-0px",
    marginBottom: "14px",
    fontSize: "12px",
    fontWeight: 400,
  };

  const Tags = ({ data, handleDelete }) => {
    return (
      <MDBox
        sx={{
          background: "#283240",
          height: "100%",
          display: "flex",
          padding: "5px 10px",
          margin: "0.2rem",
          justifyContent: "center",
          alignContent: "center",
          color: "#ffffff",
          borderRadius: "10px"
        }}
      >
        <Stack direction='row' gap={1}>
          <Typography sx={{ fontSize: "12px" }}>{data}</Typography>
          <Cancel
            sx={{ cursor: "pointer" }}
            onClick={() => {
              handleDelete(data);
            }}
          />
        </Stack>
      </MDBox>
    );
  };

export default function BusinessEditDialog(props) {
  const classes = useStyles();
  const tagRef = useRef();
  const { open, setOpen, values, setValues, errors, submitHandle, fetchTopics, topics, industries } = props;
  const [checked, setChecked] = React.useState(false);
  const [address, setAddress] = React.useState(values && values.location);
  const [coordinates, setCoordinates] = React.useState({
    lat: null,
    lng: null
  });

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleDelete = (value) => {
    const newtags = values.hashtags.filter((val) => val !== value);
    setValues({...values, hashtags: newtags});
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let val = "";
    if (tagRef.current.value.trim() != "") {
      if (/^#/i.test(tagRef.current.value)) {
        val = tagRef.current.value;
      } else {
        val = "#" + tagRef.current.value;
      }
      setValues({...values, hashtags: [...values.hashtags, val]});
      tagRef.current.value = "";
    }
  };

  const handleSelect = async value => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setValues({
      ...values,
      location: value,
    })
    setCoordinates(latLng);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
            <MDBox p={2}>
                <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
                    <MDBox>
                        <MDTypography style={topHead} >
                            Business Details
                        </MDTypography>
                        <MDTypography style={topText} >
                            You can update your business Information
                        </MDTypography>
                    </MDBox>
                    <MDBox>
                        <ClearIcon  onClick={() => setOpen(false)}
                        style={{ width: "32px", height: "32px" }} 
                        />
                    </MDBox>
                </MDBox>
                <Grid container spacing={3} style={{marginTop: "25px"}}>
                    <Grid item xs={12} md={6}>
                    <FormField
                        label="Business name"
                        name="name"
                        placeholder="Company Y"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                      />
                      {errors.name && (
                        <MDTypography style={mdText1}>
                          {errors.name}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                      <div>
                        <FormField label='Address' {...getInputProps({ placeholder: "Type address" })} />
                        <div
                          style={suggestions.length > 0 ?
                            { position: 'absolute', zIndex: 9999, backgroundColor: '#fff', padding: '15px', border: '1px solid #eaeaea', minWidth: '200px' }
                          : { display: 'none' }
                          }>
                          {loading ? <div>...loading</div> : null}

                          {suggestions.map(suggestion => {
                            const style = {
                              backgroundColor: suggestion.active ? "#000" : "#fff",
                              color: suggestion.active ? "#fff" : "#000",
                              padding: '10px 10px',
                              fontSize: "12px"
                            };

                            return (
                              <div {...getSuggestionItemProps(suggestion, { style })}>
                                {suggestion.description}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                      {errors.location && (
                        <MDTypography style={mdText1}>
                          {errors.location}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        options={Array.isArray(industries) ? industries : []}
                        value={values.industries}
                        onChange={(event, newValue) => {
                          setValues({
                            ...values,
                            industries: newValue,
                            topics:[]
                          });
                          fetchTopics(newValue);
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Choose Industry"
                            name="buisnessIndustry"
                            placeholder="Select Industry"
                            InputLabelProps={{ shrink: true }}
                            multiple
                          />
                        )}
                      />
                      {errors.industries && (
                        <MDTypography style={mdText1}>
                          {errors.industries}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <Autocomplete
                        multiple
                        options={Array.isArray(topics) ? topics : []}
                        value={values.topics}
                        onChange={(event, newValue) => {
                          console.log("New values: ", newValue);
                          setValues({
                            ...values,
                            topics: newValue,
                          });
                        }}
                        renderInput={(params) => (
                          <FormField
                            {...params}
                            label="Topic"
                            name="topics"
                            placeholder="Select topic"
                            InputLabelProps={{ shrink: true }}
                            multiple
                          />
                        )}
                      />
                      {errors.topics && (
                        <MDTypography style={mdText1}>
                          {errors.topics}
                        </MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormField
                        label="Business Phone"
                        name="phone"
                        placeholder="+61 3 123 123"
                        type="number"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      {errors.phone && (
                        <MDTypography style={mdText1}>{errors.phone}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormField
                        label="Business Email"
                        name="email"
                        placeholder="john@email.com"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                      />
                      {errors.email && (
                        <MDTypography style={mdText1}>{errors.email}</MDTypography>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                    <FormField
                        label="Call to action"
                        name="cta"
                        placeholder="Call to action"
                        type="text"
                        value={values.cta}
                        onChange={handleChange}
                      />
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                    <form className={classes.form} onSubmit={e => handleOnSubmit(e)}>
                        <TextField
                          inputRef={tagRef}
                          fullWidth
                          disabled={values && values.hashtags && values.hashtags.length >= 30}
                          variant='standard'
                          size='small'
                          sx={{ margin: "1rem 0", flexWrap: "wrap" }}
                          margin='none'
                          placeholder={values && values.hashtags && values.hashtags.length < 30 ? "Enter hashtags" : ""}
                          InputProps={{
                            startAdornment: (
                              <React.Fragment>
                                {values && Array.isArray(values.hashtags) && values.hashtags.map((data, index) => {
                                  return (
                                    <Tags data={data} handleDelete={handleDelete} key={index} />
                                  );
                                })}
                              </React.Fragment>
                            ),
                          }}
                        />
                      </form>
                    </Grid>
                </Grid>
                <MDBox style={box} >
                    <MDButton variant="outlined" style={mdButton} >
                        Cancel
                    </MDButton>
                    <MDButton style={button} onClick={() => submitHandle()} variant="contained" >
                        Save
                    </MDButton>
                </MDBox>
            </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
