import * as actionType from "./scheduleActionType";

const initialState = {
    isError: false,
    isLoading: false,
    schedules: [],
    reload: false
}

export const scheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_SCHEDULES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.GET_SCHEDULES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.GET_SCHEDULES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                schedules: action.payload ? action.payload.data.rows : [],
            };
        case actionType.UPDATE_SCHEDULES:
            return {
                ...state,
                isError: false,
                isLoading: true,
                reload: false
            };
        case actionType.UPDATE_SCHEDULES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
                reload: false
            };
        case actionType.UPDATE_SCHEDULES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false,
                schedules: action.payload ? action.payload.data.rows : [],
                reload: true
            };
        case actionType.UPDATE_ONLY_SCHEDULES:
            return {
                ...state,
                isError: false,
                isLoading: true,
            };
        case actionType.UPDATE_ONLY_SCHEDULES_FAILURE:
            return {
                ...state,
                isError: true,
                isLoading: false,
            };
        case actionType.UPDATE_ONLY_SCHEDULES_SUCCESS:
            return {
                ...state,
                isError: false,
                isLoading: false
            };
        default: 
            return state
    }
}