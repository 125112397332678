import React, { useState, useEffect, useRef } from "react";
import DashboardLayout from "../../shared/components/DashboardLayout";
import DashboardNavbar from "../../shared/components/DashboardNavbar";
// icon
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import DeleteIcon from '@mui/icons-material/Delete';
// import DoneIcon from "@mui/icons-material/Done";
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

// mui
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import Tooltip from '@mui/material/Tooltip';

// MD
import MDBox from "../../shared/components/MDBox";
// import MDInput from "../../shared/components/MDInput";
import MDTypography from "../../shared/components/MDTypography";
import MDAvatar from "../../shared/components/MDAvatar";
import MDButton from "../../shared/components/MDButton";

import { useInitFbSDK } from "./../../hooks/facebook-hooks";

import SelectPagesDialog from "./component/select-pages-dialog";
import GooglePagesDialog from "./component/google-business-dialog";
import Loading from './../../shared/Loading';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteConfirm } from './component/delete-confirm';

const tooltipText = {
  background: "#35363E",
  backdropFilter: "blur(240px)", 
  borderRadius: "8px",
  color: "rgba(255, 255, 255, 0.87)", 
  fontWeight: "400", 
  fontSize: "12px", 
  padding: "8px",
  opacity: 0.9
}

export default function ConnectMediaPage(props) {
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  const { getLinkedinPage, linkedinPages, gmbAccounts, gmbLocations, googleAccount, googleLocation, getFBToken, fbToken, selectedAccount, isLoading, user, addProfile, fetchCurrentUser, profiles, isSaved, updateProfile, deleteProfile } = props;
  const X = 34;
  const Y = -34;
  const translat = {
    transform: `translate(${X}px, ${Y}px)`,
  };

  const emptyProfile = {
    AccountId: selectedAccount.id,
    type: "",
    name: "",
    email: "",
    pageId: "",
    refreshToken: "",
    accessToken: "",
    picture: "",
    custom: JSON.stringify({}),
    cta: "",
    hashtags: [],
    firstComment: 0,
    status: 1,
  }
  
  const isFbSDKInitialized = useInitFbSDK();
  const [fbUserAccessToken, setFbUserAccessToken] = useState();
  const [fbUserId, setFbUserId] = useState();
  const [facebookPages, setFacebookPages] = useState([]);
  const [instaPages, setInstaPages] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [type, setType] = useState(false);
  const [openG, setOpenG] = useState(false);
  const [selected, setSelected] = useState(emptyProfile);
  const [errors, setErrors] = useState({});
  const [showType, setShowType] = useState("fb");

  const submitHandle = () => {
    setErrors(validate(selected));
  };
  const validate = (selected) => {
    let errorss = {};
    let isError = false;
    if (!selected.pageId) {
      isError = true;
      errorss.pageId = "Please select page to continue.";
    }
    if (!isError) {
      if (!selected.id) {
        addProfile({...selected, hashtags: selected.hashtags.join(" ")});
      } else {
        updateProfile({ id: selected.id, cta: selected.cta, hashtags: selected.hashtags.join(" "), firstComment: selected.firstComment });
      }
    }
    return errorss;
  };

  const submitGMB = (data) => {
    if (!selected.id) {
      addProfile({...data, hashtags: ""});
    } else {
      updateProfile({ id: selected.id, cta: selected.cta, hashtags: selected.hashtags.join(" ") });
    }
  }
  
  const logInToFB = React.useCallback((type) => {
    setType(type);
    window.FB.login((response) => {
      if(fbToken == ""){
        getFBToken({ accessToken: response.authResponse.accessToken});
      } else {
        if (type === "fb") {
          getFacebookPages(fbToken, fbUserId);
        } else if (type === "insta") {
          getInstaPages(fbToken, fbUserId);
        }
      }
      setFbUserId(response.authResponse.userID);
    }, {scope: 'email, instagram_basic, pages_show_list, instagram_content_publish', return_scopes: true});
  }, []);

  const showFacebookPages = (data) => {
    console.log("data: ", data);
    setOpen(true);
    setType("fb");
    setFacebookPages(data);
  }

  const getFacebookPages = (accessToken, userId) => {
    window.FB.api(
      `${userId}/accounts?fields=picture,name,pageId,access_token&access_token=${accessToken}`,
      async (data) => {
        console.log("New data: ", data.data);
        showFacebookPages(data.data)
      }
    )
  }

  const getInstaPages = async (accessToken, userId) => {
    window.FB.api(
      `${userId}/accounts?fields=access_token,picture,instagram_business_account{id,ig_id,name,username}&access_token=${accessToken}`,
      async (data) => {
        console.log("Data:", data);
        setInstaPages(data.data.filter(item => item.instagram_business_account));
        setOpen(true);
        setType("insta");
      }
    )
  }

  useEffect(() => {
    if (isSaved) {
      fetchCurrentUser();
      setOpen(false);
      setDeleteOpen(false);
      setSelected(emptyProfile);
    }
  }, [isSaved]);

  const isProfile = (type) => {
    if (selectedAccount && selectedAccount.Profiles && Array.isArray(selectedAccount.Profiles)) {
      const x = selectedAccount.Profiles.filter(y => y.type == type);
      return x.length > 0; 
    }
    return false;
  }

  useEffect(() => {
    if(fbToken !== ""){
      console.log("Type: ", type);
      setFbUserAccessToken(fbToken);
      if (type === "fb") {
        getFacebookPages(fbToken, fbUserId);
      } else if (type === "insta") {
        getInstaPages(fbToken, fbUserId);
      }
    }
  }, [fbToken]);

  useEffect(() => {
    if (sessionStorage.getItem("at") !== null) {
      getGMBAccount();
      setOpenG(true);
    }
    if(sessionStorage.getItem("lat") !== null) {
      getLinkedinPage({ accessToken: sessionStorage.getItem("lat") });
      setType("linkedin");
      setOpen(true);
    }
  }, []);

  function getGMBAccount() {
    googleAccount({ accessToken: sessionStorage.getItem("at") });
  }
  
  function getGMBLocations (accountId) {
    googleLocation({ accessToken: sessionStorage.getItem("at"), accountId: accountId })
  }

  const profileLength = selectedAccount && Array.isArray(selectedAccount.Profiles) && selectedAccount.Profiles.length;
  const platformsAvailable = selectedAccount && selectedAccount.Subsciption && selectedAccount.Subsciption.platformAccess;

  return (
    <DashboardLayout>
      <DeleteConfirm
        deleteProfile={deleteProfile}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected(emptyProfile);
          setDeleteOpen(false);
        }}
      />
      <Loading loading={isLoading} />
      <SelectPagesDialog
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
          setSelected(emptyProfile);
          if(sessionStorage.getItem("lat") !== null) {
            sessionStorage.removeItem("lat");
            sessionStorage.removeItem("lrt");
          }
        }}
        submitHandle={submitHandle}
        facebookPages={facebookPages}
        instaPages={instaPages}
        linkedinPages={linkedinPages && linkedinPages.elements}
        type={type}
        selected={selected}
        setSelected={setSelected}
        errors={errors}
        setErrors={setErrors}
        fbToken={fbToken}
        fbUserId={fbUserId}
      />
      <GooglePagesDialog
        accounts={gmbAccounts}
        getLocations={getGMBLocations}
        open={openG}
        setOpen={setOpenG}
        onClose={() => {
          setOpenG(false);
          setSelected(emptyProfile);
          sessionStorage.removeItem("at");
          sessionStorage.removeItem("rt");
        }}
        selected={{
          ...selected,
          accessToken: sessionStorage.getItem("at"),
          refreshToken: sessionStorage.getItem("rt"),
          type: "gmb"
        }}
        setSelected={setSelected}
        submitHandle={submitGMB}
        locations={gmbLocations}
      />
      
      <DashboardNavbar />
      <Card style={{width: "1027px"}}>
        <MDBox p={3}>
          <MDTypography style={{ fontSize: "20px", fontWeight: "700", color: "#2A3342" }}>
            Connect More Social Profiles
          </MDTypography>
          <MDTypography style={{ fontSize: "16px", fontWeight: "400", color: "#556987", marginTop: "3px"}}>
            Please add your social profiles here.
          </MDTypography>
          {/* <Stack direction="row" style={{marginTop: "31px"}} > */}
          <MDBox style={{display: "flex", marginTop: "31px"}}>
            <MDBox style={{marginRight: "35px"}} onClick={() => logInToFB("insta")}>
              {isProfile("insta") ? (
                <div>
                  <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#1B172E",
                    borderRadius: "100px",
                    height: "56px",
                    width: "56px",
                  }}
                >
                  <img
                  src="/instagram_white.svg"
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                  }} />
                </MDBox>
                    <EditOutlinedIcon
                      color="primary"
                      style={{...translat, color: "#000", padding: "5px", background: "#fff", borderRadius: "50%", marginLeft: "7px", marginTop: "10px"}}
                    />
                </div>
              ) : platformsAvailable == profileLength ? (
                <div>
                  <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "56px",
                    width: "56px",
                    margin: "0px auto",
                    opacity: 0.4
                  }}
                >
                  <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                    <img
                    src="/instagram_black.svg"
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "7px",
                    }} />
                  </Tooltip>
                </MDBox>
              </div>
              ) : (
                <div>
                  <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "56px",
                    width: "56px",
                    margin: "0px auto",
                  }}
                >
                  <img
                  src="/instagram_black.svg"
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                  }} />
                </MDBox>
                    <AddCircleOutlineIcon color="dark" style={{...translat, marginLeft: "7px", marginTop: "10px"}} />
                </div>
              )}
            </MDBox>
            <MDBox style={{marginRight: "35px"}}>
              {isProfile("linkedin") ? (
                <div>
                  <MDBox
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#1B172E",
                      borderRadius: "100px",
                      height: "56px",
                      width: "56px",
                      margin: "0px auto",
                    }}
                  >
                    <LinkedInIcon
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "21px",
                        width: "21px",
                        margin: "16px",
                        color: "#fff",
                      }}
                    />
                  </MDBox>
                    <EditOutlinedIcon
                      color="primary"
                      style={{...translat, marginLeft: "7px", marginTop: "10px", color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                    />
                </div>
              ) : platformsAvailable == profileLength ? (
                <div>
                  <MDBox
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E6E6EA",
                      borderRadius: "100px",
                      height: "56px",
                      width: "56px",
                      margin: "0px auto",
                      opacity: 0.4
                    }}
                  >
                    <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                      <LinkedInIcon
                        style={{
                          height: "21px",
                          width: "21px",
                          margin: "16px",
                          color: "#000",
                        }}
                      />
                    </Tooltip>
                  </MDBox>
                </div>
              ) : (
                <div>
                  <MDBox
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E6E6EA",
                      borderRadius: "100px",
                      height: "56px",
                      width: "56px",
                      margin: "0px auto",
                    }}
                    onClick={() => {
                      sessionStorage.setItem("redirectUrl", "/social-profiles");
                      window.location.href = `${process.env.REACT_APP_API_URL}/linkedin/auth`;
                    }}
                  >
                    <LinkedInIcon
                      style={{
                        height: "21px",
                        width: "21px",
                        margin: "16px",
                        color: "#000",
                      }}
                    />
                  </MDBox>
                    <AddCircleOutlineIcon color="dark" style={{...translat, marginLeft: "7px", marginTop: "10px"}} />
                </div>
              )}
            </MDBox>
            <MDBox style={{marginRight: "35px"}} onClick={() => logInToFB("fb")}>
              {isProfile("fb") ? (
                <div>
                  <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#1B172E",
                    borderRadius: "100px",
                    height: "56px",
                    width: "56px",
                    margin: "0px auto",
                  }}
                >
                  <FacebookRoundedIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "16px",
                      color: "#fff",
                    }}
                  />
                </MDBox>
                    <EditOutlinedIcon
                      color="primary"
                      style={{...translat, marginLeft: "7px", marginTop: "10px", color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                    />
                </div>
              ) : platformsAvailable == profileLength ? (
                <div>
                <MDBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E6E6EA",
                  borderRadius: "100px",
                  height: "56px",
                  width: "56px",
                  margin: "0px auto",
                  opacity: 0.4
                }}
              >
                <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                  <FacebookRoundedIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "16px",
                      color: "#000",
                    }}
                  />
                </Tooltip>
              </MDBox>
            </div>
              ) : (
                <div>
                  <MDBox
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "56px",
                    width: "56px",
                    margin: "0px auto",
                  }}
                >
                  <FacebookRoundedIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "16px",
                      color: "#000",
                    }}
                  />
                </MDBox>
                    <AddCircleOutlineIcon color="dark" style={{...translat, marginLeft: "7px", marginTop: "10px"}} />
                </div>
              )}
            </MDBox>
            <MDBox>
            {isProfile("gmb") ? (
              <div>
                <MDBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#1B172E",
                  borderRadius: "100px",
                  height: "56px",
                  width: "56px",
                  margin: "0px auto",
                }}
              >
                <StorefrontRoundedIcon
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "16px",
                    color: "#fff",
                  }}
                />
              </MDBox>
                  <EditOutlinedIcon
                    color="primary"
                    style={{...translat, marginLeft: "7px", marginTop: "10px", color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                  />
              </div>
              ) : platformsAvailable == profileLength ? (
                <div>
                  <MDBox
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E6E6EA",
                      borderRadius: "100px",
                      height: "56px",
                      width: "56px",
                      margin: "0px auto",
                      opacity: 0.4
                    }}
                  >
                    <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                      <StorefrontRoundedIcon
                        style={{
                          height: "21px",
                          width: "21px",
                          margin: "16px",
                          color: "#000",
                        }}
                      />
                    </Tooltip>
                </MDBox>
                </div>
              ) : (
                <div>
                  <MDBox
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#E6E6EA",
                      borderRadius: "100px",
                      height: "56px",
                      width: "56px",
                      margin: "0px auto",
                    }}
                    onClick={() => {
                      sessionStorage.setItem("redirectUrl", "/social-profiles");
                      window.location.href = `${process.env.REACT_APP_API_URL}/user/google-gmb`;
                    }}
                  >
                  <StorefrontRoundedIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "16px",
                      color: "#000",
                    }}
                  />
                </MDBox>
                <AddCircleOutlineIcon color="dark" style={{...translat, marginLeft: "7px", marginTop: "10px"}} />
                </div>
              )}
              </MDBox>
            </MDBox>
          {/* </Stack> */}
        </MDBox>
        <MDBox pb={3} px={3} style={{ marginTop: "100px" }}>
            <MDBox style={{ textAlign: "left" }}>
              <MDTypography
                style={{
                  color: "#344767",
                  fontSize: "20px",
                  fontWeight: "700",
                }}
              >
                Social Connections
              </MDTypography>
              <MDTypography
                style={{
                  color: "#7b809a",
                  fontSize: "14px",
                  fontWeight: "400",
                  marginBottom: "11px",
                }}
              >
                Setup and manage your social profile connections.
              </MDTypography>
            </MDBox>
            
            {selectedAccount && Array.isArray(selectedAccount.Profiles) && selectedAccount.Profiles.map(x => {
              return (
                <>
                <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", sm: "center" }}
                flexDirection={{ xs: "column", sm: "row" }}
              >
                <MDBox display="flex" alignItems="center">
                  <MDBox>
                    <MDAvatar src={x.picture} alt={x.name} />
                  </MDBox>
                  <MDBox ml={2}>
                    <MDTypography
                      style={{
                        color: "#344767",
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      {x.type == "fb" ? "Facebook" : (x.type == "insta" ? "Instagram" : ( x.type == "gmb" ? "Google" : "Linkedin" ) )}
                      &nbsp;({x.name})
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  width={{ xs: "100%", sm: "auto" }}
                  mt={{ xs: 1, sm: 0 }}
                >
                  <MDBox lineHeight={0} mx={2}>
                    <MDTypography
                      variant="button"
                      style={{
                        color: "#534F6B",
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      Enabled
                    </MDTypography>
                  </MDBox>
                  <MDBox mr={1}>
                    <Switch
                      sx={{
                        "& .css-t7u51m-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                          {
                            backgroundColor: "#534F6B !important",
                          },
                      }}
                      checked={x.status == 1}
                      onChange={() => updateProfile({ id: x.id, status: x.status == 1 ? 0 : 1 })}
                    />
                  </MDBox>
                  <MDBox>
                    <DeleteIcon
                      onClick={() => {
                        setSelected(x);
                        setDeleteOpen(true);
                      }}
                      style={{ marginBottom: "-3px", color: "#191919", cursor: "pointer" }} />
                  </MDBox>
                </MDBox>
              </MDBox>
              <MDBox ml={2} pl={6} pt={2} lineHeight={1}>
                <MDBox
                  style={{ backgroundColor: "#F5F5F5" }}
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  my={1}
                  py={1}
                  pl={{ xs: 1, sm: 2 }}
                  pr={1}
                >
                  <Grid container >
                    <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        color="text"
                      >
                        Post CTA
                      </MDTypography>
                    </Grid>
                    <Grid item sm={12} md={7} style={{ textAlign: "Left" }}>
                      <MDTypography
                        variant="button"
                        style={{
                          color: "#7B809A",
                          fontSize: "14px",
                          display: "flex",
                        }}
                      >
                        {x.cta}
                      </MDTypography>
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <MDButton 
                        onClick={() => {
                          setSelected({...X, hashtags: x.hashtags ? x.hashtags.split("") : []});
                          setType(x.type);
                          setOpen(true);
                        }}
                        variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                        textDecoration: "underline" }}>
                        Edit
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
                <MDBox
                  style={{ backgroundColor: "#F5F5F5" }}
                  borderRadius="lg"
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                  my={1}
                  py={1}
                  pl={{ xs: 1, sm: 2 }}
                  pr={1}
                >
                  <Grid container style={{display: "flex", alignItems: "center"}}>
                    <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                      <MDTypography
                        variant="button"
                        fontWeight="medium"
                        color="text"
                      >
                        Hashtags
                      </MDTypography>
                    </Grid>
                    <Grid item sm={12} md={7} style={{ textAlign: "Left", display: "flex" }}>
                      
                      <MDTypography
                        style={{
                          color: "#221C40",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {x.hashtags && x.hashtags.split(" ").map(c => <Chip label={c} />)}
                      </MDTypography>
                   
                    </Grid>
                    <Grid item sm={12} md={2}>
                      <MDButton
                        onClick={() => {
                          setSelected({...x, hashtags: x.hashtags ? x.hashtags.split(" ") : [] });
                          setType(x.type);
                          setOpen(true);
                        }}
                        variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                        textDecoration: "underline" }}
                      >
                        Edit
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
              <Divider style={{ background: "#D4D4D4" }} />
              </>
              )
            })}
            <MDBox style={{ textAlign: "left", marginTop: "100px" }}>
              <MDButton
                // onClick={() => setOpenS(true)}
                variant="contained"
                style={{
                  background: "#09032B",
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                  borderRadius: "8px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  fontWeight: "700",
                  width: "295px",
                  height: "50px",
                  textTransform: "inherit"
                }}
                
              >
                Save and Continue
              </MDButton>
            </MDBox>
          </MDBox>
      </Card>
    </DashboardLayout>
  );
}
