export const ADD_MASTER_CONTENT = "ADD_MASTER_CONTENT";
export const ADD_MASTER_CONTENT_FAILURE = "ADD_MASTER_CONTENT_FAILURE";
export const ADD_MASTER_CONTENT_SUCCESS = "ADD_MASTER_CONTENT_SUCCESS";

export const GET_MASTER_CONTENT = "GET_MASTER_CONTENT";
export const GET_MASTER_CONTENT_FAILURE = "GET_MASTER_CONTENT_FAILURE";
export const GET_MASTER_CONTENT_SUCCESS = "GET_MASTER_CONTENT_SUCCESS";

export const UPDATE_MASTER_CONTENT = "UPDATE_MASTER_CONTENT";
export const UPDATE_MASTER_CONTENT_FAILURE = "UPDATE_MASTER_CONTENT_FAILURE";
export const UPDATE_MASTER_CONTENT_SUCCESS = "UPDATE_MASTER_CONTENT_SUCCESS";

export const DELETE_MASTER_CONTENT = "DELETE_MASTER_CONTENT";
export const DELETE_MASTER_CONTENT_FAILURE = "DELETE_MASTER_CONTENT_FAILURE";
export const DELETE_MASTER_CONTENT_SUCCESS = "DELETE_MASTER_CONTENT_SUCCESS";
