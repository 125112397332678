import React, { useState, useEffect } from "react";
import { Dialog, DialogContent, Grid, Stack } from "@mui/material";
import MDBox from "./../../../shared/components/MDBox";
import MDAvatar from "./../../../shared/components/MDAvatar";
import MDTypography from "./../../../shared/components/MDTypography";
import MDButton from "./../../../shared/components/MDButton";
import smassign from "./../../../assets/images/social-set.svg";
import { Link } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import StorefrontTwoToneIcon from "@mui/icons-material/StorefrontTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useInitFbSDK } from "./../../../hooks/facebook-hooks";
import NewUser from "./../../../assets/images/new_theme.png";
import SelectPagesDialog from "./component/select-pages-dialog";
import GooglePagesDialog from "./component/google-business-dialog";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from '@mui/icons-material/Delete';
import Loading from './../../../shared/Loading';
import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from '@mui/material/Tooltip';
import StorefrontRoundedIcon from "@mui/icons-material/StorefrontRounded";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DeleteConfirm } from './component/delete-confirm';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Chip from "@mui/material/Chip";
import StepLabel from "@mui/material/StepLabel";
import { googleLocation } from "../../../api/user/userAction";
import { SubscriptionDialogePage } from "./../../../shared/subscription-dialoge";

const bgcolor = {
  background: "none",
  boxShadow: "none",
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-active": {
    background: "#1B1C29",
    fill: "#1B1C29",
    stroke: "#1B1C29",
    borderColor: "#1B1C29",
    height: "16px",
    width: "16px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root.Mui-completed": {
    background: "#1B1C29",
    fill: "#1B1C29",
    stroke: "#1B1C29",
    borderColor: "#1B1C29",
    height: "16px",
    width: "16px",
  },
  "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeInherit.MuiStepIcon-root": {
    background: "#9B9CA3",
    fill: "#9B9CA3",
    stroke: "#9B9CA3",
    borderColor: "#9B9CA3",
    height: "16px",
    width: "16px",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-active": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel.Mui-completed": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
      color: "#1B1C29 !important",
  },
  "& .MuiStepLabel-label.Mui-disabled.MuiStepLabel-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel": {
    color: "#9B9CA3 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-active": {
    color: "#1B1C29 !important",
  },
  "& .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-completed": {
    color: "#1B1C29 !important",
  },
};

function getSteps() {
  return ["Add Business", "Select Theme", "Socials"];
}

const tooltipText = {
  background: "#35363E",
  backdropFilter: "blur(240px)", 
  borderRadius: "8px",
  color: "rgba(255, 255, 255, 0.87)", 
  fontWeight: "400", 
  fontSize: "12px", 
  padding: "8px", 
  opacity: 0.9
}

export default function AddSocialPage(props) {
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  const steps = getSteps();
  const { fetchPackages, packages, getLinkedinPage, linkedinPages, gmbAccounts, gmbLocations, googleAccount, googleLocation, getFBToken, fbToken, selectedAccount, getAccount, refreshToken, accounts, locations, addProfile, profiles, isLoading, isSaved, user, fetchCurrentUser, updateProfile, deleteProfile } = props;
  const X = 13;
  const Y = -33;
  const translat = {
    transform: `translate(${X}px, ${Y}px)`,
    width: "18px",
    height: "18px",
    color: "#000"
  };

  const emptyProfile = {
    AccountId: selectedAccount.id,
    type: "",
    name: "",
    email: "",
    pageId: "",
    refreshToken: "",
    accessToken: "",
    picture: "",
    custom: JSON.stringify({}),
    cta: "",
    hashtags: [],
    firstComment: 0,
    status: 1,
  }
  
  const isFbSDKInitialized = useInitFbSDK();
  const [fbUserAccessToken, setFbUserAccessToken] = useState();
  const [subscriptionOpen, setSubscriptionOpen] = React.useState(false);
  const [fbUserId, setFbUserId] = useState();
  const [facebookPages, setFacebookPages] = useState([]);
  const [instaPages, setInstaPages] = useState([]);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [openG, setOpenG] = useState(false);
  const [selected, setSelected] = useState(emptyProfile);
  const [errors, setErrors] = useState({});
  const [showType, setShowType] = useState("fb");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const submitGMB = (data) => {
    if (!selected.id) {
      addProfile({...data, hashtags: ""});
    } else {
      updateProfile({ id: selected.id, cta: selected.cta, hashtags: selected.hashtags.join(" ") });
    }
  }
  
  const submitHandle = () => {
    setErrors(validate(selected));
  };
  const validate = (selected) => {
    let errorss = {};
    let isError = false;
    if (!selected.id && !selected.pageId) {
      isError = true;
      errorss.pageId = "Please select page to continue.";
    }
    if (!isError) {
      if (!selected.id) {
        addProfile({...selected, hashtags: selected.hashtags.join(" ")});
      } else {
        updateProfile({ id: selected.id, cta: selected.cta, hashtags: selected.hashtags.join(" "), firstComment: selected.firstComment });
      }
    }
    return errorss;
  };

  const logInToFB = React.useCallback((type) => {
    setType(type);
    window.FB.login((response) => {
      if(fbToken == ""){
        getFBToken({ accessToken: response.authResponse.accessToken});
      } else {
        if (type === "fb") {
          getFacebookPages(fbToken, fbUserId);
        } else if (type === "insta") {
          getInstaPages(fbToken, fbUserId);
        }
      }
      setFbUserId(response.authResponse.userID);
    }, {scope: 'email, instagram_basic, pages_show_list, instagram_content_publish', return_scopes: true});
  }, []);

  // Logs out the current Facebook user
  const logOutOfFB = React.useCallback(() => {
    window.FB.logout(() => {
      setFbUserAccessToken(null);
    });
  }, []);

  // useEffect(() => {
  //   console.log(1);
  //   if (isFbSDKInitialized) {
  //     window.FB.getLoginStatus((response) => {
  //       setFbUserAccessToken(response.authResponse?.accessToken);
  //     });
  //   }
  // }, [isFbSDKInitialized]);

  const showFacebookPages = (data) => {
    setOpen(true);
    setType("fb");
    setFacebookPages(data);
  }

  const getFacebookPages = (accessToken, userId) => {
    console.log("Access Token: ", accessToken, " ::: UserID: ", userId);
    window.FB.api(
      `${userId}/accounts?fields=picture,name,pageId,access_token&access_token=${accessToken}`,
      async (data) => {
        console.log("New data: ", data.data);
        showFacebookPages(data.data)
      }
    )
  }

  const getInstaPages = async (accessToken, userId) => {
    window.FB.api(
      `${userId}/accounts?fields=access_token,picture,instagram_business_account{id,ig_id,name,username}&access_token=${accessToken}`,
      async (data) => {
        console.log("Insta Data:", data);
        setInstaPages(data.data.filter(item => item.instagram_business_account));
        setOpen(true);
        setType("insta");
      }
    )
  }

  const isProfile = (type) => {
    if (selectedAccount && selectedAccount.Profiles && Array.isArray(selectedAccount.Profiles)) {
      const x = selectedAccount.Profiles.filter(y => y.type == type);
      return x.length > 0; 
    }
    return false;
  }

  useEffect(() => {
    if (isSaved) {
      fetchCurrentUser();
      setOpen(false);
      setDeleteOpen(false);
      setSelected(emptyProfile);
    }
  }, [isSaved]);

  useEffect(() => {
    if (sessionStorage.getItem("at") !== null) {
      getGMBAccount();
      setOpenG(true);
    }
    if(sessionStorage.getItem("lat") !== null) {
      getLinkedinPage({ accessToken: sessionStorage.getItem("lat") });
      setType("linkedin");
      setOpen(true);
    }
    fetchPackages(1);
  }, []);

  useEffect(() => {
    if(fbToken !== ""){
      setFbUserAccessToken(fbToken);
      if (type === "fb") {
        getFacebookPages(fbToken, fbUserId);
      } else if (type === "insta") {
        getInstaPages(fbToken, fbUserId);
      }
    }
  }, [fbToken]);
  
  function getGMBAccount() {
    googleAccount({ accessToken: sessionStorage.getItem("at") });
  }
  
  function getGMBLocations (accountId) {
    googleLocation({ accessToken: sessionStorage.getItem("at"), accountId: accountId })
  }

  const profileLength = selectedAccount && Array.isArray(selectedAccount.Profiles) && selectedAccount.Profiles.length;
  const platformsAvailable = selectedAccount && selectedAccount.Subsciption && selectedAccount.Subsciption.platformAccess;

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <SubscriptionDialogePage
        open={subscriptionOpen}
        onClose={() => {
            setSubscriptionOpen(false);
        }}
        packages={packages}
      />
      <DeleteConfirm
        deleteProfile={deleteProfile}
        open={deleteOpen}
        selected={selected}
        onClose={() => {
          setSelected(emptyProfile);
          setDeleteOpen(false);
        }}
      />
      <SelectPagesDialog
        open={open}
        setOpen={setOpen}
        onClose={() => {
          setOpen(false);
          setSelected(emptyProfile);
          if(sessionStorage.getItem("lat") !== null) {
            sessionStorage.removeItem("lat");
            sessionStorage.removeItem("lrt");
          }
        }}
        submitHandle={submitHandle}
        facebookPages={facebookPages}
        instaPages={instaPages}
        linkedinPages={linkedinPages && linkedinPages.elements}
        type={type}
        selected={selected}
        setSelected={setSelected}
        errors={errors}
        setErrors={setErrors}
      />
      <GooglePagesDialog
        accounts={gmbAccounts}
        getLocations={getGMBLocations}
        open={openG}
        setOpen={setOpenG}
        onClose={() => {
          setOpenG(false);
          setSelected(emptyProfile);
          sessionStorage.removeItem("at");
          sessionStorage.removeItem("rt");
        }}
        selected={{
          ...selected,
          accessToken: sessionStorage.getItem("at"),
          refreshToken: sessionStorage.getItem("rt"),
          type: "gmb"
        }}
        setSelected={setSelected}
        submitHandle={submitGMB}
        locations={gmbLocations}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={4}>
          
      <MDBox style={{textAlign: "center", marginTop: "64px"}}>
        <Stepper sx={bgcolor} activeStep={2} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
      </MDBox>
        </Grid>
      </Grid>
      <Grid container style={{ textAlign: "center", display: "grid" }}>
        <MDTypography
          style={{
            color: "#09032B",
            fontSize: "36px",
            fontWeight: "700",
            marginTop: "64px",
          }}
        >
          Connect a Social Set...
        </MDTypography>
        <Grid
          item
          style={{
            backgroundColor: "white",
            margin: "37px auto",
            borderRadius: "12px",
          }}
          sx={{
            "@media (min-width: 320px)": {
              width: "300px",
            },
            "@media (min-width: 600px)": {
              width: "528px",
            },
            "@media (min-width: 991px)": {
              width: "817px",
            },
          }}
        >
          <MDBox
            style={{
              display: "grid",
              justifyContent: "center",
              padding: "9% 0px",
            }}
          >
            <MDBox>
              <MDAvatar
                src={smassign}
                variant="square"
                style={{ width: "285px", height: "250px", margin: "0px auto" }}
              />
            </MDBox>
            <MDBox style={{ textAlign: "center" }}>
              <MDTypography
                style={{
                  color: "#2A3342",
                  fontSize: "24px",
                  fontWeight: "700",
                  marginTop: "32px",
                }}
              >
                {profileLength > 0 ? "Connect more social profiles" : "No social profiles are connected"}
              </MDTypography>
              <MDTypography
                style={{
                  color: "#556987",
                  fontSize: "18px",
                  fontWeight: "400",
                  marginTop: "5px",
                }}
              >
                Please add your social profiles
              </MDTypography>
            </MDBox>
            <MDBox style={{display: "flex", justifyContent: "center", marginTop: "31px"}}>
            <MDBox style={{marginRight: "24px"}}>
              {isProfile("insta") ? (
                <MDBox
                style={{
                  background: "#1B172E",
                  borderRadius: "100px",
                  height: "34px",
                  width: "34px",
                  margin: "0px auto",
                  cursor: "pointer"
                }}
              >
                <img
                  src="/instagram_white.svg"
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                  }} />
                  <EditOutlinedIcon
                    color="primary"
                    style={{...translat, color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                  />
              </MDBox>
              ) : platformsAvailable == profileLength ? (
                <MDBox
                  style={{
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    opacity: 0.4
                  }}
                  onClick={() => setSubscriptionOpen(true)}
                >
                  <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                    <img
                      src="/instagram_black.svg"
                      style={{
                        height: "21px",
                        width: "21px",
                        margin: "7px",
                      }} />
                  </Tooltip>
              </MDBox>
              ) : (
                <MDBox
                  style={{
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    cursor: "pointer"
                  }}
                  onClick={() => logInToFB("insta")}
              >
                <img
                  src="/instagram_black.svg"
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                  }} />
                  <AddCircleOutlineIcon color="dark" style={translat} />
              </MDBox>
              )}
              
            </MDBox>
            <MDBox style={{marginRight: "24px"}}>
              {isProfile("linkedin") ? (
                <MDBox
                  style={{
                    background: "#1B172E",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    cursor: "pointer"
                  }}
                >
                  <LinkedInIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "7px",
                      color: "#fff",
                    }}
                  />
                  <EditOutlinedIcon
                    color="primary"
                    style={{...translat, color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                  />
                </MDBox>
              ) : platformsAvailable === profileLength ? (
                <MDBox
                  style={{
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    opacity: 0.4
                  }}
                  onClick={() => setSubscriptionOpen(true)}
                >
                  <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                    <LinkedInIcon
                      style={{
                        height: "21px",
                        width: "21px",
                        margin: "7px",
                        color: "#000",
                      }}
                    />
                  </Tooltip>
                </MDBox>
              ) : (
                <MDBox
                  style={{
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    sessionStorage.setItem("redirectUrl", "/add-social");
                    window.location.href = `${process.env.REACT_APP_API_URL}/linkedin/auth`;
                  }}
                >
                  <LinkedInIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "7px",
                      color: "#000",
                    }}
                  />
                  <AddCircleOutlineIcon color="dark" style={translat} />
                </MDBox>
              )}
            </MDBox>
            <MDBox style={{marginRight: "24px"}}>
              {isProfile("fb") ? (
                <MDBox
                style={{
                  background: "#1B172E",
                  borderRadius: "100px",
                  height: "34px",
                  width: "34px",
                  margin: "0px auto",
                  cursor: "pointer"
                }}
              >
                <FacebookRoundedIcon
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                    color: "#fff",
                  }}
                />
                  <EditOutlinedIcon
                    color="primary"
                    style={{...translat, color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                  />
              </MDBox>
              ) : platformsAvailable === profileLength ? (
                <MDBox
                style={{
                  background: "#E6E6EA",
                  borderRadius: "100px",
                  height: "34px",
                  width: "34px",
                  margin: "0px auto",
                  opacity: 0.4
                }}
                onClick={() => setSubscriptionOpen(true)}
              >
                <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                  <FacebookRoundedIcon
                    style={{
                      height: "21px",
                      width: "21px",
                      margin: "7px",
                      color: "#000",
                    }}
                  />
                </Tooltip>
              </MDBox>
              ) : (
                <MDBox
                style={{
                  background: "#E6E6EA",
                  borderRadius: "100px",
                  height: "34px",
                  width: "34px",
                  margin: "0px auto",
                  cursor: "pointer"
                }}
                onClick={() => logInToFB("fb")}
              >
                <FacebookRoundedIcon
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                    color: "#000",
                  }}
                />
                  <AddCircleOutlineIcon color="dark" style={translat} />
              </MDBox>
              )}
            </MDBox>
            <MDBox>
            {isProfile("gmb") ? (
                <MDBox
                  style={{
                    background: "#1B172E",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    cursor: "pointer"
                  }}
                >
                <StorefrontRoundedIcon
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                    color: "#fff",
                  }}
                />
                  <EditOutlinedIcon
                    color="primary"
                    style={{...translat, color: "#000", padding: "5px", background: "#fff", borderRadius: "50%"}}
                  />
              </MDBox>
              ) : platformsAvailable === profileLength ? (
                <MDBox
                  style={{
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    opacity: 0.4
                  }}
                  onClick={() => setSubscriptionOpen(true)}
                >
                  <Tooltip title="Upgrade pricing plan to add more social profiles" arrow>
                    <StorefrontRoundedIcon
                      style={{
                        height: "21px",
                        width: "21px",
                        margin: "7px",
                        color: "#000",
                      }}
                    />
                  </Tooltip>
              </MDBox>
              ) : (
                <MDBox
                  style={{
                    background: "#E6E6EA",
                    borderRadius: "100px",
                    height: "34px",
                    width: "34px",
                    margin: "0px auto",
                    cursor: "pointer"
                  }}
                  onClick={() => {
                    sessionStorage.setItem("redirectUrl", "/add-social");
                    window.location.href = `${process.env.REACT_APP_API_URL}/user/google-gmb`;
                  }}
                >
                <StorefrontRoundedIcon
                  style={{
                    height: "21px",
                    width: "21px",
                    margin: "7px",
                    color: "#000",
                  }}
                />
                  <AddCircleOutlineIcon color="dark" style={translat} />
              </MDBox>
              )}
              </MDBox>
            </MDBox>
          </MDBox>
          {selectedAccount && Array.isArray(selectedAccount.Profiles) && selectedAccount.Profiles.length > 0 ? (
            <MDBox pt={0} pb={3} px={3}>
              <MDBox style={{ textAlign: "left" }}>
                <MDTypography
                  style={{
                    color: "#344767",
                    fontSize: "20px",
                    fontWeight: "700",
                  }}
                >
                  Social Connections
                </MDTypography>
                <MDTypography
                  style={{
                    color: "#7b809a",
                    fontSize: "14px",
                    fontWeight: "400",
                    marginBottom: "11px",
                  }}
                >
                  Setup and manage your social profile connections.
                </MDTypography>
              </MDBox>
                  {selectedAccount.Profiles.map((x) => {
                    return (
                      <>
                      <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <MDBox display="flex" alignItems="center">
                          <MDAvatar src={x.picture} alt={x.name} />                        
                        <MDBox ml={2}>
                          <MDTypography
                            style={{
                              color: "#344767",
                              fontSize: "20px",
                              fontWeight: "700",
                            }}
                          >
                            {x.type == "fb" ? "Facebook" : (x.type == "insta" ? "Instagram" : ( x.type == "gmb" ? "Google" : "Linkedin" ) )}
                            &nbsp;({x.name})
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        width={{ xs: "100%", sm: "auto" }}
                        mt={{ xs: 1, sm: 0 }}
                      >
                        <MDBox lineHeight={0} mx={2}>
                          <MDTypography
                            variant="button"
                            style={{
                              color: "#534F6B",
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            Enabled
                          </MDTypography>
                        </MDBox>
                        <MDBox mr={1}>
                          <Switch
                            sx={{
                              "& .css-t7u51m-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                                {
                                  backgroundColor: "#534F6B !important",
                                },
                            }}
                            checked={x.status == 1}
                            onChange={() => updateProfile({ id: x.id, status: x.status == 1 ? 0 : 1 })}
                          />
                        </MDBox>
                        <MDBox>
                          <DeleteIcon
                            onClick={() => {
                              setSelected(x);
                              setDeleteOpen(true);
                            }}
                            style={{ marginBottom: "-3px", color: "#191919", cursor: "pointer" }} />
                        </MDBox>
                      </MDBox>
                    </MDBox>
                    <MDBox ml={2} pl={6} pt={2} lineHeight={1}>
                      <MDBox
                        style={{ backgroundColor: "#F5F5F5" }}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={1}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                      >
                        <Grid container>
                          <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              color="text"
                            >
                              Post CTA
                            </MDTypography>
                          </Grid>
                          <Grid item sm={12} md={7} style={{ textAlign: "Left" }}>
                            <MDTypography
                              variant="button"
                              style={{
                                color: "#7B809A",
                                fontSize: "14px",
                                display: "flex",
                              }}
                            >
                              {x.cta}
                            </MDTypography>
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <MDButton 
                              onClick={() => {
                                setSelected({...x, hashtags: x.hashtags ? x.hashtags.split(" ") : []});
                                setType(x.type);
                                setOpen(true);
                              }}
                              variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                              textDecoration: "underline" }}>
                              Edit
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                      <MDBox
                        style={{ backgroundColor: "#F5F5F5" }}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={1}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                      >
                        <Grid container style={{display: "flex", alignItems: "center"}}>
                          <Grid item sm={12} md={3} style={{ textAlign: "Left" }}>
                            <MDTypography
                              variant="button"
                              fontWeight="medium"
                              color="text"
                            >
                              Hashtags
                            </MDTypography>
                          </Grid>
                          <Grid item sm={12} md={7} style={{ textAlign: "Left", display: "flex" }}>
                            <MDTypography
                              style={{
                                color: "#221C40",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                            >
                              {x.hashtags && Array.isArray(x.hashtags.split(" ")) && x.hashtags.split(" ").map(x => {
                                return <Chip label={x} />
                              })}
                            </MDTypography>
                         
                          </Grid>
                          <Grid item sm={12} md={2}>
                            <MDButton
                              onClick={() => {
                                setSelected({...x, hashtags: x.hashtags ? x.hashtags.split(" ") : []});
                                setType(x.type);
                                setOpen(true);
                              }}
                              variant="text" style={{ color: "#344767", float: "right", fontWeight: "700", fontSize: "16px", textTransform: "capitalize",
                              textDecoration: "underline" }}>
                              Edit
                            </MDButton>
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                    <Divider style={{ background: "#D4D4D4" }} />
                    </>
                    )
                  })}
              <MDBox style={{ textAlign: "left", marginTop: "27px" }}>
                <MDButton
                  variant="contained"
                  component={Link}
                  to="/dashboard"
                  style={{
                    background: "#09032B",
                    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
                    borderRadius: "8px",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "700",
                    width: "295px",
                    height: "50px",
                    textTransform: "inherit"
                  }}
                >
                  Save and Continue
                </MDButton>
              </MDBox>
            </MDBox>
            ) : (
              <MDBox pt={0} pb={3} px={3}>
                <MDBox style={{ textAlign: "right", marginTop: "10px" }}>
                <MDTypography
                  variant="contained"
                  component={Link}
                  to="/dashboard"
                  style={{
                    fontSize: "12px",
                    textDecoration: "underline",
                    textTransform: "inherit"
                  }}
                >
                  Skip for now
                </MDTypography>
              </MDBox>
              </MDBox>
            )}
        </Grid>
      </Grid>

    </React.Fragment>
  );
}
