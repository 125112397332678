import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MDBox from "../../../../shared/components/MDBox";
import MDTypography from "../../../../shared/components/MDTypography";
import Checkbox from "@mui/material/Checkbox";
import { makeStyles } from '@material-ui/styles';
import MDButton from "../../../../shared/components/MDButton";
import ClearIcon from "@mui/icons-material/Clear";
import MDAvatar from "../../../../shared/components/MDAvatar";
import burceMars from "../../../../assets/images/bruce-mars.jpg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import FormField from "../../../../shared/components/FormField";
import Switch from "@mui/material/Switch";
import { Grid } from "@mui/material";

const useStyles = makeStyles({
    listText: {
      '& .css-knlq9b-MuiFormControlLabel-root .MuiFormControlLabel-label ':
        {
          color: "#344767",
          fontWeight: "500",
          fontSize: "12px",
        },
    },
  });

const topHead = {
    fontWeight: "700",
    fontSize: "20px",
    color: "#344767",
    }
const topText = {
    fontWeight: "500",
    fontSize: "14px",
    marginTop: "3px",
    color: "#7B809A",
}
const box = {
    marginTop: "37px",
}
const text = {
    fontWeight: "600", 
    fontSize: "15px", 
    color: "#344767", 
    marginBottom: "25px", 
    marginTop: "32px"
}
const imageText = {
    fontWeight: "500", 
    fontSize: "18px", 
    color: "#344767"
}
const button = {
    background: "#09032B",
    width: "192px",
    height: "50px",
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.25)",
    color: "#FFFFFF",
    borderRadius: "8px",
    textTransform: "capitalize",
}

export default function InstaPagesDialog(props) {
  const classes = useStyles();
  const { open, setOpen } = props;
  const [checked, setChecked] = React.useState(false);
  const [slack2FA, setSlack2FA] = React.useState(true);
  const handleSetSlack2FA = () => setSlack2FA(!slack2FA);

  const handleChange = event => {
    setChecked(event.target.checked)
    const result = event.target.checked;
    
    console.warn('handleChange event:- ',checked);
   result  ? console.log("checked") : console.log("unchecked");
  };

  
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogContent>
          <MDBox p={2}>
            <MDBox style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox>
                <MDTypography style={topHead} >
                  Select Your Page
                </MDTypography>
                <MDTypography style={topText} >
                  You can add your page from here
                </MDTypography>
              </MDBox>
              <MDBox>
                <ClearIcon  onClick={() => setOpen(false)}
                  style={{ width: "32px", height: "32px" }} 
                />
              </MDBox>
            </MDBox>
            <MDTypography style={text}>Instagram Business Profiles</MDTypography>
            <MDBox style={{display: "flex"}}>
                <MDAvatar src={burceMars} alt="profile-image" size="md" shadow="sm"
                    style={{marginRight: "12px"}}
                />
                <MDBox style={{display: "flex", alignItems: "center"}}>
                    <MDTypography style={imageText} >
                        Royal Parvej Official
                    </MDTypography>
                </MDBox>
                <MDBox style={{marginLeft: "auto"}}>
                  <List className={classes.listText} >
                      <FormGroup>
                          <FormControlLabel control={<Checkbox />} label="Select page"
                            value={checked}
                            onChange={handleChange}
                          />
                      </FormGroup>
                  </List>
                </MDBox>
            </MDBox> 
            {checked ? 
              <Grid container style={{marginTop: "37px"}}>
                <Grid item xs={6} >
                  <FormField
                    label="Profile Post Call to Action"
                    name="profile post call to action"
                    placeholder="Call us for a free chat"
                    type="text"
                  />
                  <FormField style={{marginTop: "37px"}}
                    label="Profile Post Hashtags"
                    name="profile post hashtags"
                    placeholder="#businesstags #newone #anotherone"
                    type="text"
                  />
                </Grid>
              </Grid>
            : ""}
              <MDBox style={{display: "flex", marginTop: "37px"}}>
                <Switch
                  sx={{
                    "& .css-t7u51m-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                      {
                        backgroundColor: "#534F6B !important",
                      },
                  }}
                  checked={slack2FA}
                  onChange={handleSetSlack2FA}
                />
                <MDBox style={{marginTop: "5px"}}>
                  <MDTypography
                    variant="button"
                    style={{
                      color: "#534F6B",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {slack2FA ? "Publish hashtags as first comment" : "Disabled"}
                  </MDTypography>
                </MDBox>
              </MDBox>
            <MDBox style={box} >
                <MDButton style={button} variant="contained" >
                    Save and Continue
                </MDButton>
            </MDBox>
          </MDBox>
        </DialogContent>
      </Dialog>
    </div>
  );
}
