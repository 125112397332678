/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// img
import drake from "./../../../../../../assets/images/drake.jpg";

// Material Dashboard 2 PRO React components
import MDBox from "./../../../../MDBox";
import MDTypography from "./../../../../MDTypography";
import MDAvatar from "./../../../../MDAvatar";

function IdCell({ id, checked }) {
  return (
    <MDBox display="flex" alignItems="center">
      <MDAvatar
        src={drake}
        variant="square"
        style={{
          width: "139px",
          height: "124px",
          borderRadius: "6px",
          border: "1px solid #E8E8E8",
        }}
      />
      <MDBox sx={{ marginLeft: "18px" }}>
        <MDTypography variant="caption" fontWeight="medium" color="text">
          {id}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
}

// Setting default value for the props of IdCell
IdCell.defaultProps = {
  checked: false,
};

// Typechecking props for the IdCell
IdCell.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default IdCell;
