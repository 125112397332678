import { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import DataTable from "./../../../../shared/components/DataTable";
import IdCell from "./../../../../shared/components/DataTable/components/IdCell";
import DefaultCell from "./../../../../shared/components/DataTable/components/DefaultCell";
import { Stack } from "@mui/material";
import MDAvatar from "./../../../../shared/components/MDAvatar";
import MDBox from "./../../../../shared/components/MDBox";
import MDTypography from "./../../../../shared/components/MDTypography";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { preetyDate } from "./../../../../helper";

export default function List(props) {
  const { content, approveContent, selected, setSelected, setOpen, deleteContent, setDeleteOpen, setDynamicFields } = props;
  const [contentData, setContentData] = useState({
    columns: [
      {
        Header: "Content",
        accessor: "id",
        Cell: ({ value }) => <IdCell id={value} />,
      },
      {
        Header: "Content Type",
        accessor: "date",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "status",
        accessor: "status",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Platform",
        accessor: "customer",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Date & Time",
        accessor: "product",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
      {
        Header: "Actions",
        accessor: "revenue",
        Cell: ({ value }) => <DefaultCell value={value} />,
      },
    ],
    rows: [],
  });
  const checkPr = (x, type) => {
    if (Array.isArray(x)) {
      const y = x.filter(z => z.type == type);
      return y.length > 0;
    }
    return false;
  }

  useEffect(async () => {
    if (Array.isArray(content)) {
      const y = await Promise.all(content.map(x => {
        return {
            id: (
              <MDBox style={{display: "flex", alignItems: "center"}}>
                <MDAvatar
                  src={x.images && Array.isArray(JSON.parse(x.images)) && JSON.parse(x.images).length > 0 && JSON.parse(x.images)[0].image}
                  variant="square"
                  style={{ width: "52px", height: "40px", borderRadius: "8px", marginRight: "22px" }}
                />
                <div 
                  style={{
                    maxWidth: "250px",
                    color: "#344767",
                    fontSize: "14px",
                    fontWeight: "400",
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden"
                  }}
                >
                  {JSON.parse(x.content).all} 
                </div>
              </MDBox>
            ),
            date: (
              <MDTypography style={{ background: "rgba(76, 175, 80, 0.2)", padding: "4px 8px", textTransform: "uppercase",
                borderRadius: "8px", height: "25px", color: "#4CAF50", fontSize: "12px", fontWeight: "700" }}>
                Social Post
              </MDTypography>
            ),
            status: (
              <Stack direction="row" spacing="1">
                  { x.status == 1 ? 
                    (
                      <FolderOutlinedIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#FB8C00",
                          marginRight: "4px",
                        }}
                      />
                    ) : x.status == 6 ? 
                    (
                      <SendIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#41B853",
                          marginRight: "4px",
                          transform: "rotate(-45deg)"
                        }}
                      />
                    ) : x.status == 4 ? 
                    (<InfoOutlinedIcon
                      style={{
                        width: "20px",
                        height: "20px",
                        color: "#3672E7",
                        marginRight: "4px",
                      }}
                    />) : (
                      <AccessTimeIcon
                        style={{
                          width: "20px",
                          height: "20px",
                          color: "#FB8C00",
                          marginRight: "4px",
                        }}
                      />
                    ) }
                    <MDTypography
                      style={{
                        // whiteSpace: "nowrap",
                        // textOverflow: "ellipsis",
                        color: "#6B6880",
                        fontSize: "12px",
                        fontWeight: "400"
                      }}
                    >
                      { x.status == 1 ? "Draft" : x.status == 6 ? "Published" : x.status == 4 ? "In Queue" : x.status == 5 ? "Scheduled" : x.status == 7 ? "Failed" : "In Review" }
                    </MDTypography>
                  </Stack>
            ),
            customer: (
              <Stack direction="row" spacing="1">
                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "facebook") && (
                  <FacebookRoundedIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
                )}
                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "insta") && (
                  <InstagramIcon style={{ width: "20px", height: "20px", color: "#7B809A", marginRight: "8px" }} />
                )}
                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && checkPr(JSON.parse(x.Schedules[0].ProfileIds), "linkedin") && (
                  <LinkedInIcon style={{ width: "20px", height: "20px", color: "#7B809A" }} />
                )}
              </Stack>
            ),
            product: (
              <MDTypography style={{ color: "#7B809A", fontSize: "12px", fontWeight: "400" }} >
                {x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scTime ? preetyDate(x.Schedules[0].scTime) : ""}
              </MDTypography>
            ),
            revenue: (
              <Stack direction="row" spacing="1">
                <EditIcon
                  onClick={() => {
                    setSelected({
                      id: x.id,
                      AccountId: x.AccountId,
                      ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                      content: x.content && JSON.parse(x.content),
                      layouts: x.layouts && JSON.parse(x.layouts),
                      images: JSON.parse(x.images)[0].image,
                      hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? {all: JSON.parse(x.Schedules[0].hashtags).all.split(" "), facebook: JSON.parse(x.Schedules[0].hashtags).facebook.split(" "), insta: JSON.parse(x.Schedules[0].hashtags).insta.split(" "), gmb: JSON.parse(x.Schedules[0].hashtags).gmb.split(" "), linkedin: JSON.parse(x.Schedules[0].hashtags).linkedin.split(" ")} : { all: [], facebook: [], insta: [], gmb: [], linkedin: [] },
                      scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                      scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 ? x.Schedules[0].scTime : null,
                      status: x.status,
                      dynamics: x.dynamics ? JSON.parse(x.dynamics) : {}
                    });
                    setDynamicFields(x.dynamics ? JSON.parse(x.dynamics) : {});
                    setOpen(true);
                  }}
                  style={{ cursor: "pointer", width: "16px", height: "16px", color: "#757575" }}
                />
                <DeleteIcon
                  onClick={() => {
                    setSelected({
                      id: x.id,
                      AccountId: x.AccountId,
                      ProfileIds: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].ProfileIds),
                      content: JSON.parse(x.content),
                      layouts: JSON.parse(x.layouts),
                      images: x.images,
                      hashtags: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && JSON.parse(x.Schedules[0].hashtags),
                      scDate: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scDate,
                      scTime: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].scTime,
                      status: x.status,
                      scheduledId: x.Schedules && Array.isArray(x.Schedules) && x.Schedules.length > 0 && x.Schedules[0].id
                    });
                    setDeleteOpen(true);
                  }}
                  style={{
                    cursor: "pointer",
                    width: "16px",
                    height: "16px",
                    color: "#757575",
                    marginLeft: "8px",
                  }}
                />
              </Stack>
            ),
          }
      }));
      const dataTableData = {
        columns: [
          {
            Header: "Content",
            accessor: "id",
            Cell: ({ value }) => <IdCell id={value} />,
          },
          {
            Header: "Content Type",
            accessor: "date",
            Cell: ({ value }) => <DefaultCell value={value} />,
          },
          {
            Header: "status",
            accessor: "status",
            Cell: ({ value }) => <DefaultCell value={value} />,
          },
          {
            Header: "Platform",
            accessor: "customer",
            Cell: ({ value }) => <DefaultCell value={value} />,
          },
          {
            Header: "Date & Time",
            accessor: "product",
            Cell: ({ value }) => <DefaultCell value={value} />,
          },
          {
            Header: "Actions",
            accessor: "revenue",
            Cell: ({ value }) => <DefaultCell value={value} />,
          },
        ],
        rows: y,
      };
      setContentData(dataTableData);
    }
  }, [content])
  

  return (
    <Card style={{ marginTop: "39px" }}>
      <DataTable
        style={{ height: "858px" }}
        table={contentData}
        entriesPerPage={false}
        canSearch
      />
    </Card>
  );
}
