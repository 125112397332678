import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { RoutePath } from "./../../routes";
import { SidenavPageContainer } from "./../components/Sidenav/sidenav.container";
import { useLocation } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import MDAvatar from "./../components/MDAvatar";
import Icon from "@mui/material/Icon";
import { ConnectMediaPageContainer } from "./../../views/connectMediaPage2/connectMedia.container";
import { PostPannelPageContainer } from "./../../views/postPannel/postPannel.container";
import { SocialOverviewPageContainer } from "./../../views/socialoverview/socialOverview.container";
import { TeamPageContainer } from './../../views/teamMates/team-mates.container';
import { SubscriptionPageContainer } from './../../views/subscription/subscription-billing.container';
import { InsightPageContainer } from './../../views/insightPage/insight.container';
import { ThemePageContainer } from './../../views/theme/theme.container';
import { BusinessOverviewPageContainer } from "./../../views/businessOverview/business-overview.container";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import BarChartIcon from '@mui/icons-material/BarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoIcon from '@mui/icons-material/Info';

export default function AppRoot(props) {
  const { user, account } = props;
  const routes = [
    {
      type: "collapse",
      name: account.name,
      key: account.name,
      // route: "/drafts",
      icon: <MDAvatar src={account.logo} alt={account.name} size="sm" />,
      // component: <DraftsPageContainer />,
      // noCollapse: true,
      collapse: [
        {
          name: account.name,
          key: account.id,
          route: "/",
        },
      ],
    },
    {
      type: "collapse",
      name: "Dashboard",
      key: "dashboard",
      route: "/dashboard",
      icon: <Icon fontSize="medium">image</Icon>,
      component: <SocialOverviewPageContainer />,
      noCollapse: true,
    },
    { type: "title", title: "SOCIAL MANAGER", key: "social-manager" },
    {
      type: "collapse",
      name: "Social Profiles",
      key: "social-profiles",
      route: "/social-profiles",
      icon: <ConnectWithoutContactIcon />,
      component: <ConnectMediaPageContainer />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Schedule",
      key: "schedule",
      route: "/schedule",
      icon: <CalendarMonthIcon />,
      component: <PostPannelPageContainer />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Content Library",
      key: "content-library",
      route: "/post-pannel",
      icon: <VideogameAssetIcon />,
      component: <PostPannelPageContainer />,
      noCollapse: true,
    },
    {
      type: "collapse",
      name: "Insights",
      key: "insights",
      route: "/insight-page",
      icon: <BarChartIcon />,
      component: <InsightPageContainer />,
      noCollapse: true,
    },
    { type: "divider", key: "divider" },
    { type: "title", title: "SETTING", key: "media" },
    {
      type: "collapse",
      name: "Business Settings",
      key: "bussiness-settings",
      // route: "/insight-page",
      icon: <SettingsIcon />,
      // component: <InsightPageContainer />,
      collapse: [
        {
          name: "Business details",
          key: "business-details",
          route: "/preferences",
          icon: <InfoIcon />,
          component: <BusinessOverviewPageContainer  />,
        },
        {
          name: "Branding",
          key: "branding",
          route: "/design",
          component: <ThemePageContainer />,
        },
        {
          name: "Team",
          key: "Team",
          route: "/team",
          icon: <GroupAddIcon />,
          component: <TeamPageContainer />,
        },
      ],
    },
    {
      type: "collapse",
      name: "Billing & Plan",
      key: "billing-plan",
      route: "/billing-plan",
      icon: <SubscriptionsIcon />,
      component: <SubscriptionPageContainer />,
      noCollapse: true,
    },
  ];
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });
  const [open, setOpen] = React.useState(false);

  const location = useLocation();

  const routesWithoutSidenavPageContainer = [
    "login",
    "content-library",
    "content-table",
    "post-pannel",
    "social-profiles",
    "connected-media",
    "dashboard",
    "billing-plan",
    "team",
    "insight-page",
    "drafts",
    "business-account",
    "user-detail",
    "setting",
    "design",
    "preferences",
    "schedule",
    ""
  ];


  React.useEffect(() => {
    const loc = location.pathname.split("/");
    (user.emailVerified == 0 || (!user.accounts || !Array.isArray(user.accounts) || user.accounts.length === 0)) ? setOpen(false) : loc.length == 1 ? setOpen(true) : (routesWithoutSidenavPageContainer.indexOf(loc[1]) > 0 ? setOpen(true) : setOpen(false));
  }, [location]);

  return (
    <Fragment>
      {open ? (
        <SidenavPageContainer brandName="Social Broker" routes={routes} />
       ) : null}
       <LocalizationProvider dateAdapter={AdapterDateFns}>
        <RoutePath />
       </LocalizationProvider>
    </Fragment>
  );
}
