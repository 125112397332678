import { AppRootContainer } from "./../shared/app-root/app-root.container";
import store from "./store";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./../assets/theme";
import themeDark from "./../assets/theme-dark";
// import Sidenav from "./../shared/components/Sidenav";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenConfigurator,
} from "./../context";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
toast.configure()

function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    darkMode,
  } = controller;
  return (
    <Provider store={store}>
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <HashRouter>
          <AppRootContainer />
        </HashRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
