export const GET_FB_TOKEN = "GET_FB_TOKEN";
export const GET_FB_TOKEN_FAILURE = "GET_FB_TOKEN_FAILURE";
export const GET_FB_TOKEN_SUCCESS = "GET_FB_TOKEN_SUCCESS";

export const GET_INSIGHTS = "GET_INSIGHTS";
export const GET_INSIGHTS_FAILURE = "GET_INSIGHTS_FAILURE";
export const GET_INSIGHTS_SUCCESS = "GET_INSIGHTS_SUCCESS";

export const ADD_PROFILE = "ADD_PROFILE";
export const ADD_PROFILE_FAILURE = "ADD_PROFILE_FAILURE";
export const ADD_PROFILE_SUCCESS = "ADD_PROFILE_SUCCESS";

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";

export const DELETE_PROFILE = "DELETE_PROFILE";
export const DELETE_PROFILE_FAILURE = "DELETE_PROFILE_FAILURE";
export const DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS";
